import React, { useEffect, useState } from 'react';
import { sortByFirstName } from '@/utils/format/sort';
import { numValuesOnPage } from '@/config';
import { ICoachesList } from '@/types';
import { BottomLineBlock, Container, TableBlock, PaginationBlock } from './styles';
import Pagination from '@/common/core/Pagination';
import CoachesListHeader from './CoachesListHeader';
import CoachesListItem from './CoachesListItem';
import CoachesEditModal from '../CoachesModal/CoachesListEditModal';
import { CoachUser } from '@fitmate-coach/fitmate-types';

const CoachesList = ({ coachesList, editCoach, editCoachActivity }: ICoachesList) => {
  const [userIdCurrent, setUserIdCurrent] = useState<string>('');
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    if (coachesList.length) {
      const pageCountNew = Math.ceil(coachesList.length / numValuesOnPage);
      setPageCount(pageCountNew);
      setPage(1);
    } else {
      setPageCount(0);
      setPage(0);
    }
  }, [coachesList]);

  const handleEditCoach = (
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    timezone: string,
    timeStarting: number,
    timeFinishing: number,
  ) => {
    editCoach(userId, firstName, lastName, email, phone, timezone, timeStarting, timeFinishing);
    setIsOpenEditModal(false);
  };

  const handleEditCoachActivity = (userId: string, isActive: boolean) => {
    editCoachActivity(userId, isActive);
  };

  const toggleOpenEditModal = () => {
    setIsOpenEditModal(!isOpenEditModal);
  };

  const chooseEditCoach = (userId: string) => {
    setUserIdCurrent(userId);
    toggleOpenEditModal();
  };

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <CoachesListHeader />
      <Container>
        <TableBlock data-testid="table-block">
          <BottomLineBlock />
          {coachesList
            .sort(sortByFirstName)
            .filter(
              (_, index: number) =>
                index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
            )
            .map((item: CoachUser) => (
              <CoachesListItem
                key={item.id}
                item={item}
                chooseEditCoach={chooseEditCoach}
                handleEditCoachActivity={handleEditCoachActivity}
              />
            ))}
        </TableBlock>
        <PaginationBlock data-testid="paggination-block">
          {pageCount > 1 && (
            <Pagination count={pageCount} page={page} onChange={handleChangePage} />
          )}
        </PaginationBlock>
      </Container>
      <CoachesEditModal
        userIdCurrent={userIdCurrent}
        coachesList={coachesList}
        isOpenEditModal={isOpenEditModal}
        toggleOpenEditModal={toggleOpenEditModal}
        handleEditCoach={handleEditCoach}
      />
    </>
  );
};

export default CoachesList;
