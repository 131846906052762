import { YOUTUBE_REGEXP_PARS } from '@/config';

export const youtubeParser = (url: string) => {
  const match = url.match(YOUTUBE_REGEXP_PARS);
  return match && match[1].length === 11 ? match[1] : '';
};

export const formatDiabetes = (diabetes: number | undefined) => {
  switch (diabetes) {
    case 0:
      return 'no diabete';
    case 1:
      return 'pre diabetes';
    case 2:
      return 'type 1 diabetes';
    case 3:
      return 'type 2 diabetes';
    default:
      return 'not applicable';
  }
};
