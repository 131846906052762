import { Container } from '@mui/material';
import { HeaderBlock, TitleBlock, TitleText } from './styles';
import { IPartnerReferrals } from '@/types/partnerReferral';
import PartnerReferralsAdd from '../PartnerReferralsAdd';
import PartnerReferralsList from '../PartnerReferralsList';

const PartnerReferrals = ({
  partnerReferralsList,
  editPartnerReferral,
  createPartnerReferral,
}: IPartnerReferrals) => {
  return (
    <Container maxWidth="lg" style={{ marginTop: 10 }}>
      <HeaderBlock>
        <TitleBlock>
          <TitleText>Partner Referrals</TitleText>
        </TitleBlock>
        <div>
          <PartnerReferralsAdd createPartnerReferral={createPartnerReferral} />
        </div>
      </HeaderBlock>
      <PartnerReferralsList
        partnerReferralsList={partnerReferralsList}
        editPartnerReferral={editPartnerReferral}
      />
    </Container>
  );
};

export default PartnerReferrals;
