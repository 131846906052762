import { alpha, styled } from '@mui/system';
import { Chip, CircularProgress, Switch, Typography } from '@mui/material';
import { AddCircleOutline, Send } from '@mui/icons-material';
import MenuItem from '@/common/core/MenuItem';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
  backgroundColor: '#F2F4F7',
  height: '100%',
  borderLeft: '1px solid #E4E7ED',
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexGrow: 1,
}));

export const InputBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingTop: '6px',
  paddingBottom: '6px',
}));

export const FilesWrapperBlock = styled('div')(({ theme }) => ({
  padding: '10px 0 0 10px',
}));

export const FilesBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxHeight: '400px',
  overflowY: 'auto',
  paddingRight: '10px',
}));

export const SeparateBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'justify',
  width: 135,
  marginLeft: 24,
  marginRight: 12,
  height: '100%',
}));

export const SeparateNoteBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

export const SeparateNoteText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(15, 15, 33, 0.4)',
}));

export const TextareaBlock = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  marginRight: 15,
}));

export const SendBlock = styled('div')(({ theme }) => ({
  position: 'relative',
  width: 80,
  height: 25,
}));

export const PlusIcon = styled(AddCircleOutline)(({ theme }) => ({
  position: 'absolute',
  right: 38,
  bottom: 10,
  fontSize: 20,
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
}));

export const ExpandIcon = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: 67,
  bottom: 8,
  color: 'rgba(15, 15, 33, 0.4)',
  cursor: 'pointer',
}));

export const MessageIcon = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: '0',
  left: '18px',
}));

export const MessageSendIcon = styled(Send)<{ isActive: boolean }>(({ isActive, theme }) => ({
  color: 'rgba(15, 15, 33, 0.2)',
  cursor: 'pointer',
  position: 'absolute',
  top: '0',
  left: '18px',
  ...(isActive && {
    color: theme.palette.secondary.main,
  }),
}));

export const ChipFile = styled(Chip)(({ theme }) => ({
  maxWidth: 236,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  margin: '0 8px 8px 0',
  padding: 0,
}));

export const SubMenu = styled(MenuItem)(({ theme }) => ({
  minWidth: 180,
}));

export const SubMenuIcon = styled('div')(({ theme }) => ({
  fontSize: 20,
}));

export const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#52CDB1',
    '&:hover': {
      backgroundColor: alpha('#52CDB1', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#52CDB1',
  },
}));
