import { StyledRoot } from './styles';
import { IMenuItem } from '@/types';

const MenuItem = ({ className = '', onClick, children, isDisabled = false }: IMenuItem) => {
  return (
    <StyledRoot disabled={isDisabled} className={className} onClick={onClick}>
      {children}
    </StyledRoot>
  );
};

export default MenuItem;
