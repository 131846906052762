import { Typography } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import NextQuestion from '@/common/core/NextQuestion';
import { AddOption, Close, Icon, ItemWrapper, StyledInput, Wrapper } from './styles';
import { IRadioBlock } from '@/types';

const RadioBlock = ({
  id,
  optionHandler,
  deleteHandler,
  answerVariants,
  addOptionHandler,
  blocks,
  questionHandler,
}: IRadioBlock) => {
  return (
    <>
      {answerVariants.length !== 0 &&
        answerVariants.map((variant) => (
          <ItemWrapper key={variant.answerId}>
            <Wrapper>
              <Icon />
              <StyledInput
                value={variant.answer}
                name="question"
                type="text"
                onChange={(e) => {
                  optionHandler(id, 'answer', e.target.value, variant.answerId);
                  optionHandler(id, 'error', false, variant.answerId);
                }}
                error={variant.error}
                helperText={
                  variant.error && variant.answer === '' ? 'This option can’t be empty' : ''
                }
                autoComplete="off"
                fullWidth
              />
              {answerVariants.length > 2 && (
                <Close onClick={() => deleteHandler(variant.answerId)} />
              )}
            </Wrapper>
            <NextQuestion
              id={id}
              nextQuestionId={variant.nextQuestionId}
              answerId={variant.answerId}
              questionHandler={questionHandler}
              optionHandler={optionHandler}
              blocks={blocks}
              isRadio={true}
              error={variant.error}
            />
          </ItemWrapper>
        ))}
      <AddOption onClick={() => addOptionHandler(id)}>
        <AddRounded /> <Typography>Add next option</Typography>
      </AddOption>
    </>
  );
};

export default RadioBlock;
