import { useState, useEffect } from 'react';
import BookIcon from '@mui/icons-material/Book';
import { Done as DoneIcon, DoneAll as DoneAllIcon } from '@mui/icons-material';
import { useChatReference } from '@/hooks/chat/useChatReference';
import { formatTime } from '@/utils/format/datetime';
import {
  DateText,
  FileInfoBlock,
  FileInfoBlockLeft,
  FileInfoBlockRight,
  HandoutContainer,
  HandoutDesc,
  HandoutHeader,
  HandoutIcon,
  HandoutTitle,
  IsReadIcon,
  StyledMessageBlock,
  UnReadIcon,
  UserNameFileText,
} from './styles';
import { EMessageReferenceType, IChatMessagesListItemReference } from '@/types';
import Resources from '../ChatMessageListItemResource';
import Categories from '../ChatMessageListItemCategory';

const ChatMessagesListItemReference = ({
  references,
  userIdFrom,
  currentUserId,
  createdAtSeconds,
  toggleMenu,
  anchorEl,
  isViewed,
  messageBy,
}: IChatMessagesListItemReference) => {
  const [resourceList, setResourceList] = useState<any>([]);
  const [categoriesList, setCategoriesList] = useState<any>([]);
  const [handoutsList, setHandoutsList] = useState<any>([]);
  const { getResourceById, getCategoryById, getHandoutById } = useChatReference();

  useEffect(() => {
    references?.forEach((reference) => {
      if (reference.type === EMessageReferenceType.RESOURCE) {
        getResourceById(reference.referenceId).then((r) => {
          if (r?.exists() && r.data()) {
            setResourceList([
              { ...r.data(), parameters: new Map(Object.entries(r.data()?.parameters || {})) },
            ]);
          }
        });
      }
      if (reference.type === EMessageReferenceType.RESOURCE_CATEGORY) {
        getCategoryById(reference.referenceId).then((r) => {
          if (r?.data()) {
            setCategoriesList([r.data()]);
          }
        });
      }
      if (reference.type === EMessageReferenceType.HANDOUT) {
        getHandoutById(reference.referenceId).then((r) => {
          if (r?.data()) {
            setHandoutsList([r.data()]);
          }
        });
      }
    });

    return () => {
      setResourceList([]);
      setCategoriesList([]);
      setHandoutsList([]);
    };
  }, [references]);

  return (
    <StyledMessageBlock
      isCurrentUser={userIdFrom === currentUserId}
      anchorEl={anchorEl}
      onClick={toggleMenu}
    >
      {resourceList.length !== 0 &&
        resourceList?.map((resource: any) => (
          <Resources key={resource.categoryId} resource={resource} isMessage={true} />
        ))}
      {categoriesList.length !== 0 &&
        categoriesList?.map((category: any) => (
          <div key={category.categoryId} style={{ padding: 10 }}>
            <Categories category={category} />
          </div>
        ))}
      {handoutsList.length !== 0 &&
        handoutsList?.map((handout: any) => (
          <HandoutContainer key={handout.categoryId}>
            <HandoutHeader>
              <HandoutIcon as={BookIcon} />
              <HandoutDesc>Handout</HandoutDesc>
            </HandoutHeader>
            <HandoutTitle>{handout.title}</HandoutTitle>
          </HandoutContainer>
        ))}
      {(resourceList.length !== 0 || categoriesList.length !== 0 || handoutsList.length !== 0) && (
        <FileInfoBlock>
          <FileInfoBlockLeft>
            <UserNameFileText
              isCurrentUser={userIdFrom === currentUserId}
              hasResource={!!resourceList.length}
            >
              {messageBy}
            </UserNameFileText>
          </FileInfoBlockLeft>
          <FileInfoBlockRight>
            <DateText isCurrentUser={userIdFrom === currentUserId}>
              {formatTime(createdAtSeconds * 1000)}
            </DateText>
            {userIdFrom !== currentUserId && (
              <>{isViewed ? <IsReadIcon as={DoneAllIcon} /> : <UnReadIcon as={DoneIcon} />}</>
            )}
          </FileInfoBlockRight>
        </FileInfoBlock>
      )}
    </StyledMessageBlock>
  );
};

export default ChatMessagesListItemReference;
