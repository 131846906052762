import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { fetchOnboardingSurveyByCustomer } from '@/store/customers/onboardingSurveySlice';
import { ISurveyExistent } from '@/types';
import ChatFAQLoader from '../ChatContainer/ChatFAQ/ChatFAQLoader';
import ChatSurveysHistoryItem from '../ChatContainer/ChatSurveys/ChatSurveysHistoryItem';

const OnboardinSurveyContainer = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { loading, surveys } = useAppSelector((state) => state.onboardingSurvey);

  useEffect(() => {
    dispatch(fetchOnboardingSurveyByCustomer({ userId: params.id, surveyId: params.surveyId }));
  }, []);

  if (loading) {
    return <ChatFAQLoader />;
  }

  return (
    <div className="container mx-auto mt-4">
      <Card>
        <CardHeader title="Onboarding Survey Answers" />
        <CardContent className="overflow-auto h-screen md:max-h-[90vh]">
          {surveys?.map((survey: ISurveyExistent) => (
            <ChatSurveysHistoryItem
              key={survey.id}
              survey={survey}
              surveyIdCurrent={survey.id}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              handleSurveyIdCurrent={() => {}}
            />
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default OnboardinSurveyContainer;
