import { Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { buildTextForBreadcrumb } from '@/utils/data/surveys';
import { BreadcrumbSurvey, LeftColumn, StyledBreadcrumbs } from './styles';
import { ISurveyBreadCrumbs } from '@/types';

const SurveyBreadCrumbs = ({ handleCancel, type }: ISurveyBreadCrumbs) => {
  return (
    <LeftColumn>
      <StyledBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {[
          <BreadcrumbSurvey key={1} onClick={handleCancel}>
            Surveys
          </BreadcrumbSurvey>,
          <Typography key={2}>{buildTextForBreadcrumb(type)}</Typography>,
        ]}
      </StyledBreadcrumbs>
    </LeftColumn>
  );
};

export default SurveyBreadCrumbs;
