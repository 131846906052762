import { FormControl, InputLabel } from '@mui/material';
import { EFeatureSelectedType } from '@/types';
import { useFormContext } from 'react-hook-form';
import SelectOptionsWrapper from '@/common/core/SelectOptionsWrapper';
import { YesNoList } from '@/utils/data/question';
import { HTTP_HTTPS_REGEXP } from '@/config';
import Wrapper from '../Wrapper';
import { IVideoCall } from '@/types/videoCall';
import { SelectBlock, StyledSelect } from '../styles';
import ChatFAQLoader from '@/components/Coach/ChatContainer/ChatFAQ/ChatFAQLoader';
import { useAppSelector } from '@/hooks';
import { CurrencyList } from '@/utils/format/billings';
import SelectFormField from '@/common/core/FormElements/SelectFormField';
import InputFormField from '@/common/core/FormElements/InputFormField';

const ReferralInfo = ({ isCallCompleted, setCallCompleted }: IVideoCall) => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { control, getValues, setValue } = useFormContext();

  if (!customer) {
    return <ChatFAQLoader />;
  }

  return (
    <Wrapper blockTitle="Referral info">
      <SelectBlock>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Referral request sent</InputLabel>
          <StyledSelect
            // variant="standard"
            native
            autoWidth
            value={isCallCompleted ? EFeatureSelectedType.enabled : EFeatureSelectedType.disabled}
            onChange={({ target }) => {
              setCallCompleted(target.value === EFeatureSelectedType.enabled);
            }}
            label="Completed intro call"
          >
            <SelectOptionsWrapper items={YesNoList} />
          </StyledSelect>
        </FormControl>
      </SelectBlock>

      <InputFormField
        name="paymentReferralUrl"
        label="Referral link"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Please enter their referral URL',
          },
          validate: {
            isValidUrl: (url: string) =>
              HTTP_HTTPS_REGEXP.test(url) || 'Please enter their valid URL',
          },
        }}
      />

      <SelectFormField
        name="paymentReferralCurrency"
        label="Currency for refer a friend *"
        control={control}
        optionList={CurrencyList}
        rules={{
          required: {
            value: true,
            message: 'Please select an option',
          },
        }}
      />
    </Wrapper>
  );
};

export default ReferralInfo;
