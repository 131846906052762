import { IChildren } from '@/types';
import { Content, Wrapper } from './styles';

const Signin = ({ children }: IChildren) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Signin;
