import { getNumberDays } from '@/utils/format/datetime';
import { MessageContainer, Text, Date } from './styles';
import { IAnswerItem } from '@/types';

const AnswerItem = ({ text, createDate = '' }: IAnswerItem) => {
  const date = getNumberDays(createDate);

  return (
    <MessageContainer>
      <Text>{text}</Text>
      {date && <Date>{date}</Date>}
    </MessageContainer>
  );
};

export default AnswerItem;
