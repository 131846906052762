import React, { useState } from 'react';
import { RadioGroup } from '@mui/material';
import ShareCategory from '../ChatResourceCategories';
import { useAdminResourceCategories } from '@/hooks/admin/useAdminResourceCategories';
import { useChatCreateMessage } from '@/hooks/chat/useChatCreateMessage';
import {
  AppSideBar,
  ButtonGroup,
  CancelBtn,
  CategoriesWrapper,
  CreateBtn,
  HeaderBlock,
  TitleBlock,
  TitleText,
} from './styles';
import { IMessageReference, EMessageReferenceType, IShareCategories } from '@/types';

const ShareCategories = ({ handleOpenCategoryBlock }: IShareCategories) => {
  const [references, setReferences] = useState<IMessageReference[]>([]);
  const [activeCategoryID, setActiveCategoryID] = useState<string>('');
  const { categoriesList } = useAdminResourceCategories();
  const createMessage = useChatCreateMessage();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveCategoryID(event.target.value);
    setReferences([
      {
        type: EMessageReferenceType.RESOURCE_CATEGORY,
        referenceId: event.target.value,
      },
    ]);
  };

  const handleCreateMessage = async () => {
    await createMessage('', [], references);
    handleOpenCategoryBlock();
  };

  return (
    <AppSideBar>
      <HeaderBlock>
        <TitleBlock>
          <TitleText>Share category</TitleText>
        </TitleBlock>
      </HeaderBlock>
      <CategoriesWrapper variant="standard">
        <RadioGroup aria-label="categories" name="categories" onChange={handleChange}>
          {categoriesList.map((category) => (
            <ShareCategory category={category} key={category.id} />
          ))}
        </RadioGroup>
      </CategoriesWrapper>
      <ButtonGroup>
        <CancelBtn variant="outlined" onClick={handleOpenCategoryBlock}>
          Cancel
        </CancelBtn>
        <CreateBtn onClick={handleCreateMessage} disabled={activeCategoryID === ''}>
          Share category
        </CreateBtn>
      </ButtonGroup>
    </AppSideBar>
  );
};

export default ShareCategories;
