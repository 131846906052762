import { useCallback } from 'react';
import { db, storage } from '@/utils/firebase/firebaseInit';
import { chatRefresh } from '@/store/chat';
import { useAppDispatch } from '@/hooks';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { toast } from 'sonner';

export const useChatMessageDelete = () => {
  const dispatch = useAppDispatch();
  return useCallback(async (messageId: string) => {
    const docRef = doc(db, 'messages', messageId);
    const message = await getDoc(docRef);
    const data = message.exists() ? message.data() : null;
    if (data?.userIdFrom) {
      if (data.files) {
        try {
          await Promise.all(
            data.files.map(async (file: any) => {
              if (!file.url.includes('amazonaws')) {
                const imageRef = ref(storage, file.url);
                return deleteObject(imageRef);
              }
            }),
          );
        } catch (e) {
          toast.error(`Error deleting files for message ${messageId}`);
        }
      }
      await updateDoc(docRef, { isDeleted: true });
      dispatch(chatRefresh(data.userId));
    }
  }, []);
};
