import { EChatMessageAnalysisType, IChatMessage } from '@/types';

export function cleanMessageAnalysis(message: IChatMessage) {
  const { type } = message;
  if (Array.isArray(type)) {
    // if type is an array, return each value with regexMatchType as an array
    return type.map((t) => regexMatchType(t));
  } else if (typeof type === 'string') {
    // if type has any commas, split the string into an array and return each value with regexMatchType as an array
    if (type.includes(',')) {
      return type.split(',').map((t) => regexMatchType(t));
    }
    // if type is a string, return regexMatchType as a string
    return regexMatchType(type);
  }
  return EChatMessageAnalysisType.DEFAULT;
}

export function regexMatchType(type: string) {
  // if type contains the word 'setback' return 'setback'
  // else if type contains the word 'question' return 'question'
  // else if type contains the word 'important' return 'important'
  if (type.includes('setback')) return EChatMessageAnalysisType.SETBACK;
  if (type.includes('question')) return EChatMessageAnalysisType.QUESTION;
  if (type.includes('important')) return EChatMessageAnalysisType.IMPORTANT_INFO;
  if (type.includes('cancel')) return EChatMessageAnalysisType.CANCEL;
  return EChatMessageAnalysisType.DEFAULT;
}
