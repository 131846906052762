import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { Checkbox } from '@mui/material';
import cn from 'mcn';
import { IWeekDayOptionWithDate } from '@/config';
import { ProgramActionForm } from '@/types';
import { useEffect } from 'react';
import { useAppSelector } from '@/hooks';

const ProgramActionDays = (props: { form: UseFormReturn<ProgramActionForm, any> }) => {
  const { currentWeekPlan } = useAppSelector((state) => state.weekProgram);
  const { startDate, endDate } = currentWeekPlan;
  const daysPerWeek = useWatch({ control: props.form.control, name: 'draft.daysPerWeek' });

  const getWeekDays = (startDate: Date, endDate: Date): IWeekDayOptionWithDate[] => {
    const weekDays: IWeekDayOptionWithDate[] = [];
    const currentDate = new Date(startDate);
    let i = 1;

    while (currentDate <= endDate) {
      weekDays.push({
        value: currentDate.toISOString().split('T')[0],
        dayNumber: currentDate.getDate(),
        shortDay: currentDate.toLocaleDateString('en-US', { weekday: 'short' }),
        shortMonth: currentDate.toLocaleDateString('en-US', { month: 'short' }),
      });
      currentDate.setDate(currentDate.getDate() + 1);
      i++;
    }
    return weekDays;
  };

  const weekDays: IWeekDayOptionWithDate[] = getWeekDays(startDate.toDate(), endDate.toDate());

  if (!daysPerWeek) {
    return null;
  }

  return (
    <div>
      <div
        className={`${
          props.form.formState.errors.draft?.days ? 'border-[#FF6D7D]' : 'border-dark-2'
        } p-5 bg-[rgba(242,244,247,0.70)] border rounded-lg gap-3 grid [grid-template-columns:max-content_1fr] overflow-x-auto`}
      >
        <Controller
          control={props.form.control}
          name="draft.scheduledDays"
          defaultValue={[]}
          rules={{ required: false }}
          render={({ field }) => (
            <div className="flex gap-2">
              {weekDays.map((item) => {
                const checked = (field.value ?? []).includes(item.value);
                const onChange = () => {
                  if (!checked) {
                    return field.onChange([...(field.value ?? []), item.value]);
                  }
                  return field.onChange([...(field.value ?? [])].filter((x) => x !== item.value));
                };
                return (
                  <div className="flex flex-col items-center" key={item.value}>
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      className={cn('!m-0 !p-0', [checked, '!text-primary', '!text-dark/20'])}
                      onChange={onChange}
                      checked={checked}
                    />
                    <p className="text-xs font-semibold text-dark/40 text-center">
                      {item.shortDay}
                    </p>
                    <p className="text-xs font-semibold text-dark/40 text-center">
                      {item.dayNumber}
                    </p>
                    <p className="text-xs font-semibold text-dark/40 text-center">
                      {item.shortMonth}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        />
      </div>
      {!!props.form.formState.errors.draft?.days && (
        <p className="text-[#FF6D7D]">{props.form.formState.errors.draft?.days.message}</p>
      )}
    </div>
  );
};

export default ProgramActionDays;
