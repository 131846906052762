import { Dispatch, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormControl, InputLabel, Select } from '@mui/material';
import { daysPerWeekOptions, weekDaysOptions } from '@/config';
import Input from '@/common/core/Input';
import { ProgramActionForm, TFocusArea } from '@/types';
import { Button, CompactSelect } from '../../ChatProgramComponents';
import GoalProgress from '../ChatProgramSmartGoalProgress';
import ProgramActionDays from '../ChatProgramActionDays';
import { toast } from 'sonner';

const statuses = ['new', 'existing', 'past', 'complete'];

export default function ChatProgramSmartGoalForm(props: {
  onClose: () => void;
  plan: Pick<TFocusArea, 'title' | 'notes'>;
  action?: Partial<ProgramActionForm>;
  setActions?: Dispatch<SetStateAction<ProgramActionForm | null>>;
  onSave?: (action: ProgramActionForm) => any;
  isNew?: boolean;
}) {
  const action = props.action?.draft ??
    props.action?.published ?? {
      goal: '',
      description: '',
      strategy: '',
      isNew: true,
      isDeleted: false,
      days: [],
      daysPerWeek: 0,
    };

  const form = useForm<ProgramActionForm>({
    defaultValues: {
      ...props.action,
      draft: {
        status: 'new' as any,
        ...action,
      },
    },
  });

  const onResetCompleted = () => {
    toast.success('🙌Goal progress has been reset successfully');
    props.onClose();
  };

  return (
    <form
      className="space-y-5 text-left"
      onSubmit={form.handleSubmit((programActionInput) => {
        if (!form.formState.isDirty) return;
        if (props.onSave) {
          props.onSave(programActionInput);
          if (props.setActions) props.setActions(null);
        }
        props.onClose();
      })}
    >
      <div className="space-y-1">
        <div className="flex justify-between items-top gap-2">
          <h5 className="text-dark font-medium text-left pt-1.5">{props.plan.title}</h5>
          <div className="flex items-baseline">
            <label className="text-dark/40 text-sm pr-3 whitespace-nowrap">Set as</label>
            <Controller
              control={form.control}
              name="draft.status"
              render={({ field }) => (
                <CompactSelect
                  value={field.value}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  options={statuses.map((value: any) => ({ value }))}
                />
              )}
            />
          </div>
        </div>

        <p className="text-sm">Please add the details that you’ve agreed with your client.</p>
      </div>
      <div className="flex flex-col">
        <label className="text-left text-sm font-semibold pb-3">Specific goal name</label>
        <Controller
          control={form.control}
          name="draft.goal"
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              multiline={true}
              className="w-full !m-0 !min-h-[44px]"
              placeholder="Add Name"
              error={!!form.formState.errors.draft?.goal}
              {...field}
            />
          )}
        />

        {form.formState.errors.draft?.goal && (
          <p className="text-[#FF6D7D]">Please enter the goal</p>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-left text-sm font-semibold pb-3">Strategy</label>
        <Controller
          control={form.control}
          name="draft.strategy"
          render={({ field }) => (
            <Input
              multiline={true}
              className="w-full !m-0 !min-h-[44px]"
              error={!!form.formState.errors.strategy}
              placeholder="Add Strategy"
              {...form.register('draft.strategy')}
              {...field}
            />
          )}
        />

        {form.formState.errors.draft?.strategy && (
          <p className="text-[#FF6D7D]">Please enter the strategy</p>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-left text-sm font-semibold pb-3">Assignment</label>
        <Controller
          control={form.control}
          name="draft.description"
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              multiline={true}
              className="w-full !m-0 !min-h-[44px]"
              placeholder="Add Assignment"
              error={!!form.formState.errors.draft?.description}
              {...field}
            />
          )}
        />

        {form.formState.errors.draft?.description && (
          <p className="text-[#FF6D7D]">Please enter the assignment</p>
        )}
      </div>

      {props.action?.id && (
        <GoalProgress actionId={props.action.id} onResetCompleted={onResetCompleted} />
      )}

      <div className="flex flex-col">
        <label className="text-left text-sm font-semibold pb-3">When</label>

        <div className="grid grid-cols-1 gap-3">
          <Controller
            control={form.control}
            name="draft.daysPerWeek"
            rules={{ required: true, validate: (x) => typeof x === 'number' }}
            render={({ field }) => (
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Times per week</InputLabel>
                <Select
                  // variant="standard"
                  native
                  value={field.value}
                  onChange={(e: any) => field.onChange(Number(e.target.value))}
                  label="Times per week"
                  className="!w-full"
                  error={!!form.formState.errors.draft?.daysPerWeek}
                >
                  <option aria-label="None" value="" key={0}></option>
                  <option value="0" key={10}>
                    0
                  </option>
                  {daysPerWeekOptions.map((item) => (
                    <option value={item.toString()} key={item}>
                      {item}
                    </option>
                  ))}
                </Select>
                {form.formState.errors.draft?.daysPerWeek && (
                  <p className="text-[#FF6D7D]">Please select a number</p>
                )}
              </FormControl>
            )}
          />
        </div>
      </div>
      <ProgramActionDays form={form} />
      <div className="flex gap-3 items-end">
        <div className="w-full" />
        <Button
          size="lg"
          variant="outlined"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            props.onClose();
          }}
        >
          Cancel
        </Button>

        <Button size="lg" type="submit" disabled={!props.onSave || !form.formState.isDirty}>
          Save
        </Button>
      </div>
    </form>
  );
}
