import axios from 'axios';
import { SIMILARITY_API_QUERY_PATH } from '@/config';
import { ISimilarity } from '@/types';

export class SimilarityService {
  static async searchSimilarity(query: string, userId: string): Promise<ISimilarity> {
    const headers = {
      'search-ai-api-auth': process.env.REACT_APP_SIMILARITY_API_TOKEN || '',
    };

    const params = {
      Retriever: { user_id: userId },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_SIMILARITY_API_URL}${SIMILARITY_API_QUERY_PATH}`,
      { query, params },
      {
        timeout: 6000,
        headers,
      },
    );

    return response.data;
  }
}
