import { styled } from '@mui/system';
import Input from '@/common/core/Input';
import { FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';

export const AppWrapper = styled('div')({});

export const FormContainer = styled('div')({
  flexBasis: '100%',
});

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  maxWidth: 600,
  '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
    transform: 'translate(14px, 17px) scale(1)',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
});

export const AddLink = styled(Input)({
  fontSize: 14,
  color: 'rgba(0, 0, 0, 0.6)',
  '& .MuiFormHelperText-root': {},
});

export const AddTitle = styled(Input)({
  fontWeight: 600,
  fontSize: 24,
  color: 'black',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-input::placeholder': {
    color: 'rgba(15, 15, 33, 0.2)',
  },
});

export const AddDesc = styled(TextField)({
  fontSize: 14,
  padding: 14,
  color: 'black',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-input::placeholder': {
    color: 'rgba(15, 15, 33, 0.4)',
  },
  '& .MuiInput-underline::before': {
    display: 'none',
  },
  '& .MuiInput-underline::after': {
    display: 'none',
  },
});

export const InputGroupWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
  marginTop: 38,
  marginBottom: 10,
});

export const InputGroup = styled('div')({
  display: 'flex',
  padding: 24,
  backgroundColor: '#F9FAFC',
  borderRadius: 12,
  flexWrap: 'wrap',
  gap: 20,
  '& .MuiFormControl-root:last-child': {
    flexBasis: '100%',
    maxWidth: '100%',
  },
  '& .MuiFormControl-root': {
    backgroundColor: 'transparent',
  },
  '& .MuiInputBase-input': {
    backgroundColor: 'transparent',
  },
  '& .MuiFormControl-marginNormal': {
    marginTop: 0,
    marginBottom: 0,
    height: 'revert',
    // maxWidth: 264,
    width: '100%',
  },
  '& .MuiOutlinedInput-input': {
    height: 'revert',
    padding: '10px 8px',
    borderRadius: 8,
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
    transform: 'translate(14px, 12px) scale(1) !important',
    fontSize: 14,
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.85) !important',
  },
});

export const DifficultySelect = styled(FormControl)({
  width: '100%',
  // maxWidth: 264,
  '& .MuiFormLabel-root': {
    fontSize: 14,
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 13px) scale(1)',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.85)',
  },
});

export const DefaultOptionValue = styled('option')({
  display: 'none',
});
