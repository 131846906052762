import React, { useState, useEffect } from 'react';
import Input from '@/common/core/Input';
import Modal from '@/common/core/Modal';
import { ITagEditModal } from '@/types';
import { Box } from '@mui/material';

const TagEditModal = ({
  isOpenAddModal,
  toggleOpenAddModal,
  handleEditTag,
  currentTag,
}: ITagEditModal) => {
  const [tag, setTag] = useState<string>('');
  const [tagError, setTagError] = useState<string>('');

  useEffect(() => {
    if (isOpenAddModal) {
      setTag(currentTag);
      setTagError('');
    }
  }, [isOpenAddModal]);

  const handleSubmit = () => {
    setTagError(tag ? '' : 'Please fill the tag');
    if (tag) {
      handleEditTag(tag);
    }
  };

  return (
    <Modal
      widthMax={340}
      title="Rename tag"
      open={isOpenAddModal}
      handleClose={toggleOpenAddModal}
      handleSubmit={handleSubmit}
      textBtnClose="Cancel"
      textBtnSubmit="Save"
    >
      <div>
        <div>
          <Box component="div" sx={{ paddingBottom: 2 }}>
            <Input
              error={tagError ? true : false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTag(e.target.value)}
              value={tag}
              id="tag"
              label="Name of tag"
              helperText={tagError}
              type="text"
              fullWidth
            />
          </Box>
        </div>
      </div>
    </Modal>
  );
};

export default TagEditModal;
