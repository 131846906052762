import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '@/utils/firebase/firebaseInit';
import { toast } from 'sonner';

export const useAuthLogout = () => {
  const navigate = useNavigate();

  return async () => {
    try {
      signOut(auth).then(() => {
        navigate('/login');
        toast.success('You logged out.');
      });
    } catch (error) {
      console.error('error', error);
    }
  };
};
