import { useAdminCoaches } from '@/hooks/admin/useAdminCoaches';
import Coaches from './Coaches';

const CoachesContainer = () => {
  const { coachesList, createCoach, editCoach, editCoachActivity } = useAdminCoaches();

  return (
    <Coaches
      coachesList={coachesList}
      editCoach={editCoach}
      editCoachActivity={editCoachActivity}
      createCoach={createCoach}
    />
  );
};

export default CoachesContainer;
