import { FormProvider, useForm } from 'react-hook-form';
import { CancelBtn, CreateBtn } from '../styles';
import { useUserProfile } from '@/hooks/profile/useUserProfile';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { fetchCustomerById } from '@/store/customers/customerInfosSlice';
import { Box, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';

function AgreedActionsTakenForm({
  isOpen,
  openEditMode,
}: {
  isOpen: boolean;
  openEditMode: (value: boolean) => void;
}) {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const dispatch = useAppDispatch();
  const form = useForm({
    defaultValues: {
      agreedActionsTaken: customer?.agreedActionsTaken,
    },
  });

  const { editUserProfileAsObject } = useUserProfile(customer!.id!);

  async function onSubmit({ agreedActionsTaken }: { agreedActionsTaken?: string }) {
    await editUserProfileAsObject({ agreedActionsTaken });
    dispatch(fetchCustomerById(customer!.id));
    openEditMode(false);
  }

  return (
    <FormProvider {...form}>
      <form className="space-y-5 text-left flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        {!isOpen ? (
          <Box component="div" sx={{ whiteSpace: 'pre-line' }}>
            {customer?.agreedActionsTaken || 'No agreed actions taken'}
          </Box>
        ) : (
          <FormControl>
            <TextField multiline rows={4} {...form.register('agreedActionsTaken')} />
          </FormControl>
        )}
        {isOpen && (
          <div>
            <CreateBtn variant="contained" type="submit">
              Save
            </CreateBtn>
            <CancelBtn
              variant="outlined"
              onClick={() => {
                form.setValue('agreedActionsTaken', customer?.agreedActionsTaken, {
                  shouldDirty: false,
                });
                openEditMode(false);
              }}
            >
              Cancel
            </CancelBtn>
          </div>
        )}
      </form>
    </FormProvider>
  );
}

export default AgreedActionsTakenForm;
