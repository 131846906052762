import { InputAdornment } from '@mui/material';
import Search from '@mui/icons-material/Search';

import { clientSearchQuery } from '@/store/client-search/clientSearchSlice';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Container, SearchInput, SearchInputIcon, SearchInputIconButton } from './styles';
import { useEffect } from 'react';

const ChatThreadsSearch = () => {
  const value = useAppSelector((x) => x.clientSearch.query);
  const dispatch = useAppDispatch();

  // Initialised results data by searching with empty string
  useEffect(() => void dispatch(clientSearchQuery({ query: '' })), []);

  return (
    <Container>
      <SearchInput
        autoComplete="off"
        id="outlined-adornment-weight"
        placeholder="Search clients..."
        value={value}
        onChange={(e) => dispatch(clientSearchQuery({ query: e.target.value }))}
        fullWidth={true}
        startAdornment={
          <InputAdornment position="start">
            <SearchInputIconButton edge="start" size="large">
              <SearchInputIcon as={Search} />
            </SearchInputIconButton>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
      />
    </Container>
  );
};

export default ChatThreadsSearch;
