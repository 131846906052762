import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useFilesUpload } from '@/hooks/useFilesUpload';
import { FileInput, Img, Root, Span, ThumbArea } from './styles';
import Button from '../Button';
import { IImageUploader } from '@/types';

const ImageUploader = ({
  caption,
  className = null,
  imageUrl = '',
  handleUploaded,
  destination,
  sx = {},
}: IImageUploader) => {
  const fileRef = useRef<any>(null);
  const { uploadFiles, filesList, emptyFilesList, removeFile } = useFilesUpload();
  const [isImageUploading, setImageUploading] = useState<boolean>(false);

  useEffect(() => {
    if (filesList.length) {
      setImageUploading(false);
      handleUploaded(filesList[0].url);
      if (imageUrl) {
        removeFile(imageUrl);
      }
    }
  }, [filesList]);

  const handleClickFileUploader = () => {
    fileRef.current.click();
  };

  const chooseFileInUploader = (e: any) => {
    emptyFilesList();
    setImageUploading(true);
    uploadFiles([e.target.files[0]], destination);
    fileRef.current.value = '';
  };

  return (
    <Root className={className} sx={sx}>
      <ThumbArea isImageUploading={isImageUploading}>
        {isImageUploading ? (
          <CircularProgress size={50} />
        ) : imageUrl.length === 0 ? (
          <>
            <Span> {caption}</Span>
            <Button onClick={handleClickFileUploader}>Browse image</Button>
          </>
        ) : (
          <Img src={imageUrl} onClick={handleClickFileUploader} alt="File Uploader" />
        )}

        <FileInput
          type="file"
          id="file"
          ref={fileRef}
          onChange={chooseFileInUploader}
          accept="image/*"
        />
      </ThumbArea>
    </Root>
  );
};

export default ImageUploader;
