import { styled } from '@mui/system';
import { Checkbox, Typography } from '@mui/material';
import Input from '@/common/core/Input';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: 20,
  flexWrap: 'wrap',
});

export const OptionBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginRight: 15,
});

export const OptionCheckbox = styled(Checkbox)({
  color: 'rgba(15, 15, 33, 0.2)',
  padding: 0,
});

export const OptionText = styled(Typography)<{ isTextValidation: boolean }>(
  ({ isTextValidation }) => ({
    fontSize: 16,
    fontWeight: 400,
    color: 'rgba(16, 16, 28, 0.6)',
    marginLeft: 11,
    padding: 0,
    ...(isTextValidation && {
      whiteSpace: 'nowrap',
    }),
  }),
);

export const OptionSelectBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 8,
  cursor: 'pointer',
});

export const OptionSelectText = styled('span')({
  fontSize: 16,
  fontWeight: 400,
  whiteSpace: 'nowrap',
});

export const OptionSelectIcon = styled('span')({
  marginLeft: 5,
  color: 'rgba(0, 0, 0, 0.54)',
});

export const SubMenu = styled('span')({
  fontSize: 14,
  fontWeight: 400,
  minWidth: 190,
  height: 37,
});

export const TextValidationBlock = styled('div')({});

export const TextValidationBlockInput = styled(Input)({
  marginBottom: 0,
  '& .MuiInputBase-input': {
    height: '27px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

export const Break = styled('div')({
  flexBasis: '100%',
  height: 0,
});
