import React, { useState, useEffect } from 'react';
import SummaryHover from './SummaryHover';
import { StyledAccordion, StyledAccordionDetails, SubCategoryList } from './styles';
import { IResourceCategory, IResourceHandleCategory } from '@/types';
import TagItem from './TagItem';

export const ResourcesCategory = ({
  category,
  handleCategorySelected,
  handleTagsSelected,
  currentCategoryId,
  level,
}: IResourceHandleCategory) => {
  const MAX_ORDER_VALUE = 9999;
  const [children, setChildren] = useState<IResourceCategory[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [picture, setPicture] = useState<string>('');
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [order, setOrder] = useState<number>(MAX_ORDER_VALUE);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setChildren(
      category.children.sort((curr: IResourceCategory, next: IResourceCategory) => {
        return curr?.order - next?.order;
      }),
    );
    setTags(category.tags);
    setName(category.name);
    setPicture(category.picture);
    setOrder(category.order);
  }, [category]);

  useEffect(() => {
    if (!currentCategoryId) {
      setIsSelected(false);
    } else {
      const isCurrent: boolean = currentCategoryId === category.id;
      if (isCurrent) {
        setIsSelected(true);
        setExpanded(true);
      } else {
        setIsSelected(false);
      }
    }
  }, [currentCategoryId]);

  const getCurrentCategory = (): IResourceCategory => {
    return {
      id: category.id,
      name: name,
      tags: tags,
      children: children,
      picture: picture,
      order: order,
    };
  };

  const handleChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
    handleCategorySelected(getCurrentCategory());
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let copy = [...selectedTags, event.target.value];
    if (selectedTags.includes(event.target.value)) {
      copy = copy.filter((tag) => tag !== event.target.value);
    }
    setSelectedTags(copy);
    handleTagsSelected([...copy]);
  };

  return (
    <StyledAccordion elevation={0} square expanded={expanded} onChange={handleChange}>
      <SummaryHover categoryName={name} isSelected={isSelected} level={level} />
      {(children.length || tags) && (
        <StyledAccordionDetails>
          {children.map((child) => (
            <ResourcesCategory
              category={child}
              level={level + 1}
              handleTagsSelected={handleTagsSelected}
              handleCategorySelected={handleCategorySelected}
              currentCategoryId={currentCategoryId}
              key={child.id}
            />
          ))}
          {tags && (
            <SubCategoryList>
              {tags.map((tag, index) => (
                <TagItem
                  index={index}
                  tag={tag}
                  handleCheckboxChange={handleCheckboxChange}
                  key={index}
                />
              ))}
            </SubCategoryList>
          )}
        </StyledAccordionDetails>
      )}
    </StyledAccordion>
  );
};
