import { Arrow, CategoryName, StyledAccordionSummary } from './styles';
import { ISummaryResourceCategoryHover } from '@/types';

const SummaryHover = ({
  categoryName,
  isSelected = false,
  level = 0,
}: ISummaryResourceCategoryHover) => {
  return (
    <StyledAccordionSummary expandIcon={<Arrow />} style={{ paddingLeft: 16 * level }}>
      <CategoryName isSelected={isSelected}>{categoryName}</CategoryName>
    </StyledAccordionSummary>
  );
};

export default SummaryHover;
