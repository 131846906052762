import { Typography } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { AddOption, Close, Icon, StyledInput, Wrapper } from './styles';
import { ICheckBoxBlock } from '@/types';

const CheckBoxBlock = ({
  id,
  optionHandler,
  deleteHandler,
  answerVariants,
  addOptionHandler,
}: ICheckBoxBlock) => {
  return (
    <>
      {answerVariants.length !== 0 &&
        answerVariants.map((variant) => (
          <Wrapper key={variant.answerId}>
            <Icon />
            <StyledInput
              error={variant.error && variant.answer === ''}
              helperText={
                variant.error && variant.answer === '' ? 'This option can’t be empty' : ''
              }
              autoComplete="off"
              onChange={(e) => {
                optionHandler(id, 'answer', e.target.value, variant.answerId);
                optionHandler(id, 'error', false, variant.answerId);
              }}
              value={variant.answer}
              name="question"
              type="text"
              fullWidth
              required
            />
            {answerVariants.length > 2 && <Close onClick={() => deleteHandler(variant.answerId)} />}
          </Wrapper>
        ))}
      <AddOption onClick={() => addOptionHandler(id)}>
        <AddRounded /> <Typography>Add next option</Typography>
      </AddOption>
    </>
  );
};

export default CheckBoxBlock;
