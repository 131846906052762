import { useEffect, useState } from 'react';
import { DocumentData } from 'firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';
import { ISurveyExistent, ISurveyTemplate } from '@/types';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

export const useChatSurveys = (userId: string) => {
  const [surveysTemplateList, setSurveysTemplateList] = useState<ISurveyTemplate[]>([]);
  const [surveysList, setSurveysList] = useState<ISurveyExistent[]>([]);
  const [onboardingSurveys, setOnboardingSurveys] = useState<ISurveyExistent[]>([]);

  useEffect(() => {
    const convertFromDocumentData = (
      data: DocumentData | undefined,
      id: string,
    ): ISurveyTemplate => {
      return {
        id: id,
        title: data?.title,
        questions: data?.questions,
        /*        startMessage: data?.startMessage,
        endMessage: data?.endMessage,*/
        createdAt: data?.createdAt?.seconds,
        actionId: data?.actionId,
        onboardingType: data?.onboardingType,
      };
    };

    const q = query(collection(db, 'survey_templates'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setSurveysTemplateList(
        snapshot.docs.map((rawSurvey) => convertFromDocumentData(rawSurvey.data(), rawSurvey.id)),
      );
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let unsubscribe = () => {};
    if (userId) {
      const q = query(collection(db, 'surveys'), where('userId', '==', userId));
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const results = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setSurveysList(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          results.toSorted(),
        );
      });
    }
    return () => unsubscribe();
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const q = query(
        collection(db, 'users', userId, 'onboarding_surveys'),
        where('status', '==', 'completed'),
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const result: any[] = [];
        querySnapshot.forEach((doc) => {
          result.push({ ...doc.data(), id: doc.id });
        });
        setOnboardingSurveys(
          result.sort(
            (a: ISurveyExistent, b: ISurveyExistent) =>
              b?.createdAt?.seconds - a?.createdAt?.seconds,
          ),
        );
      });

      return () => unsubscribe();
    }
  }, [userId]);

  return {
    surveysTemplateList,
    surveysList,
    onboardingSurveys,
  };
};
