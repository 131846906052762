import { useState, useEffect, useCallback } from 'react';
import { db } from '@/utils/firebase/firebaseInit';
import { IResourceCategory } from '@/types';
import { getDocs, collection, updateDoc, doc, deleteDoc, addDoc } from 'firebase/firestore';

export const useAdminResourceCategories = () => {
  const [categoriesList, setCategoriesList] = useState<IResourceCategory[]>([]);
  const MAX_ORDER_VALUE = 9999;

  useEffect(() => {
    const refCategories = getDocs(collection(db, 'resources_categories'));
    refCategories.then((snapshot) => {
      const findChildren = (parentId: string): IResourceCategory[] => {
        const result: IResourceCategory[] = [];
        snapshot.docs
          .filter((data) => data.data().categoryId === parentId)
          .forEach((rawCategory) => {
            const children: IResourceCategory[] = findChildren(rawCategory.id);
            result.push({
              id: rawCategory.id,
              name: rawCategory.data().name,
              children,
              picture: rawCategory.data().picture,
              tags: rawCategory.data().tags,
              order: rawCategory.data()?.order || MAX_ORDER_VALUE,
            });
          });
        return result;
      };
      setCategoriesList(findChildren(''));
    });
  }, []);

  const editCategory = useCallback(async (categoryId: string, name: string, picture: string) => {
    const docRef = doc(db, 'resources_categories', categoryId);
    await updateDoc(docRef, {
      name: name,
      picture: picture,
    });
  }, []);

  const editCategoryTags = useCallback(async (categoryId: string, tags: string[]) => {
    const docRef = doc(db, 'resources_categories', categoryId);
    await updateDoc(docRef, {
      tags: tags,
    });
  }, []);

  const removeCategory = useCallback(async (categoryId: string) => {
    await deleteDoc(doc(db, 'resources_categories', categoryId));
  }, []);

  const addNewCategory = useCallback(async (name: string, pictureUrl: string, parentId: string) => {
    const docRef = await addDoc(collection(db, 'resources_categories'), {
      name: name,
      categoryId: parentId,
      picture: pictureUrl,
      tags: [],
    });

    return docRef.id;
  }, []);

  return {
    categoriesList,
    editCategory,
    editCategoryTags,
    addNewCategory,
    removeCategory,
  };
};
