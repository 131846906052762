/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore';
import { IFocusAreaStateInterface } from '@/types';
import { db } from '@/utils/firebase/firebaseInit';

export const focusAreaDocRef = (focusAreaId: string) => doc(db, 'focus_areas', focusAreaId);

export const focusAreaCollectionRef = () => collection(db, 'focus_areas');

const initialState: IFocusAreaStateInterface = {
  loading: false,
  showAddModal: false,
  focusAreas: [],
};

export const getFocusAreas = createAsyncThunk('focusArea/getFocusAreas', async () => {
  try {
    const querySnapshot = await getDocs(focusAreaCollectionRef());

    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (e: any) {
    console.log(e);
  }
});

export const createFocusArea = createAsyncThunk(
  'focusArea/createFocusArea',
  async (args, APIThunk: any) => {
    try {
      // @ts-ignore
      const { name } = args;
      const { dispatch, getState } = APIThunk;
      const { selectedHandout } = getState().handout;

      await addDoc(focusAreaCollectionRef(), {
        name,
        handoutId: selectedHandout.id,
        createdAt: new Date(),
      });

      dispatch(getFocusAreas());
    } catch (e: any) {
      console.log(e);
    }
  },
);

export const updateFocusArea = createAsyncThunk(
  'focusArea/updateFocusArea',
  async (args, APIThunk: any) => {
    try {
      // @ts-ignore
      const { name, id } = args;
      const { dispatch, getState } = APIThunk;
      const { selectedHandout } = getState().handout;

      await updateDoc(focusAreaDocRef(id), {
        name,
        handoutId: selectedHandout.id,
        updatedAt: new Date(),
      });

      dispatch(getFocusAreas());
    } catch (e: any) {
      console.log(e);
    }
  },
);

export const deleteFocusArea = createAsyncThunk(
  'focusArea/deleteFocusArea',
  async (id: any, APIThunk: any) => {
    try {
      const { dispatch } = APIThunk;

      await deleteDoc(focusAreaDocRef(id));

      dispatch(getFocusAreas());
    } catch (e: any) {
      console.log(e);
    }
  },
);

export const focusAreaSlice = createSlice({
  name: 'focusArea',
  initialState,
  reducers: {
    displayAddModal: (state) => {
      state.showAddModal = true;
    },
    hideAddModal: (state) => {
      state.showAddModal = false;
      state.selectedFocusArea = undefined;
    },
    startEditFocusArea: (state, action) => {
      state.selectedFocusArea = action.payload;
      state.showAddModal = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFocusAreas.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFocusAreas.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFocusAreas.fulfilled, (state, action) => {
        state.loading = false;
        state.focusAreas = action.payload;
      })
      .addCase(createFocusArea.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFocusArea.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createFocusArea.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export const { hideAddModal, displayAddModal, startEditFocusArea } = focusAreaSlice.actions;

export default focusAreaSlice.reducer;
