import { useState } from 'react';
import Button from '@/common/core/Button';
import { ICoachesAdd } from '@/types';
import CoachesAddModal from '../CoachesModal/CoachesAddModal';

const CoachesAdd = ({ createCoach }: ICoachesAdd) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);

  const toggleOpenAddModal = () => {
    setIsOpenAddModal(!isOpenAddModal);
  };

  const handleCreateCoach = (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    timezone: string,
    timeStarting: number,
    timeFinishing: number,
  ) => {
    createCoach(firstName, lastName, email, phone, timezone, timeStarting, timeFinishing);
    setIsOpenAddModal(false);
  };

  return (
    <>
      <div>
        <Button onClick={toggleOpenAddModal}>Add new coach</Button>
      </div>
      <CoachesAddModal
        isOpenAddModal={isOpenAddModal}
        toggleOpenAddModal={toggleOpenAddModal}
        handleCreateCoach={handleCreateCoach}
      />
    </>
  );
};

export default CoachesAdd;
