import { EFeatureFlagsType } from '@/types';

export const FeatureFlags = {
  new_program_tab: {
    title: 'New program tab',
  },
  in_app_review: {
    title: 'In app. review',
  },
  nutrition_baseline: {
    title: 'Nutrition baseline',
  },
} as const satisfies Record<EFeatureFlagsType, { title: string }>;
