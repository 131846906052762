import { styled } from '@mui/system';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Button from '@/common/core/Button';

export const Title = styled(Typography)({
  fontWeight: 500,
  fontSize: '28px',
  marginBottom: '24px',
});

export const ListWrapper = styled('div')({
  maxHeight: '1000px',
  overflowY: 'scroll',
  marginBottom: '36px',
});

export const ItemWrapper = styled(Accordion)({
  backgroundColor: '#F9FAFC',
  border: 'none',
  boxShadow: 'none',
  marginBottom: '16px',
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
});

export const AccordionSummaryStyled = styled(AccordionSummary)({
  padding: '0px 32px',
  border: '1px solid #E4E7ED',
  borderRadius: '4px 4px 0px 0px',
  '& .MuiAccordionSummary-content': {
    margin: '14px 0',
  },
});

export const ItemTitle = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  fontSize: '18px',
});

export const AccordionDetailsStyled = styled(AccordionDetails)({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 36px',
  '& .MuiButton-containedPrimary:hover': {
    backgroundColor: '#FFFFFF',
  },
});

export const ReviewedButton = styled(Button)({
  alignSelf: 'end',
  width: '66px',
  color: 'rgba(15, 15, 33, 0.6)',
  border: '1px solid rgba(15, 15, 33, 0.2)',
  borderRadius: '8px',
  backgroundColor: '#F9FAFC',
  fontWeight: 600,
  boxShadow: 'none',
});
