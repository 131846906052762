import ResourcesContentHeader from './ResourcesContentHeader';
import FilteredContent from './ResourcesContentList';
import { AppWrapper } from './styles';
import { IResourcesContent } from '@/types';

const ResourcesContent = ({
  selectedCategory,
  selectedTags,
  handleCategorySelected,
}: IResourcesContent) => {
  return (
    <>
      <AppWrapper>
        <ResourcesContentHeader />
        <FilteredContent
          selectedCategory={selectedCategory}
          selectedTags={selectedTags}
          handleCategorySelected={handleCategorySelected}
        />
      </AppWrapper>
    </>
  );
};

export default ResourcesContent;
