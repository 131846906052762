import React, { useState, useEffect } from 'react';
import Pagination from '@/common/core/Pagination';
import { numValuesOnPage } from '@/config';
import { BottomLineBlock, Container, PaginationBlock, TableBlock } from './styles';
import { sortByFirstAssignedAndName } from '@/utils/format/sort';
import { IAdminUser, IClients, ICoachAssignmentDataConverted } from '@/types';
import ClientsListHeader from './ClientsListHeader';
import ClientsListItem from './ClientsListItem';
import ClientsEditModal from '../ClientModal/ClientsEditModal';
import CoachAssignmentModal from '../ClientModal/CoachAssignmentModal';

const ClientsList = ({ usersList }: IClients) => {
  const [userIdCurrent, setUserIdCurrent] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isOpenAssignmentModal, setIsOpenAssignmentModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    const pageCountNew = Math.ceil(usersList.length / numValuesOnPage);
    setPageCount(pageCountNew);
  }, [usersList.length]);

  const toggleOpenEditModal = () => {
    setIsOpenEditModal((prev) => !prev);
  };

  const toggleOpenAssignmentModal = () => {
    setIsOpenAssignmentModal((prev) => !prev);
  };

  const chooseEditUser = (userId: string) => {
    setUserIdCurrent(userId);
    setIsOpenEditModal(true);
  };

  const chooseAssignmentUser = (
    userId: string,
    userName: string,
    coachAssignment: ICoachAssignmentDataConverted[],
  ) => {
    setUserIdCurrent(userId);
    setUserName(userName);
    setIsOpenAssignmentModal(true);
  };

  const handleChangePage = (event: React.ChangeEvent<any>, pageNew: number) => {
    setPage(pageNew);
  };

  return (
    <>
      <ClientsListHeader />
      <Container>
        <TableBlock data-testid="table-block">
          <BottomLineBlock />
          {usersList
            .sort(sortByFirstAssignedAndName)
            .filter(
              (_, index: number) =>
                index >= (page - 1) * numValuesOnPage && index < page * numValuesOnPage,
            )
            .map((item: IAdminUser) => (
              <ClientsListItem
                key={item.id}
                item={item}
                chooseEditUser={chooseEditUser}
                chooseAssignmentUser={chooseAssignmentUser}
              />
            ))}
        </TableBlock>
        <PaginationBlock data-testid="paggination-block">
          {pageCount > 1 && (
            <Pagination count={pageCount} page={page} onChange={handleChangePage} />
          )}
        </PaginationBlock>
      </Container>
      {isOpenEditModal && (
        <ClientsEditModal
          userIdCurrent={userIdCurrent}
          usersList={usersList}
          isOpenEditModal={isOpenEditModal}
          toggleOpenEditModal={toggleOpenEditModal}
        />
      )}
      {isOpenAssignmentModal && (
        <CoachAssignmentModal
          userIdCurrent={userIdCurrent}
          userName={userName}
          isOpenModal={isOpenAssignmentModal}
          toggleOpenModal={toggleOpenAssignmentModal}
        />
      )}
    </>
  );
};

export default ClientsList;
