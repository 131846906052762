import { useAdminPartnerReferrals } from '@/hooks/admin/useAdminPartnerReferrals';
import PartnersReferral from './PartnerReferrals';

const PartnerReferralsContainer = () => {
  const { partnerReferralList, createPartnerReferral, editPartnerReferral } =
    useAdminPartnerReferrals();

  return (
    <PartnersReferral
      partnerReferralsList={partnerReferralList}
      editPartnerReferral={editPartnerReferral}
      createPartnerReferral={createPartnerReferral}
    />
  );
};

export default PartnerReferralsContainer;
