import Modal from '@/common/core/Modal';
import Picker from '@emoji-mart/react';
import { Container } from './styles';
import { IEmojiModal } from '@/types';

const EmojiModal = ({ isOpen, toggleOpen, handleSubmit }: IEmojiModal) => {
  const handleChoseEmoji = (e: any) => {
    handleSubmit(e?.native);
  };

  return (
    <Modal
      widthMax={400}
      title="Add emoji"
      open={isOpen}
      handleClose={toggleOpen}
      textBtnClose="Close"
    >
      <Container>
        <Picker set="facebook" heme="light" onEmojiSelect={handleChoseEmoji} />
      </Container>
    </Modal>
  );
};

export default EmojiModal;
