import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Input from '@/common/core/Input';
import Button from '@/common/core/Button';

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: 368,
  height: 520,
  padding: 20,
  backgroundColor: '#fff',
}));

export const LogoBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: 47,
  paddingBottom: 27,
  paddingLeft: 47,
  paddingRight: 47,
}));

export const LoginBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: 47,
  paddingRight: 47,
  paddingBottom: 20,
}));

export const InputBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingBottom: 20,
  paddingLeft: 47,
  paddingRight: 47,
}));

export const ButtonBlock = styled('div')(({ theme }) => ({
  paddingTop: 15,
  paddingLeft: 47,
  paddingRight: 47,
}));

export const TitleText = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 40,
  lineHeight: '48px',
  color: '#000000',
}));

export const LoginText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '24px',
  color: '#000000',
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  margin: 0,
}));

export const ForgotBlock = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 34,
  paddingLeft: 47,
  paddingRight: 47,
}));

export const Logo = styled('img')(({ theme }) => ({
  width: 120,
}));

export const ForgotText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '24px',
  color: '#000000',
  cursor: 'pointer',
  textDecoration: 'none',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 56,
  backgroundColor: 'red',
  fontWeight: 500,
  fontSize: 20,
  borderRadius: 8,
  margin: 0,
}));
