import { paymentStatus } from '@/config';
import { EPaymentStatusValue, IPaymentStatus } from '@/types';

export const getUserStatus = (
  userStatus?: string,
  isPaid?: boolean,
  isEnabledReferralUrl?: boolean,
): IPaymentStatus | undefined => {
  if (userStatus && userStatus === 'active' && !isEnabledReferralUrl) {
    return paymentStatus.find(
      (status: IPaymentStatus) => status.labelShort === 'paid (no RAF discount)',
    );
  }

  if (userStatus) {
    return paymentStatus.find((status: IPaymentStatus) => status.value === userStatus);
  }

  if (isPaid) {
    return paymentStatus.find(
      (status: IPaymentStatus) => status.value === EPaymentStatusValue.PAID,
    );
  }

  return paymentStatus.find(
    (status: IPaymentStatus) => status.value === EPaymentStatusValue.UNPAID,
  );
};
