import { useState, useEffect } from 'react';
import { AppWrapper } from './styles';
import { IResourceCategory } from '@/types';
import ResourcesSidebar from './ResourcesSidebar';
import ResourcesContent from './ResourcesContent';

const ResourceContainer = () => {
  const [selectedCategory, setSelectedCategory] = useState<IResourceCategory | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');

  useEffect(() => {
    if (selectedCategory) {
      setSelectedCategoryId(selectedCategory.id);
    } else {
      setSelectedCategoryId('');
    }
  }, [selectedCategory, selectedTags]);

  const handleCategorySelected = (category: IResourceCategory | null) => {
    setSelectedCategory(category);
  };

  const handleTagsSelected = (tags: string[]) => {
    setSelectedTags(tags);
  };

  return (
    <AppWrapper>
      <ResourcesSidebar
        handleCategorySelected={handleCategorySelected}
        handleTagsSelected={handleTagsSelected}
        currentCategoryId={selectedCategoryId}
      />
      <ResourcesContent
        selectedCategory={selectedCategory}
        selectedTags={selectedTags}
        handleCategorySelected={handleCategorySelected}
      />
    </AppWrapper>
  );
};

export default ResourceContainer;
