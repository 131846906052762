import AnswerItem from '../AnswerItem';
import { Container, Title, Text } from './styles';
import { IAnswersList } from '@/types';

const AnswersList = ({ documents, currentMessage }: IAnswersList) => {
  return (
    <Container>
      {currentMessage && (
        <>
          <Title>Your message</Title>
          <Text>{currentMessage}</Text>
        </>
      )}

      <Title>Previous messages</Title>

      {documents.map((document, index) =>
        document.content ? (
          <AnswerItem
            key={`answer${index}`}
            text={document.content}
            createDate={document.meta?.created_at}
          />
        ) : null,
      )}
    </Container>
  );
};

export default AnswersList;
