import { ActionsProgramListTitle, weekDaysOptions } from '@/config';
import {
  Container,
  TopBlock,
  ActionTitleBlock,
  ActionTitleText,
  NewBlock,
  NewText,
  ContentBlock,
  ActionBodyText,
  Details1Block,
  DetailsText,
  Details2Block,
} from './styles';
import { serviceHandlingStatus } from '@/components/Coach/ProgramContainer/ProgramActionsList/serviceHandlingStatus';
import { IProgramActionsListItem, IWeekDayOption } from '@/types';

// DEPRECATED OLD VERSION
const ProgramActionsListItem = ({ action, programAction }: IProgramActionsListItem) => {
  const { actualStatus } = serviceHandlingStatus(programAction!);
  const { title } = action;

  const statusSettings = ActionsProgramListTitle[actualStatus];

  return (
    <>
      <Container containerBackground={statusSettings.containerBackground}>
        <TopBlock>
          <ActionTitleBlock>
            <ActionTitleText>{title}</ActionTitleText>
          </ActionTitleBlock>
          <NewBlock
            chipBackground={statusSettings.chipBackground}
            chipBorder={statusSettings.chipBorder}
            color={statusSettings.color}
          >
            <NewText color={statusSettings.color}>{statusSettings.title}</NewText>
          </NewBlock>
        </TopBlock>
        <ContentBlock
          hasAction={
            !!(programAction?.actionId && (programAction?.daysPerWeek || programAction?.firstDay))
          }
        >
          <ActionBodyText>{programAction?.goal}</ActionBodyText>
        </ContentBlock>
        {programAction?.actionId &&
          (programAction?.daysPerWeek !== 0 || programAction?.firstDay) && (
            <>
              {programAction?.daysPerWeek !== 0 && programAction?.firstDay && (
                <Details1Block>
                  <DetailsText>Times per week</DetailsText>
                  <DetailsText>
                    {programAction?.daysPerWeek}{' '}
                    {programAction?.days &&
                      programAction?.days.length > 0 &&
                      programAction?.days?.length < 7 && (
                        <>
                          (
                          {programAction?.days
                            .sort()
                            ?.map(
                              (item: number) =>
                                weekDaysOptions.find(
                                  (item2: IWeekDayOption) => item2.value === item,
                                )?.short,
                            )
                            .join(', ')}
                          )
                        </>
                      )}
                    {programAction?.days?.length === 7 && <> (Mon-Sun)</>}
                  </DetailsText>
                </Details1Block>
              )}
              <Details2Block>
                {programAction?.firstDay ? (
                  <>
                    <DetailsText>Start day</DetailsText>
                    <DetailsText>
                      {
                        weekDaysOptions.find(
                          (item: IWeekDayOption) => item.value === programAction?.firstDay,
                        )?.label
                      }
                    </DetailsText>
                  </>
                ) : (
                  <>
                    <DetailsText>Times per week</DetailsText>
                    <DetailsText>
                      {programAction?.daysPerWeek}{' '}
                      {programAction?.days &&
                        programAction?.days?.length > 0 &&
                        programAction?.days?.length < 7 && (
                          <>
                            (
                            {programAction?.days
                              .sort()
                              ?.map(
                                (item: number) =>
                                  weekDaysOptions.find(
                                    (item2: IWeekDayOption) => item2.value === item,
                                  )?.short,
                              )
                              .join(', ')}
                            )
                          </>
                        )}
                      {programAction?.days?.length === 7 && <> (Mon-Sun)</>}
                    </DetailsText>
                  </>
                )}
              </Details2Block>
            </>
          )}
      </Container>
    </>
  );
};

export default ProgramActionsListItem;
