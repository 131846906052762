import { useEffect } from 'react';
import { TextField } from '@mui/material';
import Modal from '@/common/core/Modal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { assignTeamToCustomer, prepareTeamAssignment } from '@/store/customers/customersSlice';
import { sendasInit } from '@/store/sendAs/sendAsSlice';
import { getTeams } from '@/store/teams/teamSlice';
import { fetchCustomerById } from '@/store/customers/customerInfosSlice';
import {
  ButtonBlock,
  ButtonCancel,
  ButtonSubmit,
  DescriptionBlock,
  DescriptionTitleText,
  SelectBlock,
  SelectTitleBlock,
  SelectTitleText,
  StyledAutocomplete,
  TitleBlock,
  TitleText,
} from './styles';
import { ICoachAssignmentModal } from '@/types';

const CoachAssignmentModal = ({
  userIdCurrent,
  userName,
  isOpenModal,
  toggleOpenModal,
}: ICoachAssignmentModal) => {
  const dispatch = useAppDispatch();
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const { teams } = useAppSelector((state) => state.team);

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  const handleAssignCoach = (team: string) => {
    dispatch(prepareTeamAssignment({ team, userId: userIdCurrent }));
  };

  const handleSubmit = () => {
    dispatch(assignTeamToCustomer({ userId: userIdCurrent }));
    dispatch(fetchCustomerById(userIdCurrent));
    if (coachData) {
      dispatch(sendasInit({ clientUser: userIdCurrent }));
    }
    toggleOpenModal();
  };

  return (
    <>
      <Modal widthMax={390} title="" handleClose={toggleOpenModal} open={isOpenModal}>
        <TitleBlock>
          <TitleBlock>
            <TitleText>Edit coach assignment for</TitleText>
            <TitleText>{userName}</TitleText>
          </TitleBlock>
          <DescriptionBlock>
            <DescriptionTitleText>Select the main coach for the given client.</DescriptionTitleText>
          </DescriptionBlock>
          <SelectTitleBlock>
            <SelectTitleText>Select main coach team</SelectTitleText>
          </SelectTitleBlock>
          <SelectBlock>
            <StyledAutocomplete
              noOptionsText="No results"
              fullWidth
              filterSelectedOptions={true}
              openOnFocus={true}
              options={teams}
              onChange={(event, newValue) => {
                handleAssignCoach(newValue as any);
              }}
              getOptionLabel={(option: any) =>
                `${option.mainCoach.firstName} ${option.mainCoach.lastName}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  value=""
                  placeholder="Type here to search"
                  variant="outlined"
                />
              )}
            />
          </SelectBlock>

          <ButtonBlock>
            <ButtonCancel onClick={toggleOpenModal} variant="outlined">
              Cancel
            </ButtonCancel>
            <ButtonSubmit onClick={handleSubmit}>Save</ButtonSubmit>
          </ButtonBlock>
        </TitleBlock>
      </Modal>
    </>
  );
};

export default CoachAssignmentModal;
