import {
  EActionsProgramOptions,
  EActionsProgramStatus,
  EPaymentListType,
  IActionProgramItem,
  IActionsProgramOptionsListItem,
  IFilter,
  IPaymentListItem,
  IPaymentStatus,
  ISort,
  IThreadsFilter,
} from '@/types';

import actionsAllIcon from 'assets/icons/actions_all.svg';
import actionsNewIcon from 'assets/icons/actions_new.svg';
import actionsOldIcon from 'assets/icons/actions_old.svg';
import actionsCompeteIcon from 'assets/icons/actions_complete.svg';

export const SIMILARITY_API_QUERY_PATH = '/query';

export const TINY_MCE_API_KEY = '2y5jfzr6mxfvdhi5wqr32b46tflxf2r6hp52m0kmzl3pvtrw';

export const AVATAR_FILES_DIR = 'avatar';
export const CHAT_FILES_DIR = 'chat_uploads';
export const RESOURCE_FILES_DIR = 'resource_uploads';
export const RESOURCE_RECIPE_HTML_DIR = 'resource_recipe_html';

export const numValuesOnPage = 10;
export const maxFileAttachSize = 1048576000;
export const COEFF_INCHES_TO_CM = 2.54;
export const COEFF_LBS_TO_KG = 0.453592;

export const MINIMAL_COACHES_TO_ASSIGN = 1;

export const USERS_PER_PAGE = 10;

export const YOUTUBE_REGEXP_PARS =
  /^https?:\/\/(?:www\.youtube(?:-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^#&?\n/<>"']*)/i;

export const HTTP_HTTPS_REGEXP =
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

export const EMAIL_REGEXP = /\S+@\S+\.\S+/;

export const timezonesUSList = [
  {
    value: '-8',
    label: 'PST',
    isForCoach: true,
    isForClient: true,
  },
  {
    value: '-6',
    label: 'CST',
    isForCoach: false,
    isForClient: true,
  },
  {
    value: '-5',
    label: 'EST',
    isForCoach: true,
    isForClient: true,
  },
];

export const categoriesList = [
  {
    id: 'calories_in',
    name: 'Nutrition: quality foods/drinks',
  },
  {
    id: 'calories_out',
    name: 'Activity: increase your metabolism',
  },
  {
    id: 'lifestyle',
    name: 'Lifestyle for success',
  },
];

export const selfFeelingValues = [1, 2, 3, 4, 5];

export const energyLevelValues: { [key: number]: string } = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

export const actionsProgramOptionsList: IActionsProgramOptionsListItem[] = [
  {
    value: EActionsProgramOptions.ALL,
    label: 'All actions',
    icon: actionsAllIcon,
  },
  {
    value: EActionsProgramOptions.PAST,
    label: 'Past actions',
    icon: actionsOldIcon,
  },
  {
    value: EActionsProgramOptions.CURRENT,
    label: 'New and Existing actions',
    icon: actionsNewIcon,
  },
  {
    value: EActionsProgramOptions.COMPLETE,
    label: 'Complete actions',
    icon: actionsCompeteIcon,
  },
];
export const daysPerWeekOptions = [1, 2, 3, 4, 5, 6, 7];

export interface IWeekDayOption {
  value: number;
  label: string;
  short: string;
}

export interface IWeekDayOptionWithDate {
  value: string;
  dayNumber: number;
  shortDay: string;
  shortMonth: string;
}

export const weekDaysOptions: IWeekDayOption[] = [
  {
    value: 1,
    label: 'Monday',
    short: 'Mon',
  },
  {
    value: 2,
    label: 'Tuesday',
    short: 'Tue',
  },
  {
    value: 3,
    label: 'Wednesday',
    short: 'Wed',
  },
  {
    value: 4,
    label: 'Thursday',
    short: 'Thu',
  },
  {
    value: 5,
    label: 'Friday',
    short: 'Fri',
  },
  {
    value: 6,
    label: 'Saturday',
    short: 'Sat',
  },
  {
    value: 7,
    label: 'Sunday',
    short: 'Sun',
  },
];

export const sort: ISort[] = [
  { text: 'Newest clients', query: { key: 'createdat', value: 'desc' } }, // default
  { text: 'Oldest clients', query: { key: 'createdat', value: 'asc' } },
  { text: 'Recent online clients', query: { key: 'lastseenat', value: 'desc' } },
  { text: 'A to Z, a to z', query: { key: 'firstname', value: 'asc' } },
  { text: 'Z to A, z to a', query: { key: 'firstname', value: 'desc' } },
];

export const filtersAllClientsDefault: IFilter[] = [
  {
    text: 'Not assigned to coach',
    checked: false,
    query: { coachassignment__hascoachesassigned: ['false'] },
  },
  { text: 'Unpaid (Lead)', checked: false, query: { billingdata__status: ['unpaid'] } },
  { text: 'Trial', checked: false, query: { billingdata__status: ['trial'] } },
  {
    text: 'Paid',
    checked: false,
    query: { billingdata__isreferrallinkenabled: ['true'] },
  },
  {
    text: 'Paid (no RAF discount)',
    checked: false,
    query: {
      any: [{ billingdata__status: ['active'] }],
      none: [{ billingdata__isreferrallinkenabled: ['true'] }],
    },
  },
  { text: 'Cancelled', checked: false, query: { billingdata__status: ['cancelled'] } },
];

export const threadsFilter: IThreadsFilter[] = [
  { label: 'Read', value: 'read', field: 'isMessagesUnRead' },
  { label: 'Unread', value: 'unread', field: 'isMessagesUnRead' },
  { label: 'All', value: 'all', field: 'all' }, // default
];

export const paymentStatus: IPaymentStatus[] = [
  { label: 'Trial', labelShort: 'trial', value: 'trial', color: '#24C09F' },
  { label: 'Paid', labelShort: 'paid', value: 'active', color: '#24C09F' },
  { label: 'Unpaid (Lead)', labelShort: 'unpaid', value: 'unpaid', color: '#FBB867' }, // default
  { label: 'Retargeting', labelShort: 'retargeting', value: 'retargeting', color: '#FBB867' },
  {
    label: 'Paid (no RAF discount)',
    labelShort: 'paid (no RAF discount)',
    value: 'active',
    color: '#24C09F',
  },
  { label: 'Cancelled', labelShort: 'cancelled', value: 'cancelled', color: '#FF405C' },
  { label: 'Overdue', labelShort: 'past due', value: 'past_due', color: '#FF405C' },
];

export const paymentTypeList: IPaymentListItem[] = [
  { title: 'Unpaid (Lead)', value: EPaymentListType.UNPAID, dataIsPaid: false },
  { title: 'Trial', value: EPaymentListType.TRIAL, dataIsPaid: true },
  { title: 'Paid', value: EPaymentListType.ACTIVE_REF, dataIsPaid: true },
  { title: 'Paid (no RAF discount)', value: EPaymentListType.ACTIVE, dataIsPaid: true },
  { title: 'Cancelled', value: EPaymentListType.CANCELLED, dataIsPaid: false },
  { title: 'Retargeting', value: EPaymentListType.RETARGETING, dataIsPaid: false },
  { title: 'Overdue', value: EPaymentListType.PAST_DUE, dataIsPaid: false },
];

export const cancelPauseRequestList = [
  { title: 'Cancel (cost)', value: 'cancel_cost' },
  { title: 'Cancel (achieved goals)', value: 'cancel_achieved_goals' },
  { title: 'Cancel (needs in-person)', value: 'cancel_needs_in_person' },
  { title: 'Cancel (other reason)', value: 'cancel_other' },
  { title: 'Pause (over 4 weeks)', value: 'pause_over_4_weeks' },
  { title: 'Pause (4 or less weeks)', value: 'pause_less_4_weeks' },
];

export const cancelPauseOutcomeList = [
  { title: 'Accepted proposal', value: 'accepted_proposal' },
  { title: 'Rejected proposal', value: 'rejected_proposal' },
  { title: 'Other', value: 'other' },
];

export const ActionsProgramListTitle: IActionProgramItem = {
  [EActionsProgramStatus.CURRENT]: {
    title: 'Current',
    chipBackground: '#52CDB1',
    chipBorder: '#06A581',
    containerBackground: '#F9FFFD',
    color: '#FFF',
  },
  [EActionsProgramStatus.NEW]: {
    title: 'New',
    chipBackground: '#52CDB1',
    chipBorder: '#06A581',
    containerBackground: '#F9FFFD',
    color: '#FFF',
  },
  [EActionsProgramStatus.EXISTING]: {
    title: 'Existing',
    chipBackground: 'transparent',
    chipBorder: '#06A581',
    containerBackground: '#F9FFFD',
    color: '#000',
  },
  [EActionsProgramStatus.PAST]: {
    title: 'Past',
    chipBackground: '#5045C7',
    chipBorder: '#5045C7',
    containerBackground: '#F9F9FF',
    color: '#FFF',
  },
  [EActionsProgramStatus.COMPLETE]: {
    title: 'Complete',
    chipBackground: '#0F0F21',
    chipBorder: '#0F0F2199',
    containerBackground: '#E6EAF2',
    color: '#FFF',
  },
};

export const feedbackTypesDocs = {
  newMeal: '18izIIraBHIau7thZwnLj6zZ698ANZr6iiUMZdh6d2j8',
  existingMeal: '1icE9z6g4VCSML4qxh5vnfEoam3ZJf9XtcIufNnHQiMs',
  newOther: '1qVXRBE9Z9vbuu4Ui9hqH2aFWCloPTEFxvTvRwWP4Y28',
  existingOther: '1-OsaAw4niT0Bm-YpJgLi6jIDCMt51CZRpJLq_nr8nEU',
};
