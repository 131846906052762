import { auth } from '@/utils/firebase/firebaseInit';
import { useAppSelector } from '@/hooks';
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { toast } from 'sonner';

export const usePasswordEdit = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);

  const editPassword = async (passwordCurrent: string, passwordNew: string) => {
    try {
      if (!coachData?.email && auth.currentUser) {
        await signInWithEmailAndPassword(auth, coachData?.email, passwordCurrent);
        await updatePassword(auth.currentUser, passwordNew);
        toast.success('Your password has been successfully changed.');
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  return { editPassword };
};
