/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import { TCardComponent } from '@/types';
import ChatProgramPlanCard from '../ChatProgramPlanCard';
import { FocusArea } from '@fitmate-coach/fitmate-types';

const PlanCard: TCardComponent<FocusArea & { id: string }, HTMLDivElement> = forwardRef(
  ({ item, handle, disabled, ...props }, ref) => {
    return (
      <div ref={ref} {...props} {...handle}>
        <ChatProgramPlanCard disabled={disabled} focusArea={item} />
      </div>
    );
  },
);

export default PlanCard;
