import React, { useState } from 'react';
import { Box } from '@mui/material';
import { MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@/common/core/Menu';
import {
  Icon,
  Length,
  PaginationBlock,
  PaginationWrapper,
  PerPage,
  StyledPagination,
  Text,
} from './styles';
import { IChatAllClientsListHeader } from '@/types';

const ChatAllClientsListHeader = ({
  currentPage,
  setCurrentPage,
  usersPerPage,
  setUsersPerPage,
  totalPages,
}: IChatAllClientsListHeader) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const toggleMenu = (e: React.MouseEvent<any>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleChangeList = (value: number) => {
    setUsersPerPage(value);
    setAnchorEl(null);
  };

  return (
    <PaginationWrapper>
      <PaginationBlock>
        <StyledPagination
          count={totalPages}
          onChange={handleChange}
          page={currentPage}
          showFirstButton
          showLastButton
          color="primary"
        />
      </PaginationBlock>
      <Box display="flex">
        <Text>Users per page:</Text>
        <PerPage onClick={toggleMenu}>
          <Length>{usersPerPage}</Length>
          <Icon as={ArrowDropDownIcon} />
        </PerPage>
        <Menu toggleMenu={toggleMenu} anchorEl={anchorEl}>
          <MenuItem onClick={() => handleChangeList(10)}>10</MenuItem>
          <MenuItem onClick={() => handleChangeList(25)}>25</MenuItem>
          <MenuItem onClick={() => handleChangeList(50)}>50</MenuItem>
        </Menu>
      </Box>
    </PaginationWrapper>
  );
};

export default ChatAllClientsListHeader;
