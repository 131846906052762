import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Grid } from '@mui/material';
import { IChatControl } from '@/types';
import { AllClientsButton, ControlBlock, FontBold, Icon } from './styles';
import ChatThreadsSearch from './ChatThreadsSearch';
import ChatThreadsFilter from './ChatThreadsFilter';
import ChatHeader from './ChatHeader';
import ChatShift from './ChatShift';
import ChatThreadsReadFilter from './ChatThreadsReadFilter';
import { useAppSelector } from '@/hooks';
import { SquareActivity } from 'lucide-react';

const ChatControl = ({ handleOpenAllClients, isOpenAllClients }: IChatControl) => {
  const { coachData } = useAppSelector((state) => state.coachAuth);

  return (
    <ControlBlock>
      <Grid item xs={12}>
        <ChatHeader />
      </Grid>
      {coachData?.toggleApiToken && (
        <Grid item xs={12}>
          <ChatShift />
        </Grid>
      )}
      <Grid item xs={12}>
        <div className="w-full flex items-center justify-center bg-[#F2F4F7] p-2 cursor-pointer">
          <SquareActivity className="mx-2 text-[#9B9CA5]" />
          <a href="/monitor" className="text-[#9B9CA5]">
            Clients onboarding monitoring
          </a>
        </div>
        <AllClientsButton
          isOpenAllClients={isOpenAllClients}
          style={{ marginBottom: '0.3rem' }}
          onClick={handleOpenAllClients}
          fullWidth={true}
        >
          <Icon as={PeopleAltIcon} /> All clients
        </AllClientsButton>
      </Grid>
      <Grid item xs={12} className="flex flex-row justify-between p-2">
        <FontBold>Messages</FontBold>
        <ChatThreadsReadFilter />
      </Grid>
      <Grid item xs={12}>
        <ChatThreadsSearch />
      </Grid>
      <Grid item xs={12}>
        <ChatThreadsFilter />
      </Grid>
    </ControlBlock>
  );
};

export default ChatControl;
