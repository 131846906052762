import { useNavigate } from 'react-router-dom';
import { Tabs } from '@mui/material';
import LogoutCoach from '@/common/core/LogoutCoach';
import { BackText, CenterBlock, Container, LeftBlock, RightBlock, StyledTab } from './styles';
import { ECoachProfileMenuItem, IProfileHeader } from '@/types';

const ProfileHeader = ({ pageProfile, handlePageProfile }: IProfileHeader) => {
  const navigate = useNavigate();
  return (
    <Container>
      <LeftBlock>
        <BackText onClick={() => navigate('/coach-dashboard')}>Back to dashboard</BackText>
      </LeftBlock>
      <CenterBlock>
        <Tabs
          value={pageProfile}
          TabIndicatorProps={{
            sx: (theme) => ({
              height: 4,
              backgroundColor: '#8587E5',
              borderRadius: theme.palette.primary.main,
            }),
          }}
          aria-label="Switch conversation and program"
        >
          <StyledTab
            label="Profile"
            value={ECoachProfileMenuItem.PROFILE}
            onClick={() => handlePageProfile('profile')}
          />
          <StyledTab
            label="Password"
            value={ECoachProfileMenuItem.PASSWORD}
            onClick={() => handlePageProfile('password')}
          />
          <StyledTab
            label="Toggl"
            value={ECoachProfileMenuItem.TOGGL}
            onClick={() => handlePageProfile('toggl')}
          />
        </Tabs>
      </CenterBlock>
      <RightBlock>
        <LogoutCoach />
      </RightBlock>
    </Container>
  );
};

export default ProfileHeader;
