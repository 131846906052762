import axios from 'axios';
import { IResponseDataSaveTask, IResponseDataMe } from '@/types';

/*
If for some reason you change the domain name, requests to the Toggl API from this domain will be blocked by the CORS policy.
To this, you need to add a new (changed) domain to the white list toggle.
To do this, you need to contact the technical support of the toggl tracker at support@toggl.com and provide them with this domain.
NOTE! correspondence must be conducted from the email address on which the workspace was created, in which you want to track the time
 */
export class ToggleApi {
  // we use this route to make sure that the coach has provided a valid API key
  static async getToggleMe(toggleApiToken: string): Promise<IResponseDataMe> {
    const url = `${process.env.REACT_APP_TOGGLE_URL}/me`;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Basic ${toggleApiToken}`,
    };

    return await axios.get(url, {
      headers,
    });
  }

  static async saveTask(
    toggleApiToken: string,
    currentStartTime: string,
    description: string,
    duration: number,
    tags: string[],
    task?: number,
  ): Promise<IResponseDataSaveTask> {
    const url = `${process.env.REACT_APP_TOGGLE_URL}/workspaces/${process.env.REACT_APP_TOGGLE_WORKSPACE_ID}/time_entries`;
    // TODO if more requests are created, transfer headers to interceptors, or make a custom hook and take toggleApiToken key from userData
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Basic ${toggleApiToken}`,
    };

    const params = {
      created_with: 'fitmate web',
      description: description,
      duration: duration,
      tags: tags,
      ...(task && { task_id: task }),
      billable: false,
      start: currentStartTime,
      at: currentStartTime,
      stop: null,
      pid: Number(process.env.REACT_APP_TOGGLE_PROJECT_ID),
      wid: Number(process.env.REACT_APP_TOGGLE_WORKSPACE_ID),
    };

    const response = await axios
      .post(url, params, {
        headers,
      })
      .catch((error) => {
        console.log(error);
      });
    return response?.data as IResponseDataSaveTask;
  }
}
