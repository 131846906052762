import { Banner, BannerText, Icon } from './styles';
import { Error } from '@mui/icons-material';

const VersionBanner = () => {
  return (
    <Banner onClick={() => window.location.reload()}>
      <Icon>
        <Error />
      </Icon>
      <BannerText>
        Your version is outdated. Please refresh the page to get the latest version. Click HERE to
        refresh.
      </BannerText>
    </Banner>
  );
};

export default VersionBanner;
