/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OpenAI from 'openai';
import { PromptTemplate } from '@langchain/core/prompts';
import { IProgramActionsInterface } from '@/types';
import { getGdocDocById } from '@/api/gdrive';
import { fetchCustomerById } from '../customers/customerInfosSlice';
import { db } from '@/utils/firebase/firebaseInit';
import { doc } from '@firebase/firestore';
import { deleteField, updateDoc } from 'firebase/firestore';
import { toast } from 'sonner';
import { closeDrawer, setVoiceNoteMessage } from '../customers/progressFeedbackSlice';
import { feedbackTypesDocs } from '@/config';
import { setFutureMessages } from '../chat/sendMessageSlice';
import { v4 } from 'uuid';
import { Message } from '@fitmate-coach/fitmate-types';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_KEY, // defaults to process.env['OPENAI_API_KEY']
  dangerouslyAllowBrowser: true,
});

const extraTexts = {
  socialShareMeal:
    "don't forget you can also share this meal with your friends to celebrate success (and inspire them) as part of your social support goal",
  socialShareNonMeal:
    "feel free to share your success with your friends, family or colleague as part of your social support goal. Here's the place you can find which weekly targets you hit",
};

const initialState: IProgramActionsInterface = {
  shareMessage:
    "I've added a badge to this healthy meal so you can find it more easily to redo or share with friends (will do the same with any of yours meal meal where my feedback is minimal), hope that's helpful!",
  loading: false,
  messages: [],
  feedbackLaunched: false,
  canShare: false,
  currentMessageId: undefined,
  currentActionId: undefined,
  currentTrackingId: undefined,
  disableShare: true,
  shouldAddShareMessage: false,
  step: 1,
};

const extractJSON = (str: string) => {
  const jsonRegex = /{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*}/g;
  const matches = str.match(jsonRegex);
  if (matches) {
    for (const match of matches) {
      try {
        return JSON.parse(match);
      } catch (e) {
        console.log('Failed to parse JSON:', match);
      }
    }
  }
  throw new Error('No valid JSON object found in the string');
};

export const getMealFeedback = createAsyncThunk(
  'automationAi/getNewMealFeedback',
  async (args: any, APIThunk: any) => {
    const { dispatch } = APIThunk;
    const { isNew } = args;
    const futureMessages = APIThunk.getState().sendMessage.futureMessages;
    dispatch(resetError());
    dispatch(loading());
    const promptId = isNew ? feedbackTypesDocs.newMeal : feedbackTypesDocs.existingMeal;
    const gdoc = await getGdocDocById(promptId);
    const templatePrompt = gdoc.content;
    const variables = Object.keys(args).filter((k) => !['feedbackType', 'messageId'].includes(k));
    const prompt = new PromptTemplate({
      template: templatePrompt,
      inputVariables: variables,
    });

    const formattedPrompt = await prompt.format(args);
    console.log(formattedPrompt);
    const chatCompletion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: formattedPrompt }],
      model: 'gpt-4',
      temperature: 0,
    });
    dispatch(
      setFutureMessages([
        ...futureMessages,
        { id: v4(), message: chatCompletion.choices[0].message.content, type: 'feedback' },
      ]),
    );
    dispatch(
      saveMessages([
        { role: 'user', content: formattedPrompt },
        {
          role: chatCompletion.choices[0].message.role,
          content: chatCompletion.choices[0].message.content,
        },
      ]),
    );
    dispatch(moveStep());
  },
);

const socialSupportKeywords = ['social support', 'support network'];

const analyzeMessages = (messages: Message[]) => {
  return messages.some(
    (msg) =>
      msg.body &&
      socialSupportKeywords.some(
        (keyword) => !msg.body?.toLowerCase().includes(keyword.toLowerCase()),
      ),
  );
};

export const verifyProgressEligibleForBadge = createAsyncThunk(
  'automationAi/verifyProgressEligibleForBadge',
  async (args: any, APIThunk: any) => {
    const { dispatch } = APIThunk;
    const { messages } = APIThunk.getState().automationAi;
    const { customer } = APIThunk.getState().customerInfos;
    const { futureMessages } = APIThunk.getState().sendMessage;
    const { shareMessage } = APIThunk.getState().automationAi;
    const { isNutrition, socialSupportIsInPlan, isSocialSupport } = APIThunk.getState().weekProgram;
    const { message } = APIThunk.getState().progressFeedback;
    const { lastMonthMessages } = APIThunk.getState().chat;
    const toastId = toast('Sonner');

    toast.info('Checking if feedback is shareable and social support text...', {
      id: toastId,
    });

    const extraMessages = [];
    const canSendSocialShareMessage = analyzeMessages(lastMonthMessages);
    if (isNutrition) {
      toast.info('this is a meal', {
        id: toastId,
      });
      const newMessages = [
        ...messages,
        {
          role: 'user',
          content: `In case YOUR feedback does not explicitly mention improvement areas (aimed at ensuring that the meal abides to the same guidelines provided above) and I provided a picture for the meal, mark healthyMeal as boolean true otherwise mark as boolean false.
Respond to me ONLY using the following JSON formatted message: ("healthyMeal": "is the meal healthy?")`,
        },
      ];
      try {
        dispatch(resetError());
        dispatch(loading());
        const chatCompletion = await openai.chat.completions.create({
          messages: newMessages,
          model: 'gpt-4',
          temperature: 0,
        });
        const parsedContent = extractJSON(chatCompletion.choices[0].message.content!);
        dispatch(enableShareFeature());
        if (
          parsedContent.healthyMeal &&
          parsedContent.healthyMeal === true &&
          args.hasPictureAttached === 'yes'
        ) {
          toast.success('This meal is shareable', { id: toastId });
          dispatch(enableShare());
          dispatch(recommendShareMessage());
          if (
            customer?.haveEverReceivedBadge === false ||
            customer?.haveEverReceivedBadge === undefined
          ) {
            extraMessages.push({ id: v4(), message: shareMessage, type: 'badge' });
          }
        }
        if (parsedContent.healthyMeal === false) {
          toast.message('This meal is not healthy', { id: toastId });
        }
        if (canSendSocialShareMessage && socialSupportIsInPlan) {
          toast.success('can send social share message');
          extraMessages.push({
            id: v4(),
            message: extraTexts.socialShareMeal,
            type: 'social',
          });
        }
        if (extraMessages.length > 0) {
          dispatch(setFutureMessages([...futureMessages, ...extraMessages]));
        }
        dispatch(resetFeedbackLaunched());
        dispatch(resetStep());
        dispatch(closeDrawer());
        return chatCompletion.choices[0].message;
      } catch (e) {
        console.log(e);
      }
    } else if (isSocialSupport) {
      toast.info('this is a social support progress');
      const gdoc = await getGdocDocById('1eRH-V-hyY9hb9xY6lwwsr_iEJyK-tEWnGeMbTqMDqPQ'); // 4e (social support)
      const templatePrompt = gdoc.content;
      const prompt = new PromptTemplate({
        template: templatePrompt,
        inputVariables: ['customer_message'],
      });
      const formattedPrompt = await prompt.format(args);
      console.log(formattedPrompt);
      const newMessages = [
        ...messages,
        {
          role: 'user',
          content: formattedPrompt,
        },
      ];
      const chatCompletion = await openai.chat.completions.create({
        messages: newMessages,
        model: 'gpt-4',
        temperature: 0,
      });
      const parsedContent = extractJSON(chatCompletion.choices[0].message.content!);
      if (parsedContent.feedback === 'yes' && canSendSocialShareMessage && socialSupportIsInPlan) {
        toast.success('can send social share message');
        extraMessages.push({
          id: v4(),
          message:
            'by the way you can also share your progress with the sharing functions in the app, for example each week when you hit your weekly target.',
          type: 'social',
        });
      }
      if (extraMessages.length > 0) {
        dispatch(setFutureMessages([...futureMessages, ...extraMessages]));
      }
      dispatch(resetFeedbackLaunched());
      dispatch(resetStep());
      dispatch(closeDrawer());
    } else {
      if (
        canSendSocialShareMessage &&
        socialSupportIsInPlan &&
        message.actionTracking.currentDay === message.actionTracking.daysPerWeek
      ) {
        toast.info('can send social share message');
        extraMessages.push({
          id: v4(),
          message: extraTexts.socialShareNonMeal,
          type: 'social',
        });
      }
      if (extraMessages.length > 0) {
        dispatch(setFutureMessages([...futureMessages, ...extraMessages]));
      }
      dispatch(resetFeedbackLaunched());
      dispatch(resetStep());
      dispatch(closeDrawer());
    }
  },
);

export const getCompletion = createAsyncThunk(
  'automationAi/getCompletion',
  async (args: any, APIThunk: any) => {
    const { dispatch } = APIThunk;
    try {
      const { isNew, isFaq, isVoiceNote } = args;
      dispatch(resetError());
      dispatch(loading());
      const template = isNew
        ? feedbackTypesDocs.newOther
        : isFaq
        ? args.feedbackType
        : feedbackTypesDocs.existingOther;
      const { futureMessages } = APIThunk.getState().sendMessage;
      const gdoc = await getGdocDocById(template);
      const templatePrompt = gdoc.content;
      const variables = Object.keys(args).filter((k) => !['messageId'].includes(k));
      const prompt = new PromptTemplate({
        template: templatePrompt,
        inputVariables: variables,
      });
      const formattedPrompt = await prompt.format(args);
      console.log(formattedPrompt);
      const chatCompletion = await openai.chat.completions.create({
        messages: [{ role: 'user', content: formattedPrompt }],
        model: 'gpt-4',
        temperature: 0,
      });
      console.log(chatCompletion.choices[0].message.content);
      dispatch(resetFeedbackLaunched());
      if (isVoiceNote) {
        // set message value
        dispatch(moveStep());
        dispatch(setVoiceNoteMessage(chatCompletion.choices[0].message.content));
        return chatCompletion.choices[0].message.content;
      }
      dispatch(
        setFutureMessages([
          ...futureMessages,
          { id: v4(), message: chatCompletion.choices[0].message.content, type: 'feedback' },
        ]),
      );
      dispatch(
        saveMessages([
          { role: 'user', content: formattedPrompt },
          {
            role: chatCompletion.choices[0].message.role,
            content: chatCompletion.choices[0].message.content,
          },
        ]),
      );
      dispatch(saveTemperature(0));
      dispatch(saveModel('gpt-4'));
      dispatch(moveStep());
      // dispatch(closeDrawer());
      return chatCompletion.choices[0].message.content;
    } catch (e: any) {
      console.log('error while processing ai', e.toString());
      console.log(e);
      setError(e.toString());
      dispatch(resetFeedbackLaunched());
    }
  },
);

export const markActionAsShareable = createAsyncThunk(
  'automationAi/markActionAsShareable',
  async (_, APIThunk: any) => {
    const { getState } = APIThunk;
    const { customer } = getState().customerInfos;
    const { currentActionId, currentTrackingId } = getState().automationAi;
    const actionTrackingRef = doc(
      db,
      'users',
      customer.id,
      'programActions',
      currentActionId,
      'actionTracking',
      currentTrackingId,
    );
    await updateDoc(actionTrackingRef, {
      canShare: true,
    });
  },
);

export const getExtraCompletion = createAsyncThunk(
  'automationAi/getExtraCompletion',
  async (args: any, APIThunk: any) => {
    const { messages, currentModel, currentTemperature } = APIThunk.getState().automationAi;
    const { dispatch } = APIThunk;
    const newMessages = [...messages, { role: 'user', content: args.prompt }];

    try {
      const chatCompletion = await openai.chat.completions.create({
        messages: newMessages,
        model: currentModel,
        temperature: currentTemperature,
      });

      dispatch(saveMessages([...newMessages, chatCompletion.choices[0].message]));

      return chatCompletion.choices[0].message;
    } catch (e) {
      console.log(e);
    }
  },
);

interface ConvertImportantInfo {
  userId: string;
  importantInfo: string;
}

export const convertImportantInfoToImportantInfos = createAsyncThunk(
  'automationAi/convertImportantInfoToImportantInfos',
  async (args: ConvertImportantInfo, APIThunk: any) => {
    const { dispatch } = APIThunk;
    const gdoc = await getGdocDocById('1Ee0VRbLu0O-KzkaIXYTZFATRL9it6toW-eHL-yYeIlc');
    const templatePrompt = gdoc.content;
    const prompt = new PromptTemplate({
      template: templatePrompt,
      inputVariables: ['importantInfo'],
    });
    const formattedPrompt = await prompt.format({ importantInfo: args.importantInfo });
    console.log('important info prompt formatted', formattedPrompt);

    const chatCompletion = await openai.chat.completions.create({
      messages: [{ role: 'system', content: formattedPrompt }],
      model: 'gpt-4',
      temperature: 0,
    });
    try {
      if (chatCompletion.choices[0].message.content) {
        const formattedData = JSON.parse(chatCompletion.choices[0].message.content);
        const {
          mainChallenges,
          medicalConditions,
          dietaryRestrictions,
          physicalActivityRestrictions,
          nutritionPreferences,
          physicalActivityPreferences,
          otherLifeCircumstances,
          otherImportantInfos,
        } = formattedData;
        const userRef = doc(db, 'users', args.userId);
        await updateDoc(userRef, {
          importantInfos: {
            ...(mainChallenges.length > 0 && { mainChallenges: mainChallenges.join('\n') }),
            ...(medicalConditions.length > 0 && {
              medicalConditions: medicalConditions.join('\n'),
            }),
            ...(dietaryRestrictions.length > 0 && {
              dietaryRestrictions: dietaryRestrictions.join('\n'),
            }),
            ...(physicalActivityRestrictions.length > 0 && {
              physicalActivityRestrictions: physicalActivityRestrictions.join('\n'),
            }),
            ...(nutritionPreferences.length > 0 && {
              nutritionPreferences: nutritionPreferences.join('\n'),
            }),
            ...(physicalActivityPreferences.length > 0 && {
              physicalActivityPreferences: physicalActivityPreferences.join('\n'),
            }),
            ...(otherLifeCircumstances.length > 0 && {
              otherLifeCircumstances: otherLifeCircumstances.join('\n'),
            }),
            ...(otherImportantInfos.length > 0 && {
              otherImportantInfos: otherImportantInfos.join('\n'),
            }),
          },
          importantInfo: deleteField(),
        });
        dispatch(fetchCustomerById(args.userId));
        return;
      } else {
        console.log('error while converting important info');
        return;
      }
    } catch (e: any) {
      console.log('in catch for conversion', e);
      toast.error('Cannot convert important info', { description: e.toString() });
    }
  },
);

export const flagUserReceivedFirstShareBade = createAsyncThunk(
  'automationAi/flagUserReceivedFirstShareBade',
  async (userId: string) => {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      haveEverReceivedBadge: true,
    });
  },
);

export const flagUserReceivedSocialShareFeedback = createAsyncThunk(
  'automationAi/flagUserReceivedSocialShareFeedback',
  async (userId: string) => {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      haveEverReceivedSocialShareFeedback: true,
    });
  },
);

export const aiSlice = createSlice({
  name: 'automationAi',
  initialState,
  reducers: {
    saveMessages: (state, action) => {
      state.messages = action.payload;
    },
    resetMessages: (state) => {
      state.messages = [];
      state.feedbackLaunched = false;
      state.loading = false;
      state.canShare = false;
      state.currentActionId = undefined;
      state.currentMessageId = undefined;
      state.currentTrackingId = undefined;
      state.disableShare = true;
      state.shouldAddShareMessage = false;
    },
    loading: (state) => {
      state.feedbackLaunched = true;
      state.loading = true;
    },
    setError: (state, action) => {
      state.errorMsg = action.payload;
    },
    resetError: (state) => {
      state.errorMsg = undefined;
    },
    saveModel: (state, action) => {
      state.currentModel = action.payload;
    },
    saveTemperature: (state, action) => {
      state.currentTemperature = action.payload;
    },
    enableShare: (state) => {
      state.canShare = true;
    },
    toggleShare: (state) => {
      state.canShare = !state.canShare;
      state.shouldAddShareMessage = !state.shouldAddShareMessage;
    },
    setCurrentMessageId: (state, action) => {
      state.currentMessageId = action.payload;
    },
    setCurrentActionId: (state, action) => {
      state.currentActionId = action.payload;
    },
    setCurrentTrackingId: (state, action) => {
      state.currentTrackingId = action.payload;
    },
    enableShareFeature: (state) => {
      state.disableShare = false;
    },
    recommendShareMessage: (state) => {
      state.shouldAddShareMessage = true;
    },
    resetFeedbackLaunched: (state) => {
      state.feedbackLaunched = false;
    },
    moveStep: (state) => {
      state.step += 1;
    },
    resetStep: (state) => {
      state.step = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompletion.fulfilled, (state, action) => {
        state.loading = false;
        state.feedbackLaunched = false;
        // @ts-ignore
        // state.messages = [...state.messages, action.payload];
      })
      .addCase(getCompletion.rejected, (state) => {
        state.loading = false;
        state.feedbackLaunched = false;
      })
      .addCase(getCompletion.pending, (state) => {
        state.loading = true;
        state.errorMsg = undefined;
      })
      .addCase(getExtraCompletion.pending, (state) => {
        state.loading = true;
        state.errorMsg = undefined;
      })
      .addCase(getExtraCompletion.fulfilled, (state, action) => {
        state.loading = false;
        if (!action.payload) return;
        // @ts-ignore
        state.messages = [...state.messages, action.payload];
      })
      .addCase(convertImportantInfoToImportantInfos.pending, (state) => {
        state.loading = true;
      })
      .addCase(convertImportantInfoToImportantInfos.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getMealFeedback.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(verifyProgressEligibleForBadge.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export const {
  saveMessages,
  resetMessages,
  loading,
  setError,
  resetError,
  saveTemperature,
  saveModel,
  enableShare,
  setCurrentMessageId,
  setCurrentActionId,
  setCurrentTrackingId,
  toggleShare,
  enableShareFeature,
  recommendShareMessage,
  resetFeedbackLaunched,
  moveStep,
  resetStep,
} = aiSlice.actions;

export default aiSlice.reducer;
