import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import { timezonesUSList } from '@/config';
import Modal from '@/common/core/Modal';
import InputError from '@/common/core/InputError';
import { phoneValidation } from '@/utils/validation/phoneValidation';
import {
  ButtonBlock,
  ButtonCancel,
  ButtonSubmit,
  Container,
  ElementBlock,
  StyledInput,
  StyledSelect,
  TitleBlock,
  TitleText,
} from '@/components/Admin/CoachesContainer/CommonStyles';
import { IPartnerReferralsAddModal } from '@/types';
import { checkDuplicateEmail } from '@/utils/tools/emails';

const PartnerReferralsAddModal = ({
  isOpenAddModal,
  toggleOpenAddModal,
  handleCreatePartnerReferral,
}: IPartnerReferralsAddModal) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  useEffect(() => {
    if (isOpenAddModal) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setFirstNameError('');
      setLastNameError('');
      setEmailError('');
    }
  }, [isOpenAddModal]);

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(phoneValidation(e?.target?.value));
  };

  const handleSubmit = async () => {
    setFirstNameError(firstName ? '' : 'Please fill First Name');
    setLastNameError(lastName ? '' : 'Please fill Last Name');
    setEmailError(email ? '' : 'Please fill Email');

    if (firstName && lastName && email) {
      const emailIsExist = await checkDuplicateEmail(email);
      emailIsExist
        ? setEmailError(emailIsExist ? 'This email already exists' : '')
        : handleCreatePartnerReferral(firstName, lastName, email, phone, timezone);
    }
  };

  return (
    <>
      <Modal widthMax={370} title="" open={isOpenAddModal}>
        <Container>
          <TitleBlock>
            <TitleText>Add new PartnerReferral</TitleText>
          </TitleBlock>
          <ElementBlock>
            <StyledInput
              error={!!firstNameError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
              value={firstName}
              label="First Name"
              inputProps={{
                'data-testid': 'input-first-name',
              }}
              helperText={firstNameError}
              type="text"
              fullWidth
            />
          </ElementBlock>
          <ElementBlock>
            <StyledInput
              error={!!lastNameError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
              value={lastName}
              label="Last Name"
              inputProps={{
                'data-testid': 'input-last-name',
              }}
              helperText={lastNameError}
              type="text"
              fullWidth
            />
          </ElementBlock>
          <ElementBlock>
            <StyledInput
              error={!!emailError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              value={email}
              label="Email"
              inputProps={{
                'data-testid': 'input-email',
              }}
              helperText={emailError}
              type="email"
              fullWidth
            />
          </ElementBlock>
          <ElementBlock>
            <StyledInput
              onChange={handleChangePhone}
              value={phone}
              label="Phone"
              inputProps={{
                'data-testid': 'input-phone',
              }}
              type="text"
              fullWidth
            />
          </ElementBlock>
          <ElementBlock>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Timezone</InputLabel>
              <StyledSelect
                // variant="standard"
                label="Timezone"
                inputProps={{
                  'data-testid': 'select-timezone',
                }}
                native
                onChange={(e: any) => setTimezone(e.target.value)}
                value={timezone}
              >
                <option aria-label="None" value="" key={0}></option>
                {timezonesUSList.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </StyledSelect>
              <InputError text="" />
            </FormControl>
          </ElementBlock>
          <ButtonBlock>
            <ButtonCancel onClick={toggleOpenAddModal} variant="outlined">
              Cancel
            </ButtonCancel>
            <ButtonSubmit onClick={handleSubmit}>Create</ButtonSubmit>
          </ButtonBlock>
        </Container>
      </Modal>
    </>
  );
};

export default PartnerReferralsAddModal;
