import { addDoc, collection, query } from 'firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';

export const trackEvent = async (
  userId: string,
  coachId: string,
  type: string,
  beforeValue?: any,
  afterValue?: any,
): Promise<void> => {
  const ref = collection(db, 'status_log');
  await addDoc(ref, {
    userId,
    coachId,
    type,
    createdAt: new Date(),
    ...(beforeValue && { beforeValue }),
    ...(afterValue && { afterValue }),
  });
  return;
};
