import { styled } from '@mui/system';
import { CheckBoxOutlineBlankRounded, CloseRounded } from '@mui/icons-material';
import Input from '@/common/core/Input';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  marginBottom: '18px',
}));

export const Icon = styled(CheckBoxOutlineBlankRounded)(({ theme }) => ({
  position: 'absolute',
  left: '0',
  zIndex: 1,
  marginRight: '10px',
  color: 'rgba(15, 15, 33, 0.2);',
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  width: '100%',
  margin: '0px !important',
  height: 'auto',
  paddingLeft: '0px',
  '& .MuiInputBase-input': {
    height: '27px',
    marginLeft: '34px !important',
    marginRight: '26px !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid rgba(15, 15, 33, 0.2)',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 'unset',
  },
}));

export const Close = styled(CloseRounded)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  zIndex: 1,
  padding: '10px 0 10px 10px',
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.2)',
  fontSize: '16px',
}));

export const AddOption = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.4)',
}));
