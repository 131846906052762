import { Grid } from '@mui/material';
import { useAdminActions } from '@/hooks/admin/useAdminActions';
import { buildReversStructure, isAllActionNew } from '@/utils/data/action';
import {
  ColumnContainer,
  ColumnContentBlock,
  ColumnLineBlock,
  Container,
  NewCreatedActions,
  SubTypeNameBlock,
  SubTypeNameText,
  TypeNameBlock,
  TypeNameText,
} from './styles';
import { IAction, IActionCategory, IActionSubCategory, IActionsList } from '@/types';
import ActionsListItem from '../ActionsListItem';

const ActionsList = ({
  categoriesList,
  subcategoriesList,
  handleChooseAction,
  categoryListNew,
}: IActionsList) => {
  const { actionsList } = useAdminActions();

  const chooseEditAction = (actionId: string) => {
    const action = actionsList.find((action: IAction) => action.id === actionId);
    action && handleChooseAction(action);
  };

  return (
    <Container>
      {isAllActionNew(actionsList) ? (
        <Grid container spacing={0}>
          {buildReversStructure(categoryListNew).map((category, indexColumn: number) => (
            <Grid item md={4} key={category.enum}>
              <ColumnContainer>
                <ColumnContentBlock>
                  <TypeNameBlock>
                    <TypeNameText>{category.label}</TypeNameText>
                  </TypeNameBlock>
                  {category.habitTypes.map((habit: { name: string; id: string }, index) => (
                    <div key={`categoryHabitTypes${index}`}>
                      <SubTypeNameBlock>
                        <SubTypeNameText>{habit.name}</SubTypeNameText>
                      </SubTypeNameBlock>
                      {actionsList
                        .filter(
                          (action: IAction) =>
                            action.categoryId === habit.id && category.enum === action.habitType,
                        )
                        .sort((a, b) => a.sortingIndex - b.sortingIndex)
                        .map((action: IAction) => (
                          <ActionsListItem
                            key={action.id}
                            action={action}
                            chooseEditAction={chooseEditAction}
                          />
                        ))}
                    </div>
                  ))}
                </ColumnContentBlock>
                {indexColumn !== categoriesList.length - 1 && <ColumnLineBlock />}
              </ColumnContainer>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Grid container spacing={0}>
            {categoriesList.map((cat: IActionCategory, indexColumn: number) => (
              <Grid item md={4} key={cat.id}>
                <ColumnContainer>
                  <ColumnContentBlock>
                    <TypeNameBlock>
                      <TypeNameText>{cat.name}</TypeNameText>
                    </TypeNameBlock>
                    {subcategoriesList
                      .filter(
                        (subCat: IActionSubCategory) =>
                          subCat.category === cat.id &&
                          actionsList.filter(
                            (action: IAction) => action.subcategoryId === subCat.id,
                          ).length,
                      )
                      .map((subCat: IActionSubCategory) => (
                        <div key={subCat.id}>
                          <SubTypeNameBlock>
                            <SubTypeNameText>{subCat.name}</SubTypeNameText>
                          </SubTypeNameBlock>
                          {actionsList
                            .filter((action: IAction) => action.subcategoryId === subCat.id)
                            .sort((a, b) => a.sortingIndex - b.sortingIndex)
                            .map((action: IAction) => (
                              <ActionsListItem
                                key={action.id}
                                action={action}
                                chooseEditAction={chooseEditAction}
                              />
                            ))}
                        </div>
                      ))}
                  </ColumnContentBlock>
                  {indexColumn !== categoriesList.length - 1 && <ColumnLineBlock />}
                </ColumnContainer>
              </Grid>
            ))}
          </Grid>
          <NewCreatedActions>New created actions</NewCreatedActions>
          <Grid container spacing={0}>
            {buildReversStructure(categoryListNew).map((category, indexColumn: number) => (
              <Grid item md={4} key={category.enum}>
                <ColumnContainer>
                  <ColumnContentBlock>
                    <TypeNameBlock>
                      <TypeNameText>{category.label}</TypeNameText>
                    </TypeNameBlock>
                    {category.habitTypes.map((habit: { name: string; id: string }) => (
                      <div key={habit.id}>
                        <SubTypeNameBlock>
                          <SubTypeNameText>{habit.name}</SubTypeNameText>
                        </SubTypeNameBlock>
                        {actionsList
                          .filter((action: IAction) => action.categoryId === habit.id)
                          .sort((a, b) => a.sortingIndex - b.sortingIndex)
                          .map((action: IAction) => (
                            <ActionsListItem
                              key={action.id}
                              action={action}
                              chooseEditAction={chooseEditAction}
                            />
                          ))}
                      </div>
                    ))}
                  </ColumnContentBlock>
                  {indexColumn !== categoriesList.length - 1 && <ColumnLineBlock />}
                </ColumnContainer>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default ActionsList;
