import { timezonesUSList } from '@/config';
import { formatTime } from '@/utils/format/datetime';
import { IAdminCoach, ICoachAssignment, ICoachAssignmentConverted } from '@/types';

export const fromMapToObject = (coachAssignment: ICoachAssignment): ICoachAssignmentConverted => {
  if (coachAssignment && coachAssignment.coaches) {
    const coaches = Object.entries(coachAssignment.coaches).map((coach) => {
      const [userId, data] = coach;
      return { userId: userId, isMain: data.isMain };
    });
    return { coaches, hasCoachesAssigned: coachAssignment.hasCoachesAssigned };
  } else {
    return { coaches: null, hasCoachesAssigned: false };
  }
};

export const shiftInfo = (docData: IAdminCoach): string => {
  const { timezone, shiftData } = docData;

  const timeStarting = shiftData?.timeStarting ? shiftData?.timeStarting.seconds * 1000 : 0;
  const timeFinishing = shiftData?.timeFinishing ? shiftData?.timeFinishing.seconds * 1000 : 0;

  const timezoneLabel =
    timezone !== '' ? timezonesUSList.find((item) => item.value === timezone)?.label : '';

  return timeStarting !== 0 && timeFinishing !== 0
    ? `(${formatTime(timeStarting)} - ${formatTime(timeFinishing)} ${timezoneLabel})`
    : '';
};
