import { memo } from 'react';
import { format, isAfter, isSameDay, parseISO } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { Timestamp } from 'firebase/firestore';
import { TProgramWeeksData } from '@/types';

const ChatProgramWeeksForm = memo(
  (props: { index: number }) => {
    const form = useFormContext<TProgramWeeksData>();

    return (
      <div className="flex gap-4">
        <Controller
          control={form.control}
          name={`weeks.${props.index}.week`}
          rules={{ required: true }}
          render={(weekNumberInput) => {
            return (
              <TextField
                required
                label="Program week number"
                variant="outlined"
                className="[&>div]:rounded-lg"
                type="number"
                value={weekNumberInput.field.value || 0}
                InputProps={{
                  classes: {},
                }}
              />
            );
          }}
        />

        <Controller
          control={form.control}
          name={`weeks.${props.index}.startDate`}
          rules={{
            required: true,
            validate: (weekStartDateInput, frm) => {
              const prev = frm.weeks[props.index - 1]?.endDate?.toDate();
              if (
                !prev ||
                isSameDay(weekStartDateInput.toDate(), prev) ||
                isAfter(weekStartDateInput.toDate(), prev)
              )
                return true;
              return 'Must start after week before';
            },
          }}
          render={(weekStartDateInput) => (
            <TextField
              label="Start date"
              type="date"
              variant="outlined"
              className="[&>div]:rounded-lg"
              error={!!weekStartDateInput.fieldState.error}
              helperText={weekStartDateInput.fieldState.error?.message}
              {...weekStartDateInput.field}
              value={format(weekStartDateInput.field.value.toDate(), 'yyyy-MM-dd')}
              onChange={(v) =>
                weekStartDateInput.field.onChange(
                  Timestamp.fromDate(parseISO(v.target.value + 'T12:00:00Z')),
                )
              }
            />
          )}
        />

        <Controller
          control={form.control}
          name={`weeks.${props.index}.endDate`}
          rules={{
            required: true,
            validate: (weekEndDateInput, values) =>
              isAfter(weekEndDateInput.toDate(), values.weeks[props.index].startDate.toDate())
                ? undefined
                : 'End date must be after start date',
          }}
          render={(weekEndDateInput) => (
            <TextField
              label="End date"
              type="date"
              variant="outlined"
              className="[&>div]:rounded-lg"
              error={!!weekEndDateInput.fieldState.error}
              helperText={weekEndDateInput.fieldState.error?.message}
              {...weekEndDateInput.field}
              value={format(weekEndDateInput.field.value.toDate(), 'yyyy-MM-dd')}
              onChange={(v) =>
                weekEndDateInput.field.onChange(
                  Timestamp.fromDate(parseISO(v.target.value + 'T12:00:00Z')),
                )
              }
            />
          )}
        />

        {/* <button
          type='button'
          className='icon-[mdi--delete] text-error w-6 h-6 my-5 relative -top-1 hover:text-error/50'
          onClick={onRemove}
        ></button> */}
      </div>
    );
  },
  (ChatProgramWeeksFormInit, ChatProgramWeeksFormEdit) =>
    ChatProgramWeeksFormInit.index === ChatProgramWeeksFormEdit.index,
);

export default ChatProgramWeeksForm;
