import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RecipeContent from '@/components/Admin/ResourcesContainer/ResourcesAdd/ResourceRecipeArticleBlocks';
import { useAdminHandouts } from '@/hooks/admin/useAdminHandouts';
import { AppContent, AppWrapper, ButtonGroup, CancelBtn, CreateBtn } from './styles';
import { IResourceBlock } from '@/types';
import HandoutsAddSidebar from '../HandoutsAddSidebar';
import { toast } from 'sonner';

const NewHandoutsContainer = () => {
  const [resourceData, setResourceData] = useState<any>({ blocks: [] });
  const [blocks, setBlocks] = useState<IResourceBlock[]>([]);
  const [picture, setPicture] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [categoryId, setCategoryId] = useState<string>('');
  const [validator, setValidator] = useState<boolean>(false);
  const { addNewHandout, getHandoutById, editHandout } = useAdminHandouts();

  const navigate = useNavigate();
  const { handoutId } = useParams<{ handoutId?: string }>();

  useEffect(() => {
    setPicture(resourceData?.picture);
    setBlocks(resourceData?.blocks);
    setTitle(resourceData?.title);
    setCategoryId(resourceData?.categoryId);
    setValidator(!!resourceData?.title && !!resourceData?.picture);
  }, [resourceData]);

  useEffect(() => {
    if (handoutId) {
      getHandoutById(handoutId).then((handout) => {
        setResourceData(handout);
      });
    }
  }, []);

  const handleHandoutSubmit = () => {
    addNewHandout(title, categoryId, blocks, picture).then(() => {
      toast.success('Handout is added');
      navigate('/handouts');
    });
  };

  const handleHandoutUpdate = () => {
    editHandout(title, categoryId, blocks, picture, handoutId).then(() => {
      toast.success('Handout is updated');
      navigate('/handouts');
    });
  };

  const handleResourceDataChange = (callback: React.SetStateAction<any>) => {
    setResourceData(callback);
  };

  return (
    <div>
      <AppWrapper>
        <AppContent>
          <HandoutsAddSidebar
            handleResourceDataChange={handleResourceDataChange}
            resourceData={resourceData}
          />
          <RecipeContent
            block={blocks}
            resourceData={resourceData}
            handleResourceDataChange={handleResourceDataChange}
            isArticle={true}
          />
        </AppContent>
      </AppWrapper>
      <ButtonGroup>
        <CancelBtn variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </CancelBtn>
        {!handoutId ? (
          <CreateBtn disabled={!validator} onClick={handleHandoutSubmit}>
            Create
          </CreateBtn>
        ) : (
          <CreateBtn disabled={!validator} onClick={handleHandoutUpdate}>
            Update
          </CreateBtn>
        )}
      </ButtonGroup>
    </div>
  );
};

export default NewHandoutsContainer;
