import React, { useEffect, useState } from 'react';
import {
  checkFirstTimeMessageWarning,
  resetFirstTimeMessageWarning,
  selectSender,
  sendasInit,
} from '@/store/sendAs/sendAsSlice';
import { useAppDispatch, useAppSelector } from '@/hooks';
import Menu from '@/common/core/Menu';

import {
  SeparateSelectArrowBlock,
  SeparateSelectArrowIcon,
  SeparateSelectBlock,
  SeparateSelectInfoBlock,
  SeparateSelectInfoText,
  SubMenu,
  SubMenuText,
} from './styles';
import { CircularProgress } from '@mui/material';

const ChatAddMessageSelectCoach = () => {
  const dispatch = useAppDispatch();
  const sendAs = useAppSelector((x) => x.sendAs);
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { loading } = sendAs;
  const { id } = customer || {};

  useEffect(() => {
    if (id) {
      dispatch(sendasInit({ clientUser: id }));
    }
  }, [id, dispatch]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) =>
    anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);

  if (loading || !id) {
    return <CircularProgress />;
  }

  return (
    <>
      <SeparateSelectBlock onClick={toggleMenu}>
        <SeparateSelectInfoBlock>
          <SeparateSelectInfoText>
            {sendAs.selected
              ? `${sendAs.selected.firstName} ${sendAs.selected.main ? '(main)' : ''}`
              : 'Me'}
          </SeparateSelectInfoText>
        </SeparateSelectInfoBlock>
        <SeparateSelectArrowBlock>
          <SeparateSelectArrowIcon />
        </SeparateSelectArrowBlock>
      </SeparateSelectBlock>
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        {sendAs.coaches.map((coach) => (
          <SubMenu
            key={coach.id}
            onClick={() => {
              setAnchorEl(null);
              dispatch(selectSender(coach));
              if (coach.main) {
                dispatch(resetFirstTimeMessageWarning());
              } else {
                dispatch(checkFirstTimeMessageWarning({ userId: id, userIdFrom: coach.id! }));
              }
            }}
          >
            <SubMenuText isActive={sendAs.selected?.id === coach?.id}>
              {coach.firstName} {coach.main && '(main coach)'}
            </SubMenuText>
          </SubMenu>
        ))}
      </Menu>
    </>
  );
};

export default ChatAddMessageSelectCoach;
