/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react';
import ModalConfirm from '@/common/core/Modal/ModalConfirm';
import ModalInform from '@/common/core/Modal/ModalInform';
import { useAdminResourceCategories } from '@/hooks/admin/useAdminResourceCategories';
import { useAdminResource } from '@/hooks/admin/useAdminResource';
import { AppSideBar, HeaderBlock, MainBlock, TitleBlock, TitleText } from './styles';
import { IResourceCategory, IResourcesSideBar } from '@/types';
import { ResourcesCategory } from './ResourcesCategory';
import CategoryEditModal from './ResourcesCategoryEditModal';
import CategoryAddModal from './ResourcesCategoryAddModal';
import TagAddModal from './ResourcesTagAddModal';
import TagEditModal from './ResourcesTagEditModal';

const Resources = ({
  handleCategorySelected,
  handleTagsSelected,
  currentCategoryId = '',
}: IResourcesSideBar) => {
  const [isOpenCategoryEditModal, setIsOpenCategoryEditModal] = useState<boolean>(false);
  const [isOpenCategoryAddModal, setIsOpenCategoryAddModal] = useState<boolean>(false);
  const [isOpenTagAddModal, setIsOpenTagAddModal] = useState<boolean>(false);
  const [isOpenCategoryRemoveModal, setIsOpenCategoryRemoveModal] = useState<boolean>(false);
  const [isOpenTagRemoveModal, setIsOpenTagRemoveModal] = useState<boolean>(false);
  const [isOpenTagEditModal, setIsOpenTagEditModal] = useState<boolean>(false);
  const [isOpenInformResourceModal, setIsOpenInformResourceModal] = useState<boolean>(false);
  const [isOpenInformTagModal, setIsOpenInformTagModal] = useState<boolean>(false);
  const [category, setCategory] = useState<IResourceCategory>();
  const [tagIndex, setTagIndex] = useState<number>(0);
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [callbackCategoryEdited, setCallbackCategoryEdited] = useState<
    (name: string, url: string) => void
  >(() => (name: string, url: string) => {});
  const [callbackCategoryAdded, setCallbackCategoryAdded] = useState<
    (newCategory: IResourceCategory) => void
  >(() => (newCategory: IResourceCategory) => {});
  const [callbackTagAdded, setCallbackTagAdded] = useState<(tag: string) => void>(
    () => (tag: string) => {},
  );
  const [callbackCategoryRemoved, setCallbackCategoryRemoved] = useState<
    (categoryId: string) => void
  >(() => () => {});
  const [callbackTagEdited, setcallbackTagEdited] = useState<
    (tagIndex: number, newTag: string) => void
  >(() => () => {});
  const [callbackTagRemoved, setcallbackTagRemoved] = useState<(tagIndex: number) => void>(
    () => () => {},
  );
  const { categoriesList, editCategory, addNewCategory, editCategoryTags, removeCategory } =
    useAdminResourceCategories();

  const { resourcesList } = useAdminResource();
  const MAX_ORDER_VALUE = 9999;

  const toggleCategoryEditModal = () => {
    setIsOpenCategoryEditModal(!isOpenCategoryEditModal);
  };

  const toggleCategoryAddModal = () => {
    setIsOpenCategoryAddModal(!isOpenCategoryAddModal);
  };

  const toggleTagAddModal = () => {
    setIsOpenTagAddModal(!isOpenTagAddModal);
  };

  const toggleCategoryRemoveModal = () => {
    setIsOpenCategoryRemoveModal(!isOpenCategoryRemoveModal);
  };

  const toggleTagEditModal = () => {
    setIsOpenTagEditModal(!isOpenTagEditModal);
  };

  const toggleTagRemoveModal = () => {
    setIsOpenTagRemoveModal(!isOpenTagRemoveModal);
  };

  const toggleInformResourceModal = () => {
    setIsOpenInformResourceModal((prevState) => !prevState);
  };

  const toggleInformTagModal = () => {
    setIsOpenInformTagModal((prevState) => !prevState);
  };

  const handleCategoryEdit = (
    sourceCategory: IResourceCategory,
    categoryEditedHandler: (name: string, url: string) => void,
  ) => {
    setCallbackCategoryEdited(() => categoryEditedHandler);
    setCategory(sourceCategory);
    toggleCategoryEditModal();
  };

  const handleCategoryEdited = (name: string, url: string) => {
    editCategory(category!.id, name, url);
    toggleCategoryEditModal();
    callbackCategoryEdited(name, url);
  };

  const handleCategoryAdd = (
    parentId: string,
    categoryAddedHandler: (newCategory: IResourceCategory) => void,
  ) => {
    setCallbackCategoryAdded(() => categoryAddedHandler);
    setParentCategoryId(parentId);
    toggleCategoryAddModal();
  };

  const handleCategoryCreated = async (name: string, url: string) => {
    const id: string = await addNewCategory(name, url, parentCategoryId);
    const newCategory = {
      id: id,
      name: name,
      children: [],
      picture: url,
      tags: [],
      order: MAX_ORDER_VALUE,
    };
    callbackCategoryAdded(newCategory);
    toggleCategoryAddModal();
  };

  const handleTagAdd = (
    sourceCategory: IResourceCategory,
    callbackTagAdded: (tag: string) => void,
  ) => {
    setCategory(sourceCategory);
    setCallbackTagAdded(() => callbackTagAdded);
    toggleTagAddModal();
  };

  const handleTagAdded = (tag: string) => {
    if (category !== undefined) {
      const newTagArray = [...category.tags, tag];
      editCategoryTags(category.id, newTagArray);
      callbackTagAdded(tag);
      toggleTagAddModal();
    }
  };

  const handleCategoryRemove = (
    sourceCategory: IResourceCategory,
    callbackCategoryRemoved: (categoryId: string) => void,
  ) => {
    if (sourceCategory !== undefined) {
      const includedRecourse = resourcesList.some(
        (recourse) => recourse.categoryId === sourceCategory.id,
      );
      if (!!sourceCategory.children.length || includedRecourse === false) {
        setCallbackCategoryRemoved(() => callbackCategoryRemoved);
        setCategory(sourceCategory);
        toggleCategoryRemoveModal();
      } else {
        toggleInformResourceModal();
      }
    }
  };

  const handleCategoryRemoved = async () => {
    if (category !== undefined) {
      await removeCategory(category.id);
      callbackCategoryRemoved(category.id);
      toggleCategoryRemoveModal();
    }
  };

  const handleTagEdit = (
    sourceCategory: IResourceCategory,
    tagIndex: number,
    callbackTagEdited: (tagIndex: number, tag: string) => void,
  ) => {
    setCategory(sourceCategory);
    setTagIndex(tagIndex);
    setcallbackTagEdited(() => callbackTagEdited);
    toggleTagEditModal();
  };

  const handleTagEdited = (newTag: string) => {
    toggleTagEditModal();
    if (category !== undefined) {
      const tags = [...category.tags];
      tags[tagIndex] = newTag;
      editCategoryTags(category.id, tags);
      callbackTagEdited(tagIndex, newTag);
    }
  };

  const handleTagRemove = (
    sourceCategory: IResourceCategory,
    tagIndex: number,
    callbackTagRemoved: (tagIndex: number) => void,
  ) => {
    if (sourceCategory !== undefined) {
      const includedRecourse = resourcesList.filter(
        (recourse) => recourse.categoryId === sourceCategory.id,
      );
      const includedTags: string[] = [];
      includedRecourse.forEach((resource) => {
        const recourseTags = resource.tags;
        if (recourseTags.includes(sourceCategory.tags[tagIndex])) {
          includedTags.push(sourceCategory.tags[tagIndex]);
        }
      });
      if (includedTags.length > 0) {
        toggleInformTagModal();
      } else {
        setCategory(sourceCategory);
        setTagIndex(tagIndex);
        setcallbackTagRemoved(() => callbackTagRemoved);
        toggleTagRemoveModal();
      }
    }
  };

  const handleTagRemoved = () => {
    toggleTagRemoveModal();
    if (category !== undefined) {
      const tags = category.tags.filter((item, i) => i !== tagIndex);
      editCategoryTags(category.id, tags);
      callbackTagRemoved(tagIndex);
    }
  };

  return (
    <AppSideBar>
      <HeaderBlock>
        <TitleBlock>
          <TitleText>Resources</TitleText>
        </TitleBlock>
      </HeaderBlock>
      <MainBlock>
        {categoriesList.map((category) => (
          <ResourcesCategory
            category={category}
            level={0}
            handleCategorySelected={handleCategorySelected}
            handleTagsSelected={handleTagsSelected}
            handleEditCategory={handleCategoryEdit}
            handleAddCategory={handleCategoryAdd}
            handleAddTag={handleTagAdd}
            handleRemoveCategory={handleCategoryRemove}
            handleEditTag={handleTagEdit}
            handleDeleteTag={handleTagRemove}
            removeChild={() => {}}
            currentCategoryId={currentCategoryId}
            key={category.id}
          />
        ))}
      </MainBlock>

      <CategoryEditModal
        isOpenAddModal={isOpenCategoryEditModal}
        toggleOpenAddModal={toggleCategoryEditModal}
        handleEditCategory={handleCategoryEdited}
        category={category}
      />

      <CategoryAddModal
        isOpenAddModal={isOpenCategoryAddModal}
        toggleOpenAddModal={toggleCategoryAddModal}
        handleCreateCategory={handleCategoryCreated}
      />

      <TagAddModal
        isOpenAddModal={isOpenTagAddModal}
        toggleOpenAddModal={toggleTagAddModal}
        handleCreateTag={handleTagAdded}
        tags={category === undefined ? [] : category.tags}
      />

      <ModalConfirm
        isOpenConfirmModal={isOpenCategoryRemoveModal}
        toggleOpenConfirmModal={toggleCategoryRemoveModal}
        handleSumbit={handleCategoryRemoved}
        title="Do you want to delete this subcategory?"
        note="You will not be able to undo this."
      />

      <ModalConfirm
        isOpenConfirmModal={isOpenCategoryRemoveModal}
        toggleOpenConfirmModal={toggleCategoryRemoveModal}
        handleSumbit={handleCategoryRemoved}
        title="Do you want to delete this category?"
        note="You will not be able to undo this."
      />

      <ModalConfirm
        isOpenConfirmModal={isOpenTagRemoveModal}
        toggleOpenConfirmModal={toggleTagRemoveModal}
        handleSumbit={handleTagRemoved}
        title="Do you want to delete this tag?"
        note="You will not be able to undo this."
      />

      <ModalInform
        isOpenInformModal={isOpenInformResourceModal}
        closeOpenInformModal={toggleInformResourceModal}
        title="You can’t delete this res because you have at least 1 res there"
        note="You can delete a subcategory if it does not contain res."
      />

      <ModalInform
        isOpenInformModal={isOpenInformTagModal}
        closeOpenInformModal={toggleInformTagModal}
        title="You can’t delete this tag because you have at least 1 resource there"
        note="You can delete a tag if it does not contain resource."
      />

      <TagEditModal
        isOpenAddModal={isOpenTagEditModal}
        toggleOpenAddModal={toggleTagEditModal}
        handleEditTag={handleTagEdited}
        currentTag={
          category === undefined || category?.tags === undefined ? '' : category?.tags[tagIndex]
        }
      />
    </AppSideBar>
  );
};

export default Resources;
