import { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Alert } from '@mui/material';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@/common/core/Button';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getCompletion, getExtraCompletion, resetMessages } from '@/store/automation/aiSlice';
import {
  getActivityPlan,
  getCurrentWeight,
  getGlp1AndDiabetes,
  getLastWeekExistingGoals,
  getLastWeekNewGoals,
  getLastWeekObstacles,
  getStartWeight,
  getUserAge,
  getUserCalendarWeek,
} from '@/utils/data/user';
import {
  AppBarTitle,
  FeedbackText,
  FeedbackTitle,
  StyledAppBar,
} from '../DrawerFeedbackProgress/styles';
import { resetFaq } from '@/store/customers/progressFaqSlice';
import { fetchCurrentProgramWeek, fetchWeekProgram } from '@/store/program/weekProgramSlice';
import { fetchCustomerById } from '@/store/customers/customerInfosSlice';
import { fetchBodyProfiles } from '@/store/customers/bodyProfileSlice';
import { clearQuotedMessage } from '@/store/chat/quotedMessageSlice';
import { listProgramActions } from '@/store/program/programActionsSlice';
import { IframeCloseIcon, StyledDrawer } from '@/styles/drawers';
import { useDrawerOffset } from '@/hooks/chat/useDrawerOffset';
import { ProgramAction } from '@fitmate-coach/fitmate-types';

const DrawerFaq = () => {
  const dispatch = useAppDispatch();
  const { message, openedDrawer } = useAppSelector((state) => state.progressFaq);
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { weeks, currentWeekPlan } = useAppSelector((state) => state.weekProgram);
  const { profiles } = useAppSelector((state) => state.bodyProfile);
  const { loading, messages, feedbackLaunched, errorMsg } = useAppSelector(
    (state) => state.automationAi,
  );
  const { programActions } = useAppSelector((state) => state.programActions);
  const [fitmateRelated, setFitmateRelated] = useState('no');
  const [extraContent, setExtraContent] = useState('');
  const [called, setCalled] = useState(false);
  const getDrawerOffset = useDrawerOffset();

  useEffect(() => {
    if (message) {
      const { userId } = message;
      dispatch(fetchCustomerById(userId));
    }
  }, [message]);

  useEffect(() => {
    if (customer?.id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchWeekProgram(customer.id));
      dispatch(fetchBodyProfiles(customer.id));
    }
  }, [customer]);

  useEffect(() => {
    if (weeks && weeks.length > 0 && customer?.id) {
      dispatch(
        fetchCurrentProgramWeek({ userId: customer.id, currentPlanId: weeks[weeks.length - 1].id }),
      );
      dispatch(listProgramActions(customer.id));
    }
  }, [weeks, customer]);

  useEffect(() => {
    if (customer && weeks && programActions && message && !called) {
      handleLaunchProgressFeedback();
      setCalled(true);
    }
  }, [customer, weeks, programActions, message, called]);

  const handleLaunchProgressFeedback = async () => {
    if (customer) {
      const calorieTargetAction = programActions.find((pa: ProgramAction) =>
        pa.published?.goal.includes('calorie'),
      );
      const payload = {
        feedbackType: '13jwMaFuPw5EDk7Yc8ktR9oBxVirvC_kGiu92B6GuMBM', // gdoc id
        client_name: customer.firstName,
        'client.gender': customer.gender ?? 'not applicable',
        client_age: getUserAge(customer),
        'client.height': customer.height ?? 'missing information',
        client_start_weight: getStartWeight(profiles),
        client_current_weight: getCurrentWeight(profiles),
        glp1_diabetes_status: getGlp1AndDiabetes(customer),
        activity_plan: getActivityPlan(customer),
        calendar_week_number: getUserCalendarWeek(customer),
        last_weeks_goals: getLastWeekNewGoals(weeks, programActions),
        last_weeks_existing_goals: getLastWeekExistingGoals(weeks, programActions),
        obstacles: getLastWeekObstacles(currentWeekPlan),
        target_daily_calorie: '',
        target_daily_protein: '',
        target_daily_fibre: '',
        customerQuestion: message.body,
        caloryTarget: calorieTargetAction ? 'yes' : 'no',
        isRelatedToFitmate: fitmateRelated,
        user_important_infos: customer.importantInfo,
        weekNumber: currentWeekPlan?.weekNumber ?? 'not week set',
        goalArea: '',
        isFaq: true,
      };

      dispatch(getCompletion(payload));
    }
  };

  const handleExtraFeedback = () => {
    dispatch(
      getExtraCompletion({
        prompt: extraContent,
      }),
    );
  };

  const handleCloseDrawer = () => {
    dispatch(resetMessages());
    dispatch(resetFaq());
    dispatch(clearQuotedMessage());
  };

  return (
    <StyledDrawer
      isOpen={!!openedDrawer}
      variant="persistent"
      anchor="right"
      open={openedDrawer}
      PaperProps={{
        width: 400,
      }}
      width={400}
      rightOffset={getDrawerOffset('faq')}
    >
      <StyledAppBar position="sticky">
        <Toolbar>
          <AppBarTitle>Give feedback or answer question</AppBarTitle>
          <IframeCloseIcon as={CloseIcon} onClick={handleCloseDrawer} />
        </Toolbar>
      </StyledAppBar>
      <Container>
        {errorMsg && <Alert severity="error">An error occurred: {errorMsg}</Alert>}

        {!feedbackLaunched && (
          <Alert severity="info">
            Please fill this form.
            <br />
            <br />
            <b>IMPORTANT:</b> FAQs are any inquiry to seek information and clarification (are eggs a
            good protein source?).
            <br />
            <br />
            If you're client is actually having a setback that's expressed as a question or as a
            comment e.g "I struggled to go to the gym yesterday because was too tired, not sure what
            to do?", please use the Setback Co-pilot OR address the setback in the end of week
            review instead.”
          </Alert>
        )}

        {!feedbackLaunched && (
          <Card>
            <CardContent>
              <div>
                <Typography>Is this question related to Fitmate App or program?</Typography>
                <Select
                  className="mt-3 mb-7"
                  variant="outlined"
                  fullWidth
                  labelId="fitmate-related"
                  id="fitmate-related"
                  value={fitmateRelated}
                  onChange={(e) => setFitmateRelated(e.target.value as string)}
                >
                  <MenuItem value="no">No</MenuItem>
                  <MenuItem value="yes">Yes</MenuItem>
                </Select>
              </div>
            </CardContent>
            <CardActions>
              <Button color="primary" onClick={handleLaunchProgressFeedback}>
                Start feedback
              </Button>
            </CardActions>
          </Card>
        )}

        {loading && (
          <div>
            <CircularProgress /> generating feedback...
          </div>
        )}

        {messages && messages.length > 0 && (
          <Card style={{ marginTop: 10 }}>
            <FeedbackTitle title="FAQ feedback:" />
            <CardContent>
              <FeedbackText>
                please share any info that could improve the answer and make it more customized (eg
                the client's calorie target is 1460 calories, the client is vegetarian, etc.)
              </FeedbackText>
              <TextField
                variant="outlined"
                multiline
                fullWidth
                value={extraContent}
                onChange={(e) => setExtraContent(e.target.value)}
              />
            </CardContent>
            <CardActions>
              <Button color="primary" variant="contained" onClick={handleExtraFeedback}>
                Send
              </Button>
            </CardActions>
          </Card>
        )}
      </Container>
    </StyledDrawer>
  );
};

export default DrawerFaq;
