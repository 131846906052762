import { useState } from 'react';
import { Typography } from '@mui/material';
import { ArrowDropUpRounded, Edit } from '@mui/icons-material';
import Questions from '../ChatSurveysQuestions';
import { useChatSurveyUpdate } from '@/hooks/chat/useChatSurveyUpdate';
import { EStatus, EStatusText, IChatSurveysActive, ISurvey } from '@/types';
import { AccordionSummaryStyled, ItemTitle, ItemWrapper, ReviewedButton, Title } from './styles';
import { toast } from 'sonner';

const ChatSurveysActive = ({ surveysList }: IChatSurveysActive) => {
  const { editSurvey } = useChatSurveyUpdate();

  const [isExpanded, setIsExpanded] = useState<string | false>(false);
  const [editableSurvey, setEditableSurvey] = useState<ISurvey>();

  const isCompleted = (status: string) => status === EStatus.COMPLETED;

  const handleChange = (panelId: string) => (event: any, isExpanded: boolean) => {
    setIsExpanded(isExpanded ? panelId : false);
    setEditableSurvey(getCurrentSurvey(panelId));
  };

  const getCurrentSurvey = (surveyId: string) =>
    surveysList.find((survey) => survey.id === surveyId);

  const handleReviewed = (id: string | undefined) => {
    if (id) {
      editSurvey(id, { ...editableSurvey, status: EStatus.APPROVED }).then(() => {
        setIsExpanded(false);
        toast.success('Reviewed');
      });
    }
  };

  const handleEditSurvey = (
    surveyId: string,
    indexStage: number,
    questionId: string,
    value: string[],
  ) => {
    const currentSurvey = getCurrentSurvey(surveyId);
    currentSurvey?.stages[indexStage].questions.forEach((item: any) => {
      if (item.id === questionId) {
        item.answer.answers = value;
      }
    });
    setEditableSurvey(currentSurvey);
  };

  return (
    <div>
      <Title>Active survey</Title>
      {surveysList.length > 0 &&
        surveysList.map(({ id, status, stages, title }: ISurvey) => (
          <ItemWrapper
            key={id}
            expanded={isExpanded === id}
            onChange={handleChange(id ?? '')}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummaryStyled
              expandIcon={<ArrowDropUpRounded sx={{ transform: 'rotate(180deg)' }} />}
              aria-controls={title}
              id={id}
            >
              <ItemTitle>
                {title}&nbsp;-&nbsp;
                <span style={isCompleted(status) ? { color: 'theme.palette.secondary.main' } : {}}>
                  {EStatusText[status]}&nbsp;
                </span>
                {isCompleted(status) && <Edit sx={{ color: 'theme.palette.secondary.main' }} />}
              </ItemTitle>
            </AccordionSummaryStyled>
            <AccordionSummaryStyled>
              {stages.map(({ questions }, index) => (
                <Questions
                  key={index}
                  indexStage={index}
                  isEditable={isCompleted(status)}
                  questions={questions}
                  status={status}
                  suerveyId={id}
                  handleEditSurvey={handleEditSurvey}
                />
              ))}
              {isCompleted(status) && (
                <ReviewedButton onClick={() => handleReviewed(id)}>Reviewed</ReviewedButton>
              )}
            </AccordionSummaryStyled>
          </ItemWrapper>
        ))}
      {!surveysList.length && (
        <Typography>There will appear any active survey you send to user</Typography>
      )}
    </div>
  );
};

export default ChatSurveysActive;
