import { formatDistanceToNow } from 'date-fns';
import { useAppSelector } from '@/hooks';
import { TFirePlan } from '@/types';

const UpdateMessage = (props: { plan?: TFirePlan }) => {
  const { currentPlanStatus } = useAppSelector((state) => state.weekProgram);
  const { programActionStatus } = useAppSelector((state) => state.programActions);

  const message = (() => {
    if (currentPlanStatus === 'draft')
      return 'Clicking this will make the plan visible to the client.';
    const timeAgo = formatDistanceToNow(props.plan?.lastPublishedDate?.toDate() ?? Date.now(), {
      addSuffix: true,
    });
    if (currentPlanStatus === 'published' && programActionStatus === 'published')
      return `Your plan is up-to-date. Last published ${timeAgo}`;
    return `Last published ${timeAgo}`;
  })();

  return <>{message}</>;
};

export default UpdateMessage;
