import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { getLocationByType } from '@/utils/data/surveys';
import { HeaderBlock, ListWrapper, StyledButton, Template, TitleText } from './styles';
import { ISurveyTemplate } from '@/types';
import SurveysItem from '../SurveysItem';

interface ISurveysContent {
  title: string;
  buttonTitle: string;
  list: ISurveyTemplate[];
  type: string;
}

const SurveysContent = ({ title, buttonTitle, list, type }: ISurveysContent) => {
  const navigate = useNavigate();

  return (
    <Template>
      <HeaderBlock>
        <TitleText>{title}</TitleText>
        <StyledButton onClick={() => navigate(getLocationByType(type))}>{buttonTitle}</StyledButton>
      </HeaderBlock>
      <ListWrapper>
        {list.length > 0 &&
          list.map((survey: ISurveyTemplate) => (
            <SurveysItem key={survey.id} surveysItem={survey} />
          ))}
        {!list.length && (
          <Typography>{`Empty list ${title}. Please, press '${buttonTitle}' button`}</Typography>
        )}
      </ListWrapper>
    </Template>
  );
};

export default SurveysContent;
