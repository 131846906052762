import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Checkbox, IconButton } from '@mui/material';
import { TagName, StyledTagItem } from '../ResourcesCategory/CommonStyles';
import { ITagItem } from '@/types';
import ThreeDotsMenu from '../ResourcesCategoryThreeDotsMenu';

const TagItem = ({
  index,
  tag,
  chooseRenameTag,
  chooseDeleteTag,
  handleCheckboxChange,
}: ITagItem) => {
  const [visibility, setVisibility] = useState<object>({ visibility: 'hidden' });
  const [anchorEditMenuEl, setAnchorEditMenuEl] = useState<null | HTMLElement>(null);

  const toggleTagEditMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (anchorEditMenuEl) {
      setAnchorEditMenuEl(null);
    } else {
      setAnchorEditMenuEl(e.currentTarget);
    }
  };

  const openTagEditMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setVisibility({ visibility: 'hidden' });
    setAnchorEditMenuEl(null);
    chooseRenameTag(index);
  };

  const handleDeleteTag = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setVisibility({ visibility: 'hidden' });
    setAnchorEditMenuEl(null);
    chooseDeleteTag(index);
  };

  const toggleVisibilityOn = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setVisibility({ visibility: 'visible' });
  };

  const toggleVisibilityOff = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setVisibility({ visibility: 'hidden' });
  };

  return (
    <StyledTagItem onMouseOver={toggleVisibilityOn} onMouseLeave={toggleVisibilityOff}>
      <TagName
        key={index}
        control={
          <Checkbox
            onChange={handleCheckboxChange}
            key={`${tag}-key-checkbox`}
            name={tag}
            value={tag}
            color="primary"
          />
        }
        label={tag}
      />
      <>
        <IconButton style={visibility} onClick={toggleTagEditMenu} size="large">
          <MoreVertIcon />
        </IconButton>
        <ThreeDotsMenu
          anchorEl={anchorEditMenuEl}
          editTitle="Rename"
          toggleHandler={toggleTagEditMenu}
          renameClickHandler={openTagEditMenu}
          deleteClickHandler={handleDeleteTag}
        />
      </>
    </StyledTagItem>
  );
};

export default TagItem;
