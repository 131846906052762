import { styled } from '@mui/system';
import { Container } from '@mui/material';
import Input from '@/common/core/Input';
import Button from '@/common/core/Button';

export const Wrapper = styled(Container)(({ theme }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  paddingBottom: '100px',
}));

export const CentralColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '842px',
  overflowY: 'auto',
  paddingRight: '24px',
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  marginBottom: '32px',
}));

export const StyledInputTitle = styled(Input)(({ theme }) => ({
  marginTop: '0px',
  height: 'auto',
  fontSize: '32px',
  fontWeight: 500,
}));

export const ItemsWrapper = styled('ul')(({ theme }) => ({
  width: '100%',
  paddingLeft: '0px',
  marginTop: '24px',
}));

export const AddQuestion = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  boxShadow: '0px -1px 0px #E4E7ED',
  borderRadius: 8,
  padding: '12px 43px',
  marginBottom: '36px',
  height: '44px !important',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  backgroundColor: 'transparent',
  color: 'rgba(15, 15, 33, 0.6)',
  fontSize: 14,
  fontWeight: 600,
}));

export const ButtonGroup = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  padding: '12px 32px 12px 0',
  backgroundColor: '#F9FAFC',
  boxShadow: '0px -1px 0px #E4E7ED',
  justifyContent: 'flex-end',
  display: 'flex',
  gap: 8,
  zIndex: 1,
}));

export const CancelBtn = styled(Button)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  backgroundColor: 'transparent',
  color: 'rgba(15, 15, 33, 0.6)',
}));

export const CreateBtn = styled(Button)(({ theme }) => ({
  padding: '12px 43px',
  marginRight: 28,
  color: 'white',
  boxShadow: 'none',
  border: 'none',
  borderRadius: 8,
}));
