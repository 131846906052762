import { Typography, Radio } from '@mui/material';
import {
  MessageText,
  OpenBtn,
  ResourceCaption,
  ResourceCardCoach,
  ResourceCardHeader,
  ResourceCardWrapperCoach,
  ResourceImgCoach,
  TitleCoach,
} from '@/styles/resources';
import { EResourceTypes, IResourcesExtention } from '@/types';
import { convertToMinutes, timeConvert } from '@/utils/format/convert';

const Resources = ({ resource, checkedId = '', isMessage = false }: IResourcesExtention) => {
  const getVideoPreview = (url: string) => {
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const videoId: string | null = urlParams.get('v');
    return 'https://i.ytimg.com/vi/' + videoId + '/mqdefault.jpg';
  };

  const calcDuration = () => {
    return (
      convertToMinutes(resource?.parameters?.get('prep_time')) +
      convertToMinutes(resource?.parameters?.get('cook_time'))
    );
  };

  return (
    <ResourceCardWrapperCoach checkedResource={checkedId === resource?.id} isMessage={isMessage}>
      <div style={{ position: 'relative' }}>
        {!isMessage && (
          <ResourceCardHeader>
            <OpenBtn>
              <Typography>Open</Typography>
            </OpenBtn>
            <Radio value={resource?.id} checked={checkedId === resource?.id} />
          </ResourceCardHeader>
        )}
        <ResourceCardCoach>
          {resource?.thumbnail && (
            <ResourceImgCoach src={resource.thumbnail} alt={resource.title} />
          )}
          {resource?.type === EResourceTypes.WORKOUT && (
            <ResourceImgCoach src={getVideoPreview(resource.blocks[1].url)} alt={resource.title} />
          )}
          {resource?.type === EResourceTypes.RECIPE && (
            <ResourceCaption>
              <MessageText>{`${resource?.parameters?.get('calories')} kcal`}</MessageText>
              <MessageText>{timeConvert(calcDuration())}</MessageText>
            </ResourceCaption>
          )}
        </ResourceCardCoach>
      </div>
      <TitleCoach checkedId={checkedId === resource?.id}>{resource?.title}</TitleCoach>
    </ResourceCardWrapperCoach>
  );
};

export default Resources;
