import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Edit as EditIcon, PersonAdd as AssignIcon } from '@mui/icons-material';
import Menu from '@/common/core/Menu';
import { formatDateTime } from '@/utils/format/datetime';
import { timezonesUSList } from '@/config';
import { IClientsListItem } from '@/types';
import {
  ActionsBlock,
  BottomLineBlock,
  Container,
  DateBlock,
  GenderBlock,
  LocationBlock,
  NameBlock,
  NameBottomBlock,
  NameTopBlock,
  StyledAssignIcon,
  StyledMenuIcon,
  SubMenu,
  Text1,
  Text2,
  Text3,
  TimezoneBlock,
} from './styles';

const ClientsListItem = ({ item, chooseEditUser, chooseAssignmentUser }: IClientsListItem) => {
  const {
    id: userId,
    firstName,
    lastName,
    email,
    gender,
    location,
    timezone,
    createdAt,
    coachAssignment,
    partner,
  } = item;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const timezoneLabel =
    timezone !== '' ? timezonesUSList.find((item) => item.value === timezone)?.label : '';

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEdit = () => {
    chooseEditUser(userId);
    setAnchorEl(null);
  };

  const handleAssignment = () => {
    chooseAssignmentUser(userId, `${firstName} ${lastName}`, coachAssignment?.coaches || []);
    setAnchorEl(null);
  };

  return (
    <>
      <Container>
        <NameBlock>
          <NameTopBlock>
            <Text1>
              {firstName !== '' ? firstName : '-'} {lastName !== '' ? lastName : '-'}{' '}
              {partner ? '[BGS client]' : ''}
            </Text1>
          </NameTopBlock>
          <NameBottomBlock>
            <Text3>
              {email} - {userId}{' '}
            </Text3>
          </NameBottomBlock>
        </NameBlock>
        <GenderBlock>
          <Text2>{gender !== '' ? gender : '-'}</Text2>
        </GenderBlock>
        <LocationBlock>
          <Text2>{location !== '' ? location : '-'}</Text2>
        </LocationBlock>
        <TimezoneBlock>
          <Text2>{timezoneLabel}</Text2>
        </TimezoneBlock>
        <DateBlock>
          <Text2>{formatDateTime(createdAt?.seconds * 1000)}</Text2>
        </DateBlock>
        <ActionsBlock>
          {coachAssignment?.team || coachAssignment?.hasCoachesAssigned ? (
            <IconButton onClick={toggleMenu} size="large">
              <StyledMenuIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() =>
                chooseAssignmentUser(
                  userId,
                  `${firstName} ${lastName}`,
                  coachAssignment?.coaches || [],
                )
              }
              size="large"
            >
              <StyledAssignIcon />
            </IconButton>
          )}
        </ActionsBlock>
      </Container>
      <BottomLineBlock />
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <SubMenu onClick={handleEdit} key="edit">
          Edit personal info
          <EditIcon sx={{ fontSize: 18 }} />
        </SubMenu>
        <SubMenu onClick={handleAssignment} key="assign">
          Edit coach
          <br />
          assignment
          <AssignIcon sx={{ fontSize: 18 }} />
        </SubMenu>
      </Menu>
    </>
  );
};

export default ClientsListItem;
