import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { CategoryCaption, CategoryCoachCard, CategoryCoachImg } from './styles';
import { ICategories, IResourceCategory } from '@/types';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const Categories = ({ category, handleCategorySelected = () => {} }: ICategories) => {
  const [children, setChildren] = useState<IResourceCategory[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [picture, setPicture] = useState<string>('');
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setChildren(category.children);
    setTags(category.tags);
    setName(category.name);
    setPicture(category.picture);
  }, [category]);

  const getCurrentCategory = (): IResourceCategory => {
    return {
      id: category.id,
      name: name,
      tags: tags,
      children: children,
      picture: picture,
      order: 0,
    };
  };

  const handleCategoryClick = () => {
    handleCategorySelected(getCurrentCategory());
  };

  return (
    <CategoryCoachCard onClick={handleCategoryClick}>
      {category.picture && <CategoryCoachImg src={category.picture} alt={category.name} />}
      <CategoryCaption>
        <Typography>{category.name}</Typography>
      </CategoryCaption>
    </CategoryCoachCard>
  );
};

export default Categories;
