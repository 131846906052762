// NOTE: this reducer acts as a fix for a broken local state.
// We shall remove it when it's taken care of.
// See /src/components/Coach/ChatContainer/ChatProgram/ChatProgramSmartGoal/ChatProgramSmartGoalForm/index.tsx

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type TDraftPayload } from '@/types';

const initialState: TDraftPayload = {
  content: {},
};

const savedDraftSlice = createSlice({
  name: 'savedDraft',
  initialState,

  reducers: {
    update: (state, action: PayloadAction<TDraftPayload>) => {
      // NOTE: Some form fields are triggering private properties, members of a class,
      // causing Redux to crash (no problem with some other tested state managers).
      // That's why we need to clone.
      state.content = structuredClone(action.payload.content);
    },

    empty: (state) => {
      state.content = {};
    },
  },
});

export const { update, empty } = savedDraftSlice.actions;

export default savedDraftSlice.reducer;
