import { styled } from '@mui/system';
import Button from '@/common/core/Button';

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 30,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 44,
  borderRadius: 8,
  fontWeight: 600,
}));
