import { useEffect, useState } from 'react';
import { Card, CompactSelect, Tooltip, Warning } from '../ChatProgramComponents';
import { InputBlock, SelectBlock } from './styles';
import ChatProgramNutritionHelp from './ChatProgramNutritionHelp';
import ChatProgramNutritionAlert from './ChatProgramNutritionAlert';
import { ClientUser, ENutritionCaloriesTarget } from '@fitmate-coach/fitmate-types';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { fetchOnboardingSurveyByCustomer } from '@/store/customers/onboardingSurveySlice';
import {
  assignActivitiesToCustomer,
  assignNutritionTargetsTypeToCustomer,
} from '@/store/customers/customerInfosSlice';
import { useConfirm } from './hooks/ConfirmModal';
import Baseline from './NutritionTargets/Baseline';
import Refined from './NutritionTargets/Refined';

const ChatProgramNutrition = ({ customer }: { customer: ClientUser }) => {
  const dispatch = useAppDispatch();
  const { isReadOnly } = useAppSelector((state) => state.customerInfos);
  const { profiles } = useAppSelector((state) => state.bodyProfile);
  const { surveys } = useAppSelector((state) => state.onboardingSurvey);
  const [weight, setWeight] = useState<number>(0);
  const [hasBaselineFeatureFlag, setHasBaselineFeatureFlag] = useState<boolean>(false);
  const [attentionNeeded, setAttentionNeeded] = useState<boolean>(true);
  const modal = useConfirm({
    title: 'Are you sure?',
    cancelButtonText: 'Cancel',
    confirmButtonText: "Yes I'm sure",
    description:
      'Changing back to the Baseline Calorie target mode will automatically publish to the client the nutritional table that’s based on their initial sign-up data, including BMI and activity levels.',
  });

  // If no feature flags
  useEffect(() => {
    if (!customer?.featureFlags?.includes(ENutritionCaloriesTarget.baseline_calorie_target)) {
      return;
    }

    setHasBaselineFeatureFlag(true);
  }, [customer?.featureFlags]);

  // If no nutritionTargetTypes, set default nutritionTargetType
  useEffect(() => {
    if (!customer || customer.nutritionTargetsType) {
      return;
    }

    if (!hasBaselineFeatureFlag) {
      dispatch(
        assignNutritionTargetsTypeToCustomer({
          id: customer.id,
          nutritionTargetsType: ENutritionCaloriesTarget.refined_calorie_target,
        }),
      );
      return;
    }

    dispatch(
      assignNutritionTargetsTypeToCustomer({
        id: customer.id,
        nutritionTargetsType: ENutritionCaloriesTarget.baseline_calorie_target,
      }),
    );
  }, [hasBaselineFeatureFlag, customer]);

  // If the onboarding migration to customer is not done, fetch the onboarding survey
  useEffect(() => {
    if (!customer || customer.activities) {
      return;
    }

    dispatch(fetchOnboardingSurveyByCustomer({ userId: customer.id }));
  }, [customer.activities]);

  // onboarding survey migrations
  useEffect(() => {
    if (!surveys) {
      return;
    }

    if (!customer?.activities) {
      const unparsedActivitiesQ10 =
        surveys[0]?.stages[0].questions.find(
          (question: { title: string; unit: string }) =>
            question.title === 'Q10' && question.unit === 'mins',
        )?.answer?.answers ?? [];
      const unparsedActivitiesQ11 =
        surveys[0]?.stages[0].questions.find(
          (question: { title: string; unit: string }) =>
            question.title === 'Q11' && question.unit === '',
        )?.answer?.answers ?? [];

      if (!unparsedActivitiesQ10[0] || !unparsedActivitiesQ11[0]) {
        // No unboarding survey found
        return;
      }
      const r = /\d+/;
      const lowIntensityCardioMinutes = Number(unparsedActivitiesQ10[0].match(r));
      const moderateIntensityCardioMinutes = Number(unparsedActivitiesQ10[1].match(r));
      const vigorousIntensityCardioMinutes = Number(unparsedActivitiesQ10[2].match(r));
      const resistanceTraining2Plus = unparsedActivitiesQ11[0].includes('2+');

      dispatch(
        assignActivitiesToCustomer({
          id: customer.id,
          activities: {
            lowIntensityCardioMinutes,
            moderateIntensityCardioMinutes,
            vigorousIntensityCardioMinutes,
            resistanceTrainingMinutes: 0,
            resistanceTraining2Plus,
          },
        }),
      );
    }
  }, [surveys]);

  // Get and assign weight from bodyProfile
  useEffect(() => {
    if (!profiles || profiles.length === 0) {
      return;
    }

    setWeight(profiles[profiles.length - 1]?.weight);
  }, [profiles]);

  useEffect(() => {
    if (!weight || !customer.dateOfBirth || !customer.height || !customer.gender) {
      setAttentionNeeded(true);
      return;
    }

    setAttentionNeeded(false);
  }, [weight, customer]);

  // Show warning message on card
  const warning = (() => {
    if (
      attentionNeeded &&
      customer.nutritionTargetsType === ENutritionCaloriesTarget.baseline_calorie_target
    ) {
      return (
        <Warning className="bg-[#FFEBF1] rounded-t-2xl !items-start">
          Attention Needed: Update the client info tab with the following info to reveal the
          Nutritional Targets to the client.
          <ul className="list-disc px-8 pt-4">
            {!weight && <li>Weight</li>}
            {!customer.height && <li>Height</li>}
            {!customer.dateOfBirth && <li>Age</li>}
            {!customer.gender && <li>Sex</li>}
          </ul>
        </Warning>
      );
    }
    if (
      customer.nutritionTargetsType === ENutritionCaloriesTarget.refined_calorie_target &&
      !hasBaselineFeatureFlag
    ) {
      return (
        <Warning className="bg-[#FFEBF1] rounded-t-2xl !items-start">
          Action Needed: This client signed up before the Nutritional Targets feature was available.
          Once you've agreed a calorie target with the client, input it here to unlock this feature
          in the client app.
        </Warning>
      );
    }
    if (
      ENutritionCaloriesTarget.refined_calorie_target === customer.nutritionTargetsType &&
      !customer.nutritionTargets?.lastPublishedAt
    ) {
      return (
        <Warning className="bg-[rgba(234,234,251,1)] rounded-t-2xl !items-start">
          Attention: The client will still see their 'Baseline calorie target' nutritional targets
          until you set and submit the new calorie target below.
        </Warning>
      );
    }
  })();

  return (
    <Card warning={warning}>
      <div className="p-5 flex justify-between w-full items-center">
        <h4 className="font-bold text-xl flex gap-1.5">
          Nutrition targets
          <Tooltip
            size="lg"
            offset={0.2}
            anchor={<span className="icon-[mdi--info-outline] w-6 h-6 text-dark/40 mt-0.5" />}
          >
            <ChatProgramNutritionHelp />
          </Tooltip>
        </h4>
        <SelectBlock>
          {modal.dialog}
          {customer.nutritionTargetsType && (
            <CompactSelect
              value={customer.nutritionTargetsType}
              disabled={isReadOnly || !hasBaselineFeatureFlag}
              onChange={(e) => {
                if (e.target.value === ENutritionCaloriesTarget.refined_calorie_target) {
                  dispatch(
                    assignNutritionTargetsTypeToCustomer({
                      id: customer.id,
                      nutritionTargets: customer.nutritionTargets,
                      nutritionTargetsType: e.target.value as ENutritionCaloriesTarget,
                    }),
                  );
                  return;
                }
                modal
                  .confirm()
                  .then(() => {
                    dispatch(
                      assignNutritionTargetsTypeToCustomer({
                        id: customer.id,
                        nutritionTargets: customer.nutritionTargets,
                        nutritionTargetsType: e.target.value as ENutritionCaloriesTarget,
                      }),
                    );
                  })
                  .catch(() => {
                    // Do nothing
                  });
              }}
              options={[
                {
                  label: 'Baseline calorie target',
                  value: ENutritionCaloriesTarget.baseline_calorie_target,
                },
                {
                  label: 'Refined calorie target',
                  value: ENutritionCaloriesTarget.refined_calorie_target,
                },
              ]}
            />
          )}
          {!hasBaselineFeatureFlag && (
            <Tooltip
              size="lg"
              offset={0.9}
              anchor={<span className="icon-[mdi--lock-outline] w-6 h-6 text-dark/40 mt-0.5" />}
            >
              <ChatProgramNutritionAlert />
            </Tooltip>
          )}
        </SelectBlock>
      </div>
      <InputBlock>
        {ENutritionCaloriesTarget.refined_calorie_target === customer.nutritionTargetsType ? (
          <Refined customer={customer} />
        ) : (
          !attentionNeeded &&
          hasBaselineFeatureFlag && <Baseline customer={customer} weight={weight} />
        )}
      </InputBlock>
    </Card>
  );
};

export default ChatProgramNutrition;
