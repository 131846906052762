import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { matchCustomerToStripe } from '@/store/customers/customerInfosSlice';

const ButtonToCheckStripe = () => {
  const dispatch = useAppDispatch();
  const { stripeLoading, customer } = useAppSelector((state) => state.customerInfos);

  const handleCheckStripe = async () => {
    dispatch(
      matchCustomerToStripe({
        email: customer?.billingData?.email ?? customer?.email,
        userId: customer?.id,
      }),
    );
  };

  return (
    <Button variant="outlined" color="primary" onClick={handleCheckStripe} disabled={stripeLoading}>
      Fetch stripe user
    </Button>
  );
};

export default ButtonToCheckStripe;
