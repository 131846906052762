import React, { useState } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { IHoverAdditionalBlock } from '@/types';
import MenuButtons from './MenuButtons';

const HoverAdditionalBlock = ({ deleteClickHandler, index, children }: IHoverAdditionalBlock) => {
  const [backgroundColor, setBackgroundColor] = useState<object>({ backgroundColor: 'revert' });

  const chooseItem = (handler: () => void) => {
    handler();
  };

  const toggleVisibilityOn = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setBackgroundColor({ backgroundColor: '#E8E8FF', borderRadius: 4 });
  };

  const toggleVisibilityOff = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setBackgroundColor({ backgroundColor: 'revert' });
  };

  return (
    <Draggable key={index} draggableId={index.toString()} index={index}>
      {(draggableProvided: DraggableProvided) => {
        return (
          <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
            <div
              onMouseEnter={toggleVisibilityOn}
              onMouseLeave={toggleVisibilityOff}
              style={{ ...backgroundColor, position: 'relative', marginBottom: 12 }}
            >
              <div style={{ position: 'absolute', left: -20, top: 0 }}>
                <MenuButtons
                  deleteClickHandler={() => chooseItem(deleteClickHandler)}
                  draggableProvided={draggableProvided}
                />
              </div>
              {children}
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default HoverAdditionalBlock;
