import { useState, useEffect } from 'react';
import { useAdminHandouts } from '@/hooks/admin/useAdminHandouts';
import ModalConfirm from '@/common/core/Modal/ModalConfirm';
import HandoutsList from '../HandoutsList';
import { AppWrapper, AppWrapperContent } from './styles';
import { IHandout } from '@/types';

const HandoutsContent = () => {
  const [handouts, setHandouts] = useState<IHandout[]>([]);
  const [isOpenHandoutRemoveModal, setIsOpenHandoutRemoveModal] = useState<boolean>(false);
  const [handoutId, setHandoutId] = useState<string>('');
  const { handoutsList, removeHandout } = useAdminHandouts();

  useEffect(() => {
    setHandouts(handoutsList);
  }, [handoutsList]);

  const toggleHandoutRemoveModal = () => {
    setIsOpenHandoutRemoveModal((prev) => !prev);
  };

  const handleHandoutRemove = (handoutId: string) => {
    setHandoutId(handoutId);
    toggleHandoutRemoveModal();
  };

  const handleHandoutRemoved = async () => {
    if (handoutId) {
      await removeHandout(handoutId);
      setHandouts((prev) => prev.filter((handout) => handout.id !== handoutId));
      toggleHandoutRemoveModal();
    }
  };

  return (
    <>
      <AppWrapper>
        <AppWrapperContent>
          {handouts.map((handout) => {
            return (
              <HandoutsList
                key={handout.id}
                handout={handout}
                deleteHandler={handleHandoutRemove}
              />
            );
          })}
        </AppWrapperContent>
      </AppWrapper>
      <ModalConfirm
        isOpenConfirmModal={isOpenHandoutRemoveModal}
        toggleOpenConfirmModal={toggleHandoutRemoveModal}
        handleSumbit={handleHandoutRemoved}
        title="Do you want to delete this handout?"
        note="You will not be able to undo this."
      />
    </>
  );
};

export default HandoutsContent;
