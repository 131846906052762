import { IProgramAction } from '@/types';
import GoalProgressPreview from '../ChatProgramSmartGoalProgressPreview';
import { useAppSelector } from '@/hooks';
import { useEffect, useState } from 'react';
import { collection, doc, onSnapshot } from '@firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';

const GoalProgress = (props: { actionId: string; onResetCompleted: () => void }) => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const programActionId = props.actionId;
  const [programAction, setProgramAction] = useState<IProgramAction | undefined>();

  useEffect(() => {
    if (customer?.id) {
      const programActionsCollection = collection(db, 'users', customer?.id, 'programActions');
      const unsubscribe = onSnapshot(doc(programActionsCollection, programActionId), (docSnap) => {
        setProgramAction(docSnap.data() as IProgramAction);
      });

      return () => unsubscribe();
    }
  }, [customer?.id, programActionId]);

  if (
    !['current', 'new', 'existing'].includes(
      programAction?.draft?.status ?? programAction?.published?.status ?? '',
    )
  )
    return null;
  if (!programAction?.lastTracking) return null;

  return (
    <GoalProgressPreview
      progress={programAction?.lastTracking?.currentDay ?? 0}
      finnish={programAction?.lastTracking?.daysPerWeek ?? 0}
    />
  );
};

export default GoalProgress;
