import { TableBody, TableCaption, TableCell, TableRow } from '@/components/ui/table';
import { Check, X } from 'lucide-react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect } from 'react';
import { fetchAppDownloaders } from '@/store/customers/customersSlice';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import {
  AllocatedCell,
  EmailFailed,
  EmailScheduled,
  EmailSent,
  EmailSkipped,
  NegativeCell,
  PositiveCell,
  StatusCell,
} from '@/components/Monitor/Cells';

const AppDownloaderTable = () => {
  const dispatch = useAppDispatch();
  const { appDownloaders } = useAppSelector((state) => state.customers);
  useEffect(() => {
    dispatch(fetchAppDownloaders());
  }, []);

  if (!appDownloaders?.length) {
    return <TableCaption className="text-center font-bold w-full">No recent user</TableCaption>;
  }
  return (
    <TableBody>
      {appDownloaders?.map((user) => (
        <TableRow key={user.id}>
          <TableCell className="font-medium">
            <a
              href={`/coach-dashboard/client/${user.id}`}
            >{`${user.firstName} ${user.lastName}`}</a>
          </TableCell>
          <TableCell>{format((user.createdAt as Timestamp).toDate(), 'MM/dd/yyyy')}</TableCell>
          <TableCell>
            <StatusCell attribute={user.timezone} />
          </TableCell>
          <TableCell>
            <StatusCell attribute={user.appVersion} />
          </TableCell>
          <TableCell>
            <AllocatedCell
              attribute={
                user.monitorOnboarding?.teamAllocated ?? user.coachAssignment?.team ?? 'failed'
              }
            />
          </TableCell>
          <TableCell>
            {user.monitorOnboarding?.getStartedEmailStatus === 'sent' && <EmailSent />}
            {user.monitorOnboarding?.getStartedEmailStatus === 'skipped' && <EmailSkipped />}
            {user.monitorOnboarding?.getStartedEmailStatus === 'scheduled' && <EmailScheduled />}
            {!user.monitorOnboarding && <EmailFailed />}
          </TableCell>
          <TableCell>
            {user.timezone && user.appVersion && user.coachAssignment?.team ? (
              <PositiveCell />
            ) : (
              <NegativeCell />
            )}
          </TableCell>
          <TableCell>
            <AllocatedCell attribute={user.monitorOnboarding?.introMessageStatus ?? 'upcoming'} />
          </TableCell>
          <TableCell>
            <AllocatedCell attribute={user.monitorOnboarding?.startedOnboarding ?? 'upcoming'} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default AppDownloaderTable;
