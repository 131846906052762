import { styled } from '@mui/system';
import Input from '@/common/core/Input';

export const InputBlock = styled('div')<{ isFullWidth?: boolean; mb: number }>(
  ({ isFullWidth = false, mb = 15 }) => ({
    width: isFullWidth ? '100%' : '320px',
    marginBottom: mb,
  }),
);

export const StyledInput = styled(Input)({
  width: '100%',
  margin: 0,
});
