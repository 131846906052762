import { useState, useEffect } from 'react';
import { RESOURCE_FILES_DIR } from '@/config';
import { CategoryWrapper, Thumb, TitleBlock, TitleText } from './styles';
import { IResourceThumbnail } from '@/types';

const ResourceThumbnail = ({ handleResourceDataChange, resourceData }: IResourceThumbnail) => {
  const [imgUrl, setImgUrl] = useState<string>('');

  useEffect(() => {
    setImgUrl(resourceData?.thumbnail);
  }, [resourceData]);

  const handleImageUploaded = (url: string) => {
    setImgUrl(url);
    handleResourceDataChange((prev: any) => ({ ...prev, thumbnail: url }));
  };

  return (
    <CategoryWrapper>
      <TitleBlock>
        <TitleText>Choose thumbnail</TitleText>
      </TitleBlock>
      <Thumb
        caption="Drag image here to start uploading"
        imageUrl={imgUrl}
        handleUploaded={handleImageUploaded}
        destination={RESOURCE_FILES_DIR}
      />
    </CategoryWrapper>
  );
};

export default ResourceThumbnail;
