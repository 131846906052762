import {
  IChatMessageLast,
  ICoachAssignmentConverted,
  IFirebaseTimestamp,
  TClientSearchUser,
} from '@/types';
import {
  ENutritionCaloriesTarget,
  IBillingData,
  IDietaryHabits,
  ILifestyle,
} from '@fitmate-coach/fitmate-types';
import { IPartnerData } from '@fitmate-coach/fitmate-types';
import { FieldValue, Timestamp } from 'firebase/firestore';

export enum EUserRoles {
  ADMIN = 'admin',
  COACH = 'coach',
  PARTNER_REFERRAL = 'partner_referral',
}

export interface IUser {
  uid: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  avatarUrl: string;
  role: EUserRoles;
  isInviteAccepted?: boolean;
  isActive?: boolean;
  confirmId: string;
  createdAt: IFirebaseTimestamp | FieldValue;
}

export interface IUserInfo {
  id: string;
  createdAt?: IFirebaseTimestamp;
  email?: string;
  firstName: string;
  lastName: string;
  gender?: string;
  dateOfBirth?: number;
  familyStatus?: string;
  location?: string;
  dietaryPreferences?: string;
  occupation?: string;
  height?: number;
  weight?: number;
  otherMemorableInfo?: string;
  healthGoals?: string;
  billingData?: IBillingData | null;
  featureFlags?: string[];
  pregnancy?: boolean;
  lifestyle?: ILifestyle;
  dietaryHabits?: IDietaryHabits;
  timezone?: string;
  diabetes?: number;
  glp1drugs?: boolean;
}

export interface IAdminUser extends IUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  gender?: string;
  location?: string;
  role?: string;
  timezone?: string;
  isActive?: boolean;
  confirmId?: string;
  createdAt: IFirebaseTimestamp;
  coachAssignment: ICoachAssignmentConverted | null;
  toggleApiToken?: string | null;
  partner?: string;
}

export interface IUserThreadData {
  firstName?: string;
  lastName?: string;
  email: string;
  lastSeenAt?: IFirebaseTimestamp;
  billingData: IBillingData | null;
  isSaved?: boolean;
  isMessagesUnRead?: boolean;
  isMain: boolean | null;
  lastMessage?: IChatMessageLast;
  timezone?: string;
  partner?: string;
  diabetes?: number;
  glp1drugs?: boolean;
  partnerData?: IPartnerData;
  appVersion?: string;
  copilotFlow?: string;
}

export type TCurrentUserType = IUserThreadDataComplete | null;

export interface IUserThreadDataComplete extends IUserThreadData {
  timezone?: string;
  userId: string;
  avatarUrl: string;
  isMessagesUnRead?: boolean;
  isSaved: boolean;
  messageLastFromUser?: boolean;
  diabetes?: number;
  glp1drugs?: boolean;
  appVersion?: string;
  coachAssignment: ICoachAssignmentConverted | null;
}

export interface IUserActivities {
  lowIntensityCardioMinutes: number;
  moderateIntensityCardioMinutes: number;
  vigorousIntensityCardioMinutes: number;
  resistanceTrainingMinutes: number;
  resistanceTraining2Plus: boolean;
}

export interface IUserNutritionTargets {
  weight: number;
  height: number;
  age: number;
  gender: string; // TODO: Move to enum
  caloriesTarget: number;
  lastPublishedAt: Timestamp;
}

export type TFireBaseUser = {
  migration?: string; // @TODO: remove once migration is completed (2.0.0)
  id?: string;
  displayName?: string;
  shiftData?: {
    timeStarting?: Timestamp;
    timeFinishing?: Timestamp;
  };
  timezone?: string;
  gender?: string;
  dateOfBirth?: number; // TODO there are users where this is set as a string;
  wasIntroShown?: boolean;
  activities?: IUserActivities;
  nutritionTargetsType?: ENutritionCaloriesTarget;
  nutritionTargets?: IUserNutritionTargets;
  billingData?: {
    email?: string;
    isPaid?: boolean;
    referralLink?: null | {
      wasShown: boolean;
      currency: string;
      url: string;
    };
    isReferralLinkEnabled?: boolean;
    status?: 'trial' | 'unpaid' | 'active' | 'cancelled';
    paymentPlan?: number;
    planStartDate?: Timestamp;
    isPaidByPartner?: boolean;
    subscriptionStatus?: string;
    subscriptionPauseStartDate?: Timestamp;
    subscriptionPauseEndDate?: Timestamp;
    cancellationDate?: Timestamp;
  };
  coachAssignment?: {
    team?: string;
    hasCoachesAssigned: boolean;
    coaches: null | Record<string, { isMainCoach?: boolean; isMain?: boolean }>;
  };
  lastSeenAt?: Timestamp;
  lastMessage?: {
    owner?: string; // TODO actually always exists
    createdAt?: Timestamp;
    lastMessageId?: string; // TODO actually always exists
    body?: string;
    isCustomerMsg?: boolean;
    answerBefore?: null;
    answerRequired?: null | Timestamp;
  };
  userAssigment?: Record<string, { isSaved: boolean; firstName: string }>;
  location?: string;
  height?: number; // TODO there are users where this is set as a string;
  pregnancy?: boolean;
  weight?: number; // TODO there are users where this is set as a string;
  partner?: string;
  dietaryPreferences?: string;
  familyStatus?: string;
  healthGoals?: string | null | undefined;
  otherMemorableInfo?: string;
  occupation?: string;
  savedUsers?: Record<string, TFireBaseUser>;
  dietaryHabits?: {
    foodCravings: number;
    proteinsBreakfast: number;
    simpleCarbs?: number;
    proteinsLunches?: number;
  };
  lifestyle?: {
    stressLevel: number;
    physicalActivity?: number;
  };
  featureFlags?: string[];
  bio?: string;
  dublicateMessageTest?: boolean;
  toggleApiToken?: string;
  lifeStyle?: {
    stressLevel?: number;
    physicalActivity: number;
  };
  programWeeks?: Record<number, { endDate: Timestamp; startDate: Timestamp }>;
  isPaid?: boolean;
  hasActiveOnboardingSurvey?: boolean;
  appVersion?: string;
  mobile?: string;
  phoneNumber?: string;
  isVirtual?: boolean;
  isDeleted?: boolean;
  role?: 'coach' | 'chat_bot' | 'user' | 'admin';
  avatarUrl?: string;
  isActive?: boolean;
  email?: string;
  createdAt?: Timestamp;
  authProvider?: string;
  firstName?: string;
  lastName?: string;
  uid?: string | null;
  isInviteAccepted?: boolean;
  phone?: string;
  confirmId?: string;
  isShiftStarted?: boolean;
  isMessagesUnRead?: boolean;
  userAssignment?: Record<string, TFireBaseUser> | null;
  diabetes?: number;
  glp1drugs?: boolean;
  partnerData?: IPartnerData;
  metadata?: any;
  updatedAt?: Timestamp;
  copilotFlow?: string;
};

export type IRegisterUserProps = Required<
  Pick<TFireBaseUser, 'email' | 'firstName' | 'lastName' | 'phone'>
>;

export interface IInvitationForm {
  user: any;
  answerError: string;
  registerUser: (docId: string, password: string, user: IRegisterUserProps) => void;
}

export type TSearchResponse = {
  results: TClientSearchUser[];
  facets: { coachassignment__team: [{ data: { value: string; count: number }[] }] };
};

export interface ICurrentUser {
  currentUserId: string;
}
