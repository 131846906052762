import { useContext, useEffect } from 'react';
import { Context } from '@/context/Context';
import { useAppDispatch, useAppSelector } from '@/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ImportantInfo from './ImportantInfo';
import AgreedActionsTaken from './AgreedActionsTaken';
import { fetchCustomerById } from '@/store/customers/customerInfosSlice';
import { useDrawerOffset } from '@/hooks/chat/useDrawerOffset';
import { IframeCloseIcon, StyledDrawer } from '@/styles/drawers';

const DrawerClientInfo = () => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const dispatch = useAppDispatch();

  const isClientInfoOpen = useContext(Context).isClientInfoOpen;
  const setIsClientInfoOpen = useContext(Context).setIsClientInfoOpen;

  const getDrawerOffset = useDrawerOffset();

  useEffect(() => {
    if (!customer?.id || !isClientInfoOpen) {
      return;
    }

    dispatch(fetchCustomerById(customer.id));
  }, [isClientInfoOpen]);

  if (!customer) {
    return null;
  }

  return (
    <StyledDrawer
      isOpen={!!isClientInfoOpen}
      variant="persistent"
      anchor="right"
      open={isClientInfoOpen}
      rightOffset={getDrawerOffset('clientInfo')}
      width={340}
    >
      <AppBar position="sticky" className="h-11 flex">
        <Toolbar>
          <Typography className="mb-5 ml-3">About {customer.firstName}</Typography>
          <IframeCloseIcon
            className="justify-center"
            as={CloseIcon}
            onClick={() => setIsClientInfoOpen(false)}
          />
        </Toolbar>
      </AppBar>
      <Box component="main" margin={2}>
        <ImportantInfo />
        <AgreedActionsTaken />
      </Box>
    </StyledDrawer>
  );
};

export default DrawerClientInfo;
