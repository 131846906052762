import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CancelBtn, CreateBtn } from '../styles';
import { useUserProfile } from '@/hooks/profile/useUserProfile';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { fetchCustomerById } from '@/store/customers/customerInfosSlice';
import { Accordion, AccordionDetails, AccordionSummary, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ImportantInfoItemAccordion = ({ title, content }: { title: string; content?: string }) => {
  const [expanded, setExpanded] = useState<boolean>(!!content);
  const handleChange = () => {
    if (!content) return;
    setExpanded(!expanded);
  };
  return (
    <Accordion defaultExpanded={expanded} onChange={handleChange} disabled={!content}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{title}</AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

function ImportantInfosFormWithRichEditor({
  isOpen,
  openEditMode,
}: {
  isOpen: boolean;
  openEditMode: (value: boolean) => void;
}) {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const dispatch = useAppDispatch();
  const form = useForm({
    defaultValues: {
      mainChallenges: customer?.importantInfos?.mainChallenges,
      medicalConditions: customer?.importantInfos?.medicalConditions,
      dietaryRestrictions: customer?.importantInfos?.dietaryRestrictions,
      physicalActivityRestrictions: customer?.importantInfos?.physicalActivityRestrictions,
      nutritionPreferences: customer?.importantInfos?.nutritionPreferences,
      physicalActivityPreferences: customer?.importantInfos?.physicalActivityPreferences,
      otherLifeCircumstances: customer?.importantInfos?.otherLifeCircumstances,
      otherImportantInfos: customer?.importantInfos?.otherImportantInfos,
    },
  });

  const { editUserProfileAsObject } = useUserProfile(customer!.id!);

  async function onSubmit({
    mainChallenges,
    medicalConditions,
    dietaryRestrictions,
    physicalActivityRestrictions,
    nutritionPreferences,
    physicalActivityPreferences,
    otherLifeCircumstances,
    otherImportantInfos,
  }: any) {
    await editUserProfileAsObject({
      importantInfos: {
        mainChallenges,
        medicalConditions,
        dietaryRestrictions,
        physicalActivityRestrictions,
        nutritionPreferences,
        physicalActivityPreferences,
        otherLifeCircumstances,
        otherImportantInfos,
      },
    });
    dispatch(fetchCustomerById(customer!.id));
    openEditMode(false);
  }

  return (
    <FormProvider {...form}>
      <form className="space-y-5 text-left flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        {!isOpen && (
          <div>
            <ImportantInfoItemAccordion
              title="Main challenges"
              content={customer?.importantInfos?.mainChallenges}
            />
            <ImportantInfoItemAccordion
              title="Medical conditions"
              content={customer?.importantInfos?.medicalConditions}
            />
            <ImportantInfoItemAccordion
              title="Dietary restrictions"
              content={customer?.importantInfos?.dietaryRestrictions}
            />
            <ImportantInfoItemAccordion
              title="Physical activity restrictions"
              content={customer?.importantInfos?.physicalActivityRestrictions}
            />
            <ImportantInfoItemAccordion
              title="Nutrition preferences"
              content={customer?.importantInfos?.nutritionPreferences}
            />
            <ImportantInfoItemAccordion
              title="Physical activity preferences"
              content={customer?.importantInfos?.physicalActivityPreferences}
            />
            <ImportantInfoItemAccordion
              title="Other life circumstances"
              content={customer?.importantInfos?.otherLifeCircumstances}
            />
            <ImportantInfoItemAccordion
              title="Other important infos"
              content={customer?.importantInfos?.otherImportantInfos}
            />
          </div>
        )}
        {isOpen && (
          <>
            <FormControl>
              <b>Main challenges:</b>
              <TextField multiline rows={8} {...form.register('mainChallenges')} />
            </FormControl>
            <FormControl>
              <b>Medical conditions:</b>
              <TextField multiline rows={8} {...form.register('medicalConditions')} />
            </FormControl>
            <FormControl>
              <b>Dietary restrictions:</b>
              <TextField multiline rows={8} {...form.register('dietaryRestrictions')} />
            </FormControl>
            <FormControl>
              <b>Physical activity restrictions:</b>
              <TextField multiline rows={8} {...form.register('physicalActivityRestrictions')} />
            </FormControl>
            <FormControl>
              <b>Nutrition preferences:</b>
              <TextField multiline rows={8} {...form.register('nutritionPreferences')} />
            </FormControl>
            <FormControl>
              <b>Physical activity preferences:</b>
              <TextField multiline rows={8} {...form.register('physicalActivityPreferences')} />
            </FormControl>
            <FormControl>
              <b>Other life circumstances:</b>
              <TextField multiline rows={8} {...form.register('otherLifeCircumstances')} />
            </FormControl>
            <FormControl>
              <b>other important infos:</b>
              <TextField multiline rows={8} {...form.register('otherImportantInfos')} />
            </FormControl>
          </>
        )}
        {isOpen && (
          <div>
            <CreateBtn variant="contained" type="submit">
              Save
            </CreateBtn>
            <CancelBtn
              variant="outlined"
              onClick={() => {
                form.setValue('mainChallenges', customer?.importantInfos?.mainChallenges, {
                  shouldDirty: false,
                });
                form.setValue('medicalConditions', customer?.importantInfos?.medicalConditions, {
                  shouldDirty: false,
                });
                form.setValue(
                  'dietaryRestrictions',
                  customer?.importantInfos?.dietaryRestrictions,
                  {
                    shouldDirty: false,
                  },
                );
                form.setValue(
                  'physicalActivityRestrictions',
                  customer?.importantInfos?.physicalActivityRestrictions,
                  { shouldDirty: false },
                );
                form.setValue(
                  'nutritionPreferences',
                  customer?.importantInfos?.nutritionPreferences,
                  { shouldDirty: false },
                );
                form.setValue(
                  'physicalActivityPreferences',
                  customer?.importantInfos?.physicalActivityPreferences,
                  { shouldDirty: false },
                );
                form.setValue(
                  'otherLifeCircumstances',
                  customer?.importantInfos?.otherLifeCircumstances,
                  { shouldDirty: false },
                );
                form.setValue(
                  'otherImportantInfos',
                  customer?.importantInfos?.otherImportantInfos,
                  {
                    shouldDirty: false,
                  },
                );
                openEditMode(false);
              }}
            >
              Cancel
            </CancelBtn>
          </div>
        )}
      </form>
    </FormProvider>
  );
}

export default ImportantInfosFormWithRichEditor;
