import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Input from '@/common/core/Input';
import Modal from '@/common/core/Modal';
import { RESOURCE_FILES_DIR } from '@/config';
import { Label, Thumb } from './styles';
import { ICategoryEditModal } from '@/types';

const CategoryEditModal = ({
  isOpenAddModal,
  toggleOpenAddModal,
  handleEditCategory,
  category,
}: ICategoryEditModal) => {
  const [name, setName] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');

  useEffect(() => {
    if (isOpenAddModal && category !== undefined) {
      setName(category.name);
      setUrl(category.picture);
    }
  }, [isOpenAddModal, category]);

  const handleSubmit = () => {
    setNameError(name ? '' : 'Please fill the tag');

    if (name) {
      handleEditCategory(name, url);
    }
  };

  const handleImageUploaded = (url: string) => {
    setUrl(url);
  };

  return (
    <Modal
      widthMax={340}
      title="Edit category"
      open={isOpenAddModal}
      handleClose={toggleOpenAddModal}
      handleSubmit={handleSubmit}
      textBtnClose="Cancel"
      textBtnSubmit="Create"
    >
      <div>
        <div>
          <Box component="div" sx={{ paddingBottom: 2 }}>
            <Label>Thumbnail</Label>
            <Thumb
              caption="Drag image here to start uploading thumbnail"
              handleUploaded={handleImageUploaded}
              imageUrl={url}
              destination={RESOURCE_FILES_DIR}
            />
          </Box>
          <Box component="div" sx={{ paddingBottom: 2 }}>
            <Input
              error={!!nameError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              value={name}
              id="name"
              label="Name of subcategory"
              helperText={nameError}
              type="text"
              fullWidth
            />
          </Box>
        </div>
      </div>
    </Modal>
  );
};

export default CategoryEditModal;
