import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import { useAdminActions } from '@/hooks/admin/useAdminActions';
import { AutocompleteSelectAction, CustomStyleAutocompleteAdmin } from './styles';
import { IAction, ISelectAction } from '@/types';

const START_SEARCH = 3;
const OPTIONS_LIMIT_INITIAL = 10;
const OPTIONS_LIMIT_ACTIVE = 1000;

const SelectAction = ({ actionError, setActionError, actionId, setActionId }: ISelectAction) => {
  const { actionsList } = useAdminActions();
  const defaultFilterOptions = createFilterOptions();
  const [value, setValue] = useState<IAction>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [optionsLimit, setOptionsLimit] = useState<number>(OPTIONS_LIMIT_INITIAL);

  useEffect(() => {
    if (actionId) {
      setValue(actionsList.find((item) => item.id === actionId));
    }
    setIsOpen(false);
  }, [actionsList]);

  useEffect(() => {
    setOptionsLimit(OPTIONS_LIMIT_INITIAL);
    setIsOpen(false);
  }, []);

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, optionsLimit);
  };

  const handleChange = (e: React.ChangeEvent<{ name?: string | undefined }>, newValue: any) => {
    setActionId(newValue.id);
    setActionError(false);
    setValue(actionsList.find((item) => item.id === newValue.id));
    setIsOpen(false);
  };

  const onInputChange = (e: React.ChangeEvent<object>, value: string) => {
    if (value.length === 0) {
      setOptionsLimit(OPTIONS_LIMIT_INITIAL);
    } else {
      setOptionsLimit(OPTIONS_LIMIT_ACTIVE);
    }
    if ((value === '' || value.length >= START_SEARCH) && !!e) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <AutocompleteSelectAction
      onInputChange={(e, value) => onInputChange(e, value)}
      open={isOpen}
      filterSelectedOptions={true}
      openOnFocus={true}
      freeSolo={true}
      disableClearable
      filterOptions={filterOptions}
      value={value ?? undefined}
      id="linked-action"
      onChange={(e, newValue) => handleChange(e, newValue)}
      options={actionsList
        .filter((item) => !item.isDeleted)
        .sort((a: any, b: any) => b.createdAt.seconds - a.createdAt.seconds)}
      getOptionLabel={(option: any) => option.title.toString()}
      renderInput={(params) => (
        <TextField
          onBlur={() => setIsOpen(false)}
          error={actionError}
          helperText={actionError ? 'Pleas, link the action' : ''}
          {...params}
          label="Linked action"
          variant="outlined"
          placeholder="Type 3 letters to search"
        />
      )}
      PaperComponent={({ children }) => (
        <CustomStyleAutocompleteAdmin>{children}</CustomStyleAutocompleteAdmin>
      )}
    />
  );
};

export default SelectAction;
