import { useState, useEffect } from 'react';
import { IResourceCategory, ISubcategoriesSelect } from '@/types';
import { InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { DefaultOptionValue, SubCategoriesSelect } from './styles';

const SubcategoriesSelect = ({
  category,
  handleActiveCategoryChange,
  handleCurrentCategoryIdChange,
  level = 1,
  categoriesBranch = [],
  handleResourceDataChange,
}: ISubcategoriesSelect) => {
  const [value, setValue] = useState<string | unknown>('');
  const [resourceCategories, setResourceCategories] = useState<IResourceCategory[]>([]);

  useEffect(() => {
    setResourceCategories(category.children);
    handleActiveCategoryChange(category);
    handleCurrentCategoryIdChange(category.id);
  }, [category]);

  useEffect(() => {
    if (categoriesBranch.length !== 0) {
      if (level < categoriesBranch.length) {
        setValue(categoriesBranch[level].id);
        //TODO: Change logic for selecting subcategories
        // if (level === categoriesBranch.length - 1) {
        //   handleActiveCategoryChange(categoriesBranch[categoriesBranch.length - 1]);
        // }
      }
    }
  }, [categoriesBranch]);

  const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
    setValue(event.target.value);
    handleResourceDataChange((prev: any) => {
      return { ...prev, categoryId: event.target.value };
    });
  };

  if (resourceCategories.length) {
    return (
      <>
        {resourceCategories.length && (
          <SubCategoriesSelect variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">{`Name of subcategory (level ${level})`}</InputLabel>
            <Select
              native
              value={value}
              onChange={handleSelectChange}
              label={`Name of subcategory (level ${level})`}
              inputProps={{
                name: 'subcategory',
                id: 'outlined-subcategory',
              }}
            >
              <DefaultOptionValue aria-label="None" value="" />
              {resourceCategories.map((resourceCategory) => (
                <option key={resourceCategory.id} value={resourceCategory.id}>
                  {resourceCategory.name}
                </option>
              ))}
            </Select>
          </SubCategoriesSelect>
        )}
        {resourceCategories
          .filter((resourceCategory) => resourceCategory.id === value)
          .map((resourceCategory) => (
            <SubcategoriesSelect
              key={resourceCategory.id}
              handleActiveCategoryChange={handleActiveCategoryChange}
              handleCurrentCategoryIdChange={handleCurrentCategoryIdChange}
              category={resourceCategory}
              level={level + 1}
              categoriesBranch={categoriesBranch}
              handleResourceDataChange={handleResourceDataChange}
            />
          ))}
      </>
    );
  } else {
    return null;
  }
};

export default SubcategoriesSelect;
