import { ButtonBlock, Container, StyledButton } from './styles';
import ChatUserProfileViewList from './ChatUserProfileViewList';
import { ClientUser } from '@fitmate-coach/fitmate-types';
import { useAppSelector } from '@/hooks';

const ChatUserProfileView = ({
  userData,
  toggleEditingMode,
}: {
  userData: ClientUser | null;
  toggleEditingMode: () => void;
}) => {
  const { isReadOnly } = useAppSelector((state) => state.customerInfos);
  return (
    <Container>
      <ChatUserProfileViewList userData={userData} />

      <ButtonBlock>
        <StyledButton disabled={isReadOnly} onClick={toggleEditingMode} variant="outlined">
          Edit Profile
        </StyledButton>
      </ButtonBlock>
    </Container>
  );
};

export default ChatUserProfileView;
