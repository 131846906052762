import { IChatAddMessage } from '@/types';
import ChatAddMessageForm from './ChatAddMessageForm';

const ChatAddMessage = ({
  files,
  handleChangeFiles,
  handleDeleteFile,
  messageEdited,
  messageFileEdited,
  handleMessageEdited,
  handleMessageFileEdited,
  handleOpenResoucesBlock,
  handleOpenCategoryBlock,
  handleOpenHandoutBlock,
  answerFAQChosen,
  handleAnswerFAQChosen,
  isInputMessageExpanded,
  handleIsInputMessageExpanded,
  onScrollChat,
  parentHeight,
}: IChatAddMessage) => {
  return (
    <ChatAddMessageForm
      files={files}
      handleChangeFiles={handleChangeFiles}
      handleDeleteFile={handleDeleteFile}
      messageEdited={messageEdited}
      messageFileEdited={messageFileEdited}
      handleMessageEdited={handleMessageEdited}
      handleMessageFileEdited={handleMessageFileEdited}
      handleOpenResoucesBlock={handleOpenResoucesBlock}
      handleOpenCategoryBlock={handleOpenCategoryBlock}
      handleOpenHandoutBlock={handleOpenHandoutBlock}
      answerFAQChosen={answerFAQChosen}
      handleAnswerFAQChosen={handleAnswerFAQChosen}
      isInputMessageExpanded={isInputMessageExpanded}
      handleIsInputMessageExpanded={handleIsInputMessageExpanded}
      onScrollChat={onScrollChat}
      parentHeight={parentHeight}
    />
  );
};

export default ChatAddMessage;
