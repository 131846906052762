import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import SavvySignupTable from '@/components/Monitor/SavvySignupTable';
import AppDownloaderTable from '@/components/Monitor/AppDownloaderTable';
import { Suspense } from 'react';
import { Gauge, SquareActivity } from 'lucide-react';

const MonitorContainer = () => {
  return (
    <div className="h-screen w-screen p-4 bg-grey-100">
      <div>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Gauge />
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <SquareActivity className="mx-2 text-[#9B9CA5]" />
              <BreadcrumbLink href="/monitor">Monitor</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <Card className="mt-4">
        <CardHeader>
          <CardTitle>SAVVY WEB SIGN UPS</CardTitle>
          <CardDescription>(must have completed checkout)</CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Client</TableHead>
                <TableHead>Signup date</TableHead>
                <TableHead>Confirmed email</TableHead>
                <TableHead>Get started email</TableHead>
                <TableHead>Membership email</TableHead>
                <TableHead>App login</TableHead>
                <TableHead>Timezone saved</TableHead>
                <TableHead>App version added</TableHead>
                <TableHead>(Complete questions email)</TableHead>
                <TableHead>Complete questions</TableHead>
                <TableHead>Team allocation</TableHead>
                <TableHead>Access chat</TableHead>
                <TableHead>Auto system messages</TableHead>
                <TableHead>Auto coach messages</TableHead>
              </TableRow>
            </TableHeader>
            <Suspense fallback={<div>Loading...</div>}>
              <SavvySignupTable />
            </Suspense>
          </Table>
        </CardContent>
      </Card>

      <Card className="mt-4">
        <CardHeader>
          <CardTitle>APP DOWNLOADERS</CardTitle>
          <CardDescription>
            (must have done part 1 questions and created an app account)
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Client</TableHead>
                <TableHead>Account created date</TableHead>
                <TableHead>Timezone saved</TableHead>
                <TableHead>App version added</TableHead>
                <TableHead>Team allocation</TableHead>
                <TableHead>Get started email</TableHead>
                <TableHead>Access chat</TableHead>
                <TableHead>Auto system messages</TableHead>
                <TableHead>Auto coach messages</TableHead>
              </TableRow>
            </TableHeader>
            <AppDownloaderTable />
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default MonitorContainer;
