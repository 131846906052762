import { clientSearchRefresh } from '@/store/client-search/clientSearchSlice';
import { fetchCustomerById } from '@/store/customers/customerInfosSlice';
import { db } from '@/utils/firebase/firebaseInit';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { doc, getDoc, writeBatch } from 'firebase/firestore';
import { toast } from 'sonner';

export const useSetChatReadStatus = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customerInfos);

  const setStatusRead = async (userId: string, status: boolean) => {
    const batch = writeBatch(db);
    const userRef = doc(db, 'users', userId);

    batch.update(userRef, 'isMessagesUnRead', !status);
    if (!status) {
      const user = await getDoc(userRef);
      const answerRequired = user.data()?.lastMessage?.createdAt;
      batch.update(userRef, 'lastMessage.answerRequired', answerRequired);
    } else {
      batch.update(userRef, 'lastMessage.answerRequired', null);
    }

    batch.commit().then(() => {
      if (status) {
        toast.success('Chat with the user has been marked as read');
      } else {
        toast.success('Chat with the user has been marked as unread');
      }
    });
    if (customer?.id) {
      dispatch(fetchCustomerById(userId));
    }
    setTimeout(() => dispatch(clientSearchRefresh()), 3000); // Wait for search index to update
  };

  return setStatusRead;
};
