import { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import Search from '@mui/icons-material/Search';
import { SearchBlock, SearchInput, SearchInputIcon, SearchInputIconButton } from './styles';
import { IChatAllClientsSearch } from '@/types';

const ChatAllClientsSearch = ({ searchValue, setSearchValue }: IChatAllClientsSearch) => {
  const [value, setValue] = useState<string>(searchValue);

  useEffect(() => {
    const timer = setTimeout(() => setSearchValue(value), 500);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <SearchBlock>
      <SearchInput
        id="outlined-adornment-weight"
        autoComplete={'off'}
        placeholder="Search by first name / last name / email"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth={true}
        startAdornment={
          <InputAdornment position="start">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*// @ts-ignore*/}
            <SearchInputIconButton edge="start" size="large">
              <SearchInputIcon as={Search} />
            </SearchInputIconButton>
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
      />
    </SearchBlock>
  );
};

export default ChatAllClientsSearch;
