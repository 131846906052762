import {
  Firestore,
  collection,
  doc,
  CollectionReference,
  DocumentReference,
} from '@firebase/firestore';
import { TTeam } from '@/types';
import { db } from '@/utils/firebase/firebaseInit';

export const getTeamsRef = (client: Firestore) =>
  collection(client, 'teams') as CollectionReference<TTeam>;

export const getTeamRef = (id: string) => doc(db, 'teams', id) as DocumentReference<TTeam>;
