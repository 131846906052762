import { createSlice } from '@reduxjs/toolkit';
import { IAboutClientInterface } from '@/types/aboutClient';

const initialState: IAboutClientInterface = {
  openedDrawer: false,
  editMode: false,
};

export const aboutClientSlice = createSlice({
  name: 'aboutClient',
  initialState,
  reducers: {
    setEditMode: (state, action) => {
      state.editMode = action.payload.isEdit;
    },
  },
});

export const { setEditMode } = aboutClientSlice.actions;

export default aboutClientSlice.reducer;
