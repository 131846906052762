import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdminResource } from '@/hooks/admin/useAdminResource';
import { convertToTime } from '@/utils/format/convert';
import { AppContent, AppWrapper, ButtonGroup, CancelBtn, CreateBtn } from './styles';
import ResourceWorkoutContent from '../ResourceWorkoutContent';
import NewResourcesSidebar from '../ResourceSidebar';
import { toast } from 'sonner';

const WorkoutContainer = () => {
  const [parameters, setParameters] = useState<Map<string, any>>(new Map());
  const [url, setUrl] = useState<string>('');
  const [urlValidate, setUrlValidate] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [categoryId, setCategoryId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [resourceData, setResourceData] = useState<any>({});
  const [validator, setValidator] = useState<boolean>(false);
  const { addNewResourceWorkout, getResourceById, editResourceWorkout } = useAdminResource();
  const navigate = useNavigate();

  const { workoutId } = useParams<{ workoutId?: string }>();

  useEffect(() => {
    setUrl(resourceData?.url);
    setDescription(resourceData?.description);
    setTags(resourceData?.tags);
    setCategoryId(resourceData?.categoryId);
    setTitle(resourceData?.title);
    setParameters(resourceData?.parameters);
    setValidator(
      !!resourceData?.categoryId?.length &&
        !!resourceData?.url?.length &&
        !!resourceData?.description?.length &&
        urlValidate,
    );
  }, [resourceData, urlValidate]);

  useEffect(() => {
    if (workoutId) {
      getResourceById(workoutId).then((resource) => {
        resource.parameters.set(
          'view_time',
          convertToTime(resource.parameters.get('view_time') || ''),
        );
        setResourceData({
          categoryId: resource.categoryId,
          parameters: resource.parameters,
          url: resource.blocks![1]?.url,
          description: resource.blocks![0]?.body,
          tags: resource.tags,
          title: resource.title,
        });
      });
    }
  }, []);

  const handleSubmit = () => {
    addNewResourceWorkout(parameters, description, url, tags, categoryId, title).then(() => {
      toast.success('Workout is added');
      navigate('/resources');
    });
  };
  const handleEdit = () => {
    editResourceWorkout(parameters, description, url, tags, categoryId, title, workoutId).then(
      () => {
        toast.success('Workout is updated');
        navigate('/resources');
      },
    );
  };

  const handleResourceDataChange = (callback: React.SetStateAction<any>) => {
    setResourceData(callback);
  };
  const handleUrlValidate = (validate: boolean) => {
    setUrlValidate(validate);
  };

  return (
    <div>
      <AppWrapper>
        <AppContent>
          <NewResourcesSidebar
            resourceData={resourceData}
            handleResourceDataChange={handleResourceDataChange}
          />
          <ResourceWorkoutContent
            resourceData={resourceData}
            handleResourceDataChange={handleResourceDataChange}
            handleUrlValidate={handleUrlValidate}
          />
        </AppContent>
      </AppWrapper>
      <ButtonGroup>
        <CancelBtn variant="outlined" onClick={() => navigate(-1)}>
          Cancel
        </CancelBtn>
        {!workoutId ? (
          <CreateBtn onClick={handleSubmit} disabled={!validator}>
            Create
          </CreateBtn>
        ) : (
          <CreateBtn onClick={handleEdit} disabled={!validator}>
            Update
          </CreateBtn>
        )}
      </ButtonGroup>
    </div>
  );
};

export default WorkoutContainer;
