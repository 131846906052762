import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import MenuItem from '@/common/core/MenuItem';

export const SubMenu = styled(MenuItem)({
  minWidth: 190,
});

export const Delete = styled(Typography)({
  color: '#FF6D7D',
  fontSize: 14,
});

export const StyledDeleteIcon = styled('div')({
  color: '#FF6D7D',
});

export const TagItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: 65,
  '&:hover': {
    backgroundColor: '#F2F4F7 !important',
  },
});

export const StyledMenuItem = styled(Typography)({
  fontSize: 14,
});
