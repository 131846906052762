import { Check, Timer, X } from 'lucide-react';

export const StatusCell = ({ attribute }: { attribute: string | undefined }) => {
  if (typeof attribute !== 'undefined') {
    return <div className="bg-[#E1F6F2] w-[50px] h-[20px]" />;
  } else {
    return <div className="bg-[#FFEBF1] w-[50px] h-[20px]" />;
  }
};

export const PositiveCell = () => <div className="bg-[#E1F6F2] w-[50px] h-[20px]" />;
export const NegativeCell = () => <div className="bg-[#FFEBF1] w-[50px] h-[20px]" />;

export const AllocatedCell = ({ attribute }: { attribute: string }) => {
  switch (attribute) {
    case 'success':
      return <PositiveCell />;
    case 'scheduled':
      return (
        <div className="flex items-center justify-center w-[50px] h-[20px]">
          <Timer />
        </div>
      );
    case 'failed':
      return <NegativeCell />;
    case '2nd_chance':
      return (
        <div className="flex items-center justify-center w-[50px] h-[20px]">
          <Timer />
        </div>
      );
    case 'upcoming':
      return <div className="bg-white w-[50px] h-[20px]" />;
    default:
      return <div className="bg-[#E1F6F2] w-[50px] h-[20px]" />;
  }
};

export const EmailSent = () => (
  <div className="flex items-center justify-start">
    <Check className="text-green-600" />
  </div>
);
export const EmailScheduled = () => (
  <div className="flex items-center justify-center w-[50px] h-[20px]">
    <Timer />
  </div>
);
export const EmailSkipped = () => <p>Skipped</p>;
export const EmailFailed = () => (
  <div className="flex items-center justify-start">
    <X className="text-red-500" />
  </div>
);
