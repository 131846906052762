import { useFormContext } from 'react-hook-form';
import InputFormField from '@/common/core/FormElements/InputFormField';
import Wrapper from '../Wrapper';

const OtherInfo = () => {
  const { control } = useFormContext();

  return (
    <Wrapper blockTitle="Other personal info">
      <InputFormField name="familyStatus" label="Family status" control={control} />
      <InputFormField name="location" label="Location" control={control} />
      {/*<SelectFormField name='timezone' label='Timezone' control={control} optionList={TimeZones} />*/}
      <InputFormField name="dietaryPreferences" label="Dietary preferences" control={control} />
      <InputFormField name="occupation" label="Occupation" control={control} />
      <InputFormField
        name="otherMemorableInfo"
        label="Other memorable info"
        control={control}
        multiline={{ rows: 3, maxRows: 9 }}
      />
      <InputFormField
        name="healthGoals"
        label="Health Goals"
        control={control}
        multiline={{ rows: 3, maxRows: 9 }}
      />
    </Wrapper>
  );
};

export default OtherInfo;
