import { useForgotPassword } from '@/hooks/auth/useForgotPassword';
import ForgotPasswordForm from '../ForgetPasswordForm';

const ForgotPassword = () => {
  const resetPassword = useForgotPassword();

  return <ForgotPasswordForm resetPassword={resetPassword} />;
};

export default ForgotPassword;
