import React, { useState } from 'react';
import { Typography, Radio, RadioGroup } from '@mui/material';
import { Edit, RadioButtonCheckedRounded } from '@mui/icons-material';
import {
  AnswerTitle,
  EditIcon,
  Icon,
  LocalWrapper,
  Question,
  QuestionText,
  Row,
  StyledFormControlLabel,
  TypeTitle,
  VariantIcon,
  VariantsListWrapper,
} from '../styles';
import { IRadioSurvey } from '@/types';

const RadioComponent = ({
  indexStage,
  data,
  isEditable,
  suerveyId,
  handleEditSurvey,
}: IRadioSurvey) => {
  const { id, question, type, answer, answerVariants, title } = data;
  const isAnswerVariantHasId = !data.answerVariants.some((variant) => variant.nextQuestionId);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [value, setValue] = useState<string[]>(answer?.answers || ['']);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue([(event.target as HTMLInputElement).value]);

    if (handleEditSurvey) {
      handleEditSurvey(suerveyId || '', indexStage || 0, id, [
        (event.target as HTMLInputElement).value,
      ]);
    }
  };

  return (
    <Question>
      <Row>
        <LocalWrapper>
          <Icon as={RadioButtonCheckedRounded} />
          <QuestionText>
            {title} {question}
          </QuestionText>
        </LocalWrapper>
        {isEditable && isAnswerVariantHasId && (
          <EditIcon as={Edit} onClick={() => setEditMode(true)} />
        )}
      </Row>
      <Row>
        <LocalWrapper>
          <TypeTitle>Type</TypeTitle>
          <Typography>{type}</Typography>
        </LocalWrapper>
      </Row>
      <Row>
        <LocalWrapper>
          <AnswerTitle>Answers</AnswerTitle>
          <VariantsListWrapper variant="standard">
            <RadioGroup
              aria-labelledby="answers-radio-buttons"
              value={value[0]}
              name="radio-buttons-group"
              onChange={handleInput}
            >
              {answerVariants.map((variant, index) => (
                <StyledFormControlLabel
                  key={index + variant.answer}
                  value={variant.answer}
                  control={<VariantIcon as={Radio} disabled={!editMode} />}
                  label={variant.answer}
                />
              ))}
            </RadioGroup>
          </VariantsListWrapper>
        </LocalWrapper>
      </Row>
    </Question>
  );
};

export default RadioComponent;
