import { getGdocDocById } from '@/api/gdrive';
import { PromptTemplate } from '@langchain/core/prompts';
import { ChatOpenAI } from '@langchain/openai';

export const classifyImportantInfo = async (message: string) => {
  try {
    const template = await getGdocDocById('14D4YTMRzXPQLSqZQbN8RKMTD3vHuKhsOHxXiON0bNXc');
    const promptTemplate = PromptTemplate.fromTemplate(template.content);
    const model = new ChatOpenAI({
      modelName: 'gpt-4o',
      temperature: 0,
      openAIApiKey: process.env.REACT_APP_OPENAI_KEY,
    });
    const chain = promptTemplate.pipe(model);
    const result = await chain.invoke({
      customer_message: message,
      category_list:
        'main challenges, medical conditions, dietary restrictions, physical activities restrictions, preference nutrition, preference in physical activities, other life circumstances, other infos',
    });
    return Promise.resolve(JSON.parse(result.content as string));
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};
