import { styled, Theme } from '@mui/system';
import { MenuItem } from '@mui/material';

export const DateTextLeft = {
  color: 'rgba(15, 15, 33, 0.4) !important',
};

export const DateTextRight = {
  color: '#96BCB4 !important',
};

export const FileContainerLeft = {
  alignItems: 'flex-start',
};

export const FileContainerRight = {
  alignItems: 'flex-end',
};

export const FileBlockLeft = {
  marginLeft: 24,
  backgroundColor: 'rgba(197, 197, 254, 0.16)',
  borderRadius: '12px 12px 12px 0px',
};

export const FileBlockRight = {
  marginRight: 24,
  backgroundColor: '#D6F5EE',
  borderRadius: '12px 12px 0px 12px',
};

export const FileBlockChosen = {
  backgroundColor: '#F2F4F7 !important',
};

export const DateText = styled('div')<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
  fontSize: 12,
  fontWeight: 400,
  ...(isCurrentUser ? DateTextLeft : DateTextRight),
}));

export const FileContainer = styled('div')<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  ...(isCurrentUser ? FileContainerLeft : FileContainerRight),
}));

export const FileBlock = styled('div')<{ isCurrentUser: boolean; fileChosen: boolean }>(
  ({ isCurrentUser, fileChosen }) => ({
    display: 'flex',
    flexDirection: 'column',
    minWidth: 204,
    marginTop: 6,
    ...(isCurrentUser ? FileBlockLeft : FileBlockRight),
    ...(fileChosen ? FileBlockChosen : {}),
  }),
);

export const FileInfoBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: 6,
}));

export const FileInfoBlockLeft = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '50%',
  paddingLeft: 9,
}));

export const FileInfoBlockRight = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '50%',
  paddingRight: 9,
}));

export const FileImageBlock = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

export const FileImage = styled('img')(({ theme }: { theme: Theme }) => ({
  marginTop: 8,
  marginBottom: 8,
  width: '100%',
  height: 'auto',
  maxWidth: 180,
  maxHeight: 360,
}));

export const UserNameFileText = styled('div')<{ isCurrentUser: boolean }>(({ isCurrentUser }) => ({
  fontSize: 12,
  fontWeight: 400,
  width: 100,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(isCurrentUser ? DateTextLeft : DateTextRight),
}));

export const SharedMessage = styled('div')(({ theme }: { theme: Theme }) => ({
  marginRight: 28,
}));

export const ReadIcon = styled('div')(({ theme }: { theme: Theme }) => ({
  fontSize: 12,
  padding: 0,
  margin: 0,
  marginLeft: 2,
}));

export const UnReadIcon = styled(ReadIcon)(({ theme }: { theme: Theme }) => ({
  ...ReadIcon,
  color: 'rgb(150, 188, 180)',
}));

export const IsReadIcon = styled(ReadIcon)(({ theme }: { theme: Theme }) => ({
  ...ReadIcon,
  bgColor: 'rgb(71, 178, 154)',
}));

export const SubMenu = styled(MenuItem)(({ theme }: { theme: Theme }) => ({
  minWidth: 150,
}));

export const SubMenuDelete = styled(SubMenu)(({ theme }: { theme: Theme }) => ({
  color: '#FF6D7D',
}));
