import { styled } from '@mui/system';
import Button from '@/common/core/Button';
import { Menu, MenuItem, Typography } from '@mui/material';

export const MenuButtonList = styled(Button)({
  display: 'flex',
  justifyContent: 'space-around',
  textTransform: 'revert',
  borderRadius: 8,
  padding: '12px 20px',
});

export const MenuButtonListText = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

export const MenuWrapper = styled('div')({
  marginRight: 20,
  marginTop: 10,
  display: 'flex',
  justifyContent: 'flex-end',
});

export const StyledMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    minWidth: 200,
    filter: 'drop-shadow(0px 5px 20px rgba(23, 33, 48, 0.24))',
    borderRadius: 8,
    marginTop: 6,
    '& .MuiList-padding': {
      padding: '0 !important',
    },
  },
});

export const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E4E7ED',
  '&:last-child': {
    borderBottom: 'none',
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'revert',
  },
});
