import React, { useState, useEffect } from 'react';
import TagItem from '../ResourcesCategoryTagItem';
import SummaryHover from '../ResourcesCategorySummaryHover';
import { StyledAccordion, StyledAccordionDetails, SubCategoryList } from './styles';
import { IResourceCategory, IResourcesCategory } from '@/types';

export const ResourcesCategory = ({
  category,
  handleEditCategory,
  handleAddCategory,
  handleAddTag,
  handleRemoveCategory,
  handleEditTag,
  handleDeleteTag,
  removeChild,
  handleCategorySelected,
  handleTagsSelected,
  currentCategoryId,
  level,
}: IResourcesCategory) => {
  const MAX_ORDER_VALUE = 9999;
  const [children, setChildren] = useState<IResourceCategory[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [picture, setPicture] = useState<string>('');
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [order, setOrder] = useState<number>(MAX_ORDER_VALUE);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setChildren(
      category.children.sort((curr: IResourceCategory, next: IResourceCategory) => {
        return curr?.order - next?.order;
      }),
    );
    setTags(category.tags);
    setName(category.name);
    setPicture(category.picture);
    setOrder(category.order);
  }, [category]);

  useEffect(() => {
    if (!currentCategoryId) {
      setIsSelected(false);
    } else {
      const isCurrent: boolean = currentCategoryId === category.id;
      if (isCurrent) {
        setIsSelected(true);
        setExpanded(true);
      } else {
        setIsSelected(false);
      }
    }
  }, [currentCategoryId]);

  const getCurrentCategory = (): IResourceCategory => {
    return {
      id: category.id,
      name: name,
      tags: tags,
      children: children,
      picture: picture,
      order: order,
    };
  };

  const handleCreateCategory = (newCategory: IResourceCategory) => {
    setChildren([...category.children, newCategory]);
    if (!expanded) {
      setExpanded(true);
    }
  };

  const handleCreateTag = (name: string) => {
    setTags([...tags, name]);
    if (!expanded) {
      setExpanded(true);
    }
  };

  const handleChange = (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
    handleCategorySelected(getCurrentCategory());
  };

  const handleCategoryEdited = (newName: string, url: string) => {
    setName(newName);
    setPicture(url);
  };

  const handleTagEdited = (tagIndex: number, newTag: string) => {
    const copy = [...tags];
    copy[tagIndex] = newTag;
    setTags(copy);
  };

  const chooseEditCategory = () => {
    handleEditCategory(getCurrentCategory(), handleCategoryEdited);
  };

  const chooseRemoveCategory = () => {
    handleRemoveCategory(getCurrentCategory(), removeChild);
  };

  const chooseNewCategory = () => {
    handleAddCategory(category.id, handleCreateCategory);
  };

  const chooseNewTag = () => {
    handleAddTag(getCurrentCategory(), handleCreateTag);
  };

  const chooseEditTag = (index: number) => {
    handleEditTag(getCurrentCategory(), index, handleTagEdited);
  };

  const chooseDeleteTag = (index: number) => {
    handleDeleteTag(getCurrentCategory(), index, removeTag);
  };

  const removeTag = (index: number) => {
    setTags(tags.filter((item, i) => i !== index));
  };

  const removeChildCategory = (childId: string) => {
    const newArray: IResourceCategory[] = children.filter((item) => item.id !== childId);
    category.children = newArray;
    setChildren(newArray);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let copy = [...selectedTags, event.target.value];
    if (selectedTags.includes(event.target.value)) {
      copy = copy.filter((tag) => tag !== event.target.value);
    }
    setSelectedTags(copy);
    handleTagsSelected([...copy]);
  };

  return (
    <StyledAccordion elevation={0} square expanded={expanded} onChange={handleChange}>
      <SummaryHover
        categoryName={name}
        addCategoryClickHandler={chooseNewCategory}
        addTagClickHandler={chooseNewTag}
        renameClickHandler={chooseEditCategory}
        deleteClickHandler={chooseRemoveCategory}
        isSelected={isSelected}
        level={level}
      />
      {(children.length || tags) && (
        <StyledAccordionDetails>
          {children.map((child) => (
            <ResourcesCategory
              category={child}
              level={level + 1}
              handleTagsSelected={handleTagsSelected}
              handleCategorySelected={handleCategorySelected}
              handleEditCategory={handleEditCategory}
              handleAddCategory={handleAddCategory}
              handleAddTag={handleAddTag}
              handleRemoveCategory={handleRemoveCategory}
              handleEditTag={handleEditTag}
              handleDeleteTag={handleDeleteTag}
              removeChild={removeChildCategory}
              currentCategoryId={currentCategoryId}
              key={child.id}
            />
          ))}
          {tags && (
            <SubCategoryList>
              {tags.map((tag, index) => (
                <TagItem
                  index={index}
                  tag={tag}
                  handleCheckboxChange={handleCheckboxChange}
                  chooseRenameTag={chooseEditTag}
                  chooseDeleteTag={chooseDeleteTag}
                  key={index}
                />
              ))}
            </SubCategoryList>
          )}
        </StyledAccordionDetails>
      )}
    </StyledAccordion>
  );
};
