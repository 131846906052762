import { sendEmail } from '@/utils/firebase/firebaseTools';
import { Base64 } from 'js-base64';
import { db } from '../firebase/firebaseInit';
import { collection, getDocs, query, where } from '@firebase/firestore';
import { EUserRoles } from '@/types';

export const inviteEmailNotification = (emailData: {
  to: string;
  subject: string;
  message: string;
}) => {
  return sendEmail(emailData);
};

interface IGenerateInvitationUrlProps {
  firstName: string;
  lastName: string;
  confirmId: string;
  email: string;
  id: string;
  phone?: string | null;
}

export function generateInvitationUrl(urlInfo: IGenerateInvitationUrlProps) {
  return `${window.location.protocol}//${window.location.host}/invitation/${Base64.toBase64(
    JSON.stringify(urlInfo),
  )}`;
}

export const inviteEmailNotificationCoach = (
  firstName: string,
  lastName: string,
  confirmId: string,
  email: string,
  id: string,
  phone: string | undefined,
) => {
  const urlInfo = {
    firstName,
    lastName,
    confirmId,
    email,
    id,
    ...(phone && { phone }),
  };
  const url = generateInvitationUrl(urlInfo);

  return inviteEmailNotification({
    to: email,
    subject: 'Coach invitation',
    message: `Hello  ${firstName} ${lastName}.<br><br>
                Please follow this link and fill your personal data:  <a href="${url}">${url}</a>
      <br><br>
      FitMate Staff`,
  });
};

export const inviteEmailNotificationReferralPartner = (
  firstName: string,
  lastName: string,
  confirmId: string,
  email: string,
  id: string,
  phone: string | undefined,
) => {
  const urlInfo = {
    firstName,
    lastName,
    confirmId,
    email,
    id,
    ...(phone && { phone }),
  };
  const url = generateInvitationUrl(urlInfo);

  return inviteEmailNotification({
    to: email,
    subject: "Unlock Your Clients' Progress on Fitmate Coach",
    message: `Hi  ${firstName} ${lastName},<br><br>
      At Fitmate Coach, we're dedicated to supporting your clients on their journey to better health, and we're excited to offer you a window into their progress.<br/>
      To ensure you have real-time insights and can celebrate every milestone alongside them, please take a moment to set up your exclusive partner account:<br/>
      <a href="${url}">Setup Your Partner Account</a><br/><br/>
      Thank you for partnering with us to make a difference in your clients' lives.<br/><br/>
      Warm regards,<br/>
      The Fitmate Coach Team`,
  });
};

export const checkDuplicateEmail = async (email: string): Promise<boolean> => {
  const q = query(
    collection(db, 'users'),
    where('role', '==', EUserRoles.COACH),
    where('email', '==', email),
  );
  const sameEmailCustomers = await getDocs(q);

  return !!sameEmailCustomers.docs.length;
};
