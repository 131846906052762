import { Checkbox } from '@mui/material';
import { TagName, StyledTagItem } from './styles';
import { ITagItemExtension } from '@/types';

const TagItem = ({ index, tag, handleCheckboxChange }: ITagItemExtension) => {
  return (
    <StyledTagItem>
      <TagName
        key={index}
        control={
          <Checkbox
            onChange={handleCheckboxChange}
            key={`${tag}-key-checkbox`}
            name={tag}
            value={tag}
            color="primary"
          />
        }
        label={tag}
      />
    </StyledTagItem>
  );
};

export default TagItem;
