import { styled } from '@mui/system';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import Button from '@/common/core/Button';

export const ListWrapper = styled('div')({
  maxHeight: '1000px',
  overflowY: 'scroll',
  marginBottom: '36px',
});

export const ItemWrapper = styled(Accordion)({
  backgroundColor: '#F9FAFC',
  border: 'none',
  boxShadow: 'none',
  marginBottom: '16px',
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
});

export const AccordionSummaryStyled = styled(AccordionSummary)({
  padding: '0px 32px',
  border: '1px solid #E4E7ED',
  borderRadius: '4px 4px 0px 0px',
  '& .MuiAccordionSummary-content': {
    margin: '14px 0',
  },
  '& .MuiButton-containedPrimary:hover': {
    backgroundColor: '#FFFFFF',
  },
});

export const ItemTitle = styled(Typography)<{ isDeclined: boolean }>(({ isDeclined }) => ({
  fontWeight: 600,
  fontSize: '18px',
  ...(isDeclined && {
    color: 'theme.palette.error.main',
  }),
}));

export const AccordionDetailsStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 36px',
});

export const HeaderWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const RightSide = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Error = styled('span')({
  color: '#FF6D7D',
});

export const IsStatusDeclined = styled('span')({
  color: '#FF6D7D',
});

export const ItemButton = styled(Button)({
  marginRight: '8px',
  color: 'rgba(15, 15, 33, 0.6)',
  border: '1px solid rgba(15, 15, 33, 0.2)',
  borderRadius: '8px',
  backgroundColor: '#F9FAFC',
  fontWeight: 600,
  boxShadow: 'none',
});

export const Date = styled(Typography)({
  fontSize: '16px',
  color: 'rgba(15, 15, 33, 0.4)',
});

export const ExpandArrowIcon = styled('span')({
  transform: 'rotate(180deg)',
});
