import { Box, CircularProgress } from '@mui/material';
import { StyledText } from './styles';
import { ICircularProgressWithLabel } from '@/types';

const CircularProgressWithLabel = ({
  progressValue,
  finishValue,
  color,
}: ICircularProgressWithLabel) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', color: color }}>
      <CircularProgress
        color="inherit"
        variant="determinate"
        value={progressValue > finishValue ? 100 : (progressValue / finishValue) * 100}
        size={46}
        thickness={5.6}
        style={{
          borderRadius: '100%',
          boxShadow: 'inset 0 0 0px 5.6px rgba(210, 212, 217, 0.4)',
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledText>{`${progressValue}/${finishValue}`}</StyledText>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
