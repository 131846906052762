import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { fetchCustomerById } from '@/store/customers/customerInfosSlice';
import { EChatPageType } from '@/types';
import ChatProgram from '../../Coach/ChatContainer/ChatProgram';
import ChatUserProfile from '../../Coach/ChatContainer/ChatUserProfile';
import { CenterBlock, CenterProgramBlock, CenterTopBlock, Container } from './styles';
import ClientNavigation from '../ClientNavigation';
import ChatSurveys from '../../Coach/ChatContainer/ChatSurveys';

const ClientContentContainer = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [pageType, setPageType] = useState<string>(EChatPageType.PROGRAM);
  const { customer } = useAppSelector((state) => state.customerInfos);

  useEffect(() => {
    dispatch(fetchCustomerById(id));
  }, []);

  const handlePageType = (value: string) => {
    setPageType(value);
  };

  return (
    <>
      <CenterTopBlock>
        {!!customer && <ClientNavigation pageType={pageType} handlePageType={handlePageType} />}
      </CenterTopBlock>
      <Container>
        <CenterBlock>
          {pageType === EChatPageType.PROGRAM && (
            <CenterProgramBlock>
              <ChatProgram />
            </CenterProgramBlock>
          )}
          {pageType === EChatPageType.USER_PROFILE && (
            <CenterProgramBlock>
              <ChatUserProfile />
            </CenterProgramBlock>
          )}

          {pageType === EChatPageType.SURVEY && customer?.id && (
            <CenterProgramBlock>
              <ChatSurveys currentUserId={customer.id} />
            </CenterProgramBlock>
          )}
        </CenterBlock>
      </Container>
    </>
  );
};

export default ClientContentContainer;
