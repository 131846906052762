import { formatTime } from '@/utils/format/datetime';
import { StyledAccessibilityNewIcon, SurveyHorizontalBlock, SurveyHorizontalChip } from '../styles';
import { IBodyProfileChip } from '@/types';

const BodyProfileChip = ({ message }: IBodyProfileChip) => {
  const { createdAt, bodyProfileData } = message;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { type, date } = bodyProfileData;

  const buildLabel = () => {
    switch (type) {
      case 'measurement_added':
        return `Client added measurements for ${date} at `;
      case 'measurement_updated':
        return `Client updated measurements for ${date} at `;
      case 'start_weight_updated':
        return 'Client updated start weight at ';
      case 'start_weight_added':
        return 'Client added start weight at ';
      case 'goal_updated':
        return 'Client updated goal weight at ';
      default:
        return 'Not found';
    }
  };

  return (
    <SurveyHorizontalBlock>
      <SurveyHorizontalChip
        icon={<StyledAccessibilityNewIcon />}
        label={`${buildLabel()} ${formatTime(createdAt.seconds * 1000)}`}
      />
    </SurveyHorizontalBlock>
  );
};

export default BodyProfileChip;
