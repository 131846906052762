import { db } from '@/utils/firebase/firebaseInit';
import { updateDoc, doc, deleteField } from 'firebase/firestore';

export const editProfile = async (
  userId: string,
  firstName: string,
  lastName: string,
  phone: string,
  displayName: string,
  bio: string,
): Promise<any> => {
  try {
    const docRef = doc(db, 'users', userId);
    await updateDoc(docRef, {
      firstName,
      lastName,
      phone: phone ?? deleteField(),
      displayName: displayName ?? deleteField(),
      bio: bio ? bio : deleteField(),
    });
  } catch (error) {
    console.error('error', error);
  }
};
