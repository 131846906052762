import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { where } from '@firebase/firestore';
import { startOfDay, endOfDay } from 'date-fns';
import {
  query,
  getDocs,
  orderBy,
  collection,
  limit,
  updateDoc,
  doc,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';
import { IBodyProfileInterface } from '@fitmate-coach/fitmate-types';

const initialState: IBodyProfileInterface = {
  loading: true,
  goals: null,
  profiles: null,
  showEditStartBodyProfile: false,
  showEditGoal: false,
  showAddBodyProfile: false,
  showFrequencyModal: false,
  editProfileId: null,
};

const getBodyProfileByUserAndDate = async (userId: string, date: any) => {
  try {
    const ref = collection(db, 'users', userId, 'body_profiles');
    const q = query(
      ref,
      where('createdAt', '>=', startOfDay(new Date(date))),
      where('createdAt', '<=', endOfDay(new Date(date))),
    );

    const docSnap = await getDocs(q);

    return docSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (e) {
    console.log('error', e);
  }
};

export const fetchBodyGoals = createAsyncThunk(
  'bodyProfile/fetchBodyGoals',
  async (userId: string) => {
    try {
      const ref = collection(db, 'users', userId, 'body_profile_goals');
      const q = query(ref, orderBy('createdAt', 'desc'), limit(1));
      const docSnap = await getDocs(q);

      return docSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }))[0];
    } catch (e) {
      console.log('error', e);
    }
  },
);

export const fetchBodyProfiles = createAsyncThunk(
  'bodyProfile/fetchBodyProfiles',
  async (userId: string) => {
    const ref = collection(db, 'users', userId, 'body_profiles');
    const q = query(ref, orderBy('createdAt', 'desc'));
    const docSnap = await getDocs(q);

    return docSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  },
);

export const updateStartBodyProfile = createAsyncThunk(
  'bodyProfile/updateStartBodyProfile',
  async (data: any, APIThunk) => {
    const dispatch = APIThunk.dispatch;
    try {
      const { weight, id, userId, date, selfFeeling, energyLevel } = data;
      const docRef = doc(db, 'users', userId, 'body_profiles', id);
      await updateDoc(docRef, {
        weight,
        createdAt: new Date(date),
        updatedAt: new Date(),
        selfFeeling,
        energyLevel,
      });
      dispatch(fetchBodyProfiles(userId));
    } catch (e) {
      console.log(e);
    }
  },
);

export const addBodyProfile = createAsyncThunk(
  'bodyProfile/addBodyProfile',
  async (data: any, APIThunk) => {
    const dispatch = APIThunk.dispatch;
    const { weight, selfFeeling, bodyWaist, heartRate, energyLevel, userId, date } = data;
    const docRef = collection(db, 'users', userId, 'body_profiles');
    // we need to check if an entry for this date already exist.
    const existingRecords = await getBodyProfileByUserAndDate(userId, new Date(date));
    if (existingRecords && existingRecords.length > 0) {
      const docExistingRef = doc(db, 'users', userId, 'body_profiles', existingRecords[0].id);
      await updateDoc(docExistingRef, {
        weight,
        selfFeeling,
        bodyWaist,
        heartRate,
        energyLevel,
      });
    } else {
      await addDoc(docRef, {
        weight,
        selfFeeling,
        bodyWaist,
        heartRate,
        energyLevel,
        createdAt: date ? new Date(date) : new Date(),
      });
    }
    dispatch(fetchBodyProfiles(userId));
  },
);

export const addBodyGoal = createAsyncThunk(
  'bodyProfile/addBodyGoal',
  async (data: any, APIThunk) => {
    const dispatch = APIThunk.dispatch;

    const { weight, userId } = data;
    const createdAt = serverTimestamp();
    const docRef = collection(db, 'users', userId, 'body_profile_goals');
    await addDoc(docRef, {
      weight,
      selfFeeling: null,
      bodyWaist: null,
      heartRate: null,
      energyLevel: null,
      createdAt,
    });

    dispatch(fetchBodyGoals(userId));
  },
);

export const bodyProfileSlice = createSlice({
  name: 'bodyProfile',
  initialState,
  reducers: {
    closeEditStartBodyProfile: (state) => {
      state.showEditStartBodyProfile = false;
      state.editProfileId = null;
    },
    toggleEditStartBodyProfile: (state) => {
      state.showEditStartBodyProfile = !state.showEditStartBodyProfile;
    },
    toggleEditGoal: (state) => {
      state.showEditGoal = !state.showEditGoal;
    },
    toggleAddBodyProfile: (state) => {
      state.showAddBodyProfile = !state.showAddBodyProfile;
      state.editProfileId = null;
    },
    closeAddBodyProfile: (state) => {
      state.showAddBodyProfile = false;
      state.editProfileId = null;
    },
    editBodyProfile: (state, action) => {
      state.showAddBodyProfile = !state.showAddBodyProfile;
      if (action.payload) {
        state.editProfileId = action.payload.id;
      }
    },
    toggleFrequencyModal: (state) => {
      state.showFrequencyModal = !state.showFrequencyModal;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBodyGoals.pending, (state) => {
      state.goals = null;
    });
    builder.addCase(fetchBodyGoals.fulfilled, (state, action: PayloadAction<any>) => {
      state.goals = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchBodyProfiles.fulfilled, (state, action: PayloadAction<any>) => {
      state.profiles = action.payload;
    });
    builder.addCase(addBodyProfile.fulfilled, (state) => {
      state.loading = true;
      state.editProfileId = null;
    });
    builder.addCase(addBodyGoal.fulfilled, (state) => {
      state.loading = true;
      state.editProfileId = null;
    });
    builder.addCase(updateStartBodyProfile.fulfilled, (state) => {
      state.loading = true;
      state.editProfileId = null;
    });
  },
});

export const {
  toggleEditStartBodyProfile,
  toggleEditGoal,
  toggleAddBodyProfile,
  editBodyProfile,
  closeEditStartBodyProfile,
  closeAddBodyProfile,
  toggleFrequencyModal,
} = bodyProfileSlice.actions;

export default bodyProfileSlice.reducer;
