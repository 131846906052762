/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, query, where, getDocs, updateDoc, deleteField } from 'firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';
import { DocumentData } from 'firebase/firestore';
import { doc } from '@firebase/firestore';

interface coachAuthState {
  coachData?: DocumentData | null;
  coachUid?: string;
  isTimeStarted?: boolean;
}

const initialState: coachAuthState = {
  coachData: null,
  isTimeStarted: false,
};

export const getCoachData = createAsyncThunk(
  'coaches/getCoachData',
  async (args: { uid: string }) => {
    const { uid } = args;
    const q = query(collection(db, 'users'), where('uid', '==', uid));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      // we have an issue with the coach uid
    }

    return { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
  },
);

export const updateCoachTogglToken = createAsyncThunk(
  'coaches/updateCoachTogglToken',
  async (args: { id: string; toggleApiToken: string }) => {
    const { id, toggleApiToken } = args;
    const docRef = doc(db, 'users', id);
    await updateDoc(docRef, {
      toggleApiToken,
    });
  },
);

export const unlinkCoachTogglToken = createAsyncThunk(
  'coaches/unlinkCoachTogglToken',
  async (args: { id: string }) => {
    const { id } = args;
    const docRef = doc(db, 'users', id);
    await updateDoc(docRef, {
      toggleApiToken: deleteField(),
    });
  },
);

export const coachAuthSlice = createSlice({
  name: 'coachAuth',
  initialState,
  reducers: {
    saveCoachUid: (state, action) => {
      // @ts-ignore
      state.coachUid = action.payload;
    },
    setIsTimeStarted: (state, action) => {
      state.isTimeStarted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCoachData.fulfilled, (state, action) => {
      state.coachData = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { saveCoachUid, setIsTimeStarted } = coachAuthSlice.actions;

export default coachAuthSlice.reducer;
