import { useState } from 'react';
import { Wrapper } from './styles';
import { ECoachProfileMenuItem } from '@/types';
import ProfileHeader from './ProfileHeader';
import ProfileForm from './ProfileForm';
import PasswordForm from './PasswordForm';
import { useAppSelector } from '@/hooks';
import TogglForm from './TogglForm';

const ProfileContainer = () => {
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const [pageProfile, setPageProfile] = useState<string>(ECoachProfileMenuItem.PROFILE);

  const handlePageProfile = (value: string) => {
    setPageProfile(value);
  };

  return (
    <>
      <ProfileHeader pageProfile={pageProfile} handlePageProfile={handlePageProfile} />
      <Wrapper isToggleApiToken={!!coachData?.toggleApiToken}>
        {pageProfile === ECoachProfileMenuItem.PROFILE && <ProfileForm />}
        {pageProfile === ECoachProfileMenuItem.PASSWORD && (
          <PasswordForm pageProfile={pageProfile} />
        )}
        {pageProfile === ECoachProfileMenuItem.TOGGL && <TogglForm />}
      </Wrapper>
    </>
  );
};

export default ProfileContainer;
