import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import SelectOptionsWrapper from '@/common/core/SelectOptionsWrapper';
import { EUserRoles } from '@/types';
import Wrapper from '../Wrapper';
import { useEffect, useState } from 'react';
import { getByRole } from '@/api/firestores';
import { where } from '@firebase/firestore';
import { SelectBlock, StyledSelect } from '../styles';

const PartnerReferralDataInfo = () => {
  const { control } = useFormContext();

  const [partnerReferralsList, setPartnerReferralsList] = useState<any[]>([]);

  useEffect(() => {
    // TODO: Arnaud, do I filter by isInviteAccepted as well there?
    getByRole(EUserRoles.PARTNER_REFERRAL, [where('isInviteAccepted', '==', true)]).then((res) => {
      setPartnerReferralsList(
        res.docs.map((doc) => {
          const data = doc.data();

          return {
            key: data.uid,
            value: data.uid,
            title: `${data.firstName} ${data.lastName}`,
          };
        }),
      );
    });
  }, []);

  const name = 'partnerReferralUid';
  const label = 'Select the partner referral';

  return (
    <Wrapper blockTitle="Partner referral">
      <SelectBlock>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple" shrink>
            {label}
          </InputLabel>
          <Controller
            render={({ field }) => (
              <StyledSelect
                // variant="standard"
                native
                autoWidth
                {...field}
                label={label}
                input={<OutlinedInput notched label={label} />}
              >
                <SelectOptionsWrapper items={partnerReferralsList} hasEmpty={true} />
              </StyledSelect>
            )}
            name={name}
            control={control}
          />
        </FormControl>
      </SelectBlock>
    </Wrapper>
  );
};

export default PartnerReferralDataInfo;
