import { useCallback } from 'react';
import { db } from '@/utils/firebase/firebaseInit';
import { doc, updateDoc, deleteField } from 'firebase/firestore';

export const useAdminEditUser = () => {
  // TODO simplify this two updates
  // TODO simplify with coach code in useUserProfile.tsx

  const editUser = useCallback(
    async (
      userId: string,
      firstName: string,
      lastName: string,
      gender: string,
      dateOfBirth: number,
      familyStatus: string,
      location: string,
      dietaryPreferences: string,
      occupation: string,
      timezone: string,
      height: number,
      weight: number,
      otherMemorableInfo: string,
      healthGoals: string,
    ) => {
      const docRef = doc(db, 'users', userId);
      await updateDoc(docRef, {
        gender: deleteField(),
        dateOfBirth: deleteField(),
        familyStatus: deleteField(),
        location: deleteField(),
        dietaryPreferences: deleteField(),
        occupation: deleteField(),
        height: deleteField(),
        weight: deleteField(),
        otherMemorableInfo: deleteField(),
        healthGoals: deleteField(),
      });

      const genderValue = gender ? { gender } : {};
      const dateOfBirthValue = dateOfBirth ? { dateOfBirth } : {};
      const familyStatusValue = familyStatus ? { familyStatus } : {};
      const locationValue = location ? { location } : {};
      const dietaryPreferencesValue = dietaryPreferences ? { dietaryPreferences } : {};
      const occupationValue = occupation ? { occupation } : {};
      const heightValue = height ? { height } : {};
      const weightValue = weight ? { weight } : {};
      const otherMemorableInfoValue = otherMemorableInfo ? { otherMemorableInfo } : {};
      const healthGoalsValue = healthGoals ? { healthGoals } : {};
      const timezoneValue = timezone ? { timezone } : {};

      const details = {
        firstName,
        lastName,
        ...genderValue,
        ...dateOfBirthValue,
        ...familyStatusValue,
        ...locationValue,
        ...dietaryPreferencesValue,
        ...occupationValue,
        ...timezoneValue,
        ...heightValue,
        ...weightValue,
        ...otherMemorableInfoValue,
        ...healthGoalsValue,
      };
      await updateDoc(docRef, details);
    },
    [],
  );

  return {
    editUser,
  };
};
