import { IResourceBlock, EResourceBlockTypes } from '@/types';

function blockToString(block: IResourceBlock): string {
  switch (block.type) {
    case EResourceBlockTypes.TEXT:
      return `<p>${block.body}</p>`;
    case EResourceBlockTypes.SUBTITLE:
      return `<h3>${block.body}</h3>`;
    case EResourceBlockTypes.PICTURE:
      return `<img src="${block.url}" />`;
    default:
      return '';
  }
}

export default blockToString;
