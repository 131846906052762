import { useState, useEffect, useCallback } from 'react';
import { db } from '@/utils/firebase/firebaseInit';
import { IAction, EActionsProgramStatus, IProgramAction } from '@/types';
import { useAppSelector } from '@/hooks';
import {
  collection,
  getDocs,
  DocumentSnapshot,
  addDoc,
  Timestamp,
  doc,
  updateDoc,
  serverTimestamp,
  deleteField,
} from 'firebase/firestore';

export const useProgramData = () => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const [programActions, setProgramActions] = useState<IProgramAction[]>([]);

  const loadUserProgramData = useCallback(async () => {
    try {
      if (customer?.id) {
        const querySnapshot = await getDocs(collection(db, 'users', customer.id, 'programActions'));
        if (!querySnapshot.empty) {
          setProgramActions(
            querySnapshot.docs.map((doc: DocumentSnapshot<any>) => ({
              ...doc.data(),
              docId: doc.id,
            })),
          );
        } else {
          setProgramActions([]);
        }
      } else {
        setProgramActions([]);
      }
    } catch (error: any) {
      console.error(error?.message ?? 'Program Actions Error');
    }
  }, [customer]);

  useEffect(() => {
    customer?.id && loadUserProgramData().then();
  }, [customer]);

  const editProgramAction = async (
    programAction: IProgramAction,
    action: IAction | null,
    isReset: boolean,
  ) => {
    if (!programAction.docId && customer?.id) {
      // new
      await addDoc(collection(db, 'users', customer.id, 'programActions'), {
        actionId: programAction.actionId,
        status: programAction.status,
        subStatus: programAction.subStatus,
        isNew: true,
        isDeleted: false,
        goal: programAction.goal ? programAction.goal.trim() : programAction.draft?.goal.trim(),
        daysPerWeek: programAction.daysPerWeek,
        ...(programAction.firstDay ? { firstDay: programAction.firstDay } : {}),
        days: programAction.days,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        assignmentTime: Timestamp.now(),
        ...action,
        description: programAction.description
          ? programAction.description.trim()
          : programAction.draft?.description.trim(),
      });
    } else {
      // if exist
      if (programAction.status === EActionsProgramStatus.PAST) {
        isReset = true;
      }
      if (programAction.docId && customer?.id) {
        const docRef = doc(db, 'users', customer.id, 'programActions', programAction.docId);
        await updateDoc(docRef, {
          status: programAction.status,
          subStatus: programAction.subStatus,
          isNew: programAction.isNew,
          goal: programAction.goal ? programAction.goal.trim() : programAction.draft?.goal.trim(),
          description: programAction.description
            ? programAction.description.trim()
            : programAction.draft?.description.trim(),
          daysPerWeek: programAction.daysPerWeek,
          ...(programAction.firstDay ? { firstDay: programAction.firstDay } : {}),
          days: programAction.days,
          ...(isReset && {
            assignmentTime: serverTimestamp,
            lastTracking: deleteField(),
          }),
          updatedAt: serverTimestamp,
          ...(programAction.status === EActionsProgramStatus.CURRENT &&
            !programAction.assignmentTime && {
              assignmentTime: serverTimestamp,
            }),
        });
      }
    }
  };

  const deleteProgramAction = useCallback(
    async (docId: string, isDeleted: boolean) => {
      if (customer?.id) {
        const docRef = doc(db, 'users', customer.id, 'programActions', docId);
        await updateDoc(docRef, {
          isDeleted,
          updatedAt: serverTimestamp,
        });
      }
    },
    [customer?.id],
  );

  return {
    programActions,
    editProgramAction,
    deleteProgramAction,
  };
};
