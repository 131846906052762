import { useState } from 'react';
import { Typography } from '@mui/material';
import { TextFields, Edit } from '@mui/icons-material';
import {
  AnswerTitle,
  AnswerValue,
  EditIcon,
  Icon,
  InputQuestion,
  LocalWrapper,
  Question,
  QuestionText,
  Row,
  TypeTitle,
} from '../styles';
import { ESurveysQuestionTypes, IMultiTextSurvey } from '@/types';

const MultiTextComponent = ({
  indexStage,
  data,
  isEditable,
  suerveyId,
  handleEditSurvey,
}: IMultiTextSurvey) => {
  const { id, question, type, answer, unit, title } = data;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [value, setValue] = useState<string[]>(answer?.answers ?? ['']);

  const handleInput = (value: string) => {
    if (handleEditSurvey) {
      setValue([value]);
      handleEditSurvey(suerveyId ?? '', indexStage ?? 0, id, [value]);
    }
  };

  return (
    <Question>
      <Row>
        <LocalWrapper>
          <Icon as={TextFields} />
          <QuestionText>
            {title} - {question}
          </QuestionText>
        </LocalWrapper>
        {isEditable && <EditIcon as={Edit} onClick={() => setEditMode(true)} />}
      </Row>
      <Row>
        <LocalWrapper>
          <TypeTitle>Type</TypeTitle>
          <Typography>
            {[
              ESurveysQuestionTypes.MULTI_TEXT,
              ESurveysQuestionTypes.MULTI_TEXT_WITH_UNITS,
            ].includes(type as any) && 'Multi Text'}
          </Typography>
        </LocalWrapper>
      </Row>
      <Row>
        <LocalWrapper>
          {editMode ? (
            <InputQuestion
              value={value[0]}
              onChange={(e) => handleInput(e.target.value)}
              autoFocus={true}
              name="title"
              type="text"
              fullWidth={true}
              multiline
              label="Answer"
            />
          ) : (
            <div className="flex flex-col">
              <AnswerTitle>Answers</AnswerTitle>{' '}
              {value?.map((v, key) => (
                <AnswerValue key={'answer' + key}>
                  {v} {unit ?? ''}
                </AnswerValue>
              ))}
            </div>
          )}
        </LocalWrapper>
      </Row>
    </Question>
  );
};

export default MultiTextComponent;
