import { createSlice } from '@reduxjs/toolkit';
import { IProgressFeedbackInterface } from '@/types';

const initialState: IProgressFeedbackInterface = {
  loading: false,
  openedDrawer: false,
  showConfirmClose: false,
  simpleFeedback: false,
};

export const progressFeedbackSlice = createSlice({
  name: 'progressFeedback',
  initialState,
  reducers: {
    initFaq: (state, action) => {
      state.openedDrawer = true;
      state.simpleFeedback = true;
      state.message = action.payload.message;
    },
    initFeedback: (state, action) => {
      state.openedDrawer = true;
      state.message = action.payload.message;
      state.actionId = action.payload.actionId;
      state.simpleFeedback = false;
    },
    resetFeedback: (state) => {
      state.message = undefined;
      state.actionId = undefined;
      state.loading = false;
      state.openedDrawer = false;
      state.showConfirmClose = false;
      state.simpleFeedback = false;
      state.feedbackType = undefined;
      state.isVoiceNote = undefined;
      state.voiceNoteMessage = undefined;
    },
    initFeedbackOnMessage: (state, action) => {
      state.openedDrawer = true;
      state.message = action.payload.message;
      state.simpleFeedback = true;
    },
    closeDrawer: (state) => {
      state.openedDrawer = false;
    },
    setFeedbackType: (state, action) => {
      state.feedbackType = action.payload;
    },
    resetFeedbackType: (state) => {
      state.feedbackType = undefined;
    },
    setIsVoiceNote: (state, action) => {
      state.isVoiceNote = action.payload;
    },
    resetIsVoiceNote: (state) => {
      state.isVoiceNote = undefined;
    },
    setVoiceNoteMessage: (state, action) => {
      state.voiceNoteMessage = action.payload;
    },
    resetVoiceNoteMessage: (state) => {
      state.voiceNoteMessage = undefined;
    },
  },
});

export const {
  initFeedback,
  resetFeedback,
  initFeedbackOnMessage,
  initFaq,
  closeDrawer,
  setFeedbackType,
  resetFeedbackType,
  setIsVoiceNote,
  resetIsVoiceNote,
  setVoiceNoteMessage,
  resetVoiceNoteMessage,
} = progressFeedbackSlice.actions;

export default progressFeedbackSlice.reducer;
