import { Chip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import {
  CloseIconBlock,
  Container,
  ContentBlock,
  LineBlock,
  LineDiv,
  MessageBlock,
  NameBlock,
  NameText,
  ReplyIcon,
  ReplyIconBlock,
  StyledCloseIcon,
} from './styles';
import { IChatEditFileMessage } from '@/types';
import { useAppSelector } from '@/hooks';

const ChatEditFileMessage = ({
  newFile,
  fileRef,
  messageFileEdited,
  handleMessageFileEdited,
}: IChatEditFileMessage) => {
  const { coachData } = useAppSelector((state) => state.coachAuth);

  const handleEditFile = () => {
    handleClickFileUploader();
  };

  const handleClickFileUploader = () => {
    fileRef.current.click();
  };

  if (!coachData) {
    return null;
  }

  return (
    <Container>
      <ReplyIconBlock>
        <ReplyIcon />
      </ReplyIconBlock>
      <LineBlock>
        <LineDiv />
      </LineBlock>
      <ContentBlock>
        <NameBlock>
          <NameText>
            {messageFileEdited?.userNameFrom || coachData.firstName + ' ' + coachData.lastName}
          </NameText>
        </NameBlock>
        <MessageBlock>
          <Chip
            key={messageFileEdited?.id}
            label={newFile?.name ?? messageFileEdited?.file?.name}
            onClick={handleEditFile}
            icon={<EditIcon />}
            size="small"
          />
        </MessageBlock>
      </ContentBlock>
      <CloseIconBlock>
        <StyledCloseIcon onClick={() => handleMessageFileEdited(null)} />
      </CloseIconBlock>
    </Container>
  );
};

export default ChatEditFileMessage;
