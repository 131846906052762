import { useInvitation } from '@/hooks/auth/useInvitation';
import InvitationForm from '../InvitationForm';

const InvitationContainer = () => {
  const { userDataInitial, answerError, registerUser } = useInvitation();

  return (
    <InvitationForm user={userDataInitial} answerError={answerError} registerUser={registerUser} />
  );
};

export default InvitationContainer;
