import { TableBody, TableCaption, TableCell, TableRow } from '@/components/ui/table';
import { Check, Timer, X } from 'lucide-react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect } from 'react';
import { fetchSavvySignup } from '@/store/customers/customersSlice';
import { Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import {
  AllocatedCell,
  EmailFailed,
  EmailScheduled,
  EmailSent,
  EmailSkipped,
  NegativeCell,
  PositiveCell,
  StatusCell,
} from '@/components/Monitor/Cells';

const SavvySignupTable = () => {
  const dispatch = useAppDispatch();
  const { savvySignupUser } = useAppSelector((state) => state.customers);
  console.log(savvySignupUser);
  useEffect(() => {
    dispatch(fetchSavvySignup());
  }, []);

  if (!savvySignupUser?.length) {
    return <TableCaption className="text-center font-bold w-full">No recent user</TableCaption>;
  }

  return (
    <TableBody>
      {savvySignupUser?.map((user) => (
        <TableRow key={user.id}>
          <TableCell className="font-medium">
            <a
              href={`/coach-dashboard/client/${user.id}`}
            >{`${user.firstName} ${user.lastName}`}</a>
          </TableCell>
          <TableCell>{format((user.createdAt as Timestamp).toDate(), 'MM/dd/yyyy')}</TableCell>
          <TableCell>
            {user.monitorOnboarding?.confirmedEmailStatus ? <PositiveCell /> : <NegativeCell />}
          </TableCell>
          <TableCell>
            {user.monitorOnboarding?.getStartedEmailStatus === 'sent' && <EmailSent />}
            {user.monitorOnboarding?.getStartedEmailStatus === 'skipped' && <EmailSkipped />}
            {user.monitorOnboarding?.getStartedEmailStatus === 'scheduled' && <EmailScheduled />}
            {!user.monitorOnboarding && <EmailFailed />}
          </TableCell>
          <TableCell>
            <div className="flex items-center justify-start">
              <Check className="text-green-600" />
              <X className="text-red-500" />
            </div>
          </TableCell>
          <TableCell>
            <StatusCell attribute={user.id} />
          </TableCell>
          <TableCell>
            <StatusCell attribute={user.timezone} />
          </TableCell>
          <TableCell>
            <StatusCell attribute={user.appVersion} />
          </TableCell>
          <TableCell>
            <AllocatedCell
              attribute={user.monitorOnboarding?.memberShipEmailStatus ?? 'upcoming'}
            />
          </TableCell>
          <TableCell>
            {user.monitorOnboarding?.surveyCompleted ? <PositiveCell /> : <NegativeCell />}
          </TableCell>
          <TableCell>
            <AllocatedCell
              attribute={
                user.monitorOnboarding?.teamAllocated ?? user.coachAssignment?.team ?? 'failed'
              }
            />
          </TableCell>
          <TableCell>
            {user.timezone &&
            user.appVersion &&
            !user.hasActiveOnboardingSurvey &&
            user.coachAssignment?.team ? (
              <PositiveCell />
            ) : (
              <NegativeCell />
            )}
          </TableCell>
          <TableCell>
            <AllocatedCell attribute={user.monitorOnboarding?.introMessageStatus ?? 'upcoming'} />
          </TableCell>
          <TableCell>
            <AllocatedCell attribute={user.monitorOnboarding?.startedOnboarding ?? 'upcoming'} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default SavvySignupTable;
