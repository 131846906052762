import { useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import deepEquals from 'lodash/isEqual';
import { TCardComponent, TColumnComponent } from '@/types';

export const DragNDropColumns = <T extends { id: string }, K extends keyof T>(props: {
  items: T[];
  columnKey: K;
  disabled?: boolean;
  columns: (T[K] & string)[];
  onReorder: (cx: T[]) => void;
  item: TCardComponent<T, any>;
  column: TColumnComponent<any>;
}) => {
  const [internal, setInternal] = useState(props.items);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (deepEquals(props.items, internal)) return;
      setInternal(props.items);
    }, 50);
    return () => clearTimeout(timeout);
  }, [props.items, internal]);

  const update = useRef(props.onReorder);
  useEffect(() => {
    update.current = props.onReorder;
  }, [props.onReorder]);

  const onDragEnd = ({ destination: dest, source }: DropResult) => {
    if (!dest) return;
    setInternal((original) => {
      const next = handleReorder(original, props.columnKey, source, dest);
      update.current(next);
      return next;
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {props.columns.map((id) => (
        <Droppable
          key={id}
          droppableId={id}
          direction="vertical"
          isCombineEnabled={false}
          mode="standard"
          isDropDisabled={props.disabled ?? false}
        >
          {(droppable) => (
            <props.column
              ref={droppable.innerRef}
              {...droppable.droppableProps}
              disabled={props.disabled}
              column={id}
            >
              {internal
                .filter((content) => content[props.columnKey] === id)
                .map((content, i) => (
                  <Draggable
                    key={content.id}
                    draggableId={content.id}
                    index={i}
                    isDragDisabled={props.disabled}
                  >
                    {(p) => (
                      <props.item
                        key={content.id}
                        item={content}
                        ref={p.innerRef}
                        handle={p.dragHandleProps}
                        disabled={props.disabled}
                        {...p.draggableProps}
                      />
                    )}
                  </Draggable>
                ))}
              {droppable.placeholder}
            </props.column>
          )}
        </Droppable>
      ))}
    </DragDropContext>
  );
};

const handleReorder = <T extends { id: string }, K extends keyof T>(
  original: T[],
  column: K,
  source: DraggableLocation,
  dest: DraggableLocation,
) => {
  // Split all items into three groups
  // source: T[] Source column
  // dest: T[] destination column
  // rest: T[] all other items
  const split = original.reduce(
    (ElemA, ElemB) => {
      if (ElemB[column] === source.droppableId)
        return { ...ElemA, source: [...ElemA.source, ElemB] };
      if (ElemB[column] === dest.droppableId) return { ...ElemA, dest: [...ElemA.dest, ElemB] };
      return { ...ElemA, rest: [...ElemA.rest, ElemB] };
    },
    { source: [] as T[], dest: [] as T[], rest: [] as T[] },
  );

  // If items is dragged within a column just move the item
  if (source.droppableId === dest.droppableId) {
    return [...move(split.source, source.index, dest.index), ...split.dest, ...split.rest];
  }

  // Update the column key for the moved item
  const moveItem = { ...split.source[source.index], [column]: dest.droppableId };

  // Add the item to the dest column and move to the right index
  const nextDest = move([moveItem, ...split.dest], 0, dest.index);

  // Remove the item from the source column
  const nextSource = split.source.filter((x) => x.id !== moveItem.id);

  return [...split.rest, ...nextSource, ...nextDest];
};

const move = <T extends Record<string, any>>(array: T[], from: number, to: number) => {
  const elemToMove = [...array];
  const element = elemToMove[from];
  elemToMove.splice(from, 1);
  elemToMove.splice(to, 0, element);
  return elemToMove;
};
