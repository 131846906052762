import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { categoriesList } from '@/config';
import { useProgramActions } from '@/hooks/program/useProgramActions';
import { useProgramData } from '@/hooks/program/useProgramData';
import { useAdminActions } from '@/hooks/admin/useAdminActions';
import { buildReversStructure } from '@/utils/data/action';
import { isCategoryNotEmpty } from '@/utils/data/category';
import {
  ColumnContainer,
  ColumnContentBlock,
  ColumnLineBlock,
  Container,
  EmptyBlock,
  EmptyText,
  SubTypeNameBlock,
  SubTypeNameText,
  TypeNameBlock,
  TypeNameText,
} from '@/styles/programActions';
import { IAction, EActionsProgramOptions, IProgramAction, IChatProgramActionsList } from '@/types';
import noSelectionsIcon from '@/assets/icons/no_selections.svg';
import ChatProgramActionsListItem from '../ChatProgramActionsListItem';

// DEPRECATED OLD VERSION
const ChatProgramActionsList = ({ actionsProgramOption }: IChatProgramActionsList) => {
  const [isNotEmptyColumnsNew, setIsNotEmptyColumnsNew] = useState<boolean[]>([true, true, true]);
  const { actionsList } = useProgramActions();
  const { programActions } = useProgramData();
  const { categoryListNew } = useAdminActions();

  useEffect(() => {
    if (!programActions.length) {
      setIsNotEmptyColumnsNew([false, false, false]);
      return;
    }

    const coreHabitsNotEmpty = isCategoryNotEmpty('core', programActions, actionsProgramOption);
    const additionalHabitsNotEmpty = isCategoryNotEmpty(
      'additional',
      programActions,
      actionsProgramOption,
    );
    const obstaclesNotEmpty = isCategoryNotEmpty('obstacle', programActions, actionsProgramOption);

    setIsNotEmptyColumnsNew([coreHabitsNotEmpty, additionalHabitsNotEmpty, obstaclesNotEmpty]);
  }, [actionsList, programActions, actionsProgramOption]);

  return (
    <Container>
      <Grid container spacing={0}>
        {buildReversStructure(categoryListNew).map((category, indexColumn: number) => (
          <Grid item md={4} key={category.enum}>
            <ColumnContainer>
              <ColumnContentBlock>
                <TypeNameBlock>
                  <TypeNameText>{category.label}</TypeNameText>
                </TypeNameBlock>
                {!isNotEmptyColumnsNew[indexColumn] && (
                  <EmptyBlock>
                    <div>
                      <img src={noSelectionsIcon} alt="" />
                    </div>
                    <div>
                      <EmptyText>No selections...</EmptyText>
                    </div>
                  </EmptyBlock>
                )}
                {isNotEmptyColumnsNew[indexColumn] &&
                  category.habitTypes
                    .filter((habitType) => {
                      const actionsListCurrent = actionsList.filter(
                        (action: IAction) =>
                          action.categoryId === habitType.id && category.enum === action.habitType,
                      );
                      let isHaveProgramActions = false;
                      actionsListCurrent.forEach((action: IAction) => {
                        let index = -1;
                        if (actionsProgramOption) {
                          index = programActions.findIndex((programAction: IProgramAction) => {
                            if (actionsProgramOption === EActionsProgramOptions.ALL) {
                              return (
                                programAction.actionId === action.id &&
                                category.enum === programAction.habitType &&
                                habitType.id === action.categoryId &&
                                !programAction.isDeleted
                              );
                            }
                            return (
                              programAction.actionId === action.id &&
                              category.enum === programAction.habitType &&
                              habitType.id === action.categoryId &&
                              programAction.status === actionsProgramOption &&
                              !programAction.isDeleted
                            );
                          });
                        }

                        if (index !== -1) {
                          isHaveProgramActions = true;
                        }
                      });

                      return actionsListCurrent.length && isHaveProgramActions;
                    })
                    .map((habitType) => (
                      <div key={habitType.id}>
                        <SubTypeNameBlock>
                          <SubTypeNameText>{habitType.name}</SubTypeNameText>
                        </SubTypeNameBlock>
                        {actionsList
                          .filter((action: IAction) => {
                            let isActionInProgram = false;
                            if (actionsProgramOption) {
                              isActionInProgram = !!programActions.find(
                                (programAction: IProgramAction) => {
                                  if (actionsProgramOption === EActionsProgramOptions.ALL) {
                                    return (
                                      programAction.actionId === action.id &&
                                      category.enum === programAction.habitType &&
                                      habitType.id === action.categoryId &&
                                      !programAction.isDeleted
                                    );
                                  }
                                  return (
                                    programAction.actionId === action.id &&
                                    category.enum === programAction.habitType &&
                                    habitType.id === action.categoryId &&
                                    programAction.status === actionsProgramOption &&
                                    !programAction.isDeleted
                                  );
                                },
                              );
                            }
                            return isActionInProgram && !action.isDeleted;
                          })
                          .sort((a, b) => a.sortingIndex - b.sortingIndex)
                          .map((action: IAction) => (
                            <ChatProgramActionsListItem
                              key={action.id}
                              action={action}
                              programAction={
                                programActions.find(
                                  (item: IProgramAction) => item.actionId === action.id,
                                ) ?? null
                              }
                            />
                          ))}
                      </div>
                    ))}
              </ColumnContentBlock>
              {indexColumn !== categoriesList.length - 1 && <ColumnLineBlock />}
            </ColumnContainer>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ChatProgramActionsList;
