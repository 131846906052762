import { EPaymentListType, ISelectOptionsItem } from '@/types';
import { ClientUser } from '@fitmate-coach/fitmate-types';

export const setUserStatus = (userData: ClientUser | null) => {
  if (
    userData?.billingData?.status &&
    userData?.billingData?.status === EPaymentListType.ACTIVE &&
    userData?.billingData?.isReferralLinkEnabled
  ) {
    return EPaymentListType.ACTIVE_REF;
  }
  return (
    userData?.billingData?.status ??
    (userData?.billingData?.isPaid ? EPaymentListType.ACTIVE : EPaymentListType.UNPAID)
  );
};

export const getSelectedList = (values: object): ISelectOptionsItem[] =>
  Object.values(values).map((val, index) => {
    return { title: val, value: index };
  });
