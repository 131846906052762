import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { db } from '@/utils/firebase/firebaseInit';
import { getDocs, query, where } from '@firebase/firestore';
import { collection } from 'firebase/firestore';
import { IBgsInterface } from '@/types';

const initialState = {
  keys: [],
  // ownerId: '4IX9dX8l5lS6Zz8JUNVgzl8L4Wx2', //BGS partner id
  // ownerId: 'UoBgD7vC3cRFVd51aJWpRAQqOk12', //Fitmate partner id
  ownerId: process.env.REACT_APP_PARTNER_ID,
} as IBgsInterface;

export const loadApiKeys = createAsyncThunk('bgs/loadApiKeys', async (args, APIThunk) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { ownerId } = APIThunk.getState().bgs;
  const q = query(collection(db, 'partners'), where('owner', '==', ownerId));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
});

export const bgsSlice = createSlice({
  name: 'bgs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadApiKeys.fulfilled, (state, action) => {
      state.keys = action.payload.map((x: any) => x.id);
    });
  },
});

export default bgsSlice.reducer;
