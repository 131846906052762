import { ActionText, ActionTitle, HandoutItem, Title } from './styles';
import { IChatShareHandoutsItem } from '@/types';

const ChatShareHandoutsItem = ({ handout, handleChange, isSelected }: IChatShareHandoutsItem) => {
  return (
    <HandoutItem isSelected={isSelected} onClick={() => handleChange(handout.id)}>
      <div>
        <Title>{handout.title}</Title>
      </div>
      <div>
        <ActionText>
          Linked action: <ActionTitle>{handout.actionName}</ActionTitle>
        </ActionText>
      </div>
    </HandoutItem>
  );
};

export default ChatShareHandoutsItem;
