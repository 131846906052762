import { useState, useEffect } from 'react';
import { v4 } from 'uuid';
import { db } from '@/utils/firebase/firebaseInit';
import { prepareField } from '@/utils/format/objects';
import { inviteEmailNotificationCoach } from '@/utils/tools/emails';
import { getByRole } from '@/api/firestores';
import { addDoc, collection, Timestamp, serverTimestamp, doc, updateDoc } from 'firebase/firestore';
import { CoachUser, EUserRoles } from '@fitmate-coach/fitmate-types';

export const useAdminCoaches = () => {
  const [coachesList, setCoachesList] = useState<CoachUser[]>([]);
  useEffect(() => {
    getByRole(EUserRoles.COACH).then((querySnapshot) => {
      setCoachesList(
        querySnapshot.docs.map((doc) => {
          const docData = doc.data() as CoachUser;
          const {
            firstName,
            lastName,
            role,
            phone,
            email,
            timezone,
            shiftData,
            avatarUrl,
            isInviteAccepted,
            isActive,
            confirmId,
            createdAt,
          } = docData;

          return {
            id: doc.id,
            firstName,
            lastName,
            phone,
            role,
            email,
            timezone: timezone ?? '',
            timeStarting: shiftData?.timeStarting ? shiftData?.timeStarting.seconds * 1000 : 0,
            timeFinishing: shiftData?.timeFinishing ? shiftData?.timeFinishing.seconds * 1000 : 0,
            avatarUrl,
            isInviteAccepted,
            isActive,
            confirmId,
            createdAt,
          };
        }),
      );
    });
  }, []);

  const createCoach = async (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    timezone: string,
    timeStarting: number,
    timeFinishing: number,
  ) => {
    const confirmId = v4();

    const timeStartingValue =
      timeStarting !== 0 ? { timeStarting: Timestamp.fromMillis(timeStarting) } : {};

    const timeFinishingValue =
      timeFinishing !== 0 ? { timeFinishing: Timestamp.fromMillis(timeFinishing) } : {};

    const details: CoachUser = {
      firstName,
      lastName,
      email,
      phone,
      avatarUrl: '',
      role: EUserRoles.COACH,
      ...prepareField({ timezone }),
      shiftData: {
        ...timeStartingValue,
        ...timeFinishingValue,
      },
      isInviteAccepted: false,
      isActive: true,
      confirmId,
      createdAt: serverTimestamp(),
      userAssignment: null,
    };

    addDoc(collection(db, 'users'), details).then((record) => {
      inviteEmailNotificationCoach(firstName, lastName, confirmId, email, record.id, phone);
      afterUpdate(record.id, {
        id: record.id,
        firstName,
        lastName,
        email,
        phone,
        avatarUrl: '',
        role: EUserRoles.COACH,
        timeStarting,
        timeFinishing,
        timezone: '',
        isInviteAccepted: false,
        isActive: true,
        confirmId,
        createdAt: serverTimestamp(),
      } as CoachUser);
    });
  };

  const afterUpdate = (userId: string, details: CoachUser) => {
    const otherCoaches = coachesList.filter((coach) => coach.id !== userId);
    const currentCoach = coachesList.find((coach) => coach.id === userId);

    setCoachesList([...otherCoaches, { ...currentCoach, ...details }]);
  };
  const editCoach = async (
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    timezone: string,
    timeStarting: number,
    timeFinishing: number,
  ) => {
    const timeStartingValue =
      timeStarting !== 0 ? { timeStarting: Timestamp.fromMillis(timeStarting) } : {};

    const timeFinishingValue =
      timeFinishing !== 0 ? { timeFinishing: Timestamp.fromMillis(timeFinishing) } : {};

    const details = {
      firstName,
      lastName,
      role: EUserRoles.COACH,
      email,
      phone,
      timezone,
      shiftData: {
        ...timeStartingValue,
        ...timeFinishingValue,
      },
    };

    const docRef = doc(db, 'users', userId);
    await updateDoc(docRef, details);
    afterUpdate(userId, {
      id: userId,
      ...details,
      timeStarting,
      timeFinishing,
    } as CoachUser);
  };

  const editCoachActivity = async (userId: string, isActiveNew: boolean) => {
    const docRef = doc(db, 'users', userId);
    await updateDoc(docRef, {
      isActive: isActiveNew,
    });

    afterUpdate(userId, {
      isActive: isActiveNew,
    } as CoachUser);
  };

  return {
    coachesList,
    createCoach,
    editCoach,
    editCoachActivity,
  };
};
