import React from 'react';
import { Select, SelectChangeEvent, Typography } from '@mui/material';
import { InputStyled, LinerScale } from './styles';
import { ISliderScale } from '@/types';

const SliderScale = ({ id, slider, isDisabled, sliderHandler }: ISliderScale) => {
  const handleValueQuestionFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;

    sliderHandler(id, slider.max, value, slider.maxDescription);
  };

  const handleValueQuestionTo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;

    sliderHandler(id, slider.max, slider.minDescription, value);
  };

  const handleLinerScale = (e: SelectChangeEvent<unknown>) => {
    const value: number = e.target.value as number;

    sliderHandler(id, value, slider.minDescription, slider.maxDescription);
  };

  return (
    <>
      <LinerScale>
        <Typography>1</Typography>
        <Typography>to</Typography>
        <Select
          // variant="standard"
          defaultValue={slider?.max}
          onChange={handleLinerScale}
          native
          disabled={isDisabled}
        >
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
        </Select>
      </LinerScale>
      <div>
        <InputStyled
          name="from"
          type="text"
          value={slider?.minDescription}
          label="1 - Name this label (optional)"
          onChange={(e) => handleValueQuestionFrom(e)}
          fullWidth
          disabled={isDisabled}
        />
        <InputStyled
          name="to"
          type="text"
          value={slider?.maxDescription}
          label={`${slider?.max} - Name this label (optional)`}
          onChange={(e) => handleValueQuestionTo(e)}
          fullWidth
          disabled={isDisabled}
        />
      </div>
    </>
  );
};

export default SliderScale;
