import { useFormContext } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import InputFormField from '@/common/core/FormElements/InputFormField';
import SelectFormField from '@/common/core/FormElements/SelectFormField';
import { SexList } from '@/utils/data/question';
import { convertKgToLb, convertLbToKg } from '@/utils/format/measures';
import { IKeyInfo } from '@/types';
import Wrapper from '../Wrapper';
import { StyledInputAdornment } from '../styles';

const KeyInfo = ({ isLbs, setIsLbs }: IKeyInfo) => {
  const { control, getValues, setValue } = useFormContext();

  const handleIsLbs = () => {
    const oldTypeWeight = getValues('weight');
    if (oldTypeWeight) {
      const newTypeWeight = isLbs ? convertLbToKg(oldTypeWeight) : convertKgToLb(oldTypeWeight);
      setValue('weight', newTypeWeight);
    }
    setIsLbs(!isLbs);
  };

  return (
    <Wrapper blockTitle="Key info" isTitleHasShortMargin>
      <InputFormField
        name="firstName"
        label="First Name*"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Fill the field',
          },
        }}
      />

      <InputFormField
        name="lastName"
        label="Last Name*"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Fill the field',
          },
        }}
      />

      <SelectFormField name="gender" label="Sex" control={control} optionList={SexList} />

      <InputFormField
        name="weight"
        label="Starting weight"
        control={control}
        inputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledInputAdornment onClick={handleIsLbs}>
                {isLbs ? 'lbs' : 'kg'}
              </StyledInputAdornment>
            </InputAdornment>
          ),
        }}
      />
    </Wrapper>
  );
};

export default KeyInfo;
