import { useState, useEffect } from 'react';
import { useAdminHandouts } from '@/hooks/admin/useAdminHandouts';
import { useChatCreateMessage } from '@/hooks/chat/useChatCreateMessage';
import {
  ButtonBlock,
  ButtonSubmit,
  CancelSubmit,
  Container,
  ContentBlock,
  TitleBlock,
  TitleText,
} from './styles';
import { IHandout, IMessageReference, EMessageReferenceType, IChatShareHandouts } from '@/types';
import ChatShareHandoutsItem from '../ChatShareHandoutsItem';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getFocusAreas } from '@/store/program/focusAreaSlice';

const ChatShareHandouts = ({ handleOpenHandoutBlock }: IChatShareHandouts) => {
  const dispatch = useAppDispatch();
  const { focusAreas } = useAppSelector((state) => state.focusArea);

  const [handouts, setHandouts] = useState<IHandout[]>([]);
  const [references, setReferences] = useState<IMessageReference[]>([]);
  const [activeHandoutId, setActiveHandoutId] = useState<string>('');
  const createMessage = useChatCreateMessage();
  const { handoutsList } = useAdminHandouts();

  useEffect(() => {
    dispatch(getFocusAreas());
  }, []);

  useEffect(() => {
    if (handoutsList.length && focusAreas?.length) {
      const handoutIds = focusAreas.map((focusArea) => focusArea.handoutId);
      const selectedHandouts = handoutsList.filter((handout) => handoutIds.includes(handout.id));
      setHandouts(
        selectedHandouts.map((handout) => {
          return {
            ...handout,
            actionName:
              focusAreas.find((focusArea) => focusArea.handoutId === handout.id)?.name ?? '',
          };
        }),
      );
    }
  }, [handoutsList, focusAreas]);

  const handleChange = (id: string) => {
    setActiveHandoutId(id);
    setReferences([
      {
        type: EMessageReferenceType.HANDOUT,
        referenceId: id,
      },
    ]);
  };

  const handleSubmit = async () => {
    await createMessage('', [], references);
    handleOpenHandoutBlock();
  };

  return (
    <Container>
      <TitleBlock>
        <TitleText>Share Handout</TitleText>
      </TitleBlock>
      <ContentBlock>
        {handouts.map((handout) => {
          return (
            <ChatShareHandoutsItem
              key={handout.id}
              handout={handout}
              handleChange={handleChange}
              isSelected={handout.id === activeHandoutId}
            />
          );
        })}
      </ContentBlock>
      <ButtonBlock>
        <CancelSubmit onClick={handleOpenHandoutBlock}>Cancel</CancelSubmit>
        <ButtonSubmit onClick={handleSubmit} disabled={activeHandoutId === ''}>
          Share handout
        </ButtonSubmit>
      </ButtonBlock>
    </Container>
  );
};

export default ChatShareHandouts;
