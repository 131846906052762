import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, orderBy, query } from '@firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';

export interface IOnboardingSurveyState {
  loading: boolean;
  currentCustomerId?: string;
  surveys?: any;
}

const initialState: IOnboardingSurveyState = {
  loading: true,
  currentCustomerId: undefined,
};

export const fetchOnboardingSurveyByCustomer = createAsyncThunk(
  'onboardingSurvey/fetchOnboardingSurveyByCustomer',
  async (args: any) => {
    // @TODO: when we will have multiple surveys we can get the surveyId from the args
    const { userId } = args;

    const querySnapshot = await getDocs(
      query(collection(db, 'users', userId, 'onboarding_surveys'), orderBy('createdAt', 'desc')),
    );

    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  },
);

export const onboardingSurveySlice = createSlice({
  name: 'onboardingSurvey',
  initialState,
  reducers: {
    saveCurrentCustomerId: (state, action) => {
      state.currentCustomerId = action.payload;
    },
    resetCurrentCustomerId: (state) => {
      state.currentCustomerId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingSurveyByCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOnboardingSurveyByCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.surveys = action.payload;
      })
      .addCase(fetchOnboardingSurveyByCustomer.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default onboardingSurveySlice.reducer;
