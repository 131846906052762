import { StyledTypography } from './styles';
import { IInputError } from '@/types';

const InputError = ({ text = '', opacityColor = false, className = '' }: IInputError) => {
  return (
    <>
      {text !== '' && (
        <StyledTypography
          hasOpacityError={opacityColor}
          // className={classnames(className, {
          //   [classes.opacityErrorInputText]: opacityColor,
          // {/*}*/}
          // )}
        >
          {text}
        </StyledTypography>
      )}
    </>
  );
};
export default InputError;
