import { HeaderIcon, HeaderTitle, Wrapper } from './styles';
import { IHeader } from '@/types';

const Header = ({ handleOpen }: IHeader) => {
  return (
    <Wrapper>
      <HeaderTitle>We found similar messages</HeaderTitle>
      <HeaderIcon onClick={handleOpen} />
    </Wrapper>
  );
};

export default Header;
