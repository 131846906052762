import { Box, List, ListItem, Popover, Typography } from '@mui/material';
import AgreedActionsTakenForm from './AgreedActionsTakenForm';
import Button from '@/common/core/Button';
import { useState, MouseEvent } from 'react';
import { styled } from '@mui/system';

const StyledPopover = styled(Popover)({
  pointerEvents: 'none',
  '& > .MuiPopover-paper': {
    padding: 4,
    borderRadius: 12,
    pointerEvents: 'auto',
  },
});

function TooltipText({
  open,
  anchorEl,
  handlePopoverClose,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
}) {
  return (
    <StyledPopover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Box width={320} padding={2} paddingRight={4} gap={2} display="flex" flexDirection="column">
        <Typography variant="h6">How this works</Typography>
        <Typography variant="body2">
          These notes will act as a refresher for next time you have a call. They should capture any
          actions that you’ve agreed with the client and <b>have completed</b>.<br />
          <br />
          Examples:
          <br />
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            fontSize: 14,
            '& li': {
              display: 'list-item',
              marginLeft: 2,
              padding: 0,
            },
          }}
        >
          <ListItem>
            Agreed to focus on meal prepping so added some recipes to their planner.
          </ListItem>
          <ListItem>
            Agreed on daily evening reminders to update the tracker - I’ve added these to the task
            list.
          </ListItem>
        </List>
        <Typography variant="body2">
          Please <b>do not</b> use this area for outstanding actions that you need other coaches to
          action.
        </Typography>
      </Box>
    </StyledPopover>
  );
}

function AgreedActionsTaken() {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box marginTop={4}>
      <TooltipText open={open} anchorEl={anchorEl} handlePopoverClose={handlePopoverClose} />
      <Typography variant="h5" display="flex">
        Agreed actions taken from last call
        <span
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          // onClick={handlePopoverOpen}
          className="icon-[mdi--info-outline] w-6 h-6 text-dark/40"
        />
      </Typography>
      <Typography variant="body2">
        These notes will act as a refresher of the actions you’ve taken for next time you have a
        call.
      </Typography>

      <Box marginTop={3}>
        <AgreedActionsTakenForm isOpen={editMode} openEditMode={setEditMode} />
        {!editMode && (
          <Button variant="outlined" onClick={() => setEditMode(true)}>
            Edit
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default AgreedActionsTaken;
