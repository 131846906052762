import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AppleIcon from '@mui/icons-material/Apple';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import FlagIcon from '@mui/icons-material/Flag';
import LanguageIcon from '@mui/icons-material/Language';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Container,
  FieldNameBlock,
  FieldNameText,
  FieldValueBlock,
  FieldValueText,
  ListBlock,
  RowBlock,
  StyledIcon,
  TitleBlock,
  TitleText,
} from './styles';
import { EIconType, IInformationBlock } from '@/types';
import ButtonToCheckStripe from '../ButtonToCheckStripe';

const InformationBlock = ({ title, dataList }: IInformationBlock) => {
  const getIcon = (iconType?: EIconType) => {
    switch (iconType) {
      case EIconType.payment:
        return <StyledIcon as={MonetizationOnIcon} />;
      case EIconType.email:
        return <StyledIcon as={MailOutlineIcon} />;
      case EIconType.apple:
        return <StyledIcon as={AppleIcon} />;
      case EIconType.identifier:
        return <StyledIcon as={VpnKeyIcon} />;
      case EIconType.appVersion:
        return <StyledIcon as={PhoneIphoneIcon} />;
      case EIconType.featureFlag:
        return <StyledIcon as={FlagIcon} />;
      case EIconType.webLink:
        return <StyledIcon as={LanguageIcon} />;
      case EIconType.warning:
        return <StyledIcon as={WarningIcon} />;
      default:
        return null;
    }
  };

  return (
    <>
      <TitleBlock>
        <TitleText>{title ?? ''}</TitleText>
      </TitleBlock>
      <ListBlock>
        <Container>
          {dataList.map((data, index) => (
            <RowBlock isColored={index % 2 === 0} key={data.title}>
              <FieldNameBlock>
                <FieldNameText>
                  {getIcon(data?.icon)} {data.title}
                </FieldNameText>
              </FieldNameBlock>

              <FieldValueBlock>
                {data.webLink && data.value && (
                  <a href={data.webLink} target="_blank" rel="noreferrer" className="flex">
                    {getIcon(EIconType.webLink)}
                    <FieldValueText className="underline">{data.value ?? '-'}</FieldValueText>
                  </a>
                )}
                {!data.webLink && <FieldValueText>{data.value ?? '-'}</FieldValueText>}
                {data.showButton && <ButtonToCheckStripe />}
              </FieldValueBlock>
            </RowBlock>
          ))}
        </Container>
      </ListBlock>
    </>
  );
};

export default InformationBlock;
