import {
  LogoutText,
  Wrapper,
  WrapperIntranetIcon,
  WrapperIntranetLink,
  WrapperLogoutIcon,
} from './styles';
import LogoutIcon from '@/assets/icons/logout.svg';
import { useAuthLogout } from '@/hooks/auth/useAuthLogout';
import {
  releaseCoachWatchingCustomer,
  updateShiftStatusByCoachId,
} from '@/store/customers/watcherSlice';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { format } from 'date-fns';

export const LogoutCoach = () => {
  const dispatch = useAppDispatch();
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const logout = useAuthLogout();

  const handleLogout = () => {
    if (coachData) {
      dispatch(releaseCoachWatchingCustomer({ coachId: coachData.id }));
      dispatch(updateShiftStatusByCoachId({ coachId: coachData.id, shiftStarted: false }));
      logout();
    }
  };

  return (
    <Wrapper onClick={handleLogout}>
      <WrapperLogoutIcon src={LogoutIcon} alt="" />
      <LogoutText>Logout</LogoutText>
    </Wrapper>
  );
};

export const IntranetCoach = () => {
  const { version } = useAppSelector((state) => state.version);
  return (
    <Wrapper>
      <WrapperIntranetLink
        href="https://sites.google.com/fitmatecoach.com/fitmatecoach/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <WrapperIntranetIcon /> Intranet
      </WrapperIntranetLink>
      {version && typeof version === 'string' && (
        <p className="text-xs">{`${format(new Date(version), 'dd/MM/yyyy')}`}</p>
      )}
    </Wrapper>
  );
};

export default LogoutCoach;
