import { useState } from 'react';
import { Tab } from '@mui/material';
import { useChatSurveys } from '@/hooks/chat/useChatSurveys';
import { Container, TabsWrapper } from './styles';
import { EChatSurveyTabs, EStatus, ICurrentUser } from '@/types';
import SurveyHistory from './ChatSurveysHistory';
import ChatSurveysActive from './ChatSurveysActive';
import ChatSurveysTemplates from './ChatSurveysTemplates';

const ChatSurveys = ({ currentUserId }: ICurrentUser) => {
  const { surveysTemplateList, surveysList, onboardingSurveys } = useChatSurveys(currentUserId);
  const [activeTab, setActiveTab] = useState<string>(EChatSurveyTabs.HISTORY);

  const handleTabChange = (event: any, value: string) => {
    setActiveTab(value);
  };

  return (
    <Container>
      <TabsWrapper
        value={activeTab}
        onChange={handleTabChange}
        aria-label="Switch New survey and History of surveys"
      >
        {/*<Tab label="New survey" value={EChatSurveyTabs.NEWSURVEY} />*/}
        <Tab label="History of surveys" value={EChatSurveyTabs.HISTORY} />
      </TabsWrapper>
      {/*@deprecated*/}
      {activeTab === EChatSurveyTabs.NEWSURVEY && (
        <>
          <ChatSurveysTemplates userId={currentUserId} surveysTemplateList={surveysTemplateList} />
          <ChatSurveysActive
            surveysList={surveysList.filter(
              (survey) =>
                survey.status === EStatus.ASSIGNED ||
                survey.status === EStatus.IN_PROGRESS ||
                survey.status === EStatus.COMPLETED,
            )}
          />
        </>
      )}
      {activeTab === EChatSurveyTabs.HISTORY && (
        <SurveyHistory
          surveysList={surveysList.filter(
            (survey) => survey.status === EStatus.APPROVED || survey.status === EStatus.CANCELED,
          )}
          onboardingSurveys={onboardingSurveys}
        />
      )}
    </Container>
  );
};

export default ChatSurveys;
