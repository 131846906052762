import { useMemo, useState } from 'react';
import { Context } from './Context';
import { IChatUser, IChildren, IUserThreadData, IUserThreadDataComplete } from '@/types';

const ContextProvider = ({ children }: IChildren) => {
  const [userAssignment, setUserAssignment] = useState<IUserThreadData[] | []>([]);
  const [savedUsers, setSavedUsers] = useState<IUserThreadData[] | []>([]);
  const [newUsers, setNewsUsers] = useState<IChatUser[] | []>([]);
  const [currentUser, setCurrentUser] = useState<IUserThreadDataComplete | null>(null);
  const [isTimeStarted, setIsTimeStarted] = useState<boolean>(false);
  const [openedMealDrawer, setOpenedMealDrawer] = useState(false);
  const [isClientInfoOpen, setIsClientInfoOpen] = useState(false);
  const [openedMyPlanDrawer, setOpenedMyPlanDrawer] = useState(false);

  const defaultContext = useMemo(
    () => ({
      currentUser, // Well, now we have the currentUser globally, so we need to review the transferring currentUser prop from component to component. To get rid of props drilling
      setCurrentUser,
      userAssignment,
      setUserAssignment,
      savedUsers,
      setSavedUsers,
      newUsers,
      setNewsUsers,
      isTimeStarted,
      setIsTimeStarted,
      openedMealDrawer,
      setOpenedMealDrawer,
      openedMyPlanDrawer,
      setOpenedMyPlanDrawer,
      isClientInfoOpen,
      setIsClientInfoOpen,
    }),
    [
      userAssignment,
      savedUsers,
      newUsers,
      currentUser,
      isTimeStarted,
      openedMealDrawer,
      openedMyPlanDrawer,
      isClientInfoOpen,
    ],
  );

  return <Context.Provider value={defaultContext}>{children}</Context.Provider>;
};

export default ContextProvider;
