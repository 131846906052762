import { useState, useEffect } from 'react';
import { Box, FormControl, InputAdornment, InputLabel } from '@mui/material';
import { timezonesUSList } from '@/config';
import Modal from '@/common/core/Modal';
import Button from '@/common/core/Button';
import SelectOptionsWrapper from '@/common/core/SelectOptionsWrapper';
import { useAdminEditUser } from '@/hooks/admin/useAdminEditUser';
import {
  dateConvertFromDatePicker,
  dateConvertToDatePicker,
  dateOfBirthDefault,
} from '@/utils/format/datetime';
import {
  convertCmToInchesAdvanced,
  convertInchesToCmAdvanced,
  convertLbToKgAdvanced,
  convertKgToLbAdvanced,
  convertCmToInches,
  convertKgToLb,
} from '@/utils/format/measures';
import { SexList } from '@/utils/data/question';
import {
  ButtonElementsBlock,
  ColumnBlock,
  Container,
  DatePickerBlock,
  InputBlock,
  InputElementsBlock,
  SelectBlock,
  StyledDatePicker,
  StyledInput,
  StyledInputAdornment,
  StyledSelect,
} from './styles';
import { IClientsEditModal } from '@/types';

const ClientsEditModal = ({
  userIdCurrent,
  usersList,
  isOpenEditModal,
  toggleOpenEditModal,
}: IClientsEditModal) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<string>(dateOfBirthDefault());
  const [familyStatus, setFamilyStatus] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [dietaryPreferences, setDietaryPreferences] = useState<string>('');
  const [occupation, setOccupation] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');
  const [height, setHeight] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [otherMemorableInfo, setOtherMemorableInfo] = useState<string>('');
  const [healthGoals, setHealthGoals] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [heightError, setHeightError] = useState<string>('');
  const [weightError, setWeightError] = useState<string>('');
  const [isInches, setIsInches] = useState<boolean>(true);
  const [isLbs, setIsLbs] = useState<boolean>(true);
  const { editUser } = useAdminEditUser();

  useEffect(() => {
    if (isOpenEditModal) {
      setIsInches(true);
      setIsLbs(true);
      const userData = usersList.find((item: any) => item.id === userIdCurrent);
      setFirstName(userData?.firstName ?? '');
      setLastName(userData?.lastName ?? '');
      setGender(userData?.gender ?? '');
      setTimezone(userData?.timezone ?? '');
      setDateOfBirth(
        typeof userData?.dateOfBirth === 'number' && userData?.dateOfBirth !== 0
          ? dateConvertToDatePicker(userData?.dateOfBirth)
          : dateOfBirthDefault(),
      );
      setFamilyStatus(userData?.familyStatus ?? '');
      setLocation(userData?.location ?? '');
      setDietaryPreferences(userData?.dietaryPreferences ?? '');
      setOccupation(userData?.occupation ?? '');
      setHeight(
        typeof userData?.height === 'number' && userData?.height !== 0
          ? userData?.height.toString()
          : '',
      );
      setWeight(
        typeof userData?.weight === 'number' && userData?.weight !== 0
          ? userData?.weight.toString()
          : '',
      );
      setOtherMemorableInfo(userData?.otherMemorableInfo ?? '');
      setHealthGoals(userData?.healthGoals ?? '');
      setFirstNameError('');
      setLastNameError('');
      setHeightError('');
      setWeightError('');
    }
  }, [isOpenEditModal, usersList, userIdCurrent]);

  const handleIsInches = () => {
    setHeight((value) =>
      isInches ? convertInchesToCmAdvanced(value) : convertCmToInchesAdvanced(value),
    );
    setIsInches(!isInches);
  };

  const handleIsLbs = () => {
    setWeight((value) => (isLbs ? convertLbToKgAdvanced(value) : convertKgToLbAdvanced(value)));
    setIsLbs(!isLbs);
  };

  const handleFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleDateOfBirth = (e: any) => {
    setDateOfBirth(e.target.value);
  };

  const handleDietaryPreferences = (e: any) => {
    setDietaryPreferences(e.target.value);
  };

  const handleOccupation = (e: any) => {
    setOccupation(e.target.value);
  };

  const handleFamilyStatus = (e: any) => {
    setFamilyStatus(e.target.value);
  };

  const handleHeight = (e: any) => {
    setHeight(e.target.value);
  };

  const handleOtherMemorableInfo = (e: any) => {
    setOtherMemorableInfo(e.target.value);
  };

  const handleGender = (e: any) => {
    setGender(e.target.value);
  };

  const handleLocation = (e: any) => {
    setLocation(e.target.value);
  };

  const handleWeight = (e: any) => {
    setWeight(e.target.value);
  };

  const handleHealthGoals = (e: any) => {
    setHealthGoals(e.target.value);
  };

  const handleSubmit = () => {
    if (!firstName || !lastName) {
      setFirstNameError(!firstName ? 'Fill the field' : '');
      setLastNameError(!lastName ? 'Fill the field' : '');
    } else {
      const dateOfBirthValue = dateConvertFromDatePicker(dateOfBirth) ?? 0;
      const heightValue =
        typeof Number(height) === 'number'
          ? isInches
            ? Number(height)
            : convertCmToInches(Number(height))
          : 0;
      const weightValue =
        typeof Number(weight) === 'number'
          ? isLbs
            ? Number(weight)
            : convertKgToLb(Number(weight))
          : 0;

      editUser(
        userIdCurrent,
        firstName,
        lastName,
        gender,
        dateOfBirthValue,
        familyStatus,
        location,
        dietaryPreferences,
        occupation,
        timezone,
        heightValue,
        weightValue,
        otherMemorableInfo,
        healthGoals,
      ).then(() => {
        toggleOpenEditModal();
      });
    }
  };

  return (
    <Modal
      widthMax={1096}
      title={`Info of ${firstName} ${lastName}`}
      open={isOpenEditModal}
      handleClose={toggleOpenEditModal}
    >
      <Container>
        <InputElementsBlock>
          <ColumnBlock>
            <StyledInput
              onChange={handleFirstName}
              value={firstName}
              label="First Name"
              inputProps={{
                'data-testid': 'input-first-name',
              }}
              helperText={firstNameError}
              type="text"
              margin="none"
              required
              fullWidth
            />
            <DatePickerBlock>
              <StyledDatePicker
                label="Date of Birth"
                value={dateOfBirth}
                onChange={handleDateOfBirth}
                margin="none"
                fullWidth
              />
            </DatePickerBlock>
            <InputBlock>
              <StyledInput
                onChange={handleDietaryPreferences}
                value={dietaryPreferences}
                label="Dietary preferences"
                inputProps={{
                  'data-testid': 'input-dietary-preferences',
                }}
                helperText=""
                type="text"
                margin="none"
                fullWidth
              />
            </InputBlock>
            <InputBlock>
              <StyledInput
                onChange={handleOccupation}
                value={occupation}
                label="Occupation"
                inputProps={{
                  'data-testid': 'input-occupation',
                }}
                helperText=""
                type="text"
                margin="none"
                fullWidth
              />
            </InputBlock>
            <InputBlock>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Timezone</InputLabel>
                <StyledSelect
                  // variant="standard"
                  label="Timezone"
                  inputProps={{
                    'data-testid': 'select-timezone',
                  }}
                  native
                  onChange={(e: any) => setTimezone(e.target.value)}
                  value={timezone}
                >
                  <option aria-label="None" value="" key={0}></option>
                  {timezonesUSList.map(({ value, label }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                </StyledSelect>
              </FormControl>
            </InputBlock>
          </ColumnBlock>
          <ColumnBlock>
            <InputBlock>
              <StyledInput
                onChange={handleLastName}
                value={lastName}
                label="Last Name"
                inputProps={{
                  'data-testid': 'input-last-name',
                }}
                helperText={lastNameError}
                type="text"
                margin="none"
                fullWidth
                required
              />
            </InputBlock>
            <InputBlock>
              <StyledInput
                onChange={handleFamilyStatus}
                value={familyStatus}
                label="Family status"
                inputProps={{
                  'data-testid': 'input-family-status',
                }}
                helperText=""
                type="text"
                margin="none"
                fullWidth
              />
            </InputBlock>
            <InputBlock>
              <StyledInput
                onChange={handleHeight}
                value={height}
                label="Height"
                helperText={heightError}
                type="text"
                margin="none"
                fullWidth
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledInputAdornment onClick={handleIsInches}>
                        {isInches ? 'in' : 'cm'}
                      </StyledInputAdornment>
                    </InputAdornment>
                  ),
                  'data-testid': 'input-height',
                }}
              />
            </InputBlock>
            <InputBlock>
              <StyledInput
                onChange={handleOtherMemorableInfo}
                value={otherMemorableInfo}
                label="Other memorable info"
                inputProps={{
                  'data-testid': 'input-other-memorable-info',
                }}
                helperText=""
                type="text"
                margin="none"
                fullWidth
                multiline
                rows={4}
              />
            </InputBlock>
          </ColumnBlock>
          <ColumnBlock>
            <SelectBlock>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Sex</InputLabel>
                <StyledSelect
                  // variant="standard"
                  native
                  autoWidth
                  value={gender}
                  onChange={handleGender}
                  label="Sex"
                  inputProps={{
                    'data-testid': 'select-gender',
                  }}
                >
                  <SelectOptionsWrapper hasEmpty items={SexList} />
                </StyledSelect>
              </FormControl>
            </SelectBlock>
            <InputBlock>
              <StyledInput
                onChange={handleLocation}
                value={location}
                label="Location"
                inputProps={{
                  'data-testid': 'input-location',
                }}
                helperText=""
                type="text"
                margin="none"
                fullWidth
              />
            </InputBlock>
            <InputBlock>
              <StyledInput
                onChange={handleWeight}
                value={weight}
                label="Weight"
                helperText={weightError}
                type="text"
                margin="none"
                fullWidth
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledInputAdornment onClick={handleIsLbs}>
                        {isLbs ? 'lbs' : 'kg'}
                      </StyledInputAdornment>
                    </InputAdornment>
                  ),
                  'data-testid': 'input-weight',
                }}
              />
            </InputBlock>
            <InputBlock>
              <StyledInput
                onChange={handleHealthGoals}
                value={healthGoals}
                label="Health Goals"
                inputProps={{
                  'data-testid': 'input-health-goals',
                }}
                helperText=""
                type="text"
                margin="none"
                fullWidth
                multiline
                rows={4}
              />
            </InputBlock>
          </ColumnBlock>
        </InputElementsBlock>
        <ButtonElementsBlock>
          <Box sx={{ marginRight: 3 }}>
            <Button onClick={toggleOpenEditModal} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Save</Button>
          </Box>
        </ButtonElementsBlock>
      </Container>
    </Modal>
  );
};

export default ClientsEditModal;
