import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import { Base64 } from 'js-base64';
import { auth, db } from '@/utils/firebase/firebaseInit';
import { IRegisterUserProps } from '@/types';
import { getCoachData } from '@/store/coaches/coachAuthSlice';
import { useAppDispatch } from '@/hooks';
import { doc, updateDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from 'sonner';

export const useInvitation = () => {
  const dispatch = useAppDispatch();
  const [userDataInitial, setUserDataInitial] = useState<any | null>(null);
  const [answerError, setAnswerError] = useState<string>('');
  const location = useLocation();
  const urlInfo = location.pathname.split('/')[2] ?? '';
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const { firstName, lastName, email, confirmId, phone, id } = JSON.parse(
        Base64.fromBase64(urlInfo),
      );
      if (firstName && lastName && confirmId && email && id) {
        setUserDataInitial({
          firstName,
          lastName,
          email,
          confirmId,
          id,
          ...(phone && { phone }),
        });
      }
    } catch (e) {
      setAnswerError('This invitation link is broken.');
    }
  }, [urlInfo]);

  const registerUser = async (docId: string, password: string, user: IRegisterUserProps) => {
    const { email, firstName, lastName, phone } = user;
    let uid = '';

    /**
     * The current coach registration flow:
     1 The super-admin creates a coach in the "coaches" tab via the "add new coach" button
     2 After clicking the "create" button, a record is created in the "users" table with the data entered in the modal window, and a link is sent to the email specified in the modal window.
     3 When the coach follows the link from the e-mail, he is directed to the "Invitation" modal window where he must come up with the password.
     4 If everything is fine, it is redirected to the coach dashboard

     Flow if the coach will follow the link again if the previous steps have been taken
     - If the coach went through the link and he was previously logged in, he gets to the coach dashboard
     - If the coach was logged out, he gets to the "Invitation" modal window, where after pressing the "Continue" button, he gets to the "Login" modal window with the warning "The user exists, please log in"
     */
    try {
      await createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          if (userCredential.user?.uid) {
            uid = userCredential.user?.uid;
            await signInWithEmailAndPassword(auth, email, password);
          }
        })
        .catch(async (e) => {
          console.log('error', e);
          if (e.code === 'auth/email-already-in-use') {
            toast.warning('The user exists, please log in.');
            navigate('/login');
          } else {
            setAnswerError(e.code);
          }
        });

      await updateDoc(doc(db, 'users', docId), {
        uid: uid,
        firstName,
        lastName,
        phone,
        isInviteAccepted: true,
        confirmId: v4(),
        authProvider: 'local',
        email: userDataInitial?.email,
        avatarUrl:
          userDataInitial?.avatarUrl ??
          `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=random`,
      });
      dispatch(getCoachData({ uid }));
      setAnswerError('');
      toast.success("You're successfully registered with invitation link");
      navigate('/');
    } catch (error) {
      console.error(error);
      setAnswerError('Something wrong with your data.');
    }
  };

  return { userDataInitial, answerError, registerUser };
};
