import React, { useState } from 'react';
import MenuButtons from '../ResourceContentMenuButtons';
import { DivButtons, DivWrapper } from './styles';
import { IHoverResource } from '@/types';

const HoverResource = ({
  handleResourceRemove,
  handleResourceEdit,
  resource,
  children,
}: IHoverResource) => {
  const [isVisible, setVisible] = useState<boolean>(false);

  const chooseItem = (handler: () => void) => {
    setVisible(false);
    handler();
  };

  const toggleVisibilityOn = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setVisible(true);
  };

  const toggleVisibilityOff = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setVisible(false);
  };

  const deleteHandler = () => {
    handleResourceRemove(resource.id);
  };

  const editHandler = () => {
    handleResourceEdit(resource.id, resource.type);
  };

  return (
    <DivWrapper onMouseEnter={toggleVisibilityOn} onMouseLeave={toggleVisibilityOff}>
      <DivButtons isVisible={isVisible}>
        <MenuButtons
          deleteClickHandler={() => chooseItem(deleteHandler)}
          editClickHandler={() => chooseItem(editHandler)}
        />
      </DivButtons>
      {children}
    </DivWrapper>
  );
};

export default HoverResource;
