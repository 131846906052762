import { styled } from '@mui/system';
import Button from '@/common/core/Button';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 20,
  marginLeft: 32,
  marginRight: 32,
});

export const ButtonBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
});

export const StyledButton = styled(Button)({
  width: 130,
  height: 44,
  color: 'rgba(15, 15, 33, 0.6)',
  border: '1px solid rgba(15, 15, 33, 0.2)',
  borderRadius: 8,
  fontWeight: 600,
});
