import React, { useState, useEffect } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Input from '@/common/core/Input';
import { usePasswordEdit } from '@/hooks/auth/usePasswordEdit';
import {
  ButtonBlock,
  Container,
  InputBlock,
  StyledButton,
  Title2Block,
  Title2Text,
  Wrapper,
} from './styles';
import { IPasswordForm } from '@/types';

const PasswordForm = ({ pageProfile }: IPasswordForm) => {
  const [passwordOld, setPasswordOld] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [isShowPasswordOld, setIsShowPasswordOld] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowPasswordRepeat, setIsShowPasswordRepeat] = useState<boolean>(false);
  const [passwordOldError, setPasswordOldError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [passwordRepeatError, setPasswordRepeatError] = useState<string>('');
  const [passwordEditSuccess, setPasswordEditSuccess] = useState<boolean>(false);
  const { editPassword } = usePasswordEdit();

  useEffect(() => {
    setPasswordOld('');
    setPassword('');
    setPasswordRepeat('');
  }, [pageProfile]);

  useEffect(() => {
    if (passwordEditSuccess) {
      setPasswordOldError('');
      setPasswordOld('');
      setPassword('');
      setPasswordRepeat('');
    }
  }, [passwordEditSuccess]);

  const toggleShowPasswordOld = () => {
    setIsShowPasswordOld(!isShowPasswordOld);
  };

  const toggleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const toggleShowPasswordRepeat = () => {
    setIsShowPasswordRepeat(!isShowPasswordRepeat);
  };

  const handleChangePasswordOld = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordOld(e.target.value);
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChangePasswordRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordRepeat(e.target.value);
  };

  const handleSubmit = async () => {
    setPasswordOldError(passwordOld ? '' : 'Please fill old password');

    setPasswordError(
      password
        ? password.length >= 6
          ? ''
          : 'Password must be at least 6 characters'
        : 'Please fill password',
    );
    setPasswordRepeatError(
      passwordRepeat
        ? passwordRepeat === password && password
          ? ''
          : 'Repeat password is not equal to password'
        : 'Please fill repeat password',
    );

    if (
      passwordOld &&
      password &&
      passwordRepeat &&
      password.length >= 6 &&
      password === passwordRepeat
    ) {
      const updatePassword = await editPassword(passwordOld, password);
      setPasswordEditSuccess(updatePassword);
      if (!updatePassword) {
        setPasswordOldError('Incorrect old password');
      }
    }
  };

  return (
    <Wrapper>
      <Container>
        <Title2Block>
          <Title2Text>Change your password</Title2Text>
        </Title2Block>
        <InputBlock>
          <Input
            error={!!passwordOldError}
            onChange={handleChangePasswordOld}
            value={passwordOld}
            label="Old password"
            helperText={passwordOldError}
            type={isShowPasswordOld ? 'text' : 'password'}
            fullWidth
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPasswordOld} edge="end" size="large">
                    {isShowPasswordOld ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputBlock>
        <InputBlock>
          <Input
            error={!!passwordError}
            onChange={handleChangePassword}
            value={password}
            label="Password"
            helperText={passwordError}
            type={isShowPassword ? 'text' : 'password'}
            fullWidth
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPassword} edge="end" size="large">
                    {isShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputBlock>
        <InputBlock>
          <Input
            error={!!passwordRepeatError}
            onChange={handleChangePasswordRepeat}
            value={passwordRepeat}
            label="Repeat password"
            helperText={passwordRepeatError}
            type={isShowPasswordRepeat ? 'text' : 'password'}
            fullWidth
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPasswordRepeat} edge="end" size="large">
                    {isShowPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputBlock>
        <ButtonBlock>
          <StyledButton onClick={handleSubmit} fullWidth={true}>
            Save
          </StyledButton>
        </ButtonBlock>
      </Container>
    </Wrapper>
  );
};

export default PasswordForm;
