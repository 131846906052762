import { useContext } from 'react';
import { Context } from '@/context/Context';
import { isObjectEmpty } from '@/utils/format/objects';
import { IChatAllClientsList, TClientSearchUser } from '@/types';
import ChatAllClientsListItem from '../ChatAllClientsListItem';
import ChatAllClientsListHeader from '../ChatAllClientsListHeader';
import { ListBlock } from '../styles';

const ChatAllClientsList = ({
  usersList,
  usersSize,
  sortValue,
  setSortValue,
  filtersValues,
  setFiltersValues,
  isOpenAllClients,
}: IChatAllClientsList) => {
  const savedUsers = useContext(Context).savedUsers;

  return (
    <>
      <ChatAllClientsListHeader
        usersSize={usersSize}
        sortValue={sortValue}
        setSortValue={setSortValue}
        filtersValues={filtersValues}
        setFiltersValues={setFiltersValues}
        isOpenAllClients={isOpenAllClients}
      />
      {!!usersList.length && (
        <ListBlock>
          {usersList.map((user: TClientSearchUser) => (
            <ChatAllClientsListItem
              key={user.id}
              user={user}
              isSavedByCurrentCoach={!isObjectEmpty(savedUsers) ? savedUsers[user.id] : false}
            />
          ))}
        </ListBlock>
      )}
    </>
  );
};

export default ChatAllClientsList;
