import React, { useState } from 'react';
import {
  CloudDownload as DownloadIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
  DoneAll as DoneAllIcon,
  PlayArrow,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@/common/core/Menu';
import { useChatFileDelete } from '@/hooks/chat/useChatFileDelete';
import { formatTime } from '@/utils/format/datetime';
import { setDefaultPreview } from '@/utils/data/preview';
import {
  DateText,
  FileBlock,
  FileContainer,
  FileImage,
  FileImageBlock,
  FileInfoBlock,
  FileInfoBlockLeft,
  FileInfoBlockRight,
  IsReadIcon,
  SubMenu,
  SubMenuDelete,
  UnReadIcon,
  UserNameFileText,
} from './styles';
import { IChatMessagesListItemFiles, IFilesAttached } from '@/types';
import { handleCopyMessageId } from '@/utils/tools/copyToClipboard';
import ReactPlayer from 'react-player';
import remarkGfm from 'remark-gfm';
import {
  QuoteBlock,
  QuoteLineBlock,
  QuoteLine,
  QuoteMessageBlock,
  QuoteBodyText,
  BodyText,
  ActionTrackingGoal,
  ActionTrackingDetails,
  QuoteNameBlock,
  QuoteName,
  StyledReactMarkDown,
  FileImagePreviewReply,
} from '../ChatMessagesListItem/styles';

const ChatMessagesListItemFiles = ({
  messageId,
  userIdFrom,
  userNameFrom,
  createdAtSeconds,
  currentUserId,
  handleMessageFileEdited,
  files,
  isViewed,
  messageBy,
  showReply,
  replyToMessage,
}: IChatMessagesListItemFiles) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [fileChosen, setFileChosen] = useState<IFilesAttached | null>(null);
  const { deleteFile } = useChatFileDelete();

  const toggleMenu = (e: React.MouseEvent<HTMLElement>, file: any) => {
    e.stopPropagation();
    if (anchorEl) {
      setAnchorEl(null);
      setFileChosen(null);
    } else {
      setAnchorEl(e.currentTarget);
      setFileChosen(file);
    }
  };

  const handleDownloadFile = () => {
    window.open(fileChosen?.url ?? '', '_blank');
    setAnchorEl(null);
    setFileChosen(null);
  };

  const handleEditFileMessage = () => {
    setAnchorEl(null);
    handleMessageFileEdited({
      id: messageId,
      userNameFrom,
      file: fileChosen,
    });
  };

  const handleDeleteFile = () => {
    deleteFile(fileChosen?.url ?? '', messageId).then(() => {
      setAnchorEl(null);
      setFileChosen(null);
    });
  };

  return (
    <>
      <FileContainer
        isCurrentUser={userIdFrom === currentUserId}
        onClick={(e: any) => e.stopPropagation()}
      >
        {files?.map((file: IFilesAttached) => {
          const { fileType, filePreview, fileIcon } = setDefaultPreview(file);

          return (
            <FileBlock
              isCurrentUser={userIdFrom === currentUserId}
              fileChosen={fileChosen === file}
              key={file.url}
              onClick={(e: any) => toggleMenu(e, file)}
              className={fileType === 'audio' ? 'w-[350px]' : ''}
            >
              <FileImageBlock className="p-2">
                {showReply && replyToMessage && (
                  <QuoteBlock>
                    <QuoteLineBlock>
                      <QuoteLine />
                    </QuoteLineBlock>
                    <QuoteMessageBlock>
                      <QuoteBodyText>
                        <BodyText as="div">
                          {replyToMessage.actionTracking ? (
                            <div>
                              <ActionTrackingGoal>
                                {replyToMessage.actionTracking.goal}
                              </ActionTrackingGoal>
                              <ActionTrackingDetails>
                                {replyToMessage.actionTracking.details}
                              </ActionTrackingDetails>
                            </div>
                          ) : (
                            <QuoteNameBlock>
                              <QuoteName>{replyToMessage?.userNameFrom}</QuoteName>
                            </QuoteNameBlock>
                          )}

                          <StyledReactMarkDown
                            children={replyToMessage?.body || ''}
                            remarkPlugins={[remarkGfm]}
                          />
                          {replyToMessage?.files?.[0]?.url && (
                            <FileImagePreviewReply src={replyToMessage?.files?.[0]?.url} alt="" />
                          )}
                        </BodyText>
                      </QuoteBodyText>
                    </QuoteMessageBlock>
                  </QuoteBlock>
                )}
                {fileType === 'image' && file?.thumbnailUrl && (
                  <FileImage src={file?.thumbnailUrl} alt="" />
                )}

                {fileType === 'audio' && (
                  <>
                    <ReactPlayer
                      height={100}
                      width={'100%'}
                      controls={true}
                      url={file.url}
                      playIcon={<PlayArrow />}
                      playing={false}
                    />
                  </>
                )}

                {fileType === 'video' && (
                  <>
                    <ReactPlayer
                      width={'100%'}
                      controls={true}
                      url={file.url}
                      playIcon={<PlayArrow />}
                    />
                  </>
                )}
              </FileImageBlock>
              <FileInfoBlock>
                <FileInfoBlockLeft>
                  <UserNameFileText isCurrentUser={userIdFrom === currentUserId}>
                    {messageBy}
                  </UserNameFileText>
                </FileInfoBlockLeft>
                <FileInfoBlockRight>
                  <DateText isCurrentUser={userIdFrom === currentUserId}>
                    {formatTime(createdAtSeconds * 1000)}
                  </DateText>
                  {userIdFrom !== currentUserId && (
                    <>
                      {isViewed ? (
                        <IsReadIcon as={DoneAllIcon} className="text-emerald-500" />
                      ) : (
                        <UnReadIcon as={DoneIcon} className="text-emerald-500" />
                      )}
                    </>
                  )}
                </FileInfoBlockRight>
              </FileInfoBlock>
            </FileBlock>
          );
        })}
      </FileContainer>
      <Menu anchorEl={anchorEl} toggleMenu={(e) => toggleMenu(e, '')}>
        <SubMenu onClick={handleDownloadFile}>
          Download
          <DownloadIcon />
        </SubMenu>
        {userIdFrom !== currentUserId && (
          <SubMenu onClick={handleEditFileMessage}>
            Edit
            <EditIcon />
          </SubMenu>
        )}
        {userIdFrom !== currentUserId && (
          <SubMenuDelete onClick={handleDeleteFile}>
            Delete
            <DeleteIcon />
          </SubMenuDelete>
        )}
        <SubMenu onClick={() => handleCopyMessageId(messageId)}>Message ID</SubMenu>
      </Menu>
    </>
  );
};

export default ChatMessagesListItemFiles;
