import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListItemIcon, ListItemText } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import {
  MenuButtonList,
  MenuButtonListText,
  MenuWrapper,
  StyledMenu,
  StyledMenuItem,
} from './styles';
import ArticleIcon from '@/assets/icons/recources_article.svg';
import RecipeIcon from '@/assets/icons/recources_recipe.svg';
import WorkoutIcon from '@/assets/icons/recources_workout.svg';

const MenuList = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MenuWrapper>
      <MenuButtonList
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
      >
        <MenuButtonListText>Create new resource</MenuButtonListText>
        <ArrowDropDown
          sx={{
            fontSize: '1.2rem',
            marginLeft: 1,
          }}
        />
      </MenuButtonList>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <StyledMenuItem onClick={() => navigate('/resources/recipe')}>
          <ListItemText primary="Recipe" />
          <ListItemIcon>
            <img src={RecipeIcon} alt="Recipe" />
          </ListItemIcon>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => navigate('/resources/article')}>
          <ListItemText primary="Article" />
          <ListItemIcon>
            <img src={ArticleIcon} alt="Article" />
          </ListItemIcon>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => navigate('/resources/workout')}>
          <ListItemText primary="Workout" />
          <ListItemIcon>
            <img src={WorkoutIcon} alt="Workout" />
          </ListItemIcon>
        </StyledMenuItem>
      </StyledMenu>
    </MenuWrapper>
  );
};

export default MenuList;
