import { useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { TFirePlanId, TFocusArea } from '@/types';
import { updateCardNotes } from '@/store/program/weekProgramSlice';
import { setProgramActionStatus } from '@/store/program/programActionsSlice';
import { Button } from '../../ChatProgramComponents';

const EditCoachNotes = (props: {
  focusArea: TFocusArea;
  weeks: TFirePlanId[];
  selectedPlan?: string;
  userId?: string;
  onClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { currentWeekPlan } = useAppSelector((state) => state.weekProgram);
  const { focusArea, weeks, selectedPlan, userId } = props;
  const [notes, setNotes] = useState(focusArea.notes ?? '');
  const onSave = () => {
    if (weeks && selectedPlan && userId) {
      dispatch(
        updateCardNotes({
          focusArea,
          currentWeekPlan,
          notes,
          userId,
        }),
      );
      dispatch(setProgramActionStatus('draft'));
    }
    props.onClose();
  };

  return (
    <div className="w-full text-left">
      <h4>{focusArea.title}</h4>
      <p className="mb-3 mt-5 text-sm">Edit coach notes</p>
      <TextareaAutosize
        className="mb-5 w-full border rounded-lg border-black/40 p-3"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />
      <div className="flex gap-3 w-full">
        <Button
          variant="outlined"
          size="lg"
          className="w-full text-center"
          onClick={() => props.onClose()}
        >
          Cancel
        </Button>
        <Button size="lg" className="w-full text-center" onClick={() => onSave()}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditCoachNotes;
