import { Dialog } from '@mui/material';
import Button from '../Button';
import { ActionsBlock, Container, ContentBlock, HeaderBlock, TitleText } from './styles';
import classnames from 'classnames';
import { IModal } from '@/types';

const Modal = ({
  widthMax = 300,
  title,
  titleCentered = false,
  open = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClose = () => {},
  handleSubmit,
  textBtnClose,
  textBtnSubmit,
  children,
  shouldUseCustomChildren,
  minHeight,
  containerClassName,
}: IModal) => {
  const paperProps = widthMax
    ? { style: { width: widthMax, ...(minHeight && { minHeight: minHeight }) } }
    : {};

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth="lg"
      PaperProps={paperProps}
    >
      {shouldUseCustomChildren ? (
        children
      ) : (
        <Container className={classnames(containerClassName)}>
          {!!title && (
            <HeaderBlock>
              <TitleText className={classnames(titleCentered && 'text-center')}> {title}</TitleText>
            </HeaderBlock>
          )}
          <ContentBlock>{children}</ContentBlock>
          {(handleSubmit || textBtnClose) && (
            <ActionsBlock>
              {textBtnClose && (
                <Button onClick={handleClose} variant="outlined">
                  {textBtnClose}
                </Button>
              )}
              {handleSubmit && (
                <Button onClick={handleSubmit}>{textBtnSubmit ? textBtnSubmit : 'Sumbit'}</Button>
              )}
            </ActionsBlock>
          )}
        </Container>
      )}
    </Dialog>
  );
};

export default Modal;
