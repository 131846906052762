import { useState } from 'react';
import { Typography } from '@mui/material';
import { TextFields, Edit } from '@mui/icons-material';
import { ITextSurvey, ESurveysQuestionTypes } from '@/types';
import {
  AnswerTitle,
  AnswerValue,
  EditIcon,
  Icon,
  InputQuestion,
  LocalWrapper,
  Question,
  QuestionText,
  Row,
  TypeTitle,
} from '../styles';

const TextComponent = ({
  indexStage,
  data,
  isEditable,
  suerveyId,
  handleEditSurvey,
}: ITextSurvey) => {
  const { id, question, type, answer, unit, title } = data;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [value, setValue] = useState<string[]>(answer?.answers || ['']);

  const handleInput = (value: string) => {
    if (handleEditSurvey) {
      setValue([value]);
      handleEditSurvey(suerveyId || '', indexStage || 0, id, [value]);
    }
  };

  return (
    <Question>
      <Row>
        <LocalWrapper>
          <Icon as={TextFields} />
          <QuestionText>
            {title} - {question}
          </QuestionText>
        </LocalWrapper>
        {isEditable && <EditIcon as={Edit} onClick={() => setEditMode(true)} />}
      </Row>
      <Row>
        <LocalWrapper>
          <TypeTitle>Type</TypeTitle>
          <Typography>
            {[
              ESurveysQuestionTypes.TEXT,
              ESurveysQuestionTypes.DATE,
              ESurveysQuestionTypes.TEXT_WITH_UNITS,
            ].includes(type as any) && 'Text'}
          </Typography>
        </LocalWrapper>
      </Row>
      <Row>
        <LocalWrapper>
          {editMode ? (
            <InputQuestion
              value={value[0]}
              onChange={(e) => handleInput(e.target.value)}
              autoFocus={true}
              name="title"
              type="text"
              fullWidth={true}
              multiline
              label="Answer"
            />
          ) : (
            <>
              <AnswerTitle>Answers</AnswerTitle>{' '}
              <AnswerValue>
                {value} {unit ?? ''}
              </AnswerValue>
            </>
          )}
        </LocalWrapper>
      </Row>
    </Question>
  );
};

export default TextComponent;
