import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  InsertLink as InsertLinkIcon,
} from '@mui/icons-material';
import Menu from '@/common/core/Menu';
import { timezonesUSList } from '@/config';
import { inviteEmailNotificationCoach } from '@/utils/tools/emails';
import { formatTime } from '@/utils/format/datetime';
import {
  ActionsBlock,
  BottomLineBlock,
  Container,
  EmailBlock,
  NameBlock,
  NameBottomBlock,
  NameTopBlock,
  PhoneBlock,
  ShiftBlock,
  StyledMenuIcon,
  SubMenu,
  Text1,
  Text2,
  Text2WithStatus,
} from './styles';
import { ICoachesListItem } from '@/types';

const CoachesListItem = ({ chooseEditCoach, item, handleEditCoachActivity }: ICoachesListItem) => {
  const {
    id: userId,
    firstName,
    lastName,
    phone,
    email,
    timezone,
    timeStarting,
    timeFinishing,
    confirmId,
    isInviteAccepted,
    isActive,
  } = item;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const shiftData =
    typeof timeStarting === 'number' &&
    timeStarting !== 0 &&
    typeof timeFinishing === 'number' &&
    timeFinishing !== 0
      ? `${formatTime(timeStarting)} - ${formatTime(timeFinishing)}`
      : '';
  const timezoneLabel =
    timezone !== '' ? timezonesUSList.find((item) => item.value === timezone)?.label : '';

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleEdit = () => {
    chooseEditCoach(userId!);
    setAnchorEl(null);
  };

  const handleEditActivity = () => {
    handleEditCoachActivity(userId!, !isActive);
    setAnchorEl(null);
  };

  const handleResendInvitation = () => {
    inviteEmailNotificationCoach(firstName!, lastName!, confirmId!, email!, userId!, phone!);
    setAnchorEl(null);
  };

  return (
    <>
      <Container>
        <NameBlock>
          <NameTopBlock>
            <Text1>
              {firstName} {lastName}
            </Text1>
          </NameTopBlock>
          <NameBottomBlock>
            <Text2WithStatus
              status={isActive ? (isInviteAccepted ? 'registered' : 'pending') : 'deactivated'}
            >
              {isActive ? (isInviteAccepted ? ' ' : 'Pending') : 'Deactivated'}
            </Text2WithStatus>
          </NameBottomBlock>
        </NameBlock>
        <EmailBlock>
          <Text2>{email}</Text2>
        </EmailBlock>
        <PhoneBlock>
          <Text2>{phone}</Text2>
        </PhoneBlock>
        <ShiftBlock>
          <Text2>
            {shiftData} {timezoneLabel}
          </Text2>
        </ShiftBlock>
        <ActionsBlock>
          <IconButton onClick={toggleMenu} size="large">
            <StyledMenuIcon />
          </IconButton>
        </ActionsBlock>
      </Container>
      <BottomLineBlock />
      <Menu anchorEl={anchorEl} toggleMenu={toggleMenu}>
        <SubMenu onClick={handleEdit}>
          Edit Info
          <EditIcon sx={{ fontSize: 18 }} />
        </SubMenu>
        <SubMenu onClick={handleEditActivity}>
          {isActive ? 'Deactivate' : 'Activate'}
          {!isActive ? (
            <VisibilityIcon sx={{ fontSize: 18 }} />
          ) : (
            <VisibilityOffIcon sx={{ fontSize: 18 }} />
          )}
        </SubMenu>
        {!isInviteAccepted && isActive && (
          <SubMenu onClick={handleResendInvitation}>
            Resend invitation
            <InsertLinkIcon sx={{ fontSize: 18 }} />
          </SubMenu>
        )}
      </Menu>
    </>
  );
};

export default CoachesListItem;
