import { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import {
  clientSearchToggleTeam,
  clientSearchFilterCoachNotAssigned,
  clientSearchFilterBgsClients,
} from '@/store/client-search/clientSearchSlice';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getTeamsRef } from '@/api/firestores';
import { Container } from './styles';
import Button from '@/common/core/Button';
import ChatThreadsFilterTeamLabel from '../ChatThreadsFilterTeamLabel';
import ChatThreadsFilterNoCoachLabel from '../ChatThreadsFilterNoCoachLabel';
import ChatThreadsFilterBgsLabel from '../ChatThreadsFilterBgsLabel';
import { Skeleton } from '@mui/material';
import { db } from '@/utils/firebase/firebaseInit';

const ChatThreadsFilter = () => {
  const [hideUnslected, setHideUnselected] = useState(false);
  const [teamCollection] = useCollection(getTeamsRef(db));
  const selected = useAppSelector((x) => x.clientSearch.filters.teams);
  const filterCoachNotAssigned = useAppSelector((x) => x.clientSearch.filters.isnewuser === 'true');
  const filterBgs = useAppSelector((x) => x.clientSearch.filters.isbgsnewuser === 'true');
  const dispatch = useAppDispatch();

  const teams = (teamCollection?.docs || [])
    .map((x) => ({ ...x.data().mainCoach, id: x.id }))
    .sort((a, b) => (a.firstName || 'z').localeCompare(b.firstName || 'z'));

  return (
    <Container>
      <div style={{ margin: '' }}>
        {!teamCollection &&
          [1, 2, 3, 4, 5].map((i) => (
            <div key={i} className="flex content-stretch">
              <Skeleton animation="wave" width={18} height={30} className="ml-1 mr-2" />
              <Skeleton animation="wave" width={280} height={30} />
            </div>
          ))}

        <FormGroup style={{ margin: '4px 0px' }}>
          {teams
            .filter((x) => (hideUnslected ? selected.includes(x.id) : true))
            .map((x) => (
              <FormControlLabel
                key={x.id}
                style={{ margin: 0, marginBottom: '.2rem' }}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    style={{ padding: '0 .2rem' }}
                    checked={selected.includes(x.id)}
                    onChange={() => dispatch(clientSearchToggleTeam(x.id))}
                  />
                }
                label={<ChatThreadsFilterTeamLabel id={x.id} active={!!selected.includes(x.id)} />}
              />
            ))}

          <FormControlLabel
            style={{ margin: 0, marginBottom: '.2rem' }}
            control={
              <Checkbox
                size="small"
                color="primary"
                style={{ padding: '0 .2rem' }}
                checked={filterCoachNotAssigned}
                onChange={() =>
                  dispatch(clientSearchFilterCoachNotAssigned(!filterCoachNotAssigned))
                }
              />
            }
            label={<ChatThreadsFilterNoCoachLabel active={!!filterCoachNotAssigned} />}
          />

          <FormControlLabel
            style={{ margin: 0, marginBottom: '.2rem' }}
            control={
              <Checkbox
                size="small"
                color="primary"
                style={{ padding: '0 .2rem' }}
                checked={filterBgs}
                onChange={() => dispatch(clientSearchFilterBgsClients(!filterBgs))}
              />
            }
            label={<ChatThreadsFilterBgsLabel active={filterBgs} />}
          />
        </FormGroup>
        <Button
          variant="outlined"
          onClick={() => setHideUnselected((x) => !x)}
          disabled={(teams.length || 0) === selected.length}
          style={{ marginLeft: '4px' }}
        >
          {hideUnslected ? 'Show all teams' : 'Hide unselected'}
        </Button>
      </div>
    </Container>
  );
};

export default ChatThreadsFilter;
