import { useState, useEffect } from 'react';
import { useAdminHandouts } from './useAdminHandouts';
import { categoriesList } from '@/config';
import { db } from '@/utils/firebase/firebaseInit';
import { cleanNumber } from '@/utils/format/numberUtils';
import { IAction, IActionCategoryNew, IActionSubCategory, IHandout } from '@/types';
import { addDoc, collection, doc, getDocs, Timestamp, updateDoc } from 'firebase/firestore';

export const useAdminActions = () => {
  //delete old action category after migration
  const [subcategoriesList, setSubcategoriesList] = useState<IActionSubCategory[]>([]);
  const [actionsList, setActionsList] = useState<IAction[]>([]);
  const { handoutsList } = useAdminHandouts();
  const [handoutsListSymbols, setHandoutsListSymbols] = useState<string[]>([]);
  const [categoryListNew, setCategoryListNew] = useState<IActionCategoryNew[]>([]);

  const getCategoryNew = async () => {
    const querySnapshot = await getDocs(collection(db, 'actions_categories'));
    !querySnapshot.empty &&
      setCategoryListNew(
        querySnapshot.docs.map((category) => {
          return {
            id: category.id,
            categoryId: category.data().categoryId,
            habitTypes: category.data().habitTypes,
            name: category.data().name,
            sortingIndex: category.data().sortingIndex,
          };
        }),
      );
  };

  const loadActionSubcategories = async () => {
    const querySnapshot = await getDocs(collection(db, 'actions_subcategories'));
    if (!querySnapshot.empty) {
      setSubcategoriesList(
        querySnapshot.docs.map((rawSubcategory) => {
          const { category, name } = rawSubcategory.data();
          return {
            id: rawSubcategory.id,
            category,
            name,
          };
        }),
      );
    }
  };

  const loadActions = async () => {
    const querySnapshot = await getDocs(collection(db, 'actions'));
    if (!querySnapshot.empty) {
      setActionsList(
        querySnapshot.docs
          .filter((action) => action.data().isDeleted !== true)
          .map((rawAction) => {
            const {
              category,
              subcategoryId,
              handoutId,
              title,
              description,
              isDeleted,
              createdAt,
              categoryId,
              habitType,
              sortingIndex,
            } = rawAction.data();
            return {
              id: rawAction.id,
              category,
              categoryId,
              habitType,
              sortingIndex,
              subcategoryId,
              handoutId: handoutId ?? '',
              title,
              description,
              isDeleted: isDeleted ?? false,
              createdAt,
            };
          }),
      );
    }
  };

  useEffect(() => {
    let handoutsListSymbolsNew: string[] = [];
    handoutsList.forEach((handout: IHandout) => {
      const title = handout.title;
      const symbol = title.toLocaleUpperCase().charAt(0);
      const index = handoutsListSymbolsNew.findIndex((item: string) => item === symbol);
      if (index === -1) {
        handoutsListSymbolsNew.push(symbol);
      }
    });
    handoutsListSymbolsNew = handoutsListSymbolsNew.sort((a: string, b: string) =>
      a.localeCompare(b),
    );
    setHandoutsListSymbols(handoutsListSymbolsNew);
  }, [handoutsList]);

  useEffect(() => {
    getCategoryNew();
    loadActionSubcategories().then(() => loadActions());
  }, []);

  const createAction = async (action: IAction) => {
    const {
      title,
      description,
      categoryId,
      habitType,
      sortingIndex,
      handoutId,
      category,
      subcategoryId,
    } = action;
    await addDoc(collection(db, 'actions'), {
      title: title.trim(),
      description: description.trim(),
      category,
      subcategoryId,
      categoryId,
      habitType,
      sortingIndex: cleanNumber(sortingIndex),
      handoutId,
      isActive: true,
      createdAt: Timestamp.now(),
    });
  };

  const editAction = async (action: IAction) => {
    const {
      id,
      title,
      description,
      category,
      subcategoryId,
      categoryId,
      habitType,
      sortingIndex,
      handoutId,
    } = action;
    const docRef = doc(db, 'actions', id);
    await updateDoc(docRef, {
      title: title.trim(),
      description: description.trim(),
      categoryId,
      category,
      subcategoryId,
      habitType,
      sortingIndex: cleanNumber(sortingIndex),
      handoutId,
    });
  };

  const deleteAction = async (actionId: string) => {
    await updateDoc(doc(db, 'actions', actionId), {
      isDeleted: true,
    });
  };

  return {
    categoryListNew,
    categoriesList,
    subcategoriesList,
    actionsList,
    handoutsListSymbols,
    createAction,
    editAction,
    deleteAction,
  };
};
