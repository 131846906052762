import { useForm, FormProvider, useWatch } from 'react-hook-form';
import Modal from '@/common/core/Modal';
import InputFormField from '@/common/core/FormElements/InputFormField';
import SelectFormField from '@/common/core/FormElements/SelectFormField';
import SelectOptionsWrapper from '@/common/core/SelectOptionsWrapper';
import { useAdminActions } from '@/hooks/admin/useAdminActions';
import { buildReversStructure } from '@/utils/data/action';
import { Container, LabelNew } from './styles';
import { IAction, IActionCategory, IActionSubCategory, IHandout, IActionsModal } from '@/types';

const ActionsModal = ({
  categoriesList,
  subcategoriesList, // TODO: to avoid old app crash, remove this row ONLY when all app users have upgraded to version v1.7.0
  handoutsListSymbols,
  handoutsList,
  isOpenModal,
  handleCloseModal,
  activeAction,
  categoryListNew,
}: IActionsModal) => {
  const { createAction, editAction } = useAdminActions();
  const isNewStructure: boolean =
    !!activeAction?.sortingIndex && !!activeAction?.habitType && !!activeAction?.categoryId;
  const actionsForm = useForm<IAction>({
    defaultValues: {
      id: activeAction?.id ?? '',
      title: activeAction?.title ?? '',
      description: activeAction?.description ?? '',
      category: activeAction?.category ?? 'calories_out', // TODO: to avoid old app crash, remove this row ONLY when all app users have upgraded to version v1.7.0
      subcategoryId: activeAction?.subcategoryId ?? subcategoriesList[0].id, // TODO: to avoid old app crash, remove this row ONLY when all app users have upgraded to version v1.7.0
      handoutId: activeAction?.handoutId ?? '',
      categoryId: activeAction?.categoryId ?? '',
      habitType: activeAction?.habitType ?? '',
      sortingIndex: activeAction?.sortingIndex ?? 1,
    },
  });

  const onCloseModal = () => {
    handleCloseModal();
    actionsForm.reset();
  };

  const handleSubmit = async (action: IAction) => {
    try {
      activeAction ? await editAction(action) : await createAction(action);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message ?? 'Some action error');
      }
    } finally {
      onCloseModal();
    }
  };

  const isPaymentReferralWasShow = useWatch({
    name: 'category',
    control: actionsForm.control,
    defaultValue: actionsForm.getValues('category') || '',
  });

  const currentHabitType = useWatch({
    name: 'habitType',
    control: actionsForm.control,
    defaultValue: actionsForm.getValues('habitType') || '',
  });

  const currentCategoryId = useWatch({
    name: 'categoryId',
    control: actionsForm.control,
    defaultValue: actionsForm.getValues('categoryId') || '',
  });

  return (
    <FormProvider {...actionsForm}>
      <Modal
        widthMax={370}
        title={activeAction ? 'Edit action' : 'Create new action'}
        open={isOpenModal}
        handleClose={onCloseModal}
        handleSubmit={actionsForm.handleSubmit(handleSubmit)}
        textBtnClose="Cancel"
        textBtnSubmit="Save"
      >
        <form id="actions-modal">
          <Container>
            <InputFormField
              name="title"
              label="Name of action"
              control={actionsForm.control}
              rules={{
                required: {
                  value: true,
                  message: 'Please fill title',
                },
              }}
              inputProps={{
                'data-testid': 'action-name',
              }}
            />

            <InputFormField
              name="description"
              label="Description"
              control={actionsForm.control}
              multiline={{ rows: 5, maxRows: 10 }}
              inputProps={{
                'data-testid': 'action-description',
              }}
            />
            {/*delete true block after migration*/}
            {!!activeAction && !isNewStructure ? (
              <>
                <SelectFormField
                  name="category"
                  label="Category"
                  control={actionsForm.control}
                  disabled={true}
                  optionList={categoriesList.map((category: IActionCategory) => ({
                    value: category.id,
                    key: category.id,
                    title: category.name,
                  }))}
                  handleChange={({ target }: any) => {
                    actionsForm.setValue('category', target.value);
                    const subCatsListFiltered = subcategoriesList.filter(
                      (subCat: any) => subCat.category === target.value,
                    );
                    actionsForm.setValue('subcategoryId', subCatsListFiltered[0]?.id ?? '');
                  }}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please fill category',
                    },
                  }}
                  inputProps={{
                    'data-testid': 'action-category',
                  }}
                />
                <SelectFormField
                  name="subcategoryId"
                  label="Subcategory"
                  control={actionsForm.control}
                  disabled={true}
                  optionList={subcategoriesList
                    .filter(
                      (subCat: IActionSubCategory) => subCat.category === isPaymentReferralWasShow,
                    )
                    .map((subCat: IActionSubCategory) => ({
                      value: subCat.id,
                      key: subCat.id,
                      title: subCat.name,
                    }))}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please fill subcategory',
                    },
                  }}
                  inputProps={{
                    'data-testid': 'action-subcategory',
                  }}
                />
                <SelectFormField
                  name="handoutId"
                  label="Handout"
                  control={actionsForm.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please fill handout',
                    },
                  }}
                  inputProps={{
                    'data-testid': 'action-handout',
                  }}
                >
                  <option aria-label="None" value="" key={0}></option>
                  {handoutsListSymbols.map((symbol: string) => (
                    <optgroup key={symbol} label={symbol}>
                      <SelectOptionsWrapper
                        items={handoutsList
                          .filter((handout: IHandout) => {
                            const title = handout.title;
                            const symbolFirst = title.toUpperCase().charAt(0);
                            return symbolFirst === symbol;
                          })
                          .map((handout: IHandout) => ({
                            value: handout.id,
                            key: handout.id,
                            title: handout.title,
                          }))}
                      />
                    </optgroup>
                  ))}
                </SelectFormField>
                <LabelNew>New structure</LabelNew>

                <SelectFormField
                  name="habitType"
                  label="Category"
                  control={actionsForm.control}
                  optionList={buildReversStructure(categoryListNew).map((category) => ({
                    value: category.enum,
                    key: category.enum,
                    title: category.label,
                  }))}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please fill category',
                    },
                  }}
                  inputProps={{
                    'data-testid': 'action-categoryNew',
                  }}
                />
                <SelectFormField
                  name="categoryId"
                  label="Subcategory"
                  control={actionsForm.control}
                  optionList={
                    currentHabitType
                      ? buildReversStructure(categoryListNew)
                          .filter((item) => item.enum === currentHabitType)[0]
                          .habitTypes.map((subCat) => ({
                            value: subCat.id,
                            key: subCat.id,
                            title: subCat.name,
                          }))
                      : []
                  }
                  rules={{
                    required: {
                      value: true,
                      message: 'Please fill subcategory',
                    },
                  }}
                  inputProps={{
                    'data-testid': 'action-categoryId',
                  }}
                />
                {currentCategoryId && (
                  <InputFormField
                    name="sortingIndex"
                    label="Order"
                    control={actionsForm.control}
                    type="number"
                    rules={{
                      required: {
                        value: true,
                        message: 'Please fill Order',
                      },
                    }}
                    inputProps={{
                      'data-testid': 'action-order',
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <SelectFormField
                  name="habitType"
                  label="Category"
                  control={actionsForm.control}
                  optionList={buildReversStructure(categoryListNew).map((category) => ({
                    value: category.enum,
                    key: category.enum,
                    title: category.label,
                  }))}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please fill category',
                    },
                  }}
                  inputProps={{
                    'data-testid': 'action-categoryNew',
                  }}
                />
                <SelectFormField
                  name="categoryId"
                  label="Subcategory"
                  control={actionsForm.control}
                  optionList={
                    currentHabitType
                      ? buildReversStructure(categoryListNew)
                          .filter((item) => item.enum === currentHabitType)[0]
                          .habitTypes.map((subCat) => ({
                            value: subCat.id,
                            key: subCat.id,
                            title: subCat.name,
                          }))
                      : []
                  }
                  rules={{
                    required: {
                      value: true,
                      message: 'Please fill subcategory',
                    },
                  }}
                  inputProps={{
                    'data-testid': 'action-categoryId',
                  }}
                />

                {currentCategoryId && (
                  <InputFormField
                    name="sortingIndex"
                    label="Order"
                    control={actionsForm.control}
                    type="number"
                    rules={{
                      required: {
                        value: true,
                        message: 'Please fill Order',
                      },
                    }}
                    inputProps={{
                      'data-testid': 'action-order',
                    }}
                  />
                )}

                <SelectFormField
                  name="handoutId"
                  label="Handout"
                  control={actionsForm.control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please fill handout',
                    },
                  }}
                  inputProps={{
                    'data-testid': 'action-handout',
                  }}
                >
                  <option aria-label="None" value="" key={0}></option>
                  {handoutsListSymbols.map((symbol: string) => (
                    <optgroup key={symbol} label={symbol}>
                      <SelectOptionsWrapper
                        items={handoutsList
                          .filter((handout: IHandout) => {
                            const title = handout.title;
                            const symbolFirst = title.toUpperCase().charAt(0);
                            return symbolFirst === symbol;
                          })
                          .map((handout: IHandout) => ({
                            value: handout.id,
                            key: handout.id,
                            title: handout.title,
                          }))}
                      />
                    </optgroup>
                  ))}
                </SelectFormField>
              </>
            )}
          </Container>
        </form>
      </Modal>
    </FormProvider>
  );
};

export default ActionsModal;
