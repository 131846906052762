import {
  NotInterested as DeclinedIcon,
  Done as CompletedIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { formatTime } from '@/utils/format/datetime';
import {
  SurveyCompletedIcon,
  SurveyDeclinedIcon,
  SurveyHorizontalBlock,
  SurveyHorizontalChip,
  SurveySendIcon,
} from '../styles';
import { ISurveyChip } from '@/types';

const SurveyChip = ({ message }: ISurveyChip) => {
  const { createdAt, isSurveyDeclined, isSurveyCompleted, isSurveyAssigned } = message;
  const buildIcon = () => {
    if (isSurveyCompleted) return <SurveyCompletedIcon as={CompletedIcon} />;
    if (isSurveyDeclined) return <SurveyDeclinedIcon as={DeclinedIcon} />;
    if (isSurveyAssigned) return <SurveySendIcon as={SendIcon} />;
  };

  const buildLabel = () => {
    if (isSurveyCompleted) return 'User completed survey at ';
    if (isSurveyDeclined) return 'Declined survey at ';
    if (isSurveyAssigned) return 'Survey sent at ';
  };

  return (
    <SurveyHorizontalBlock>
      <SurveyHorizontalChip
        icon={buildIcon()}
        label={`${buildLabel()} ${formatTime(createdAt.seconds * 1000)}`}
      />
    </SurveyHorizontalBlock>
  );
};

export default SurveyChip;
