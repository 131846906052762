import { useContext } from 'react';
import Iframe from 'react-iframe';
import { Context } from '@/context/Context';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '@/hooks';
import { IframeCloseIcon, StyledDrawer } from '@/styles/drawers';
import { useDrawerOffset } from '@/hooks/chat/useDrawerOffset';

const DrawerMyPlan = () => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  const openedMyPlanDrawer = useContext(Context).openedMyPlanDrawer;
  const setOpenedMyPlanDrawer = useContext(Context).setOpenedMyPlanDrawer;
  const getDrawerOffset = useDrawerOffset();

  if (!customer) {
    return null;
  }

  return (
    <StyledDrawer
      isOpen={openedMyPlanDrawer}
      variant="persistent"
      anchor="right"
      open={openedMyPlanDrawer}
      rightOffset={getDrawerOffset('myPlan')}
      width={340}
    >
      <IframeCloseIcon as={CloseIcon} onClick={() => setOpenedMyPlanDrawer(false)} />
      {customer?.uid && (
        <Iframe
          url={`${process.env.REACT_APP_MY_PLAN_URL}/?uid=${customer?.uid}`}
          width="340px"
          height="100%"
          display="block"
          position="relative"
        />
      )}
    </StyledDrawer>
  );
};

export default DrawerMyPlan;
