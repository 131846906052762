import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  cancelPauseOutcomeList,
  cancelPauseRequestList,
  EMAIL_REGEXP,
  paymentTypeList,
} from '@/config';
import SelectFormField from '@/common/core/FormElements/SelectFormField';
import InputFormField from '@/common/core/FormElements/InputFormField';
import { getDateFromUnix } from '@/utils/format/datetime';
import { useAppSelector } from '@/hooks';
import { EPaymentListType } from '@/types';
import Wrapper from '../Wrapper';
import ChatFAQLoader from '../../../ChatFAQ/ChatFAQLoader';
import { DatePickerBlock, DatePickerError, StyledDatePicker } from '../styles';

const PaymentInfo = () => {
  const { customer } = useAppSelector((state) => state.customerInfos);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { partner } = customer;
  const { control, getValues, setValue } = useFormContext();

  const paymentStatus = useWatch({
    name: 'paymentStatus',
    control: control,
    defaultValue: getValues('paymentStatus') || EPaymentListType.UNPAID,
  });

  const isRequiredEmail =
    paymentStatus === !partner &&
    (EPaymentListType.ACTIVE_REF || paymentStatus === EPaymentListType.ACTIVE);

  if (!customer) {
    return <ChatFAQLoader />;
  }

  return (
    <Wrapper blockTitle="Payment info">
      <SelectFormField
        name="paymentStatus"
        label="Status*"
        control={control}
        optionList={paymentTypeList}
        useEmptyOption={false}
        rules={{
          required: {
            value: true,
            message: 'Choose status',
          },
        }}
        inputProps={{
          'data-testid': 'paymentStatus',
        }}
        handleChange={({ target }) => {
          setValue('paymentStatus', target.value);
          setValue(
            'paymentIsPaid',
            !!paymentTypeList.find((v) => v.value === target.value)?.dataIsPaid,
          );
        }}
      />

      {!customer.partner && (
        <InputFormField
          name="paymentEmail"
          label="Payment email"
          control={control}
          rules={{
            required: {
              value: isRequiredEmail,
              message: 'Email is invalid',
            },
            validate: {
              isValidEmail: (email: string) =>
                (!isRequiredEmail && !email) || EMAIL_REGEXP.test(email) || 'Email is invalid',
            },
          }}
          inputProps={{
            'data-testid': 'paymentEmail',
          }}
        />
      )}

      <InputFormField
        name="paymentCreate"
        label="Date of the account creation"
        control={control}
        inputProps={{
          'data-testid': 'dateOfAccountCreation',
        }}
        value={getValues('paymentCreate') ? getDateFromUnix(getValues('paymentCreate')) : ''}
        disabled
      />

      <Controller
        name="cancellationDate"
        control={control}
        rules={{
          validate: {
            isValidDate: (date) => {
              if (!customer?.partner || paymentStatus !== 'cancelled' || date) {
                return true;
              } else {
                return 'We need the last date of service';
              }
            },
          },
        }}
        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
          <DatePickerBlock invalid={invalid}>
            <StyledDatePicker
              error={invalid}
              label="Last Date of service"
              value={value}
              onChange={onChange}
              fullWidth
            />
            <DatePickerError text={error?.message ?? ''} opacityColor />
          </DatePickerBlock>
        )}
      />

      <SelectFormField
        name="cancelPauseRequest"
        label="Cancel/Pause request"
        control={control}
        optionList={cancelPauseRequestList}
        useEmptyOption={true}
        rules={{
          required: {
            value: false,
            message: 'Choose request',
          },
        }}
        inputProps={{
          'data-testid': 'cancelPauseRequest',
        }}
        handleChange={({ target }) => {
          setValue('cancellationPauseRequest', target.value);
        }}
      />
      <SelectFormField
        name="cancelPauseOutcome"
        label="Cancel/Pause outcome"
        control={control}
        optionList={cancelPauseOutcomeList}
        useEmptyOption={true}
        rules={{
          required: {
            value: false,
            message: 'Choose outcome',
          },
        }}
        inputProps={{
          'data-testid': 'cancelPauseOutcome',
        }}
        handleChange={({ target }) => {
          setValue('cancellationPauseOutcome', target.value);
        }}
      />
    </Wrapper>
  );
};

export default PaymentInfo;
