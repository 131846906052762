import Modal from '@/common/core/Modal';
import {
  ButtonBlock,
  ButtonCancel,
  ButtonSubmit,
  Container,
  NoteBlock,
  NoteText,
  TitleBlock,
  TitleText,
} from './styles';
import { IModalConfirm } from '@/types';

const ModalConfirm = ({
  title,
  note,
  isOpenConfirmModal,
  toggleOpenConfirmModal,
  handleSumbit,
  textCancel = 'Cancel',
  textSubmit = 'Delete',
}: IModalConfirm) => {
  return (
    <Modal widthMax={327} title="" open={isOpenConfirmModal} handleClose={toggleOpenConfirmModal}>
      <Container>
        <TitleBlock>{title && <TitleText align="center">{title}</TitleText>}</TitleBlock>
        {note && (
          <NoteBlock>
            <NoteText>{note}</NoteText>
          </NoteBlock>
        )}
        <ButtonBlock>
          <ButtonCancel onClick={toggleOpenConfirmModal} variant="outlined">
            {textCancel}
          </ButtonCancel>
          <ButtonSubmit color="error" onClick={handleSumbit}>
            {textSubmit}
          </ButtonSubmit>
        </ButtonBlock>
      </Container>
    </Modal>
  );
};

export default ModalConfirm;
