import { createSlice } from '@reduxjs/toolkit';
import { IProgressFaqInterface } from '@/types';

const initialState: IProgressFaqInterface = {
  loading: false,
  openedDrawer: false,
  showConfirmClose: false,
};

export const progressFaqSlice = createSlice({
  name: 'progressFaq',
  initialState,
  reducers: {
    initFaq: (state, action) => {
      state.openedDrawer = true;
      state.message = action.payload.message;
    },
    resetFaq: (state) => {
      state.message = undefined;
      state.actionId = undefined;
      state.loading = false;
      state.openedDrawer = false;
      state.showConfirmClose = false;
    },
  },
});

export const { initFaq, resetFaq } = progressFaqSlice.actions;

export default progressFaqSlice.reducer;
