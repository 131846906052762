import { useEffect, useState } from 'react';
import { Card, CardActions, CardHeader } from '@mui/material';
import Button from '@/common/core/Button';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { fetchCoaches, resetEditTeam, updateTeam } from '@/store/coaches/coachesSlice';
import { StyledDrawer, StyledDrawerPaper } from '../styles';
import { IEditTeamDrawer } from '@/types';
import TeamForm from '../TeamForm';
import { CoachUser } from '@fitmate-coach/fitmate-types';
import { Base64 } from 'js-base64';

const EditTeamDrawer = ({ open }: IEditTeamDrawer) => {
  const dispatch = useAppDispatch();
  const { teams, coaches, editedTeam } = useAppSelector((state) => state.coaches);

  const [mainCoach, setMainCoach] = useState<CoachUser | undefined>();
  const [supportiveCoaches, setSupportiveCoaches] = useState([]);
  const [filteredCoaches, setFilteredCoaches] = useState<CoachUser[]>([]);
  const [slackChannel, setSlackChannel] = useState('');
  const [presentation, setPresentation] = useState('');
  const [book10MinCall, setBook10MinCall] = useState('');
  const [book20MinCall, setBook20MinCall] = useState('');
  const [elevenLabsApiKey, setElevenLabsApiKey] = useState('');
  const [elevenLabsVoice, setElevenLabsVoice] = useState('');
  const [videoPresentationUrl, setVideoPresentationUrl] = useState('');
  const [actionSpecificGdriveUrl, setActionSpecificGdriveUrl] = useState('');

  useEffect(() => {
    const currentTeam = teams.find((team) => team.id === editedTeam);
    if (currentTeam) {
      const currentMainCoach = coaches.find((c) => currentTeam.mainCoach.id === c.id);
      if (currentMainCoach) {
        setMainCoach(currentMainCoach);
      }
      setSupportiveCoaches(currentTeam.supportiveCoaches);
      setSlackChannel(currentTeam.slackChannel || '');
      setPresentation(currentTeam.presentation || '');
      setBook10MinCall(currentTeam.book10MinCall || '');
      setBook20MinCall(currentTeam.book20MinCall || '');
      setElevenLabsApiKey(
        currentTeam.elevenLabsApiKey ? Base64.decode(currentTeam.elevenLabsApiKey) : '',
      );
      setElevenLabsVoice(currentTeam.elevenLabsVoice || '');
      setVideoPresentationUrl(currentTeam.videoPresentationUrl || '');
      setActionSpecificGdriveUrl(currentTeam.actionSpecificGdriveUrl || '');
    } else {
      console.log('cannot find current team');
    }
  }, [editedTeam]);

  useEffect(() => {
    dispatch(fetchCoaches());
  }, []);

  useEffect(() => {
    if (coaches) {
      setFilteredCoaches(coaches);
    }
  }, [coaches]);

  useEffect(() => {
    if (mainCoach) {
      const filtered = coaches.filter((coach) => coach.id !== mainCoach.id);
      setFilteredCoaches(filtered);
    }
  }, [mainCoach]);

  const handleCancelEdit = () => {
    dispatch(resetEditTeam());
  };

  const handleEditeam = () => {
    if (!editedTeam || !mainCoach) return;
    dispatch(
      updateTeam({
        mainCoach,
        supportiveCoaches,
        editedTeam,
        slackChannel,
        presentation,
        book10MinCall,
        book20MinCall,
        elevenLabsApiKey,
        elevenLabsVoice,
        videoPresentationUrl,
        actionSpecificGdriveUrl,
      }),
    );
  };

  return (
    <StyledDrawer anchor="right" open={open} PaperProps={StyledDrawerPaper}>
      <Card className="min-h-screen overflow-auto">
        <CardHeader title="Edit team" />
        <TeamForm
          mainCoach={mainCoach}
          setMainCoach={setMainCoach}
          filteredCoaches={filteredCoaches}
          supportiveCoaches={supportiveCoaches}
          setSupportiveCoaches={setSupportiveCoaches}
          slackChannel={slackChannel}
          setSlackChannel={setSlackChannel}
          presentation={presentation}
          setPresentation={setPresentation}
          coaches={coaches}
          book10MinCall={book10MinCall}
          setBook10MinCall={setBook10MinCall}
          book20MinCall={book20MinCall}
          setBook20MinCall={setBook20MinCall}
          elevenLabsApiKey={elevenLabsApiKey}
          setElevenLabsApiKey={setElevenLabsApiKey}
          elevenLabsVoice={elevenLabsVoice}
          setElevenLabsVoice={setElevenLabsVoice}
          videoPresentationUrl={videoPresentationUrl}
          setVideoPresentationUrl={setVideoPresentationUrl}
          actionSpecificGdriveUrl={actionSpecificGdriveUrl}
          setActionSpecificGdriveUrl={setActionSpecificGdriveUrl}
        />
        <CardActions>
          <Button variant="contained" color="secondary" onClick={handleCancelEdit}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditeam}
            disabled={!mainCoach || supportiveCoaches.length < 1}
          >
            Update
          </Button>
        </CardActions>
      </Card>
    </StyledDrawer>
  );
};

export default EditTeamDrawer;
