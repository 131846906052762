import { Typography } from '@mui/material';
import { AddRounded, CheckBoxOutlineBlankRounded, CloseRounded } from '@mui/icons-material';
import { AddOption, Close, Icon, StyledInput, Wrapper } from './styles';
import { IAnswerVariant, ISurveyCheckbox } from '@/types';

const Checkbox = ({
  id,
  answerVariants,
  optionHandler,
  deleteHandler,
  addOptionHandler,
  isDisabled,
}: ISurveyCheckbox) => {
  return (
    <>
      {answerVariants.map((variant: IAnswerVariant) => (
        <Wrapper key={variant.answerId}>
          <Icon as={CheckBoxOutlineBlankRounded} />
          <StyledInput
            error={variant.error && variant.answer === ''}
            helperText={variant.error && variant.answer === '' ? 'This option can’t be empty' : ''}
            autoComplete="off"
            onChange={(e) => {
              optionHandler(id, 'answer', e.target.value, variant.answerId);
              optionHandler(id, 'error', false, variant.answerId);
            }}
            value={variant.answer}
            name="question"
            type="text"
            fullWidth
            disabled={isDisabled}
          />
          {!isDisabled && answerVariants.length > 2 && (
            <Close
              as={CloseRounded}
              onClick={() => {
                deleteHandler(variant.answerId);
              }}
            />
          )}
        </Wrapper>
      ))}
      <AddOption
        isDisabled={isDisabled}
        onClick={() => {
          addOptionHandler(id);
        }}
      >
        <AddRounded /> <Typography>Add next option</Typography>
      </AddOption>
    </>
  );
};

export default Checkbox;
