import React, { useState } from 'react';
import DragIndicator from '@/assets/icons/dragIndicator.svg';
import DotsMenu from './DotsMenu';
import { IMenuDragButtons } from '@/types';

const MenuButtons = ({ deleteClickHandler, draggableProvided }: IMenuDragButtons) => {
  const [anchorEditMenuEl, setAnchorEditMenuEl] = useState<null | HTMLElement>(null);

  const toggleCategoryEditMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (anchorEditMenuEl) {
      setAnchorEditMenuEl(null);
    } else {
      setAnchorEditMenuEl(e.currentTarget);
    }
  };

  const hideMenus = () => {
    setAnchorEditMenuEl(null);
  };

  const chooseItem = (e: React.MouseEvent<HTMLElement>, handler: () => void) => {
    e.stopPropagation();
    hideMenus();
    handler();
  };

  return (
    <>
      <div {...draggableProvided.dragHandleProps} onClick={toggleCategoryEditMenu}>
        <img src={DragIndicator} alt="" />
      </div>
      <DotsMenu
        anchorEl={anchorEditMenuEl}
        toggleHandler={toggleCategoryEditMenu}
        deleteClickHandler={(e) => chooseItem(e, deleteClickHandler)}
      />
    </>
  );
};

export default MenuButtons;
