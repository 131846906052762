import { styled } from '@mui/system';
import Button from '@/common/core/Button';

export const AppWrapper = styled('div')({
  position: 'fixed',
  display: 'flex',
  minHeight: '100%',
  minWidth: '100%',
  top: '56px',
});

export const AppContent = styled('div')({
  padding: '32px 0',
  maxHeight: 'calc(100vh - 195px)',
  display: 'flex',
  flexBasis: '100%',
});

export const ButtonGroup = styled('div')({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  padding: '12px 32px 12px 0',
  backgroundColor: '#F9FAFC',
  boxShadow: '0px -1px 0px #E4E7ED',
  justifyContent: 'flex-end',
  display: 'flex',
  gap: 8,
  '& .MuiButton-label': {
    fontSize: 14,
    fontWeight: 600,
  },
  '& .MuiButton-root': {
    boxShadow: 'none',
    borderRadius: 8,
    padding: '12px 36px',
    height: '44px !important',
    minWidth: 'revert',
    margin: 0,
  },
});

export const CancelBtn = styled(Button)({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  backgroundColor: 'transparent',
  color: 'rgba(15, 15, 33, 0.6)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});

export const CreateBtn = styled(Button)({
  padding: '12px 43px',
  marginRight: '28px !important',
  color: 'white',
  boxShadow: 'none',
  border: 'none',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});
