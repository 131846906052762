import { styled } from '@mui/system';
import { FormControl, Select, Typography } from '@mui/material';
import Input from '@/common/core/Input';

export const Row = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
});

export const RowFix = styled(Row)({
  marginTop: '16px',
  marginBottom: '24px',
});

export const FixMargin = styled(RowFix)({
  marginRight: '73px',
});

export const Wrapper = styled('div')({
  padding: '32px',
  marginBottom: '24px',
  background: '#FFFFFF',
  boxShadow: '0px 5px 20px rgba(23, 33, 48, 0.09)',
  borderRadius: '8px',
});

export const QuestionTitle = styled(Typography)({
  marginTop: '8px',
  minWidth: '95px',
  fontWeight: 600,
});

export const InputQuestion = styled(Input)({
  height: 'auto',
  '& .MuiInputBase-input': {
    height: '27px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
});

export const ActionIcons = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '27px',
});

export const Icons = styled('span')({
  margin: '2px 2.5px',
  cursor: 'pointer',
  color: 'rgba(15, 15, 33, 0.2)',
  fontSize: '20px',
});

export const Copy = styled(Icons)({
  fontSize: '16px',
});

export const TypeTitle = styled('div')({
  fontWeight: 600,
  width: '95px',
});

export const SelectQuestion = styled(FormControl)({
  width: '196px',
});

export const StyledSelect = styled(Select)({
  borderRadius: '8px',
  '& .MuiOutlinedInput-input': {
    padding: '14.5px',
  },
});

export const DefaultOptionValue = styled('div')({
  display: 'none',
});
