import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { CenterBlock, Container, LeftBlock } from './styles';
import { fetchCoaches } from '@/store/coaches/coachesSlice';
import { Grid } from '@mui/material';
import ChatHeader from '../Coach/ChatContainer/ChatControl/ChatHeader';
import ClientsList from './ClientsList';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { coaches } = useAppSelector((state) => state.coaches);
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const [isOpenFAQBlock] = useState<boolean>(false);
  const [isExpandedFAQBlock, setIsExpandedFAQBlock] = useState<boolean>(false);
  const [isSearchValueFromMessageUpdated, setIsSearchValueFromMessageUpdated] =
    useState<boolean>(false);

  useEffect(() => {
    if (coaches.length === 0) dispatch(fetchCoaches());
  }, [dispatch, coaches]);

  useEffect(() => {
    setIsExpandedFAQBlock(false);
  }, [isOpenFAQBlock]);

  useEffect(() => {
    if (isSearchValueFromMessageUpdated) {
      const timer = setTimeout(() => {
        setIsSearchValueFromMessageUpdated(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isSearchValueFromMessageUpdated]);

  return (
    <Container isToggleApiToken={!!coachData?.toggleApiToken}>
      {!isExpandedFAQBlock && (
        <LeftBlock>
          <Grid item xs={12}>
            <ChatHeader />
          </Grid>
        </LeftBlock>
      )}
      <CenterBlock>
        <ClientsList />
      </CenterBlock>
    </Container>
  );
};

export default Dashboard;
