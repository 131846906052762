import { styled } from '@mui/system';
import { Select, Typography } from '@mui/material';
import Input from '@/common/core/Input';
import TimeIcon from '@mui/icons-material/Timer';
import Button from '@/common/core/Button';

export const Container = styled('div')({});

export const ElementBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ElementSideBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  marginRight: 5,
});

export const ButtonBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: 20,
});

export const TitleBlock = styled('div')({
  marginBottom: 5,
});

export const NoteBlock = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  paddingTop: 15,
  paddingBottom: 12,
});

export const StyledInput = styled(Input)({
  '& .MuiFormLabel-root': {
    fontSize: 14,
    fontWeight: 400,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 8,
    },
  },
});

export const StyledSelect = styled(Select)({
  width: 315,
  borderRadius: 8,
});

export const TitleText = styled(Typography)({
  fontSize: 18,
  fontWeight: 600,
  color: '#0F0F21',
});

export const NoteText = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
});

export const StyledTimeIcon = styled(TimeIcon)({
  fontSize: 22,
  color: 'rgba(15, 15, 33, 0.4)',
});

export const ButtonSubmit = styled(Button)({
  fontSize: 14,
  fontWeight: 600,
  width: '48%',
  height: 44,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: 'rgb(93, 94, 160)',
  },
});

export const ButtonCancel = styled(ButtonSubmit)({
  color: 'rgba(15, 15, 33, 0.6)',
  '&:hover': {
    border: '1px solid #8587E5',
    backgroundColor: 'rgba(133, 135, 229, 0.04)',
  },
});
