import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { maximumTaskTime } from '@/config/timer';
import { Timer } from './styles';
import { ICounter } from '@/types';

const Counter = ({ durationInSec }: ICounter) => {
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  useEffect(() => {
    const localMinutes = Math.floor(durationInSec / 60) || 0;
    setMinutes(localMinutes);
    setSeconds(durationInSec - localMinutes * 60 || 0);
  }, [durationInSec]);

  return (
    <Timer isMaxDuration={durationInSec > maximumTaskTime}>
      <Typography component="div">
        {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </Typography>
    </Timer>
  );
};

export default Counter;
