import { calculateAge } from '@/utils/format/datetime';
import { calculateFullTotalCaloriesTarget } from '@fitmate-coach/fitmate-nutrition-table';
import { ClientUser, ESex } from '@fitmate-coach/fitmate-types';

export function isNutritionTargetValid(caloriesTarget: string) {
  return parseInt(caloriesTarget) >= 1000 && parseInt(caloriesTarget) <= 3500;
}

export function calculateNutritionsTarget(customer: ClientUser, weight: number) {
  const age = calculateAge(customer.dateOfBirth ?? 0);
  return calculateFullTotalCaloriesTarget(
    customer.gender as ESex,
    weight,
    customer.height as number,
    age,
    customer.activities?.lowIntensityCardioMinutes as number,
    customer.activities?.moderateIntensityCardioMinutes as number,
    customer.activities?.vigorousIntensityCardioMinutes as number,
    customer.activities?.resistanceTrainingMinutes as number,
    customer.activities?.resistanceTraining2Plus as boolean,
  );
}
