import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Button from '@/common/core/Button';

export const Wrapper = styled('div')({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const TitleBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingTop: 47,
  paddingBottom: 27,
  paddingLeft: 47,
  paddingRight: 47,
});

export const TitleText = styled('div')({
  fontWeight: 'bold',
  fontSize: 40,
  lineHeight: '48px',
  color: '#000000',
});

export const Title2Block = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  paddingTop: 20,
  paddingBottom: 0,
});

export const InputBlock = styled('div')({
  display: 'flex',
  width: 290,
  paddingTop: 8,
  paddingBottom: 8,
});

export const ButtonBlock = styled('div')({
  paddingTop: 50,
});

export const ForgotBlock = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 34,
});

export const Title2Text = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  color: '#000',
});

export const UploadLinkText = styled('div')(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '24px',
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  paddingLeft: 16,
  cursor: 'pointer',
}));

export const ForgotText = styled('div')({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: '24px',
  color: '#000000',
});

export const ErrorText = styled('div')({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '20px',
  color: 'red',
});

export const LinkText = styled('div')({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '20px',
  cursor: 'pointer',
});

export const NavLink = styled('div')({
  textDecoration: 'none',
  color: 'black',
});

export const StyledButton = styled(Button)({
  width: 290,
  height: 48,
  fontSize: 14,
  fontWeight: 600,
  borderRadius: 8,
  margin: 0,
});
