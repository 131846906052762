import { useState } from 'react';
import Modal from '@/common/core/Modal';
import { Tooltip } from '@mui/material';
import { Container, StyledDivider } from './styles';
import { ISimilarityModal } from '@/types';
import BottomButtons from './BottomButtons';
import AnswersList from './AnswerList';
import Header from './Header';
import HelperTooltipContent from './HelperTooltipContent';

const SimilarityModal = ({
  isOpen,
  onHandleClose,
  onSendMessage,
  documents,
  currentMessage = '',
}: ISimilarityModal) => {
  const [openHelper, setOpenHelper] = useState(false);

  const handleClose = () => {
    setOpenHelper(false);
    onHandleClose();
  };

  return (
    <Modal
      open={isOpen}
      shouldUseCustomChildren
      minHeight={360}
      widthMax={370}
      handleClose={handleClose}
    >
      <Tooltip
        title={<HelperTooltipContent handleClose={() => setOpenHelper(false)} />}
        sx={{
          '& .MuiTooltip-popper': {
            top: '-20px !important',
            pointerEvents: 'auto',
            '& .MuiTooltip-arrow': {
              top: '50px !important',
              color: '#FFFFFF',
              width: '12px !important',
              height: '19px !important',
            },
          },
          '& .MuiTooltip-tooltipPlacementLeft': {
            '& .MuiTooltip-arrow': {
              right: '-5px !important',
            },
          },
          '& .MuiTooltip-tooltipPlacementRight': {
            '& .MuiTooltip-arrow': {
              left: '-5px !important',
            },
          },
          '& .MuiTooltip-tooltip': {
            minWidth: 310,
            backgroundColor: '#FFFFFF',
            borderRadius: 7,
            color: '#0F0F21',
            boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.176)',
            border: '1px solid #E4E7ED',
          },
        }}
        placement="right-start"
        arrow
        open={openHelper}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <Container>
          <Header handleOpen={() => setOpenHelper(!openHelper)} />
          <AnswersList documents={documents ?? []} currentMessage={currentMessage} />
          <StyledDivider />
          <BottomButtons onHandleClose={handleClose} onHandleSubmit={onSendMessage} />
        </Container>
      </Tooltip>
    </Modal>
  );
};

export default SimilarityModal;
