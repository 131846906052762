import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@/common/core/Menu';
import { Delete, StyledMenuItem, SubMenu } from '../ResourcesCategory/CommonStyles';
import { IThreeDotsMenu } from '@/types';
import { StyledDeleteIcon } from '@/styles/dotsMenu';

const ThreeDotsMenu = ({
  anchorEl,
  toggleHandler,
  renameClickHandler,
  deleteClickHandler,
  editTitle,
}: IThreeDotsMenu) => {
  return (
    <Menu anchorEl={anchorEl} toggleMenu={toggleHandler} vertical="bottom">
      <SubMenu onClick={renameClickHandler}>
        <StyledMenuItem>{editTitle}</StyledMenuItem>
        <EditIcon />
      </SubMenu>
      <SubMenu onClick={deleteClickHandler}>
        <Delete>Delete</Delete>
        <StyledDeleteIcon as={DeleteIcon} />
      </SubMenu>
    </Menu>
  );
};

export default ThreeDotsMenu;
