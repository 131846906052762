import { IListIcon, IListItem } from '@/types';
import {
  dairySvg,
  glutenSvg,
  NutSvg,
  oneLineSvg,
  VeganSvg,
  VegetarianSvg,
  twoLineSvg,
} from '../templates/cutomIcon';
import { dairyImg, glutenImg, nutImg, veganImg, vegetarianImg } from '../templates/images';

//Name of custom toolbar button
export const degreesButtonTitle = 'degreesButtonTitle';
export const fractionsButtonTitle = 'fractionsButtonTitle';
export const oneLineButtonTitle = 'oneLineButtonTitle';
export const twoLineButtonTitle = 'twoLineButtonTitle';
export const customIconButtonTitle = 'customIconButtonTitle';

//Custom SVG name
export const oneLineIconTitle = 'oneLineSvg';
export const twoLineIconTitle = 'twoLineSvg';
export const dairyIconTitle = 'dairy';
export const glutenIconTitle = 'gluten';
export const nutIconTitle = 'nut';
export const veganIconTitle = 'vegan';
export const vegetarianIconTitle = 'vegetarian';

//Custom icon list for registry in tinyMCE
export const IconList: IListIcon[] = [
  { name: oneLineIconTitle, svg: oneLineSvg },
  { name: twoLineIconTitle, svg: twoLineSvg },
  { name: dairyIconTitle, svg: dairySvg },
  { name: glutenIconTitle, svg: glutenSvg },
  { name: nutIconTitle, svg: NutSvg },
  { name: veganIconTitle, svg: VeganSvg },
  { name: vegetarianIconTitle, svg: VegetarianSvg },
];

//Custom image list
export const customIconList: IListItem[] = [
  {
    type: 'choiceitem',
    icon: dairyIconTitle,
    text: 'Dairy-free',
    value: dairyImg,
  },
  {
    type: 'choiceitem',
    icon: glutenIconTitle,
    text: 'Gluten',
    value: glutenImg,
  },
  {
    type: 'choiceitem',
    icon: nutIconTitle,
    text: 'Nut-Free',
    value: nutImg,
  },
  {
    type: 'choiceitem',
    icon: veganIconTitle,
    text: 'Vegan',
    value: veganImg,
  },
  {
    type: 'choiceitem',
    icon: vegetarianIconTitle,
    text: 'Vegetarian',
    value: vegetarianImg,
  },
];

export const degreesTypeList: IListItem[] = [
  {
    type: 'choiceitem',
    text: '°F',
    value: '&deg;F',
  },
  {
    type: 'choiceitem',
    text: '°C',
    value: '&deg;C',
  },
];

export const fractionsTypeList: IListItem[] = [
  {
    type: 'choiceitem',
    text: '½',
    value: '&frac12;',
  },
  {
    type: 'choiceitem',
    text: '¹⁄3',
    value: '&sup1;&frasl;3',
  },
  {
    type: 'choiceitem',
    text: '²⁄3',
    value: '&sup2;&frasl;3',
  },
  {
    type: 'choiceitem',
    text: '¼',
    value: '&frac14;',
  },
  {
    type: 'choiceitem',
    text: '¾',
    value: '&frac34;',
  },
];
