import { collection, getDocs, query, where } from '@firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';
import { endOfDay, isSameDay, startOfDay, subDays } from 'date-fns';
import { EBannerType, Message } from '@fitmate-coach/fitmate-types';
import { toast } from 'sonner';
import { orderBy } from 'firebase/firestore';

const processQuery = async (q: any) => {
  const querySnapshot = await getDocs(q);
  return querySnapshot.size;
};

export const getUserByStatus = (status: string) => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('billingData.status', '==', status));
  return processQuery(q);
};

export const getTotalTeams = () => {
  const usersRef = collection(db, 'teams');
  const q = query(usersRef);
  return processQuery(q);
};

export const getCoachOnShift = () => {
  const coachRef = collection(db, 'users');
  const q = query(coachRef, where('role', '==', 'coach'), where('shiftStarted', '==', true));
  return processQuery(q);
};

export const getMsgPerCoach = async (when: string): Promise<number> => {
  let from;
  let to;
  if (when === 'yesterday') {
    from = startOfDay(subDays(new Date(), 1));
    to = endOfDay(subDays(new Date(), 1));
  } else if (when === 'today') {
    const today = new Date();
    from = startOfDay(today);
    to = endOfDay(today);
  } else {
    from = startOfDay(subDays(new Date(), 7));
    to = endOfDay(subDays(new Date(), 1));
  }

  const msgRef = collection(db, 'messages');
  const q = query(msgRef, where('createdAt', '>=', from), where('createdAt', '<=', to));

  const querySnapshot = await getDocs(q);
  const messages: Message[] = querySnapshot.docs.map((doc) => doc.data() as Message);
  const coachMessages = messages.filter(
    (msg: Message) => msg.userId !== msg.userIdFrom && msg.userRoleFrom !== 'chat_bot',
  );

  // get for each coach how many msg
  const coachIds: string[] = [];
  coachMessages.map(
    (msg: Message) => !coachIds.includes(msg.userIdFrom) && coachIds.push(msg.userIdFrom),
  );
  let stats: { coachId?: string; total?: number }[] = [];
  for (const msg of coachMessages) {
    const currentCoachId = msg.userIdFrom;
    const otherCoaches = stats.filter((stat) => stat.coachId !== currentCoachId);
    const coachStat = stats.find((stat) => stat.coachId === currentCoachId);
    if (!coachStat) {
      stats = [...stats, { coachId: currentCoachId, total: 1 }];
    } else {
      stats = [
        ...otherCoaches,
        {
          coachId: currentCoachId,
          total: coachStat.total ? coachStat.total + 1 : 1,
        },
      ];
    }
  }
  const divider = stats.length;
  if (divider === 0) return 0;
  const sum: number = stats.reduce((acc, stat) => acc + (stat.total ?? 0), 0);
  return Math.round(sum / divider);
};

const getCurrentOnboardingFlow = async () => {
  const flowRef = collection(db, 'copilot_flows');
  const q = query(flowRef, where('isDefaultOnboarding', '==', true));
  const querySnapshot = await getDocs(q);
  const flows = querySnapshot.docs.map((doc) => doc.id);
  return flows[0] ?? null;
};

export const getActiveOnboardings = async () => {
  const flow = await getCurrentOnboardingFlow();
  if (!flow) {
    toast.error('No onboarding flow found');
    return 0;
  }
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('currentCopilotFlow', '==', flow));
  const querySnapshot = await getDocs(q);
  const users = querySnapshot.docs.map((doc) => doc.data());
  return users.filter((user) => user.billingData.status !== 'cancelled').length;
};

export const getUpcomingEowReviews = async (type: string) => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('showBanner', '==', type));
  const querySnapshot = await getDocs(q);
  const users = querySnapshot.docs.map((doc) => doc.data());
  return users.filter((user) => user.billingData.status !== 'cancelled').length;
};

export const getCurrentProgramWeek = async (userId: string) => {
  const usersRef = collection(db, 'users', userId, 'plans');
  const q = query(usersRef, orderBy('endDate', 'desc'));
  const querySnapshot = await getDocs(q);
  const plans = querySnapshot.docs.map((doc) => doc.data());
  return plans.length > 0 ? plans[0] : null;
};

export const getOverDueEowReviews = async () => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('showBanner', '==', EBannerType.ACTIVE_EOW));
  const querySnapshot = await getDocs(q);
  const users = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  let overdue = 0;
  for (const user of users) {
    const plan = await getCurrentProgramWeek(user.id);
    if (!plan) continue;
    const today = new Date();
    if (!isSameDay(today, plan.endDate.toDate())) overdue++;
  }
  return overdue;
};
