import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@/common/core/Menu';
import { Delete, StyledDeleteIcon, SubMenu } from '@/styles/dotsMenu';
import { IDotsMenuExtension } from '@/types';

const DotsMenu = ({ anchorEl, toggleHandler, deleteClickHandler }: IDotsMenuExtension) => {
  return (
    <Menu anchorEl={anchorEl} toggleMenu={toggleHandler} vertical="bottom">
      <SubMenu onClick={deleteClickHandler}>
        <Delete>Delete</Delete>
        <StyledDeleteIcon as={DeleteIcon} />
      </SubMenu>
    </Menu>
  );
};

export default DotsMenu;
