import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@/hooks';
import { clearQuotedMessage } from '@/store/chat/quotedMessageSlice';
import { getCoachWatchingCustomer } from '@/store/customers/watcherSlice';
import { fetchCustomerById } from '@/store/customers/customerInfosSlice';
import { EChatPageType, IMessageEdited, IMessageFileEdited } from '@/types';
import ChatContent from '../ChatContent';

const ChatContentContainer = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [pageType, setPageType] = useState<string>(EChatPageType.CONVERSATION);
  const [files, setFiles] = useState<any[]>([]);
  const [messageEdited, setMessageEdited] = useState<IMessageEdited | null>(null);
  const [messageFileEdited, setMessageFileEdited] = useState<IMessageFileEdited | null>(null);

  useEffect(() => {
    dispatch(fetchCustomerById(id));
    dispatch(getCoachWatchingCustomer());
  }, []);

  useEffect(() => {
    handleChangeFiles([]);
    dispatch(clearQuotedMessage());
  }, [id]);

  const handlePageType = (value: string) => {
    setPageType(value);
  };

  const handleChangeFiles = (filesList: any[]) => {
    setFiles(filesList);
  };

  const handleDeleteFile = (fileId: string) => {
    const filesNew = files.filter((file: any) => file.id !== fileId);
    setFiles(filesNew);
  };

  const handleMessageEdited = (value: IMessageEdited | null) => {
    setMessageEdited(value);
  };

  const handleMessageFileEdited = (value: IMessageFileEdited | null) => {
    setMessageFileEdited(value);
  };

  return (
    <ChatContent
      pageType={pageType}
      handlePageType={handlePageType}
      files={files}
      handleChangeFiles={handleChangeFiles}
      handleDeleteFile={handleDeleteFile}
      messageEdited={messageEdited}
      messageFileEdited={messageFileEdited}
      handleMessageEdited={handleMessageEdited}
      handleMessageFileEdited={handleMessageFileEdited}
    />
  );
};

export default ChatContentContainer;
