/* eslint-disable indent */
import React, { useState, useCallback } from 'react';
import { useChatTags } from '@/hooks/chat/useChatTags';
import {
  AutocompleteBlock,
  ButtonBlock,
  ButtonDone,
  ChipChosen,
  ChipPossible,
  Container,
  ContentBlock,
  SelectAddTagBlock,
  SelectAddTagChip,
  SelectAddTagText,
  SelectBlock,
  SelectTagBlock,
  SelectTagText,
  StyledInput,
  TagsChosenBlock,
  TagsPossibleBlock,
  TagsPossibleTitleBlock,
  TagsPossibleTitleText,
  TitleBlock,
  TitleText,
} from './styles';
import { IChatMessageTag, IChatMessageTags } from '@/types';

const MIN_NUMBER_CHARS_IN_TAG = 2;
const MAX_NUMBER_ITEMS_IN_DROPDOWN = 10;
const MAX_NUMBER_ITEMS_IN_LIST = 30;

const ChatMessageTags = ({
  messageIdTagsCurrent,
  handleMessageIdTagsCurrent,
}: IChatMessageTags) => {
  const [tagValue, setTagValue] = useState<string>('');
  const [tagsChosenList, setTagsChosenList] = useState<string[]>([]);
  const { tagsPossibleList, tagsList, addTag, deleteTag, createTag } = useChatTags(
    messageIdTagsCurrent ?? '',
  );
  const checkIfTagIsOriginal = useCallback(
    (tag: string) => {
      const index = tagsPossibleList.findIndex((item: IChatMessageTag) => item.tag === tag);
      return index === -1 ? true : false;
    },
    [tagsPossibleList],
  );

  const checkIfTagIsUsed = useCallback(
    (tag: string) => {
      const index = tagsList.findIndex((item: string) => item === tag);
      return index === -1 ? true : false;
    },
    [tagsList],
  );

  const handleTagValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tagValueNew = e.target.value;
    setTagValue(tagValueNew.toLowerCase());
  };

  const handleTagCreate = () => {
    handleTagAdd(tagValue);
    createTag(tagValue.trim());
    setTagValue('');
  };

  const handleTagAdd = (value: string) => {
    if (value) {
      setTagValue('');
      const index = tagsChosenList.findIndex((tag: string) => tag === value);
      if (index === -1) {
        addTag(value);
      }
    }
  };

  const handleTagDelete = (value: string) => {
    const tagsChosenListNew = tagsChosenList.filter((tag: string) => tag !== value);
    setTagsChosenList(tagsChosenListNew);
    deleteTag(value);
  };

  return (
    <Container>
      <ContentBlock>
        <TitleBlock>
          <TitleText>Tags</TitleText>
        </TitleBlock>
        <AutocompleteBlock>
          <StyledInput
            value={tagValue}
            onChange={handleTagValue}
            autoComplete="off"
            label={tagValue ? 'Type your tags here' : ''}
            placeholder="Type your tags here"
            helperText="Select a tag or create one"
            variant="outlined"
            type="text"
            // InputLabelProps={{
            //   className: classes.inputLabel,
            //{/*}}*/}
            // InputProps={{
            //   className: classes.input,
            //   classes: {
            //     root: classes.input,
            //     focused: classes.inputFocused,
            //     notchedOutline: classes.inputNotchedOutline,
            //*},*/}
            //*}}*/}
            // FormHelperTextProps={{ className: classes.inputHelper }}
          />
          {checkIfTagIsUsed(tagValue) &&
            (tagValue.trim().length > MIN_NUMBER_CHARS_IN_TAG - 1 ||
              (tagValue.trim().length > 0 &&
                tagsPossibleList.filter((item: IChatMessageTag) => {
                  const tagValueLower = tagValue.toLowerCase();
                  const tagLower = item.tag.toLowerCase();
                  return (
                    tagLower.indexOf(tagValueLower) !== -1 &&
                    tagsList.findIndex((tag: string) => tag === item.tag) === -1
                  );
                }).length !== 0)) && (
              <SelectBlock>
                {tagValue.trim().length > MIN_NUMBER_CHARS_IN_TAG - 1 &&
                  checkIfTagIsOriginal(tagValue.trim()) && (
                    <SelectAddTagBlock>
                      <SelectAddTagText>Create</SelectAddTagText>
                      <SelectAddTagChip
                        label={tagValue}
                        variant="outlined"
                        onClick={handleTagCreate}
                      />
                    </SelectAddTagBlock>
                  )}
                {tagsPossibleList
                  .filter((item: IChatMessageTag) => {
                    const tagValueLower = tagValue.toLowerCase();
                    const tagLower = item.tag.toLowerCase();

                    return (
                      tagLower.indexOf(tagValueLower) !== -1 &&
                      tagsList.findIndex((tag: string) => tag === item.tag) === -1
                    );
                  })
                  .filter((_, index: number) => index < MAX_NUMBER_ITEMS_IN_DROPDOWN)
                  .map((item: IChatMessageTag) => (
                    <SelectTagBlock key={item.tag}>
                      <SelectTagText onClick={() => handleTagAdd(item.tag)}>
                        {item.tag}
                      </SelectTagText>
                    </SelectTagBlock>
                  ))}
              </SelectBlock>
            )}
        </AutocompleteBlock>
        <TagsChosenBlock>
          {tagsList
            .sort((a: string, b: string) => a.localeCompare(b))
            .map((tag: string, index) => (
              <ChipChosen
                key={`${tag}${index}`}
                label={tag}
                onDelete={() => handleTagDelete(tag)}
                variant="outlined"
              />
            ))}
        </TagsChosenBlock>
        <TagsPossibleTitleBlock>
          <TagsPossibleTitleText>Suggested tags</TagsPossibleTitleText>
        </TagsPossibleTitleBlock>
        <TagsPossibleBlock>
          {tagsPossibleList
            .filter(
              (item: IChatMessageTag) =>
                tagsList.findIndex((tag: string) => tag === item.tag) === -1 &&
                !item?.isAddedByCoach,
            )
            .sort((a: IChatMessageTag, b: IChatMessageTag) => a.tag.localeCompare(b.tag))
            .filter((_, index: number) => index < MAX_NUMBER_ITEMS_IN_LIST)
            .map((item: IChatMessageTag) => (
              <ChipPossible
                key={item.tag}
                label={item.tag}
                variant="outlined"
                onClick={() => handleTagAdd(item.tag)}
              />
            ))}
        </TagsPossibleBlock>
      </ContentBlock>
      <ButtonBlock>
        <ButtonDone onClick={() => handleMessageIdTagsCurrent(null)}>Done</ButtonDone>
      </ButtonBlock>
    </Container>
  );
};

export default ChatMessageTags;
