import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/utils/firebase/firebaseInit';
import { IFocusAreaHandout, IHandout } from '@/types';

const FocusAreaHandout = ({ handoutId }: IFocusAreaHandout) => {
  const [handout, setHandout] = useState({} as IHandout);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const docRef = doc(db, 'handouts', handoutId);
    getDoc(docRef).then((docSnap) => {
      setHandout({ id: docSnap.id, ...docSnap.data() } as IHandout);
    });
  }, []);

  if (!handout) return null;

  return (
    <Typography>
      <b>Handout:</b> {handout.title}
    </Typography>
  );
};

export default FocusAreaHandout;
