import { ETypeMeal, Table } from '@fitmate-coach/fitmate-nutrition-table';
import { assignNutritionTargetsToCustomer } from '@/store/customers/customerInfosSlice';
import { Timestamp } from '@firebase/firestore';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { formatDistanceToNow } from 'date-fns';
import { useAppDispatch, useAppSelector } from '@/hooks';
import InputFormField from '@/common/core/FormElements/InputFormField';
import { isNutritionTargetValid } from '../utils';
import { StyledButton } from '../styles';
import { ClientUser, ENutritionCaloriesTarget, ESex } from '@fitmate-coach/fitmate-types';

interface RefinedProps {
  customer: ClientUser;
}

interface IFormCaloriesTarget {
  caloriesTarget: string;
}

function Refined({ customer }: RefinedProps) {
  const dispatch = useAppDispatch();
  const { isReadOnly } = useAppSelector((state) => state.customerInfos);

  const caloriesForm = useForm<IFormCaloriesTarget>({
    defaultValues: {
      caloriesTarget:
        customer.nutritionTargets?.caloriesTarget !== 0
          ? customer.nutritionTargets?.caloriesTarget?.toString()
          : '',
    },
    mode: 'onChange',
  });

  const { handleSubmit, getValues, control } = caloriesForm;
  const { isValid, isSubmitting } = caloriesForm.formState;

  const onSubmit: SubmitHandler<IFormCaloriesTarget> = async (form: IFormCaloriesTarget) => {
    const caloriesTarget = Number(form.caloriesTarget);

    dispatch(
      assignNutritionTargetsToCustomer({
        id: customer.id,
        nutritionTargets: {
          ...customer.nutritionTargets,
          caloriesTarget: caloriesTarget,
          lastPublishedAt: Timestamp.fromDate(new Date()), // can be hooked
        },
      }),
    );
  };

  return (
    <div>
      <div className="flex flex-row">
        <FormProvider {...caloriesForm}>
          <form id="calories-form" className="flex gap-2" onSubmit={handleSubmit(onSubmit)}>
            <InputFormField
              name="caloriesTarget"
              label="Calories Target"
              type="text"
              fullWidth={false}
              control={control}
              disabled={isReadOnly}
              mb={0}
              rules={{
                required: true,
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Input must be a number.',
                },
                validate: (value: string) => {
                  if (!isNutritionTargetValid(value)) {
                    return 'Calorie target needs should be between 1000 and 3500 calories.';
                  }

                  return true;
                },
              }}
              inputProps={{
                inputMode: 'numeric',
                min: 1000,
                max: 3500,
              }}
            />

            <StyledButton
              size="lg"
              variant={'solid'}
              disabled={
                isReadOnly ||
                !isValid ||
                isSubmitting ||
                customer.nutritionTargets?.caloriesTarget.toString() === getValues('caloriesTarget')
              }
            >
              Update
            </StyledButton>
          </form>
          <div className="flex mt-4 ml-2">
            {customer.nutritionTargetsType === ENutritionCaloriesTarget.refined_calorie_target &&
              customer.nutritionTargets?.lastPublishedAt &&
              `Last published ${formatDistanceToNow(
                customer.nutritionTargets?.lastPublishedAt?.toDate(),
                { addSuffix: true },
              )}`}
          </div>
        </FormProvider>
      </div>
      <div className="pt-5">
        {customer.nutritionTargets?.lastPublishedAt && (
          <Table
            focusArea={[ETypeMeal.DailyTotal]}
            type={ENutritionCaloriesTarget.refined_calorie_target}
            customerSex={customer.nutritionTargets.gender as ESex}
            lastUpdatedAt={customer.nutritionTargets.lastPublishedAt?.toDate()}
            targetCalories={customer.nutritionTargets.caloriesTarget}
          />
        )}
      </div>
    </div>
  );
}

export default Refined;
