import { styled } from '@mui/system';
import Input from '@/common/core/Input';

export const AddSubTitle = styled(Input)({
  fontWeight: 600,
  fontSize: 18,
  color: 'black',
  marginBottom: 12,
  height: 'revert',
  '&.MuiFormControl-root': {
    width: '100%',
  },
  '&.MuiFormControl-marginNormal': {
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: 'transparent',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-input::placeholder': {
    color: 'rgba(15, 15, 33, 0.2)',
  },
  '& .MuiOutlinedInput-input': {
    paddingRight: 0,
    paddingLeft: 0,
    marginBottom: 0,
    backgroundColor: 'transparent',
  },
});
