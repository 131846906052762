import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Edit, CheckBoxRounded } from '@mui/icons-material';
import {
  AnswerTitle,
  EditIcon,
  Icon,
  LocalWrapper,
  Question,
  QuestionText,
  Row,
  TypeTitle,
  VariantIcon,
  VariantsListWrapper,
  VariantWrapper,
} from '../styles';
import { ICheckboxComponentSurvey, ICheckboxSurveyValueAnswers } from '@/types';

const CheckboxComponent = ({
  indexStage,
  data,
  isEditable,
  suerveyId,
  handleEditSurvey,
}: ICheckboxComponentSurvey) => {
  const { id, question, type, answer, answerVariants, title } = data;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [valueAnswers, setValueAnswers] = useState<ICheckboxSurveyValueAnswers[]>([]);

  useEffect(() => {
    const temp: any[] = [];
    answerVariants &&
      answerVariants.forEach((variant) => {
        if (answer?.answers.includes(variant.answer)) {
          temp.push({ text: variant.answer, checked: true });
        } else {
          temp.push({ text: variant.answer, checked: false });
        }
      });
    setValueAnswers(temp);
  }, []);

  const handleClick = (index: number, checked: boolean) => {
    const copy = Array.from(valueAnswers);
    copy[index].checked = checked;
    setValueAnswers(copy);
    if (handleEditSurvey) {
      handleEditSurvey(
        suerveyId || '',
        indexStage || 0,
        id,
        copy.filter((i) => !!i.checked).map((i) => i.text),
      );
    }
  };

  return (
    <Question>
      <Row>
        <LocalWrapper>
          <Icon as={CheckBoxRounded} />
          <QuestionText>
            {title} - {question}
          </QuestionText>
        </LocalWrapper>
        {isEditable && <EditIcon as={Edit} onClick={() => setEditMode(true)} />}
      </Row>
      <Row>
        <LocalWrapper>
          <TypeTitle>Type</TypeTitle>
          <Typography>{type}</Typography>
        </LocalWrapper>
      </Row>
      <Row>
        <LocalWrapper>
          <AnswerTitle>Answers</AnswerTitle>
          <VariantsListWrapper>
            {valueAnswers &&
              valueAnswers.map((item, index) => (
                <VariantWrapper key={index}>
                  <VariantIcon
                    disabled={!editMode}
                    checked={item.checked}
                    onClick={() => handleClick(index, !item.checked)}
                  />
                  <Typography>{item.text}</Typography>
                </VariantWrapper>
              ))}
          </VariantsListWrapper>
        </LocalWrapper>
      </Row>
    </Question>
  );
};

export default CheckboxComponent;
