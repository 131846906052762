import { useState, useEffect } from 'react';
import { db } from '@/utils/firebase/firebaseInit';
import { IAdminUser } from '@/types';
import { fromMapToObject } from '../service/adminClientsCoachesAssignmentService';
import { onSnapshot, query, collection, where, updateDoc, doc } from 'firebase/firestore';

export const useAdminClients = () => {
  const [usersList, setUsersList] = useState<IAdminUser[]>([]);

  useEffect(() => {
    const q = query(collection(db, 'users'), where('role', '==', 'user'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const users = querySnapshot.docs.map((doc) => {
        const user = doc.data();
        return {
          id: doc.id,
          firstName: user.firstName ?? '',
          lastName: user.lastName ?? '',
          email: user.email,
          timezone: user.timezone ?? '',
          gender: user.gender ?? '',
          location: user.location ?? '',
          dateOfBirth: user.dateOfBirth ?? 0,
          familyStatus: user.familyStatus ?? '',
          dietaryPreferences: user.dietaryPreferences ?? '',
          occupation: user.occupation ?? '',
          height: user.height ?? 0,
          weight: user.weight ?? 0,
          otherMemorableInfo: user.otherMemorableInfo ?? '',
          healthGoals: user.healthGoals ?? '',
          createdAt: user.createdAt,
          coachAssignment: fromMapToObject(user.coachAssignment || []),
          partner: user.partner ?? null,
          diabetes: user.diabetes ?? 0,
          glp1drugs: user.glp1drugs ?? false,
          partnerData: user.partnerData ?? null,
          appVersion: user.appVersion ?? undefined,
        };
      });
      const filteredUsers = users.filter((user) => user.appVersion !== undefined);
      setUsersList(filteredUsers);
    });
    return () => unsubscribe();
  }, []);

  const editUserActivity = async (userId: string, isActiveNew: boolean) => {
    await updateDoc(doc(db, 'users', userId), {
      isActive: isActiveNew,
    });
  };

  return {
    usersList,
    editUserActivity,
  };
};
