import { styled } from '@mui/system';
import ImageUploader from '@/common/core/ImageUploader';

export const Thumb = styled(ImageUploader)(({ theme }) => ({
  width: '100%',
  height: 119,
}));

export const Label = styled('span')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '200%',
  color: '#000000',
}));
