import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@/common/core/Button';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { toggleFrequencyModal } from '@/store/customers/bodyProfileSlice';
import { updateWeighCustomer } from '@/store/customers/customersSlice';

const ChatProgramProfileFrequencyModal = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customerInfos);
  const { showFrequencyModal } = useAppSelector((state) => state.bodyProfile);
  const [frequency, setFrequency] = useState(customer?.weighInFrequency);
  const [frequencyNotes, setFrequencyNotes] = useState(customer?.weighInFrequencyNotes);
  const handleClose = () => dispatch(toggleFrequencyModal());
  return (
    <Dialog
      open={showFrequencyModal}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          if (!customer?.id) return;
          dispatch(updateWeighCustomer({ userId: customer?.id, frequency, frequencyNotes }));
          handleClose();
        },
      }}
    >
      <DialogTitle>Weigh-in frequency</DialogTitle>
      <DialogContent>
        <DialogContentText>Edit weigh-in frequency infos.</DialogContentText>
        <div className="flex flex-col">
          <Select fullWidth value={frequency} onChange={(e) => setFrequency(e.target.value)}>
            <MenuItem value="Not set">Not set</MenuItem>
            <MenuItem value="Weekly or more">Weekly or more</MenuItem>
            <MenuItem value="Less than weekly">Less than weekly</MenuItem>
            <MenuItem value="Prefers not to log">Prefers not to log</MenuItem>
          </Select>
          <label>Frequency notes:</label>
          <TextField
            multiline
            rows={2}
            placeholder="Weigh-in frequency notes"
            value={frequencyNotes}
            onChange={(e) => setFrequencyNotes(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button type="submit">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatProgramProfileFrequencyModal;
