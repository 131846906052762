import { FormControl, InputLabel } from '@mui/material';
import { EFeatureSelectedType } from '@/types';
import SelectOptionsWrapper from '@/common/core/SelectOptionsWrapper';
import { YesNoList } from '@/utils/data/question';
import Wrapper from '../Wrapper';
import { IVideoCall } from '@/types/videoCall';
import { SelectBlock, StyledSelect } from '../styles';

const VideoIntroCall = ({ isCallCompleted, setCallCompleted }: IVideoCall) => {
  return (
    <Wrapper blockTitle="Video calls">
      <SelectBlock>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Completed intro call</InputLabel>
          <StyledSelect
            // variant="standard"
            native
            autoWidth
            value={isCallCompleted ? EFeatureSelectedType.enabled : EFeatureSelectedType.disabled}
            onChange={({ target }) => {
              setCallCompleted(target.value === EFeatureSelectedType.enabled);
            }}
            label="Completed intro call"
          >
            <SelectOptionsWrapper items={YesNoList} />
          </StyledSelect>
        </FormControl>
      </SelectBlock>
    </Wrapper>
  );
};

export default VideoIntroCall;
