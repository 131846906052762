import { Icon } from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import filter from '@/assets/icons/filter.svg';
import Menu from '@/common/core/Menu';
import {
  AddNewCategoryCTA,
  MenuIcon,
  StyledMenuItem,
  SubMenu,
} from '../ResourcesCategory/CommonStyles';
import { IAddMenu } from '@/types';

const AddMenu = ({
  categoryName,
  anchorEl,
  toggleHandler,
  addCategoryClickHandler,
  addTagClickHandler,
}: IAddMenu) => {
  return (
    <Menu anchorEl={anchorEl} toggleMenu={toggleHandler} vertical="bottom" horizontal="right">
      <SubMenu onClick={addCategoryClickHandler}>
        <AddNewCategoryCTA>
          <StyledMenuItem>Create new subcategory in</StyledMenuItem>
          <StyledMenuItem>'{categoryName}'</StyledMenuItem>
        </AddNewCategoryCTA>
        <CategoryIcon />
      </SubMenu>
      <SubMenu onClick={addTagClickHandler}>
        <StyledMenuItem>Add new tag</StyledMenuItem>
        <Icon>
          <MenuIcon src={filter} alt="Menu icon" />
        </Icon>
      </SubMenu>
    </Menu>
  );
};

export default AddMenu;
