import React from 'react';
import { TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import { AutocompleteWrapper, Clear, StyledAutocomplete, Wrapper } from './styles';
import { IQuestionsForActions, ISurveyTemplate } from '@/types';

const OPTIONS_LIMIT = 10;

const QuestionsForActions = ({
  surveyListActions,
  selectedSurveyActions,
  setSelectedSurveyActions,
}: IQuestionsForActions) => {
  const defaultFilterOptions = createFilterOptions();

  const handleChange = (
    e: React.ChangeEvent<{ name?: string | undefined }>,
    newValue: any,
    index: number,
  ) => {
    const copy = Array.from(selectedSurveyActions);
    copy[index] = newValue;
    setSelectedSurveyActions(copy);
    const last = selectedSurveyActions.length - 1;

    if (newValue) {
      index === last && addItem();
    }
  };

  const filterOptions = (options: any, state: any) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const deleteSurveyAction = (indexToDelete: number) => {
    setSelectedSurveyActions((prev: ISurveyTemplate[]) =>
      prev.filter((item, index) => index !== indexToDelete),
    );
  };

  const addItem = () => {
    setSelectedSurveyActions((prev: ISurveyTemplate[]) => [...prev, null]);
  };

  const isDisabled = (option: any) => {
    return !!selectedSurveyActions.find((selected) =>
      selected ? selected.id === option.id : false,
    );
  };

  return (
    <Wrapper>
      {selectedSurveyActions.map((item, index) => (
        <AutocompleteWrapper key={index}>
          <StyledAutocomplete
            disableClearable={true}
            getOptionDisabled={(option) => isDisabled(option)}
            fullWidth
            filterSelectedOptions={true}
            filterOptions={filterOptions}
            openOnFocus={true}
            options={surveyListActions.sort((a: any, b: any) => b.createdAt - a.createdAt)}
            value={item}
            onChange={(e, newValue) => handleChange(e, newValue, index)}
            id={index.toString()}
            getOptionLabel={(option: any) => option.title.toString()}
            renderInput={(params) => (
              <TextField {...params} label="Questions for Actions" variant="outlined" />
            )}
          />
          {index !== selectedSurveyActions.length - 1 && (
            <Clear as={ClearIcon} onClick={() => deleteSurveyAction(index)} />
          )}
        </AutocompleteWrapper>
      ))}
    </Wrapper>
  );
};

export default QuestionsForActions;
