import { useEffect } from 'react';
import { Switch } from '@mui/material';
import { Container, EndText, LeftBlock, RightBlock, StartText, TitleText } from './styles';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  disableWatch,
  enableWatch,
  getShiftStatusByCoachId,
  releaseCoachWatchingCustomer,
  updateShiftStatusByCoachId,
} from '@/store/customers/watcherSlice';

const ChatShift = () => {
  const dispatch = useAppDispatch();
  const { coachData, isTimeStarted } = useAppSelector((state) => state.coachAuth);
  const { shiftStarted } = useAppSelector((state) => state.watcher);

  useEffect(() => {
    dispatch(getShiftStatusByCoachId({ coachId: coachData?.id }));
  }, [coachData?.id]);

  useEffect(() => {
    if (!isTimeStarted) {
      dispatch(releaseCoachWatchingCustomer({ coachId: coachData?.id }));
      dispatch(disableWatch());
    }
  }, [isTimeStarted, coachData?.id]);

  const handleShiftToggle = () => {
    if (!shiftStarted) {
      dispatch(enableWatch());
    }
    dispatch(updateShiftStatusByCoachId({ coachId: coachData?.id, shiftStarted: !shiftStarted }));
  };

  return (
    <Container>
      <LeftBlock>
        <TitleText>My Shift</TitleText>
      </LeftBlock>
      <RightBlock>
        <div>
          <EndText>End</EndText>
        </div>
        <div>
          <Switch
            disabled={isTimeStarted}
            checked={shiftStarted}
            onChange={handleShiftToggle}
            color="secondary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            sx={{
              root: {
                margin: 0,
              },
            }}
          />
        </div>
        <div>
          <StartText>Start</StartText>
        </div>
      </RightBlock>
    </Container>
  );
};

export default ChatShift;
