import Modal from '..';
import {
  ButtonBlock,
  ButtonSubmit,
  Container,
  NoteBlock,
  NoteText,
  TitleBlock,
  TitleText,
} from './styles';
import { IModalInform } from '@/types';

// duplicate of ModalConfirm.. TODO: Remove duplication
const ModalInform = ({
  title,
  note,
  isOpenInformModal,
  closeOpenInformModal,
  textSubmit = 'OK',
}: IModalInform) => {
  return (
    <Modal widthMax={320} title="" open={isOpenInformModal} handleClose={closeOpenInformModal}>
      <Container>
        <TitleBlock>{title && <TitleText align="center">{title}</TitleText>}</TitleBlock>
        {note && (
          <NoteBlock>
            <NoteText>{note}</NoteText>
          </NoteBlock>
        )}
        <ButtonBlock>
          <ButtonSubmit variant="contained" onClick={closeOpenInformModal}>
            {textSubmit}
          </ButtonSubmit>
        </ButtonBlock>
      </Container>
    </Modal>
  );
};

export default ModalInform;
