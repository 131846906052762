import { useState } from 'react';
import { v4 } from 'uuid';
import { AVATAR_FILES_DIR } from '@/config';
import { db, storage } from '@/utils/firebase/firebaseInit';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getCoachData } from '@/store/coaches/coachAuthSlice';
import { updateDoc, doc } from 'firebase/firestore';
import { deleteObject, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export const useProfileAvatar = () => {
  const dispatch = useAppDispatch();
  const { coachData } = useAppSelector((state) => state.coachAuth);
  const [progress, setProgress] = useState<number>(0);
  const [url, setUrl] = useState<string>('');

  const updateAvatar = (file: any, user: any = {}) => {
    const fileNameParts = file.name.split('.');
    const imgFileNameNew = v4() + '.' + fileNameParts[fileNameParts.length - 1];

    try {
      const uploadRef = ref(storage, `${AVATAR_FILES_DIR}/` + imgFileNameNew);
      const uploadTask = uploadBytesResumable(uploadRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot: any) => {
          const progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error: any) => {
          throw error;
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url: string) => {
            if (user?.id || coachData?.id) {
              setUrl(url);
              await updateDoc(doc(db, 'users', user?.id ?? coachData?.id), {
                avatarUrl: url,
              });
              if (!user?.id && coachData) {
                dispatch(getCoachData({ uid: coachData.uid }));
              }
              if (user?.avatarUrl || coachData?.avatarUrl) {
                const docRef = ref(storage, user?.avatarUrl ?? coachData?.avatarUrl);
                await deleteObject(docRef);
              }
            }
          });
        },
      );
    } catch (error) {
      console.error('error', error);
    }
  };

  return { updateAvatar, progress, url };
};
